/* eslint-disable react/button-has-type */
import React from 'react';
import Logo from 'assets/icons/new-logo.svg';
import {
	Image,
	Nav,
	Navbar,
	Container,
	Offcanvas,
	DropdownButton,
	Dropdown,
	Button,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from 'helpers/contexts/AuthContext';
import GeneralProfile from 'components/Profile/GeneralProfile';
import ReportsForm from 'pages/reports/ReportsForm';
import Company from 'pages/company/CompanyModal';
import {
	ProfileContainer,
	SidebarMobileContainer,
	MobileNav,
} from './sidebar.styled';
import { LogoutConfirmation } from './Sidebar';

const alertManagementRoute = {
	title: 'Alerts',
	toLink: '/alerts',
	disable: false,
};

const LINKS = [{ title: 'Home', toLink: '/dashboard', disable: false }];

const ADMIN_LINKS = [
	{ title: 'Users', toLink: '/users', disable: false },
	{ title: 'Equipment', toLink: '/equipments', disable: false },
	{
		...alertManagementRoute,
	},
	{ title: 'Projects', toLink: '/projects', disable: false },
	{ title: 'Security Camera View', toLink: '/camera-feed', disable: false },
	{ title: 'Settings', toLink: '/settings', disable: false },
	// { title: 'Company', toLink: '/company', disable: true },
	// { title: 'Reports', toLink: '/reports', disable: true },
];

const resources = {
	title: 'Resources',
	href: 'https://posi-stop.com/support.php',
	disable: false,
};

const MenuButton = styled(Button)`
	width: 100%;
	max-width: none !important;
	text-align: left;
	margin-top: 0.5rem;
	display: block;
	color: black;
	font-weight: 500;
	border-radius: 0.25rem;
	padding: 0.8rem 1rem;
`;

function Header() {
	const { logout, user } = useAuth();
	const [showReportModal, setShowReportModal] = React.useState(false);
	const [showCompanyModal, setShowCompanyModal] = React.useState(false);
	const [logoutModal, setLogoutModal] = React.useState(false);
	const [expanded, setExpanded] = React.useState(false);
	const handleCloseDrawer = () => {
		setExpanded(false);
	};
	const handleOpenLogoutModal = () => {
		handleCloseDrawer();
		setLogoutModal(true);
	};
	const handleCloseLogoutModal = () => {
		setLogoutModal(false);
	};
	const handleOpenShowReportModal = () => {
		setShowReportModal(true);
		handleCloseDrawer();
	};
	const handleCloseShowReportModal = () => {
		setShowReportModal(false);
	};
	const handleOpenShowCompanyModal = () => {
		setShowCompanyModal(true);
		handleCloseDrawer();
	};
	const handleCloseShowCompanyModal = () => {
		setShowCompanyModal(false);
	};
	const handleLogout = (e) => {
		e.preventDefault();
		logout();
	};

	const AdminAccess =
		user.roles.isAdminUser ||
		user.roles.isManagerUser ||
		user.roles.isRigseyeUser ||
		user.roles.isDeveloper;

	return (
		<>
			<LogoutConfirmation
				logout={handleLogout}
				show={logoutModal}
				close={handleCloseLogoutModal}
			/>
			<ReportsForm
				show={showReportModal}
				handleClose={handleCloseShowReportModal}
				multi
			/>
			<Company
				show={showCompanyModal}
				handleClose={handleCloseShowCompanyModal}
			/>
			<MobileNav bg="white" expand={false} expanded={expanded}>
				<Container fluid>
					<Navbar.Toggle
						aria-controls="offcanvasNavbar"
						onClick={() => setExpanded(true)}
					/>
					<Navbar.Offcanvas
						id="offcanvasNavbar"
						aria-labelledby="offcanvasNavbarLabel"
						placement="start"
					>
						<Offcanvas.Header closeButton onHide={handleCloseDrawer}>
							<Offcanvas.Title id="offcanvasNavbarLabel" />
						</Offcanvas.Header>
						<Offcanvas.Body>
							<SidebarMobileContainer>
								<Link to="/dashboard" onClick={handleCloseDrawer}>
									<div className="logo d-flex justify-content-between">
										<Image src={Logo} title="logo" alt="logo" />
									</div>
								</Link>
								<ProfileContainer>
									<div className="profile-img">
										<Image
											src="/images/profile.png"
											alt="profile"
											title="profile"
										/>
									</div>
									<div className="details">
										<h2>{`${user.name} ${user.middlename} ${user.lastname}`}</h2>
										<h3>{user.username}</h3>
									</div>
									<DropdownButton title="">
										<GeneralProfile onClick={handleCloseDrawer} />
										<Dropdown.Item onClick={handleOpenLogoutModal}>
											Logout
										</Dropdown.Item>
									</DropdownButton>
								</ProfileContainer>
								<ul className="sidebar-nav">
									{LINKS.map((alink) => (
										<CustomNavLink
											{...alink}
											key={alink.title}
											active={window.location.pathname === alink.toLink}
											disable={alink.disable}
											onClick={handleCloseDrawer}
										/>
									))}
									{!AdminAccess && (
										<CustomNavLink
											title={alertManagementRoute.title}
											toLink={alertManagementRoute.toLink}
											active={
												window.location.pathname === alertManagementRoute.toLink
											}
											onClick={handleCloseDrawer}
											disable={alertManagementRoute.disable}
										/>
									)}

									{AdminAccess &&
										ADMIN_LINKS.map((alink) => (
											<CustomNavLink
												{...alink}
												key={alink.title}
												active={window.location.pathname === alink.toLink}
												disable={alink.disable}
												onClick={handleCloseDrawer}
											/>
										))}

									{AdminAccess && (
										<>
											<MenuButton
												variant="transparent"
												onClick={handleOpenShowCompanyModal}
											>
												Company
											</MenuButton>
											<MenuButton
												variant="transparent"
												onClick={handleOpenShowReportModal}
											>
												Reports
											</MenuButton>
										</>
									)}

									<CustomNavLink
										title={resources.title}
										key={resources.title}
										href={resources.href}
										disable={resources.disable}
										onClick={handleCloseDrawer}
									/>
								</ul>
								{/* <div className="moontek-widget">
									<span>
										Developed by{' '}
										<a
											href="https://www.micro1.io"
											rel="external noreferrer"
											target="_blank"
										>
											micro1.io
										</a>
									</span>
								</div> */}
							</SidebarMobileContainer>
						</Offcanvas.Body>
					</Navbar.Offcanvas>
					<Navbar.Brand href="/dashboard">
						<Image src={Logo} height={40} title="logo" alt="logo" />
					</Navbar.Brand>
				</Container>
			</MobileNav>
		</>
	);
}

export default Header;

function CustomNavLink({ title, toLink, active, disable, href, onClick }) {
	const style =
		disable === true ? { pointerEvents: 'none' } : { cursor: 'pointer' };
	return title === 'Resources' ? (
		<li key={title}>
			<Nav.Link
				to={href}
				href={href}
				target="_blank"
				rel="noopener noreferrer"
				className={`${'link'} ${active && !disable ? 'active' : ''}`}
				onClick={onClick}
			>
				<span>{title}</span>
			</Nav.Link>
		</li>
	) : (
		<li key={title}>
			<Nav.Link
				style={style}
				as={Link}
				to={toLink}
				href={href}
				className={`${'link'} ${active && !disable ? 'active' : ''}`}
				onClick={onClick}
			>
				<span>{title}</span>
			</Nav.Link>
		</li>
	);
}
