import ActivityBlockPosition from 'pages/details/activityCharts/ActivityBlockPosition';
import ActivityDrawworks from 'pages/details/activityCharts/ActivityDrawworks';
import ActivityPump from 'pages/details/activityCharts/ActivityPump';
import ActivityTong from 'pages/details/activityCharts/ActivityTong';
import ActivityWeightChart from 'pages/details/activityCharts/ActivityWeightChart';
import ActivityWell from 'pages/details/activityCharts/ActivityWell';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';

const ActivityTable = styled.div`
	position: absolute;
	overflow: scroll;
	@media (min-width: 1281px) {
		height: 74%;
		width: 92%;
	}
	@media (max-width: 1280px) {
		height: 70%;
		width: 93%;
	}
	@media (max-width: 1180px) {
		height: 65%;
		width: 88%;
	}
	@media (max-width: 820px) {
		height: 65%;
		width: 83%;
	}
	@media (max-width: 390px) {
		height: 54%;
		width: 99%;
	}
`;
export default function ChartActivity() {
	return (
		<ActivityTable>
			<ActivityWeightChart />
			<ActivityBlockPosition />
			<ActivityDrawworks />
			<ActivityTong />
			<ActivityWell />
			<ActivityPump />
		</ActivityTable>
	);
}
