import styled from 'styled-components';

export const CreateProjectContainer = styled.div`
	.row {
		gap: 1rem 0rem;
	}

	input {
		background: #f3f3f3;
		border-radius: 4px;
		border: none;
		height: 48px;
		padding: 0.75rem 1.125rem;
		width: 100%;
		/* margin-bottom: 1.5rem; */
	}

	select {
		background-color: #f3f3f3;
		border-radius: 4px;
		border: none;
		height: 48px;
		padding: 0.75rem 1.125rem;
		width: 100%;
	}

	button {
		background: #f3f3f3;
		border: none;
		height: 48px;
		color: ${({ theme }) => theme.colors.loginPrimary};
	}

	.inputs {
		margin-bottom: 5rem;

		label {
			width: 100%;
		}
	}

	.step-view {
		color: #777777;
		font-weight: 200;
		font-size: 2rem;
		margin-left: 0.5rem;
	}

	.create-project-header {
		cursor: pointer;
		max-width: fit-content;
		.dashboard-back-link {
			text-decoration: none;
			color: black;
			padding-right: 1.25rem;
			border-right: 1px solid #dddddd;
		}
		.project-link {
			text-decoration: none;
			color: black;
			padding-left: 0.75rem;
		}
	}

	.process {
		position: fixed;
		bottom: 0px;
		right: 0px;
		background: white;
		box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
		padding: 1.125rem;
		width: 104vw;

		.primary-button {
			background: #00834d;
			border-radius: 12px;
			color: white;
			border: none;
			padding: 0.75rem 1.75rem;
			margin-right: 1rem;
			max-width: fit-content;
			/* margin-left: auto; */
		}

		.alt-button {
			background: white;
			border-radius: 12px;
			color: black;
			border: 1px solid #00834d;
			padding: 0.75rem 1.75rem;
			margin-right: 1rem;
			max-width: fit-content;
			margin-left: auto;
		}
	}
`;

export const LogContainer = styled.div`
	/* margin: ${(props) =>
		props.isTablet || props.isMobile
			? '1rem 1rem'
			: '2rem 2rem 2rem 18rem;'}; */

	.delete {
		color: #f72b2b;
	}

	.create {
		color: #3d58e7;
	}

	.successful {
		color: #00834d !important;
		background: #d0ffd5 !important;
		border-radius: 4px !important;
		padding: 0.25rem 0.4375rem;
		width: fit-content;
	}

	.fail {
		color: #fa0000 !important;
		background: #ffdbdb !important;
		border-radius: 4px !important;
		padding: 0.25rem 0.4375rem;
		width: fit-content;
	}

	.time {
		color: #4d4d4d !important;
	}

	thead,
	tbody {
		border: none !important;
	}

	thead {
		font-size: 0.875rem;
		color: #818181;
		background: #f3f3f3;
		border-radius: 4px;

		th {
			font-weight: normal;
		}
	}

	tr {
		font-size: 0.875rem;
	}

	tbody {
		tr {
			background: white !important;

			td {
				background: white !important;
				border-bottom: 1px solid #dddddd;
			}
		}
	}

	.table {
		--bs-table-striped-bg: white;
	}

	tr {
		height: 56px;
		vertical-align: middle;
		border: none;

		th,
		td {
			border: none;
		}
	}
`;

export const ProjectInformationContainer = styled.div`
	.information {
		background: #ffffff;
		box-shadow: 0px 9px 36px rgba(0, 0, 0, 0.06);
		border-radius: 12px;
		display: flex;
		font-family: Poppins;

		.green,
		button {
			all: unset;
			cursor: pointer;
			font-family: Poppins;
			font-style: normal;
			font-weight: 500;
			font-size: 0.875rem;
			color: #00834d;
			border-right: 1px solid #dddddd;
			padding-right: 1rem;
			margin-right: 1rem;
		}

		.values {
			font-family: Poppins;
			font-weight: normal;
			font-size: 1rem;
		}

		.default-logo {
			object-fit: contain;
			background: #f5f5f5;
			border-radius: 11px;
			/* max-width: fit-content; */
			text-align: center;
			display: grid;
			align-content: center;
			font-family: Poppins;
			font-style: normal;
			font-weight: 600;
			font-size: 0.75rem;
			height: 60px;
			width: 60px;
		}

		.project-name {
			font-family: Poppins;
			font-style: normal;
			font-weight: 600;
			font-size: 1.125rem;
		}

		.tags {
			margin-right: 8rem;
			font-family: Poppins;
			font-style: normal;
			font-weight: 500;
			font-size: 0.875rem;
			opacity: 0.4;
		}

		.information-row {
			display: flex;
			flex-wrap: wrap;

			div {
				display: grid;
			}
		}
	}

	@media (max-width: 450px) {
		.information {
			display: block;

			.green {
				margin-top: 0.5rem;
			}

			.values,
			.project-name {
				margin-bottom: 1rem;
			}
		}

		.information-row {
			margin-top: 1rem;
		}
	}
`;

export const DetailContainer = styled.div`
	/* margin: ${(props) =>
		props.isTablet || props.isMobile
			? '1rem 1rem'
			: '2rem 2rem 2rem 18rem;'}; */
`;

export const CardContainer = styled.div`
	background: #ffffff;
	box-shadow: 0px 9px 36px rgba(0, 0, 0, 0.06);
	border-radius: 12px;
	margin-top: 1.75rem;
	padding: 1.25rem 1.5rem;
	height: 247px;

	.no-result {
		opacity: 0.4;
		font-weight: 500;
		font-size: 0.875rem;
		display: grid;
		place-content: center;
		/* margin-top: auto; */
		height: 100%;
	}

	.green {
		cursor: pointer;
		font-family: Poppins;
		font-style: normal;
		font-weight: 500;
		font-size: 0.875rem;
		color: #00834d;
		/* margin-right: 1rem; */
		border: none;
		background: none;
	}

	.header {
		display: flex;
		align-items: center;

		h2 {
			font-weight: 600;
			font-size: 1rem;
			margin-bottom: 0px;
		}
	}

	@media (max-width: 450px) {
		.header {
			display: block;
		}
	}
`;
