import { Image, Accordion, Container } from 'react-bootstrap';
import { ReactComponent as Logo } from 'assets/icons/new-logo.svg';
import browsers from 'assets/img/browsers.svg';
import {
	LoginContainer,
	LogoDiv,
	LoginDiv,
	TOS,
	TOSItem,
	TOSHeader,
	FormContainer,
	Note,
} from 'pages/login/login.styled';
import useResponsive from 'helpers/hooks/useResponsive';
import metadata from 'metadata/metadata.json';
import * as texts from '../../helpers/texts/tc';

function AuthLayout({ children }) {
	const { isMobile } = useResponsive();

	return (
		<div>
			<Container>
				{/* fix the error on console */}
				<LoginContainer $isMobile={isMobile}>
					<FormContainer>
						<LogoDiv>
							<Logo className='w-36 h-10' />
						</LogoDiv>
						<LoginDiv>{children}</LoginDiv>
					</FormContainer>

					<TOS>
						<TOSItem eventKey={1}>
							<TOSHeader>Terms of Use</TOSHeader>
							<Accordion.Body>
								<p style={{ textAlign: 'justify' }}>{texts.termsText}</p>
								{'- '}
								{texts.supportTeam}
								<br />
								{'- '}{' '}
								<a href={texts.supportEmailMailTo}>{texts.supportEmail}</a>
							</Accordion.Body>
						</TOSItem>
					</TOS>

					<Note>
						<h1>
							NOTE:
							<span className="fw-normal ms-1">
								This web app works best with the following compatible browsers:
							</span>
						</h1>
						<Image src={browsers} alt="broswer-support" title="broswers" />
						<h2>
							Other browsers may not be compatible or fully functional with this
							web app.
							<br /> <br /> Disable ad blocker on this site for better
							usability.
						</h2>
					</Note>
					<br />
					<span style={{ fontSize: '0.65rem', fontStyle: 'italic' }}>
						RigsEye {metadata.buildTag}
					</span>
				</LoginContainer>
			</Container>
		</div>
	);
}

export default AuthLayout;
