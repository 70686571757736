import { ReactComponent as PosiStop } from 'assets/icons/posistop.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/video.svg';
import styled from 'styled-components';

export const Info = styled.div`
	position: absolute;
	bottom: 0em;
	color: white;
	text-shadow:
		-1px 0 black,
		0 1px black,
		1px 0 black,
		0 -1px black;
	width: 94%;
	top: 1rem;
	bottom: 0;
	padding: 0.25rem;
`;

export const Camstatus = styled.span`
	span {
		margin-right: 0.5em;
	}
	.online svg {
		fill: #00854b;
	}

	.offline svg {
		fill: #ff0000;
	}
`;

export const PosiInfo = styled.div`
	// position: absolute;
	// margin-left: 1em;
	// z-index:1;
	// bottom: 6.5em;
	height: 88%;

	.tooltip-button {
		&:hover ~ .tooltip-text {
			display: block;
		}
	}
	.posi-status {
		position: absolute;
		height: 1em;
		width: 1em;
		top: 3.625rem;
		left: 6px;
		border-radius: 1em;
		cursor: help;
	}
	.posi-status.On {
		background: green;
	}
	.posi-status.Off {
		background: red;
	}
	.posi-status.Offline {
		background: grey;
	}
	.posi-status.static {
		top: auto;
		left: auto;
		display: inline;
	}

	> svg {
		height: 70%;
	}
	.level {
		transition: transform 1s ease-out;
		transform: 'translateY(0px)';
		${(props) => (props.$levelstyle ? props.$levelstyle : '')}
	}
	> span {
		display: block;
		text-shadow:
			-1px 0 black,
			0 1px black,
			1px 0 black,
			0 -1px black;
		color: white;
		font-size: small;
	}
`;

export const Posistatus = styled.span`
	.tooltip-button {
		&:hover {
			background: #fcfcfc90;
		}
		&:hover + .tooltip-text {
			display: block;
		}
	}
	.posi-status {
		// position: absolute;
		height: 1em;
		width: 1em;
		top: 3.625rem;
		left: 6px;
		border-radius: 1em;
		cursor: help;
	}
	.posi-status.On {
		background: green;
	}
	.posi-status.Off {
		background: red;
	}
	.posi-status.Offline {
		background: grey;
	}
	.posi-status.static {
		top: auto;
		left: auto;
		display: inline;
	}
	.text {
		padding-left: 1.5em;
		display: inline;
		position: relative;
		top: -4px;
	}
	span {
		display: none;
	}
	.tooltip-container {
		position: relative;
		display: flex;
		place-content: center;
	}

	p.tooltip-text {
		display: none;
		position: absolute;
		top: 0px;
		z-index: 1;
		background: #fcfcfc90;
		padding: 8px;
		font-size: 1rem;
		color: #fff;
		border-radius: 2px;
		animation: fadeIn 0.6s;
		left: 3em;
		height: 75px;
	}

	// p.tooltip-text:before {
	//   content: "";
	//   position: absolute;
	//   top: 38%;
	//   left: 0%;
	//   margin-left: -15px;
	//   border: 8px solid transparent;
	//   border-right: 8px solid #FCFCFC90;
	// }

	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`;

export const getStatus = (data) =>
	parseInt(data?.status?.status.flag, 10) === 1;
// const getStatus = (data) => data?.status.flag === 1;

const CameraDetail = ({ item, status, id, data = {} }) => {
	const tooltip = document.querySelector(`.${id}.tooltip-text`);
	const ps = document.querySelector(`.posi-status.${id}`);

	if (tooltip) {
		ps.addEventListener(
			'mouseover',
			() => {
				tooltip.style.display = 'block';
			},
			false,
		);
		ps.addEventListener(
			'mouseleave',
			() => {
				tooltip.style.display = 'none';
			},
			false,
		);
	}

	let level = 192;
	let levelStyle = null;
	//  If the `flag` is `1` then move camera vertical bar. As per old codebase.
	// Commented for verification.
	// if (getStatus(data)) {
	level = data?.positionPercent * 2 || 0;
	if (level > 192) level = 192;
	if (level < 8) level = 0;
	level *= -1;
	// } // End IF
	levelStyle = `
			fill: rgb(180, 33, 35); 
			fill-opacity: 1; stroke: rgb(0, 0, 0); 
			stroke-width: 0; 
			stroke-dasharray: none; 
			stroke-opacity: 1;
			transform: translateY(${level}px)
		`;
	return (
		<Info>
			<Camstatus>
				<span>Cam 1</span>
				<span className={item?.[1]?.cameraData?.[0]?.status}>
					<VideoIcon />
				</span>
			</Camstatus>
			<PosiInfo
				className={'posiInfo '.concat(
					item?.[0].substring(0, item?.[0].length - 3),
				)}
				$levelstyle={levelStyle}
			>
				<div
					className={`tooltip-button posi-status ${
						status ? 'On' : 'Off'
					} `.concat(item?.[0].substring(0, item?.[0].length - 3))}
				/>
				<PosiStop
					className={'posistopbar '.concat(
						item?.[0].substring(0, item?.[0].length - 3),
					)}
				/>
				<Posistatus>
					<p
						className={'tooltip-text '.concat(
							item?.[0].substring(0, item?.[0].length - 3),
						)}
					>
						{/* Replace <div> tag with <span> as we can not use <div> inside <p> tag */}
						<span>
							<span className="static posi-status On" />
							<span className="text">Posi-Stop On</span>
						</span>
						<span>
							<span className="static posi-status Off" />
							<span className="text">Posi-Stop Off</span>
						</span>
						<span>
							<span className="static posi-status Offline" />
							<span className="text">Posi-Stop Offline</span>
						</span>
					</p>
					<span
						className={'cam_'.concat(
							item?.[0].substring(0, item?.[0].length - 3),
						)}
					>
						Posi-Stop: -
					</span>
				</Posistatus>
				<span>
					{' '}
					<span
						className={'rpm rpm_'.concat(
							item?.[0].substring(0, item?.[0].length - 3),
						)}
					>
						{data?.speedFeetPerMinute || '00'}
					</span>{' '}
					RPM
				</span>
				<span>
					{' '}
					<span
						className={'weight weight_'.concat(
							item?.[0].substring(0, item?.[0].length - 3),
						)}
					>
						{data?.weight_on_hook || '-'}
					</span>{' '}
					lbs x 1000
				</span>
			</PosiInfo>
		</Info>
	);
};

export default CameraDetail;
