import { ReactComponent as Message404 } from 'assets/img/404messages.svg';
import { useRigDetail } from 'helpers/contexts/RigDetailProvider';
import DetailsCard from 'pages/details/DetailsCard';
import styled from 'styled-components';

const MessageWrapper = styled(DetailsCard)`
	.no-message-text {
		color: ${({ theme }) => theme.colors.gray3};
		font-size: 0.857rem;
	}
`;

export default function Messages() {
	const { data }: any = useRigDetail();

	const messages = data?.status?.messages?.filter(
		(message) => message.msgPage === 'posistop',
	);

	return data?.status?.messages?.length === 0 ? (
		<MessageWrapper
			className="cards cards-full-height"
			title="Today's Messages"
			print
			noalign
		>
			<div className="d-grid">
				<Message404 className="event-404" />
				<span className="text-center mt-3 no-message-text">No Messages</span>
			</div>
		</MessageWrapper>
	) : (
		<MessageWrapper
			className="cards cards-full-height"
			title="Today's Messages"
			print
			noalign
			style={{ overflowY: 'scroll' }}
		>
			{messages?.map((msg, item) => (
				<div key={msg[item]}>
					<span className="date">{msg.date.split(' ')[1]}</span>
					<span className="data">{msg.message}</span>
				</div>
			))}
		</MessageWrapper>
	);
}
