export const theme = {
	borderRadius: {
		sm: '5px',
		md: '10px',
		lg: '15px',
		xl: '20px',
	},
	fontSize: {
		sm: '0.8rem',
		md: '1rem',
		lg: '1.2rem',
		xl: '1.4rem',
	},

	colors: {
		main: '#343a40',
		secondary: 'magenta',
		primary: '#00834d',
		red: '#E04321',
		loginPrimary: '#00834d',
		activePrimary: '#C9EFDF',
		backgroundLightGray: '#f6f6f6',
		backgroundDarkGray: '#f2f2f2',
		warningText: '#CD8F55',
		warning: '#FFF1DB',
		successText: '#00834D',
		success: '#D0FFD5',
		deactiveText: '#4F4F4F',
		deactive: '#EAEAEA',
		tableHeader: '#2C2C2C',
		calibrated: '#AD00FF',
		lightGray: '#818181',
		gray: '#9AA2AC',
		gray2: '#dddddd',
		gray3: '#737272',
		white: '#ffffff',
		black: '#000000',
		borderHightLight: '#0035bf',
	},
};
