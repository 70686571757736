import DetailsCard from 'pages/details/DetailsCard';
import { ReactComponent as Message404 } from 'assets/img/404messages.svg';
import styled from 'styled-components';

const MessageWrapper = styled(DetailsCard)`
	.no-message-text {
		color: ${({ theme }) => theme.colors.gray3};
		font-size: 0.857rem;
	}
`;

export default function Messages({ data }) {
	return (
		<MessageWrapper className="cards" title="Today's Messages" print noalign>
			{data?.status?.messages?.length === 0 ? (
				<div className="d-grid">
					<Message404 className="event-404" />
					<span className="text-center mt-3 no-message-text">No Messages</span>
				</div>
			) : (
				data?.status?.messages?.map((msg) => (
					<div>
						<span className="date">{msg.date.split(' ')[1]}</span>
						<span key={msg} className="data">
							{msg?.message}
						</span>
					</div>
				))
			)}
		</MessageWrapper>
	);
}
