import { Form, Row, Col, InputGroup, FormControl } from 'react-bootstrap';
import React, { useEffect, useState, useRef } from 'react';
import {
	createCustomProjectId,
	getCustomers,
} from 'helpers/api/projectManagement';
import MapPicker from 'react-google-map-picker';
// import DatePicker from 'react-datepicker';
// import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormError from 'components/FormError';
// import moment from 'moment';
import { showError } from 'helpers/utils/misc';
import { GOOGLE_MAP_API_KEY } from 'helpers/const';
import styled from 'styled-components';
// import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import AddCustomerForm from './AddCustomerForm';

const FormWrapper = styled(Form)`
	.customer-add-button,
	.customer-remove-button {
		background-color: transparent;
		height: unset;
		font-weight: 500;
		font-size: 14px;
	}
	.customer-remove-button {
		color: red;
	}
	.calendar-icon {
		position: absolute;
		margin-left: -2rem;
		margin-top: 0.75rem;
	}
`;

const DefaultLocation = { lat: 44.045213, lng: -123.081602 };

function MapComponent({ onChange }) {
	return (
		<MapPicker
			defaultLocation={{ lat: Number(44.045213), lng: Number(-123.081602) }}
			zoom={4}
			mapTypeId="roadmap"
			style={{ height: 300, maxWidth: 725, borderRadius: 12 }}
			onChangeLocation={onChange}
			apiKey={GOOGLE_MAP_API_KEY}
		/>
	);
}
function ProjectInfoForm({ next, update, formData }) {
	const [customId, setCustomId] = useState('');
	const [location, setLocation] = useState(DefaultLocation);
	const mountedRef = useRef(false);
	const [showMap, setShowMap] = useState(false);
	const [customerList, setCustomerList] = useState([]);
	const [customerModal, setCustomerModal] = useState(false);

	useEffect(() => {
		mountedRef.current = true;
		return () => {
			mountedRef.current = false;
		};
	});
	useEffect(() => {
		const timer = setTimeout(() => {
			if (mountedRef.current) {
				setShowMap(true);
			}
		}, 1000);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	const schema = yup
		.object({
			pprojectid: yup.string(),
			pname: yup.string().required('Project Name is required'),
			pbegdate: yup.string().required('Start Date is required'),
			penddate: yup.string().required('End Date is required'),
			pwellid: yup.string().required('API Well ID is required'),
			pclient: yup.string().required('Customer is required'),
			pcoordinates: yup.string().default(`${location.lat}, ${location.lng}`),
			pwellname: yup.string().required('Well Name is required'),
			pwellnumber: yup.string().required('Well Number is required'),
			ppadname: yup.string().required('Pad Name is required'),
			pafe: yup.string().required('AFE is required'),
			ptubingsize: yup.string().required('Tubing Size is required'),
			punit: yup.string().required('Unit is required'),
			passembly_length: yup.string().required('Assembly Length is required'),
			pfloor_height: yup.string().required('Floor Height is required'),
			pkb_height: yup.string().required('KB Height is required'),
		})
		.required();

	const { register, handleSubmit, formState, setValue, reset, watch } = useForm(
		{
			resolver: yupResolver(schema),
		},
	);

	const handleChangeLocation = (lat, lng) => {
		setLocation({ lat, lng });
	};

	const onSubmit = (data) => {
		// validate
		const updatedData = {
			...data,
			pprojectid: customId,
		};
		update(updatedData);
		if (updatedData.pbegdate < updatedData.penddate) {
			next();
		} else {
			showError('Start date must be before end date');
		}
	};

	const { errors } = formState;

	const handleCustomId = () => {
		createCustomProjectId().then((res) => {
			setCustomId(res);
		});
	};

	const fetchCustomers = () => {
		getCustomers().then((res) => {
			if (
				res?.data &&
				res?.data?.clientsByCustomerId &&
				res?.data?.clientsByCustomerId?.length > 0
			) {
				setCustomerList(res?.data?.clientsByCustomerId);
			}
		});
	};

	const handleAddCustomer = () => {
		setCustomerModal(true);
	};
	const handleAddCustomerClose = () => {
		setCustomerModal(false);
	};
	const handleSaveCustomer = () => {
		fetchCustomers();
		handleAddCustomerClose();
	};
	const handleRemoveCustomer = () => {
		setValue('pclient', '');
	};

	useEffect(() => {
		// Get customers to populate the select box on customer field.
		fetchCustomers();
		handleCustomId();
		reset(formData);
	}, []);

	return (
		<FormWrapper onSubmit={handleSubmit(onSubmit)}>
			<>
				<p className="mb-3" style={{ opacity: 0.8 }}>
					To create a new Project ID please leave the field empty and click set.
				</p>
				<Row className="inputs">
					<Col lg={3}>
						Project ID*
						<InputGroup>
							<FormControl
								placeholder="Project ID"
								aria-label="pprojectid"
								aria-describedby="project id"
								{...register('pprojectid')}
								value={customId}
								disabled
								autoFocus
							/>
						</InputGroup>
						<FormError>{errors.pprojectid?.message}</FormError>
					</Col>
					<Col lg={3}>
						<span>Start Date*</span>
						<FormControl
							type="date"
							aria-label="pbegdate"
							aria-describedby="start date"
							{...register('pbegdate')}
						/>
						<FormError>{errors.pbegdate?.message}</FormError>
					</Col>
					<Col lg={3}>
						<span>End Date*</span>
						<FormControl
							type="date"
							aria-label="penddate"
							aria-describedby="end date"
							{...register('penddate')}
						/>
						<FormError>{errors.penddate?.message}</FormError>
					</Col>
					<Col lg={3}>
						<label htmlFor="pname">
							Project Name
							<input
								{...register('pname')}
								id="pname"
								type="text"
								placeholder="Project Name"
							/>
						</label>
						<FormError>{errors.pname?.message}</FormError>
					</Col>
					<Col lg={3}>
						<label htmlFor="pwellid">
							API Well ID
							<input
								{...register('pwellid')}
								id="pwellid"
								type="text"
								placeholder="API Well ID"
							/>
						</label>
						<FormError>{errors.pwellid?.message}</FormError>
					</Col>
					<Col lg={3}>
						<label htmlFor="pclient">
							<div className="d-flex justify-content-between align-items-center">
								<span>Customer</span>
								<div className="d-flex">
									<button
										type="button"
										className="customer-add-button"
										onClick={handleAddCustomer}
									>
										Add
									</button>
									{watch('pclient') === '' ? null : (
										<button
											type="button"
											className="customer-remove-button"
											onClick={handleRemoveCustomer}
										>
											Remove
										</button>
									)}
								</div>
							</div>
							<select {...register('pclient')}>
								<option value="" disabled selected>
									--Select a customer--
								</option>
								{customerList.map((customer) => (
									<option
										key={customer.clid}
										value={customer.clid}
										selected={customer?.clid === formData?.pclient}
									>
										{customer.clname}
									</option>
								))}
							</select>
						</label>

						<FormError>{errors.pclient?.message}</FormError>
					</Col>

					<Col lg={3}>
						<label htmlFor="pwellname">
							Well Name
							<input
								{...register('pwellname')}
								id="pwellname"
								type="text"
								placeholder="Well Name"
							/>
						</label>
						<FormError>{errors.pwellname?.message}</FormError>
					</Col>
					<Col lg={3}>
						<label htmlFor="pwellnumber">
							Well Number
							<input
								{...register('pwellnumber')}
								id="pwellnumber"
								type="text"
								placeholder="Well Number"
							/>
						</label>
						<FormError>{errors.pwellnumber?.message}</FormError>
					</Col>
					<Col lg={3}>
						<label htmlFor="ppadname">
							Pad name
							<input
								{...register('ppadname')}
								id="ppadname"
								type="text"
								placeholder="Pad Name"
							/>
						</label>
						<FormError>{errors.ppadname?.message}</FormError>
					</Col>
					<Col lg={3}>
						<label htmlFor="pafe">
							AFE
							<input
								{...register('pafe')}
								id="pafe"
								type="text"
								placeholder="AFE"
							/>
						</label>
						<FormError>{errors.pafe?.message}</FormError>
					</Col>
					<Col lg={3}>
						<label htmlFor="ptubingsize">
							Tubing Size
							<input
								{...register('ptubingsize')}
								id="ptubingsize"
								type="number"
								placeholder="Tubing Size"
								min={0}
								onKeyDown={(evt) =>
									['-'].includes(evt.key) && evt.preventDefault()
								}
							/>
						</label>
						<FormError>{errors.ptubingsize?.message}</FormError>
					</Col>
					<Col lg={3}>
						<Form.Group>
							<Form.Label>Measure Unit</Form.Label>
							<Form.Select {...register('punit')}>
								<option value="unit" disabled selected>
									--Select a value--
								</option>
								<option value="BPM">BPM</option>
								<option value="GPM">GPM</option>
							</Form.Select>
						</Form.Group>
						<FormError>{errors.punit?.message}</FormError>
					</Col>
					<Col lg={3}>
						<label htmlFor="passembly_length">
							Assembly Length
							<input
								{...register('passembly_length')}
								id="passembly_length"
								type="number"
								step="0.01"
								placeholder="Assembly Length"
								min={0}
								onKeyDown={(evt) =>
									['-'].includes(evt.key) && evt.preventDefault()
								}
							/>
						</label>
						<FormError>{errors.passembly_length?.message}</FormError>
					</Col>
					<Col lg={3}>
						<label htmlFor="pfloor_height">
							Floor Height
							<input
								{...register('pfloor_height')}
								id="pfloor_height"
								type="number"
								step="0.01"
								placeholder="Floor Height"
								min={0}
								onKeyDown={(evt) =>
									['-'].includes(evt.key) && evt.preventDefault()
								}
							/>
						</label>
						<FormError>{errors.pfloor_height?.message}</FormError>
					</Col>
					<Col lg={3}>
						<label htmlFor="pkb_height">
							KB Height
							<input
								{...register('pkb_height')}
								id="pkb_height"
								type="number"
								step="0.01"
								placeholder="KB Weight"
								min={0}
								onKeyDown={(evt) =>
									['-'].includes(evt.key) && evt.preventDefault()
								}
							/>
						</label>
						<FormError>{errors.pkb_height?.message}</FormError>
					</Col>

					<Col lg={3}>
						<label htmlFor="pcoordinates">
							Coordinates
							<input
								type="text"
								value={`${location.lat},${location.lng}`}
								disabled
								id="pcoordinates"
							/>
						</label>
					</Col>

					<Row>
						{showMap ? (
							<MapComponent
								cordinates={DefaultLocation}
								onChange={handleChangeLocation}
							/>
						) : null}
					</Row>
				</Row>
			</>
			<Row className="process">
				<button
					type="submit"
					className="primary-button"
					style={{ marginLeft: 'auto' }}
				>
					Next
				</button>
			</Row>

			<AddCustomerForm
				open={customerModal}
				onClose={handleAddCustomerClose}
				onSave={handleSaveCustomer}
				refetch={fetchCustomers}
			/>
		</FormWrapper>
	);
}

export default ProjectInfoForm;
