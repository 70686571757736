import { ReactComponent as Right } from 'assets/icons/right-arrow.svg';
import DetailsLayout from 'components/Layouts/DashboardLayout';
import { useAuth } from 'helpers/contexts/AuthContext';
import DetailsInfo from 'pages/details/rig/Header';
import { DetailsContainer } from 'pages/details/styled';
import { Nav } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled(DetailsContainer)`
	.back-button {
		padding-bottom: 0.75rem;
		margin-right: 1rem;
	}
	.back-link {
		margin-right: 0.75rem;
		color: ${({ theme }) => theme.colors.black};
		text-decoration: none;
	}
	.nav-view {
		margin-bottom: 1.3rem !important;
		margin-top: 1.3rem;
		flex: 1;

		a {
			margin-right: 1.5rem;
		}
	}
	.cards-full-height {
		min-height: 300px;
		/* height: auto; */
		height: 220px;
		margin-bottom: 8px;
	}
`;
function Details({ children }) {
	const { id } = useParams();
	const location = useLocation().pathname;
	const { user } = useAuth();

	return (
		<DetailsLayout>
			<Wrapper>
				<DetailsInfo />
				<div className="d-flex align-items-center">
					<div className="d-flex align-items-center back-button">
						<Right />
						<Link to="/dashboard" className="back-link">
							<span className="ms-2">BACK</span>
						</Link>
					</div>
					<Nav className="nav-view">
						<Nav.Item>
							<Link
								to={`/rigs/${id}`}
								className={location === `/rigs/${id}` ? 'active' : ''}
							>
								DETAILS
							</Link>
						</Nav.Item>
						<Nav.Item>
							<Link
								to={`/rigs/${id}/charts`}
								className={location === `/rigs/${id}/charts` ? 'active' : ''}
							>
								ACTIVITY TABLE + GRAPHS
							</Link>
						</Nav.Item>
						<Nav.Item>
							<Link
								style={{
									pointerEvents: user.access.includes('can')
										? 'auto'
										: 'none',
								}}
								to={`/rigs/${id}/engine`}
								className={location === `/rigs/${id}/engine` ? 'active' : ''}
							>
								ENGINE
							</Link>
						</Nav.Item>
						<Nav.Item>
							<Link
								style={{
									pointerEvents: user.access.includes('cam') ? 'auto' : 'none',
								}}
								to={`/rigs/${id}/camera`}
								className={location === `/rigs/${id}/camera` ? 'active' : ''}
							>
								CAMERA VIEW
							</Link>
						</Nav.Item>
						<Nav.Item>
							<Link
								style={{
									pointerEvents: user.access.includes('docsys')
										? 'auto'
										: 'none',
								}}
								to={`/rigs/${id}/docsys`}
								className={location === `/rigs/${id}/docsys` ? 'active' : ''}
							>
								DOCSYS
							</Link>
						</Nav.Item>
					</Nav>
				</div>
				{children}
			</Wrapper>
		</DetailsLayout>
	);
}

export default Details;
