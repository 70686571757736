import { gql } from '@apollo/client';

export const TONGS_QUERY = gql`
	query Tongs {
		tongs {
			tongid
			tongname
			tongtype
			air_pressure_max
			torque_max_1
			torque_max_2
		}
	}
`;

export const DELETE_TONG_BY_ID_MUTATION = gql`
	mutation deleteTong($tongId: Float!) {
		deleteTong(tongId: $tongId) {
			success
		}
	}
`;

export const UPDATE_TONG_BY_ID_MUTATION = gql`
	mutation updateTong($tongArgs: EditTongArgs!) {
		updateTong(tongArgs: $tongArgs) {
			success
		}
	}
`;

export const CREATE_TONG_MUTATION = gql`
	mutation createTong($tongArgs: CreateTongArgs!) {
		createTong(tongArgs: $tongArgs) {
			tongid
		}
	}
`;
