/* eslint-disable react/jsx-filename-extension */
import DashboardLayout from 'components/Layouts/DashboardLayout';
import useResponsive from 'helpers/hooks/useResponsive';
import SingleProjectLogList from 'pages/project-logs/SingleProjectLogList';
import SingleProjectLogListMobile from 'pages/project-logs/SingleProjectLogListMobile';

export default function SingleProjectLogs() {
	const { isMobile } = useResponsive();

	return (
		<DashboardLayout>
			{isMobile ? <SingleProjectLogListMobile /> : <SingleProjectLogList />}
		</DashboardLayout>
	);
}
