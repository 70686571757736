import { ReactComponent as VideoIcon } from 'assets/icons/video.svg';
import LogoIcon from 'assets/img/logo.png';
import TooltipHandler from 'components/TooltipHandler';
import styled from 'styled-components';
import PosiHelp from './PosiHelp';
import PosiStopChart from './PosiStopChart';
import { Camstatus, Info, Wrapper } from './styles/CameraDetails.styled';

interface PosiInfoProps {
	levelStyle?: string;
}

const PosiInfo = styled.div<PosiInfoProps>`
	// position: absolute;
	// margin-left: 1em;
	// z-index:1;
	// bottom: 6.5em;
	height: 88%;
	> svg {
		height: 70%;
	}
	.level {
		transition: transform 1s ease-out;
		transform: 'translateY(0px)';
		${(props) => (props.levelStyle ? props.levelStyle : '')}
	}
	> span {
		display: block;
		text-shadow:
			-1px 0 black,
			0 1px black,
			1px 0 black,
			0 -1px black;
		color: white;
		font-size: small;
	}
`;
export default function CameraDetails({
	data,
	handleFullView,
}: {
	data: any;
	handleFullView?: (values: any) => () => void;
}) {
	
	let level = 192;
	//  If the `flag` is `1` then move camera vertical bar. As per old codebase.
	// Commented for verification.
	// if (getStatus(data)) {
	level = data?.positionPercent * 2 || 0;
	if (level > 192) level = 192;
	if (level < 8) level = 0;
	level *= -1;
	// } // End IF
	const levelStyle = `
			fill: rgb(180, 33, 35); 
			fill-opacity: 1; stroke: rgb(0, 0, 0); 
			stroke-width: 0; 
			stroke-dasharray: none; 
			stroke-opacity: 1;
			transform: translateY(${level}px)
		`;

	return (
		<Wrapper
			draggable
			id={data?.equipment}
			onDragStart={(e) => {
				e.dataTransfer.setData('eq', data?.equipment);
				e.dataTransfer.effectAllowed = 'move';
			}}
			onDragEnd={(e) => {
				e.dataTransfer.clearData();
			}}
		>
			<Info onClick={handleFullView && handleFullView(data)}>
				<TooltipHandler content={<PosiHelp />} placement="right">
					<div
						className="status-light"
						style={{
							background:
								// eslint-disable-next-line no-nested-ternary
								data?.camera?.data?.[0]?.status === 'Online'
									? 'green'
									: data?.camera?.data?.[0]?.status === 'Offline'
										? 'red'
										: 'grey',
						}}
					/>
				</TooltipHandler>
				<Camstatus>
					<span>{data?.equipment?.replace('_D', ' Cam ')}</span>
					<span className={data?.camera?.data?.[0]?.status?.toLowerCase()}>
						<VideoIcon />
					</span>
				</Camstatus>
				<PosiInfo>
					<PosiStopChart data={data} />
					<div>
						<span className="rpm rpm_">
							{data?.data?.[0]?.speedFeetPerMinute}
						</span>{' '}
						RPM
					</div>
					<div>
						<span className="weight weight_">
							{data?.data?.[0]?.weight_on_hook}
						</span>{' '}
						lbs x 1000
					</div>
				</PosiInfo>
				<span className="posiInfo speed ">0 MPH</span>
			</Info>

			<button
				type="button"
				className="camera-wrapper"
				aria-label="Click to view camera feed"
				// onClick={handleFullView && handleFullView(data)}
			>
				<img
					src={data?.camera?.data?.[0]?.path || LogoIcon}
					alt=""
					className="camera-img"
					style={{
						objectFit: data?.camera?.data?.[0]?.path ? 'cover' : 'none',
						background: !data?.camera?.data?.[0]?.path ? 'black' : 'white',
					}}
				/>
			</button>
		</Wrapper>
	);
}
CameraDetails.defaultProps = {
	handleFullView: null,
};
