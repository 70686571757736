/* eslint-disable camelcase */
import { getToken } from 'helpers/utils/auth-provider';
import { UPDATE_EQUIPMENT_INFO_RIG_MUTATION } from '../queries/equipmentInfoQuery';
import client from './apolloClient';

export const getEquipmentInfo = (id) =>
	client.get(`/equipments/${id}/info`).then((res) => res.data);

export const updateEquipmentInfoRig = (data) =>
	client.mutate({
		mutation: UPDATE_EQUIPMENT_INFO_RIG_MUTATION,
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
		variables: {
			updateEquipmentInfoRigArgs: { ...data },
		},
	});

export const updateEquipmentInfoPump = (
	id,
	{
		pump_adjusment,
		flow_rate,
		flow_unit,
		max_suction_pressure,
		max_discharge_pressure,
		air_pressure,
		zerothreshold_pump_discharge_pressure,
		zerothreshold_pump_suction_pressure,
	},
) =>
	client
		.put(
			`/equipments/${id}/info`,
			JSON.stringify({
				pump_adjusment,
				flow_rate,
				flow_unit,
				max_suction_pressure,
				max_discharge_pressure,
				air_pressure,
				zerothreshold_pump_discharge_pressure,
				zerothreshold_pump_suction_pressure,
			}),
		)
		.then((res) => res.data);
