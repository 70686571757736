import { getProjectSms } from 'helpers/api/projectManagement';
import { Spinner } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { CardContainer } from 'pages/projectmanagement/project.styled';
import ProjectMessagesBoard from './ProjectMessagesBoard';
// import UploadImages from './UploadImages';

export default function ProjectMessages() {
	const { id } = useParams();

	const { data, isFetching } = useQuery('ProjectMessages', () =>
		getProjectSms(id),
	);

	if (isFetching) {
		return (
			<CardContainer>
				<div className="header">
					<h2>Project Messages</h2>
				</div>
				<div className="no-result" style={{ opacity: 1 }}>
					<Spinner size="sm" animation="border" className="m-auto" />
				</div>
			</CardContainer>
		);
	}

	if (data?.data?.smsDataByProjectId) {
		return (
			<CardContainer>
				<div className="header">
					<h2>Project messages</h2>
					<ProjectMessagesBoard />
					{/* <UploadImages /> */}
				</div>
				<div className="no-result">
					<span>No data found</span>
				</div>
			</CardContainer>
		);
	}

	return (
		<CardContainer>
			<div className="header">
				<h2>Project messages</h2>
				<ProjectMessagesBoard />
				{/* <UploadImages /> */}
			</div>
			<div className="d-grid mt-3">
				{data?.data?.smsDataByProjectId?.map((project) =>
					project?.map((proj) => (
						<span key={proj.id}>
							{proj.equipment}-{proj.username}-{proj.message}
						</span>
					)),
				)}
			</div>
		</CardContainer>
	);
}
