import { getEquipmentDetails } from 'helpers/api/equipments';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

const RigDetailContext = React.createContext(null);

function RigDetailContextProvider({ children }) {
	const { id } = useParams();
	const [data, setData] = useState(null);

	const detailData = useQuery(
		`detailsRig-${id}`,
		async () => getEquipmentDetails(id),
		{
			enabled: !!id,
			refetchInterval: 2000,
			refetchIntervalInBackground: false,
			refetchOnWindowFocus: false,
		},
	);

	useEffect(() => {
		if (detailData.data) {
			const equipData = detailData.data.data.equipmentData?.[0];
			setData(equipData);
		}
	}, [detailData.data]);

	// Values to store in context.
	const value: any = useMemo(
		() => ({
			data,
			refetch: detailData.refetch,
			isLoading: detailData.isLoading,
		}),
		[detailData],
	);

	return (
		<RigDetailContext.Provider value={value}>
			{children}
		</RigDetailContext.Provider>
	);
}

export const useRigDetail = () => {
	const context = React.useContext(RigDetailContext);
	if (context === undefined) {
		throw new Error(
			`useRigDetail must be used within a RigDetailContextProvider`,
		);
	}
	return context;
};

export { RigDetailContextProvider };
