import { ReactComponent as DropdownIcon } from 'assets/icons/dropdown.svg';
import { ReactComponent as More } from 'assets/icons/more.svg';
import { ReactComponent as SearchIc } from 'assets/icons/Search.svg';
import NoData from 'components/NoData';
import SortEnabledHeader from 'components/SortEnabledHeader';
import { deleteProject, getProjects } from 'helpers/api/projectManagement';
import useResponsive from 'helpers/hooks/useResponsive';
import { TableContainer } from 'pages/dashboard/tables.styled';
import React from 'react';
import {
	Button,
	Col,
	Dropdown,
	DropdownButton,
	Modal,
	Row,
	Spinner,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { UsersContainer } from '../../../components/Profile/users.styled';

const sortByList = [
	{ field: 'project_custom_id', title: 'PROJECT ID' },
	{ field: 'customerName', title: 'CUSTOMER' },
	{ field: 'padname', title: 'PAD NAME' },
	{ field: 'wellname', title: 'WELL NAME' },
	{ field: 'begins', title: 'START DATE' },
	{ field: 'ends', title: 'END DATE' },
];

export default function ProjectList() {
	const { isTablet, isMobile } = useResponsive();
	const [showDelete, setShowDelete] = React.useState(false);
	const [projs, setProjs] = React.useState();
	const [projectToBeDeleted, setProjectToBeDeleted] = React.useState();
	const [filteredProjects, setFilteredProjects] = React.useState([]);

	React.useEffect(() => {
		getProjects().then((res) => {
			const { data } = res;
			setProjs(data.projectsByCustomerId);
			setFilteredProjects(data.projectsByCustomerId);
		});
	}, []);

	const handleShowDelete = (project) => {
		setProjectToBeDeleted(project);
		setShowDelete(true);
	};

	const handleDelete = () => {
		setShowDelete(false);
		deleteProject({ id: projectToBeDeleted.id });
	};

	const handleSearch = (e) => {
		const value = e.target.value?.toLowerCase();
		setFilteredProjects(
			projs?.filter(
				(item) =>
					item.pprojectid?.toLowerCase()?.includes(value) ||
					item.pname?.toLowerCase()?.includes(value) ||
					item.pclient?.toLowerCase()?.includes(value),
			),
		);
	};

	if (!projs) {
		return (
			<UsersContainer $istablet={isTablet} $ismobile={isMobile}>
				<TableContainer>
					{/* <div
						className="mb-3"
						style={{ cursor: 'pointer', maxWidth: 'fit-content' }}
					>
						<Right />
						<Link to="/dashboard">
							<span className="ms-2">Back</span>
						</Link>
					</div> */}

					<h1 className="assignment-header mb-5">Project Management</h1>

					<Spinner
						size="md"
						animation="border"
						className="mx-auto mt-5"
						style={{ display: 'grid' }}
					/>
				</TableContainer>
			</UsersContainer>
		);
	}
	return (
		<UsersContainer $istablet={isTablet} $ismobile={isMobile}>
			<Modal size="md" show={showDelete} centered className="profile-modal">
				<Modal.Body>
					<h2
						className="text-center"
						style={{ fontSize: '1.5rem', lineHeight: '2.6875rem' }}
					>
						Are you sure you want to delete project ‘
						{projectToBeDeleted?.project_custom_id}
						’?
					</h2>

					<div className="text-center mt-5">
						<Button
							type="button"
							variant="bordered-green"
							className="me-3"
							onClick={() => setShowDelete(false)}
						>
							Cancel
						</Button>
						<Button
							type="button"
							variant="primary-green"
							onClick={handleDelete}
						>
							Yes, delete it!
						</Button>
					</div>
				</Modal.Body>
			</Modal>

			<TableContainer>
				{/* <div
					className="mb-3"
					style={{ cursor: 'pointer', maxWidth: 'fit-content' }}
				>
					<Right />
					<Link to="/dashboard">
						<span className="ms-2">Back</span>
					</Link>
				</div> */}

				{/* <h1 className="assignment-header mb-5">Projects</h1> */}

				{/* <div className="user-table"> */}
				<div className="d-flex flex-wrap mb-3">
					<div>
						<SearchIc className="search-icon" />
						<input
							className="form-control form-control-sm search-input"
							type="text"
							placeholder="Search Project"
							aria-label="Search"
							onChange={handleSearch}
						/>
					</div>
				</div>

				<div className="d-flex">
					<div className="w-50">
						<Link to="/create-project">
							<Button className="mb-4 w-100" variant="primary-green">
								Create Project
							</Button>
						</Link>
					</div>
					<div className="w-50">
						<Link to="/projects/logs">
							<Button className="mb-4 minw-100" variant="bordered-green">
								Logs
							</Button>
						</Link>
					</div>
				</div>
				<Row className="mobile-sortby-view d-flex justify-content-end align-items-center">
					<div className="sort-by-text">Sort by : </div>
					<DropdownButton
						id="dropdown-basic-button"
						className="more-actions d-flex justify-content-end"
						title={
							<div className="sorting-icon-container d-flex flex-column">
								<DropdownIcon className="sorting-up" />
								<DropdownIcon />
							</div>
						}
					>
						{sortByList.map((sortBy) => (
							<Dropdown.Item key={sortBy.field}>
								<SortEnabledHeader
									title={sortBy.title}
									field={sortBy.field}
									onSortData={setFilteredProjects}
									data={filteredProjects}
									Component="div"
									showSortingIcon={false}
								/>
							</Dropdown.Item>
						))}
					</DropdownButton>
				</Row>

				{filteredProjects?.length > 0 ? (
					filteredProjects?.map((item) => (
						<Row className="project-list-mobile mb-3" key={item.pid}>
							<Col>
								<div className="display-grid mb-3">
									<span className="tags">Project id</span>
									<span className="project-id">
										<Link to={`/projects/${item.pid}`}>
											{item.pprojectid || item.pname}
										</Link>
									</span>
								</div>
								<div className="display-grid mb-3">
									<span className="tags">Pad name</span>
									<span>{item.ppadname || '-'}</span>
								</div>
								<div className="display-grid mb-3">
									<span className="tags">Start date</span>
									<span>{item.pbegdate}</span>
								</div>
							</Col>
							<Col>
								<div className="display-grid mb-3">
									<span className="tags">Customer</span>
									<span>{item.pclient}</span>
								</div>
								<div className="display-grid mb-3">
									<span className="tags">Well name</span>
									<span>{item.pwellname || '-'}</span>
								</div>
								<div className="display-grid mb-3">
									<span className="tags">End date</span>
									<span>{item.penddate}</span>
								</div>
							</Col>
							<Col>
								<DropdownButton
									id="dropdown-basic-button"
									className="more-actions"
									title={<More />}
								>
									<Dropdown.Item href={`/edit-project/${item.pid}`}>
										Edit
									</Dropdown.Item>
									<Dropdown.Item href={`/projects/logs/${item.pid}`}>
										View log
									</Dropdown.Item>
									<Dropdown.Item onClick={() => handleShowDelete(item)}>
										Delete
									</Dropdown.Item>
								</DropdownButton>
							</Col>
						</Row>
					))
				) : (
					<NoData />
				)}

				{/* </div> */}
			</TableContainer>
		</UsersContainer>
	);
}
