import { Col, Form, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Header } from 'style/addEq.styled';
import CustomInput from '../../../components/CustomInput';

// const WEIGHT_SOURCE_INPUT_OPTIONS = [
// 	{ label: 'Load Pin', value: 'Load Pin' },
// 	{ label: 'Line Scale', value: 'Line Scale' },
// 	{ label: 'Pads', value: 'Pads' },
// ];

export default function Information({ register, errors }) {
	const { id } = useParams();
	return (
		<div className="mb-6">
			<Header>
				<h5>Equipment Information</h5>
			</Header>
			<Row>
				<CustomInput
					label="Rigseye ID"
					name="serial"
					register={register}
					errors={errors}
					disabled={id}
				/>
				<Col lg={4}>
					<Form.Group>
						<Form.Label>Subscription Type</Form.Label>
						<Form.Select {...register('subscriptiontype')}>
							<option disabled value="">
								Select Subscription Type
							</option>
							<option value="full">Full</option>
							<option value="lite">Lite</option>
						</Form.Select>
					</Form.Group>
				</Col>
				<Col lg={4}>
					<Form.Group>
						<Form.Label>Flow Unit</Form.Label>
						<Form.Select {...register('flow_unit')}>
							<option disabled value="">
								Select UoM
							</option>
							<option value="GPM">GPM</option>
						</Form.Select>
					</Form.Group>
				</Col>
				<CustomInput
					label="VIN"
					name="VIN"
					register={register}
					errors={errors}
				/>
				<Col lg={4}>
					<Form.Group>
						<Form.Label>Registration State</Form.Label>
						<Form.Select {...register('State')}>
							<option value="">Select registration state</option>
						</Form.Select>
					</Form.Group>
				</Col>

				<CustomInput
					label="Registration State No."
					name="StateReg"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="Registration No."
					name="Registration"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="License Plate"
					name="LicPlate"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="Engine Make"
					name="EngineMake"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="Engine Model"
					name="EngineModel"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="Engine Rating"
					name="EngineRating"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="Engine Serial"
					name="EngineSerial"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="Transmission"
					name="Transmission"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="Yard Assigned"
					name="yard"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Rig Make"
					name="Make"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="Rig Model"
					name="Model"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="Capacity Rating"
					name="CapacityRating"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="GVWR"
					name="GVWR"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="Mast Make"
					name="MastMake"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="Mast Model"
					name="MastModel"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="Mast Height"
					name="MastHeight"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="Mast Rating"
					name="MastRating"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="Draw Works Make"
					name="DrawworksMake"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="Parts of Line"
					name="PartsOfLine"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="Encoder Type"
					name="EncoderType"
					register={register}
					errors={errors}
				/>

				<Col lg={4}>
					<Form.Group>
						<Form.Label>Console Display Type</Form.Label>
						<Form.Select {...register('COFOType')}>
							<option value="">Select</option>
							<option value="regular">Standard</option>
							<option value="weight">Standard w/ Weight</option>
							<option value="plus">Plus</option>
						</Form.Select>
					</Form.Group>
				</Col>
				<Col lg={4}>
					<Form.Group>
						<Form.Label>Top Drive</Form.Label>
						<Form.Select {...register('TopDrive')}>
							<option value="">Select</option>
							<option value="Yes">Yes</option>
							<option value="No">No</option>
						</Form.Select>
					</Form.Group>
				</Col>
				<Col lg={4}>
					<Form.Group>
						<Form.Label>Weight Source Input</Form.Label>
						<Form.Select {...register('weight_source')}>
							<option value="">Select</option>
							<option value="Load Pin">Load Pin</option>
							<option value="Line Scale">Line Scale</option>
							<option value="Pads">Pads</option>
						</Form.Select>
					</Form.Group>
				</Col>
			</Row>
			<Header>
				<h5>Software Information</h5>
			</Header>
			<Row>
				<CustomInput
					label="DA CPU"
					name="dacpu"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="DA Engine"
					name="daengine"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="DA Wifi"
					name="dawifi"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="AUX-A CPU"
					name="auxacpu"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="AUX-B CPU"
					name="auxbcpu"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="Camera ID"
					name="camId"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="Camera Software"
					name="camSoftware"
					register={register}
					errors={errors}
				/>
			</Row>
			<Header>
				<h5>Cell & Box Information</h5>
			</Header>
			<Row>
				<CustomInput
					label="Service Provider"
					name="service_provider"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="Cell Number"
					name="cell_number"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="MEID"
					name="meid"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="DA Box Serial Number"
					name="da_boxnum"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="AUX-A Box Serial Number"
					name="aux_a_num"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="AUX-B Box Serial Number"
					name="aux_b_num"
					register={register}
					errors={errors}
				/>
			</Row>
		</div>
	);
}

// const CustomSelect = ({ label, register, key, options }) => (
// 	<Col lg={4}>
// 		<Form.Group controlId={label}>
// 			<Form.Label>{label}</Form.Label>
// 			<Form.Control as="select" {...register(`${key}`)}>
// 				{options?.map((option) => (
// 					<option key={option.value} value={option.value}>
// 						{option.label}
// 					</option>
// 				))}
// 			</Form.Control>
// 		</Form.Group>
// 	</Col>
// );
