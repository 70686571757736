import { getToken } from 'helpers/utils/auth-provider';
import client from './apolloClient';
import { COMPANY_QUERY } from '../queries/companyQuery';

export default {
	getCompanyInfo: () =>
		client.query({
			query: COMPANY_QUERY,
			context: {
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			},
		}),
	updateCompanyInfo: (id, data) =>
		client.put(`/companies/${id}`, data).then((res) => res.data),
};
