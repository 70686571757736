import { RigDetailContextProvider } from 'helpers/contexts/RigDetailProvider';
import CameraTab from 'pages/details/rig/tabs/Camera/CameraTab';

export default function CameraTabView() {
	return (
		<RigDetailContextProvider>
			<CameraTab />
		</RigDetailContextProvider>
	);
}
