import * as React from 'react';

function valuePercent(val) {
	return (val / 2.5) * 1.35;
}

const minValue = 0;
const maxValue = 500;

function Clock({ value, prevVal, newLook, ...props }) {
	return (
		<svg
			width={140}
			height={140}
			aria-label="A chart."
			style={{
				overflow: 'hidden',
				transition: 'all .5s',
			}}
			{...props}
		>
			<defs id="_ABSTRACT_RENDERER_ID_0" />
			<g>
				{!newLook && (
					<>
						<circle
							cx={69.5}
							cy={69.5}
							r={63}
							stroke="#333333"
							strokeWidth={1}
							fill="#cccccc"
						/>
						<circle
							cx={69.5}
							cy={69.5}
							r={56}
							stroke="#e0e0e0"
							strokeWidth={2}
							fill="#f7f7f7"
						/>
					</>
				)}
				<path
					d="M119.45493884734799,86.06888370749725A52,52,0,0,1,114.3372885464128,97.16992536522935L103.2529664098096,90.37744402392198A39,39,0,0,0,107.09120413551099,82.05166278062295Z"
					stroke="none"
					strokeWidth={0}
					fill="#ff9900"
				/>
				<path
					d="M114.33728854641282,97.1699253652293A52,52,0,0,1,106.76955262170048,106.76955262170047L97.57716446627538,97.57716446627532A39,39,0,0,0,103.2529664098096,90.37744402392201Z"
					stroke="none"
					strokeWidth={0}
					fill="#dc3912"
				/>
				<text
					textAnchor="middle"
					x={70}
					y={56.9}
					fontFamily="arial"
					fontSize={14}
					stroke="none"
					strokeWidth={0}
					fill="#333333"
				>
					RPM
				</text>
				<text
					textAnchor="start"
					x={43.05923163679254}
					y={99.39076836320747}
					fontFamily="arial"
					fontSize={7}
					stroke="none"
					strokeWidth={0}
					fill="#333333"
				>
					{minValue}
				</text>
				<text
					textAnchor="end"
					x={96.9407683632075}
					y={99.39076836320743}
					fontFamily="arial"
					fontSize={7}
					stroke="none"
					strokeWidth={0}
					fill="#333333"
				>
					{maxValue}
				</text>
				<path
					d="M30.09644030822848,94.45293282870641L25.662711453587207,97.16992536522935M25.490555037386812,84.46199533674755L20.545061152652018,86.06888370749726M23.344268781289607,73.67188568006316L18.160298645877347,74.07987297784794M23.776185660147547,62.678867036117204L18.640206289052834,61.865407817908M32.13800466325245,42.49165019271226L27.931116292502736,39.4351668807914M39.60583133814738,34.413000809918564L36.228701486830424,30.45888978879841M48.75324461218921,28.30089466798438L46.39249401354356,23.667660742204873M59.074756971543636,24.493087725388726L57.86084107949293,19.436764139320815M80.92524302845635,24.493087725388726L82.13915892050704,19.436764139320807M91.24675538781078,28.300894667984373L93.60750598645643,23.667660742204866M100.3941686618526,34.41300080991856L103.77129851316957,30.458889788798395M107.86199533674754,42.49165019271224L112.06888370749726,39.435166880791385M116.22381433985245,62.67886703611718L121.35979371094716,61.86540781790798M116.6557312187104,73.67188568006311L121.83970135412267,74.0798729778479M114.5094449626132,84.46199533674753L119.45493884734799,86.06888370749725M109.90355969177153,94.45293282870638L114.33728854641282,97.1699253652293"
					stroke="#666666"
					strokeWidth={1}
					fillOpacity={1}
					fill="none"
				/>
				<path
					d="M40.584357902639624,99.41564209736038L33.23044737829953,106.76955262170048M31.566611447530462,54.080369213612286L21.95826430941308,50.10046151701536M69.99999999999999,28.4L69.99999999999999,18M108.43338855246954,54.08036921361227L118.04173569058692,50.10046151701535M99.4156420973604,99.41564209736035L106.76955262170051,106.76955262170043"
					stroke="#333333"
					strokeWidth={2}
					fillOpacity={1}
					fill="none"
				/>
				<g>
					<text
						textAnchor="middle"
						x={70}
						y={113.9}
						fontFamily="arial"
						fontSize={14}
						stroke="none"
						strokeWidth={0}
						fill="#000000"
					>
						{value}
					</text>

					<path
						d="M35.351767721859176,104.64823227814082C67.42613131648096,67.42613131648096,80.02677415722525,57.39935715925572,81.31370849898477,58.68629150101524C82.60064284074429,59.97322584277476,72.57386868351904,72.57386868351904,35.351767721859176,104.64823227814082"
						stroke="#c63310"
						strokeWidth={1}
						fillOpacity={0.7}
						fill="#dc3912"
						transform={`rotate(${valuePercent(value) || 0}, 70, 70)`}
						style={{ transition: '1s' }}
					>
						<animateTransform
							id="animateNeedle"
							attributeName="transform"
							type="rotate"
							from={`${valuePercent(prevVal)}, 70, 70`}
							to={`${valuePercent(value)}, 70, 70`}
							begin="indefinite"
							dur="1s"
							repeatCount={1}
						/>
					</path>
					{/* <path
					d="M35.351767721859176,104.64823227814082C67.42613131648096,67.42613131648096,80.02677415722525,57.39935715925572,81.31370849898477,58.68629150101524C82.60064284074429,59.97322584277476,72.57386868351904,72.57386868351904,35.351767721859176,104.64823227814082"
					stroke="#c63310"
					strokeWidth={1}
					fillOpacity={0.7}
					style={{ transition: 'all 2.5s ease-in-out' }}
					fill="#dc3912"
					transform={`rotate(${value || 0}, 70, 72)`}
				/> */}

					<circle
						cx={69.5}
						cy={69.5}
						r={8}
						stroke="#666666"
						strokeWidth={1}
						fill="#4684ee"
					/>
				</g>
			</g>
		</svg>
	);
}

export default Clock;
