import useResponsive from 'helpers/hooks/useResponsive';
import { VIEWS } from 'helpers/const/views';
// import Tables from './ListView';
import ListViewIndex from './ListViewIndex';
import Mapview from '../Mapview/MapView';
import { DashboardContent } from './styled';
import DashboadHeader from './DashboardHeader';
import { useDashboard } from './DashboardContext';

function Dashboard() {
	const { view } = useDashboard();
	const { isMobile, isTablet } = useResponsive();
	return (
		<DashboardContent $ismobile={isMobile} $istablet={isTablet}>
			<DashboadHeader />
			{view === VIEWS.MAP && <Mapview />}
			{view === VIEWS.LIST && <ListViewIndex />}
		</DashboardContent>
	);
}

export default Dashboard;
