import { getToken } from 'helpers/utils/auth-provider';
import { getUnixTimestamp } from 'helpers/utils/formatters';
// import { apiURL } from './urlDataBuilders';

// const apiURLEndpoint = apiURL();
const apiURLEndpoint = 'https://api.rigseye.com';

export const generateReport = async (data) => {
	const token = getToken();
	const id = data.equipment;
	const dateSelected = data.date;
	const timeStart = data.timeStarts !== '' ? data.timeStarts : '00:00:00';
	const timeEnd = data.timeEnds !== '' ? data.timeEnds : '23:59:59';
	const reportType = data.reportType !== '' ? data.reportType : 'day';

	const dateTimeStart = `${dateSelected}T${timeStart}`;
	const dateTimeEnd = `${dateSelected}T${timeEnd}`;

	const range = `${getUnixTimestamp(dateTimeStart) / 1000},${
		getUnixTimestamp(dateTimeEnd) / 1000
	}`;

	const url = `${apiURLEndpoint}/equipments/${id}/report?range=${range}&date=${dateSelected}&timeStart=${timeStart}&timeEnd=${timeEnd}&type=${reportType}`;

	const header = {
		headers: {
			'http-authorization': token || '',
		},
	};
	const response = await fetch(url, header);
	const result = await response.json();
	return result;
};
