/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { status } from 'components/EquipmentHandler';
import { getEquipmentDetails } from 'helpers/api/equipments';
import useResponsive from 'helpers/hooks/useResponsive';
import moment from 'moment-timezone';
import { useState } from 'react';
import { Row } from 'react-bootstrap';
import { BsCardList, BsPinMap } from 'react-icons/bs';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import ProfilePopup from './ProfilePopup';

function Details() {
	const { id } = useParams();

	let { data } = useQuery('detailsPump', () => getEquipmentDetails(id), {
		enabled: !!id,
		refetchInterval: 1000,
		refetchIntervalInBackground: false,
		refetchOnWindowFocus: false,
	});
	if (data.data) {
		data = data?.data?.equipmentData;
	}

	const { isMobile, isDesktop } = useResponsive();
	const [editProfileModal, setEditProfileModal] = useState(false);

	const openTravelToSite = () => {
		window.open(
			`https://www.google.com/maps/dir/?api=1&destination=${data[0].latitude},${data[0].longitude}`,
			'_blank',
		);
	};

	const toggleEditProfileModal = (open = false) => {
		setEditProfileModal(open);
	};

	return (
		<>
			<ProfilePopup
				data={data}
				open={editProfileModal}
				onClose={toggleEditProfileModal}
			/>
			<div className="mb-2">
				<div className="details-information">
					<Row>
						<span className="eq-id">
							{data?.[0]?.type?.toUpperCase()}
							{': '}
							{data[0]?.serial ?? '-'}
						</span>
						{status(data?.[0]?.status)}

						{isMobile ? <br /> : null}
						<label className="date">
							{moment()?.format('Do MMM, YYYY')} |{' '}
							{moment()?.tz(data[0]?.timezoneIdentifier)?.format('hh:mm:ss a')}{' '}
							<span className="tags">{data[0]?.timezoneName ?? '-'}</span>
						</label>
						<span
							title="Rig Location"
							style={{ cursor: 'pointer' }}
							onClick={openTravelToSite}
						>
							<BsPinMap />
						</span>
						<span
							title="Equipment Profile"
							style={{ cursor: 'pointer' }}
							onClick={() => toggleEditProfileModal(true)}
						>
							<BsCardList />
						</span>
					</Row>

					{!isDesktop ? null : (
						<Row>
							<span className="tags">
								Yard Name: <span className="value">{data[0]?.yardName}</span>
							</span>
							<span className="tags">
								Data System Voltage:{' '}
								<span className="value">{data[0]?.powerVoltage ?? '-'}v</span>
							</span>
							<span className="tags">
								Engine: <span className="value">-</span>
							</span>
						</Row>
					)}
				</div>
			</div>
		</>
	);
}

export default Details;
