import DashboardLayout from 'components/Layouts/DashboardLayout';
import ProjectInformation from 'pages/project-details/ProjectInformation';
import ProjectMessages from 'pages/project-details/ProjectMessages';
import { DetailContainer } from 'pages/projectmanagement/project.styled';
import useResponsive from 'helpers/hooks/useResponsive';
import { Row, Col } from 'react-bootstrap';
import PipeTallyData from 'pages/project-details/PipeTallyData';
import ProjectCharts from 'pages/project-details/ProjectCharts';

export default function ProjectDetail() {
	const { isTablet, isMobile } = useResponsive();

	return (
		<DashboardLayout>
			<DetailContainer isTablet={isTablet} isMobile={isMobile}>
				<ProjectInformation />
				<Row>
					<Col lg={6}>
						<ProjectMessages />
					</Col>
					<Col lg={6}>
						<PipeTallyData />
					</Col>
					<ProjectCharts />
				</Row>
			</DetailContainer>
		</DashboardLayout>
	);
}
