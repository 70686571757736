import {
	Button,
	Form,
	Modal,
	OverlayTrigger,
	Row,
	Tooltip,
} from 'react-bootstrap';
import React from 'react';
import DatePicker from 'react-datepicker';
import { exportProjectData } from 'helpers/api/projectManagement';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import moment from 'moment';

export default function DownloadData({ project }) {
	const [modalShow, setModalShow] = React.useState();
	const [selectedDate, setSelectedDate] = React.useState(new Date());
	const [dateSelected, setDateSelected] = React.useState(false);
	const { id } = useParams();

	const handleShow = () => setModalShow(true);

	const chartselecteddate = (date) => {
		setSelectedDate(date);
	};

	const downloadApiCall = (promise) => {
		toast.promise(promise, {
			loading: 'Exporting data...',
			success: (res) => {
				setModalShow(false);
				return (
					res.msg || 'Successfully exported data please wait for the email'
				);
			},
			error: (err) => err.error || 'Error exporting data',
		});
	};
	const handleDownload = (e) => {
		e.preventDefault();
		if (e.target.fulldate.checked) {
			const promise = exportProjectData({
				project_start: project.begins,
				project_end: project.ends,
				optdate: 'fulldate',
				projectid: id,
			});
			downloadApiCall(promise);
		} else {
			const promise = exportProjectData({
				projectid: id,
				projectDates: selectedDate,
			});
			downloadApiCall(promise);
		}
	};
	const handleDate = () => {
		setDateSelected(true);
	};
	const handleDateRemoved = () => {
		setDateSelected(false);
	};

	return (
		<>
			<OverlayTrigger
				placement="top"
				trigger="hover"
				overlay={
					<Tooltip id="tooltip-top">
						<span>Download Data</span>
					</Tooltip>
				}
			>
				<DownloadIcon
					onClick={handleShow}
					className="ms-4 align-self-center cursor-pointer"
				/>
			</OverlayTrigger>

			<Modal
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={modalShow}
				onHide={() => setModalShow(false)}
				className="profile-modal"
			>
				<Modal.Body>
					<div className="information-container">
						<span className="information">Project Message Board</span>
					</div>

					<Form onSubmit={handleDownload}>
						<Row className="inputs my-3">
							<div
								className="checkbox-container d-grid justify-content-start"
								style={{ justifyContent: 'center' }}
							>
								<h2 style={{ fontSize: '1.25rem' }} className="mb-3">
									Select period
								</h2>
								<div>
									<label htmlFor="fulldate">
										<input
											type="radio"
											name="data"
											id="fulldate"
											onChange={handleDateRemoved}
										/>
										<span className="d-inline-flex position-absolute mb-3 fs-6">
											Select all project duration
										</span>
									</label>
								</div>
								<div>
									<label htmlFor="byDate">
										<input
											type="radio"
											name="data"
											id="byDate"
											onChange={handleDate}
										/>
										<span className="d-inline-flex position-absolute mb-3 fs-6">
											Select by date
										</span>
									</label>
								</div>
								{dateSelected ? (
									// <DatePicker
									// 	className="date-picker"
									// 	selected={selectedDate}
									// 	onChange={(date) => chartselecteddate(date)}
									// 	max={new Date()}
									// />

									<Form.Control
										className="w-100 h-100 date-picker border-none"
										type="date"
										onChange={(date) => chartselecteddate(date)}
										max={moment().format('YYYY-MM-DD')}
									/>
								) : null}
							</div>
						</Row>

						<div className="button-wrapper">
							<Button
								type="button"
								variant="bordered-green"
								className="me-3"
								onClick={() => setModalShow(false)}
							>
								Close
							</Button>

							<Button type="submit" variant="primary-green">
								Request
							</Button>
						</div>
					</Form>
				</Modal.Body>
			</Modal>
		</>
	);
}
