import GoogleMapReact from 'google-map-react';
import { BasicMarker } from 'pages/Mapview/MyMarker';
import { Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import React from 'react';
import { GOOGLE_MAP_API_KEY } from 'helpers/const';
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';

function createMapOptions(maps) {
	return {
		zoomControlOptions: {
			position: maps.ControlPosition.RIGHT_CENTER,
			style: maps.ZoomControlStyle.SMALL,
		},
		mapTypeId: maps.MapTypeId.HYBRID,
	};
}

export default function Location() {
	const [modalShow, setModalShow] = React.useState();
	const handleShow = () => setModalShow(true);

	return (
		<>
			<OverlayTrigger
				placement="top"
				trigger="hover"
				overlay={
					<Tooltip id="tooltip-top">
						<span>Project Site</span>
					</Tooltip>
				}
			>
				<LocationIcon
					onClick={handleShow}
					style={{ cursor: 'pointer', alignSelf: 'center' }}
				/>
			</OverlayTrigger>

			<Modal
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={modalShow}
				onHide={() => setModalShow(false)}
				className="profile-modal"
			>
				<Modal.Body>
					<div className="information-container">
						<span className="information">Project Location</span>
					</div>

					<Row className="inputs my-3">
						<GoogleMapReact
							bootstrapURLKeys={{
								key: GOOGLE_MAP_API_KEY,
								language: 'en',
								region: 'US',
							}}
							defaultCenter={{
								lat: 44.045213,
								lng: -123.081602,
							}}
							defaultZoom={15}
							style={{
								contain: 'strict',
								height: `500px`,
								position: 'relative',
								borderRadius: '12px',
								width: '100%',
							}}
							options={createMapOptions}
						>
							<BasicMarker lat={44.045213} lng={-123.081602} type="Rig" />
						</GoogleMapReact>
					</Row>

					<div className="button-wrapper mt-3">
						<button
							type="button"
							className="close me-0"
							onClick={() => setModalShow(false)}
						>
							Close
						</button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}
