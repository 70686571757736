import { rigChartLabels } from 'helpers/const/chartLabels';
import { ChartContainer } from 'pages/details/styled';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import DailyChartGeneral from './DailyChartGeneral';
import LiveChartGeneral from './LiveChartGeneral';

function ActivityDrawworks() {
	const [daily, setDaily] = React.useState(false);
	const [selectedDate, setSelectedDate] = React.useState(new Date());

	const handleDaily = (d) => {
		setDaily(d);
	};

	const chartselecteddate = (date, d) => {
		setSelectedDate(date);
		setDaily(d);
	};

	return (
		<Col lg={12}>
			<ChartContainer>
				<Row className="header">
					<Col className="align-self-center">
						<h1 className="align-self-center">{rigChartLabels.drawworks}</h1>
					</Col>
					<Col className="change-container">
						<button
							type="button"
							onClick={() => (daily ? handleDaily(false) : handleDaily(true))}
							className="change-span"
						>
							{daily ? 'Live chart' : '24h chart'}
						</button>
						<button type="button">
							<DatePicker
								selected={selectedDate}
								onChange={(date) => chartselecteddate(date, true)}
							/>
						</button>
					</Col>
				</Row>

				{daily && (
					<DailyChartGeneral
						chart="drawworks"
						date={selectedDate}
						subitem={rigChartLabels.drawworks_rpm}
					/>
				)}

				{!daily && (
					<LiveChartGeneral
						chart="drawworks"
						subchart={rigChartLabels.drawworks_rpm}
						datakey="rpm"
					/>
				)}
			</ChartContainer>
		</Col>
	);
}

export default ActivityDrawworks;
