import styled from 'styled-components';

export const TableContainer = styled.div`
	margin-top: 1.25rem;

	small {
		margin-left: 1rem;
		opacity: 0.7;
	}

	a {
		color: inherit;
		text-decoration: none;
	}

	h1,
	h2 {
		font-size: 0.875rem;
		font-weight: 700;
		color: black;
		margin-left: 0.5rem;
		margin-top: 0.75rem;
		margin-bottom: 0.75rem;
	}

	.search-icon {
		margin-bottom: -0.5rem;
		margin-left: 1rem;
		margin-top: 0.75rem;
		z-index: 99;
	}

	.sortable-header-column {
		font-size: 1rem;
	}

	.search-input {
		width: 260px;
		border-radius: 8px;
		background: ${({ theme }) => theme.colors.backgroundLightGray};
		height: 44px;
		font-size: 0.813rem;
		font-weight: 500;
		padding-left: 2.5rem;
		margin-top: -1.5rem;
	}

	@media (max-width: 450px) {
		.search-input {
			width: 100%;
			margin-bottom: 1.5rem;
		}
	}

	thead {
		background: ${({ theme }) => theme.colors.backgroundLightGray};
		border: none;

		tr {
			line-height: 2rem;
			border: none;

			th {
				border: none;
				padding: 0.75rem;
				font-size: 0.813rem;
				font-weight: 600;
				color: ${({ theme }) => theme.colors.lightGray};
			}
		}
	}

	tbody {
		border: none !important;
		margin-top: 1.25rem !important;

		tr {
			border: none;
			height: 3rem;

			td {
				border: none;
				border-bottom: 1px solid #dddddd;
				font-size: 0.875rem;
				font-weight: 500;
				line-height: 1.75rem;
				padding: 12px;
			}
		}

		--bs-table-striped-bg: none;
		a {
			color: inherit;
			text-decoration: none;
		}
	}

	.accordion-item {
		background: transparent;
		border: none;
	}

	.accordion-button {
		background-color: ${({ theme }) => theme.colors.tableHeader};
		color: white;
	}

	.accordion-button:after {
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
	}

	.equipment-id {
		font-size: 1rem;
		font-weight: 500;
		color: ${({ theme }) => theme.colors.loginPrimary} !important;
	}

	.equipment-id-deactive {
		font-size: 1rem;
		font-weight: 500;
		color: black !important;
		opacity: 1;
	}

	.calibrated {
		color: ${({ theme }) => theme.colors.calibrated} !important;
		font-weight: 600 !important;
	}

	.calibrated-warning {
		color: red;
		font-weight: 600 !important;
	}

	.card {
		background: #ffffff;
		border: 1px solid #dddddd;
		margin: 1rem 0px;
		border-radius: 8px;
		padding: 1rem;
		display: block;

		div {
			align-items: center;
		}

		.head {
			display: block;
			button {
				float: right;
			}
		}

		.details {
			display: flex;
			margin-top: 1.25rem;

			* {
				margin-right: 1rem;
			}
		}
	}

	.mobile {
		h1 {
			color: ${({ theme }) => theme.colors.lightGray};
			font-size: 0.75rem;
			font-weight: 500;
			margin: 0rem;
		}

		.tag {
			font-family: 'Poppins';
			font-style: normal;
			font-weight: 500;
			font-size: 0.75rem;
			line-height: 18px;
			opacity: 0.5;
			margin-bottom: 0.25rem;
		}
	}

	.assignment-row-container {
		margin: 0px 0px;
		.assignment-row {
			display: inline-flex;
			align-items: center;
			background: #f3f3f3;
			height: 56px;
		}

		.assignment-item {
			display: inline-flex;
			align-items: center;
		}
	}

	.assignment-header {
		font-family: 'Poppins';
		font-weight: 600;
		font-size: 1.5rem;
		font-weight: bold;
	}

	.nav-link {
		all: unset;
		min-width: max-content;

		color: ${({ theme }) => theme.colors.gray};
		background-color: transparent;
		border: none;
		text-decoration: none !important;
		/* margin-right: 1rem; */
		margin-left: 1rem;
		cursor: pointer;

		.active {
			color: black;
			background-color: transparent;
			border: none;
			box-shadow: none;
			padding-bottom: 0.75rem;
			border-bottom: 2px solid #0035bf;
		}

		&:hover,
		&:active {
			color: black;
			background-color: transparent;
			border: none;
			border-bottom: 1px solid #0035bf;
			box-shadow: none;
			/* padding-bottom: 0.75rem; */
			/* border-bottom: 2px solid #0035bf; */
		}
	}

	.nav-link.active {
		background: #ffffff;
		border-bottom: 1px solid #0035bf;
	}

	.mobile-user-table {
		padding: 0rem !important;

		.header {
			flex-direction: column-reverse;
		}
	}

	.user-table {
		background: white;
		padding: 1.125rem;
		border-radius: 12px;
		overflow: auto;

		tr {
			th {
				min-width: 125px;
			}
		}

		.primary-button {
			background: #00834d;
			padding: 0.75rem 2rem;
			border: none;
			border-radius: 12px;
			float: right;

			&:active,
			&:hover {
				border: none;
			}
		}

		.alt-button {
			background: white;
			padding: 0.75rem 2rem;
			border: 1px solid #00834d;
			border-radius: 12px;
			float: right;
			color: black;

			&:active,
			&:hover {
				border: 1px solid #00834d;
			}
		}

		@media (max-width: 800px) {
			.primary-button {
				float: left;
				margin-top: 1rem;
			}

			table {
				margin-top: 2rem !important;
			}

			.header {
				flex-wrap: wrap-reverse;
			}

			.alt-button {
				float: left;
				margin-left: 1rem;
			}
		}
	}

	.project-list-mobile {
		border: 1px solid #dddddd;
		border-radius: 8px;
		padding: 1rem;

		.successful {
			color: #00834d !important;
			background: #d0ffd5 !important;
			border-radius: 4px !important;
			padding: 0.25rem 0.4375rem;
			width: fit-content;
		}

		.fail {
			color: #fa0000 !important;
			background: #ffdbdb !important;
			border-radius: 4px !important;
			padding: 0.25rem 0.4375rem;
			width: fit-content;
		}

		span {
			font-weight: 500;
			font-size: 0.9rem;
		}

		.tags {
			color: #818181;
			font-weight: 500;
			font-size: 0.85rem;
			text-transform: uppercase;
		}

		.dropdown-toggle {
			float: right;
		}
	}

	.accordion-header {
		button {
			&:hover,
			&:focus {
				border-color: none !important;
				box-shadow: none;
			}
		}
	}
`;
