import React from 'react';
import { Button, Image } from 'react-bootstrap';
import styled from 'styled-components';
import Illustration from 'assets/img/404.svg';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	img {
		max-width: 600px;
		width: 75%;
	}
	.container {
		max-width: 500px;
		text-align: center;
	}
	button.btn {
		background: ${(props) => props.theme.colors.loginPrimary};
		padding: 10px 30px;
		height: 3.5rem;
		border-radius: 8px;
		border: none;
		font-weight: 500;
		font-size: 1rem;
	}
`;

function Page404() {
	return (
		<Wrapper>
			<Image src={Illustration} alt="404 Not Found" title="404" />
			<div className="mt-5">
				<Link to="/">
					<Button> {'<'} Back to home</Button>
				</Link>
			</div>
		</Wrapper>
	);
}

export default Page404;
