export const VIEWS = {
	LIST: 'list',
	MAP: 'map',
};

export const WEIGHT = {
	TOTAL: 'total',
	HOOK: 'hook',
	BIT: 'bit',
};

export const SORT = {
	ASC: 'asc',
	DESC: 'desc',
};
