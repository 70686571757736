import { gql } from '@apollo/client';

export const UPDATE_EQUIPMENT_INFO_RIG_MUTATION = gql`
	mutation updateEquipmentInfoRig(
		$updateEquipmentInfoRigArgs: UpdateEquipmentInfoRigArgs!
	) {
		updateEquipmentInfoRig(
			updateEquipmentInfoRigArgs: $updateEquipmentInfoRigArgs
		) {
			success
			message
		}
	}
`;
