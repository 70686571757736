/* eslint-disable no-useless-escape */
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError';
import states from 'helpers/const/us-states.json';
import { getToken } from 'helpers/utils/auth-provider';
import { useEffect, useState } from 'react';
import { Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import * as yup from 'yup';
import { ADD_CLIENTS_DATA_MUTATION } from '../../../helpers/queries/clientsQuery';

const FormWrapper = styled(Form)`
	.notes-textarea {
		height: unset;
	}
`;

function AddCustomerForm({ open, onClose, onSave }) {
	const [isLoading, setIsLoading] = useState(false);

	const schema = yup
		.object({
			customerName: yup.string(),
			customerPhone: yup.string(),
			customerEmail: yup
				.string()
				.matches(
					/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
					'Invalid email',
				),
			customerContact: yup.string(),
			customerAddress: yup.string(),
			customerCity: yup.string(),
			customerState: yup.string(),
			customerZipcode: yup.string(),
			customerNotes: yup.string(),
		})
		.required();

	const [addClientsData] = useMutation(ADD_CLIENTS_DATA_MUTATION, {
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
	});

	const { register, handleSubmit, formState, reset } = useForm({
		resolver: yupResolver(schema),
	});

	const onSubmit = async (customer) => {
		setIsLoading(true);
		const customerData = {
			name: customer.customerName,
			address1: customer.customerAddress1,
			address2: customer.customerAddress2,
			city: customer.customerCity,
			state: customer.customerState,
			zipcode: customer.customerZipcode,
			contact: customer.customerContact,
			phone: customer.customerPhone,
			fax: customer.customerFax,
			email: customer.customerEmail,
			notes: customer.customerNotes,
		};

		try {
			await addClientsData({
				variables: {
					...customerData,
				},
			});
			setIsLoading(false);
			onSave();
			toast.success('Customer added successfully');
		} catch (error) {
			setIsLoading(false);
			toast.error(error.msg || 'Error adding entry');
		}
	};

	useEffect(() => {
		if (open) {
			reset();
			setIsLoading(false);
		}
	}, [open]);

	const { errors } = formState;
	return (
		<Modal
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			show={open}
			onHide={() => onClose(false)}
			className="profile-modal"
		>
			<Modal.Body>
				<div className="information-container">
					<span className="information">Add customer </span>
				</div>

				<FormWrapper onSubmit={handleSubmit(onSubmit)}>
					<Row className="inputs">
						<Col md={6} sm={12} className="mb-3">
							<label htmlFor="customer-name">
								Customer Name*
								<input
									{...register('customerName')}
									id="customer-name"
									required
								/>
							</label>
							<FormError>{errors.customerName?.message}</FormError>
						</Col>
						<Col md={6} sm={12} className="mb-3">
							<label htmlFor="phone" className="w-100">
								Phone*
								<input {...register('customerPhone')} id="phone" required />
							</label>
							<FormError>{errors.customerPhone?.message}</FormError>
						</Col>
						<Col md={6} sm={12} className="mb-3">
							<label htmlFor="email" className="w-100">
								Email
								<input {...register('customerEmail')} type="text" id="email" />
							</label>
							<FormError>{errors.customerEmail?.message}</FormError>
						</Col>
						<Col md={6} sm={12} className="mb-3">
							<label htmlFor="contact" className="w-100">
								Contact
								<input {...register('customerContact')} id="contact" />
							</label>
							<FormError>{errors.customerContact?.message}</FormError>
						</Col>
						<Col lg={6} sm={12} className="mb-3">
							<label htmlFor="address1" className="w-100">
								Address 1
								<input {...register('customerAddress1')} id="address1" />
								<small>{errors?.address?.message}</small>
							</label>
						</Col>
						<Col lg={6} sm={12} className="mb-3">
							<label htmlFor="address2" className="w-100">
								Address 2
								<input {...register('customerAddress2')} id="address2" />
								<small>{errors?.address2?.message}</small>
							</label>
						</Col>
						<Col lg={6} sm={12} className="mb-3">
							<label htmlFor="city" className="w-100">
								City
								<input {...register('customerCity')} id="city" />
								<small>{errors?.city?.message}</small>
							</label>
						</Col>
						<Col lg={6} sm={12} className="mb-3">
							<label htmlFor="state" className="w-100">
								State
								<select {...register('customerState')} id="state">
									{states.map((state) => (
										<option
											defaultValue={state.abbreviation}
											key={state.abbreviation}
										>
											{state.name}
										</option>
									))}
								</select>
							</label>
						</Col>
						<Col lg={6} sm={12} className="mb-3">
							<label htmlFor="zip" className="w-100">
								Zip Code
								<input {...register('customerZipcode')} id="zip" />
								<small>{errors?.zip?.message}</small>
							</label>
						</Col>
						<Col lg={6} sm={12} className="mb-3">
							<label htmlFor="fax" className="w-100">
								Fax
								<input {...register('customerFax')} id="fax" />
								<small>{errors?.fax?.message}</small>
							</label>
						</Col>
						<Col lg={12} sm={12} className="mb-3">
							<label htmlFor="notes" className="w-100">
								Notes
								<textarea
									rows={4}
									placeholder="Write here..."
									className="notes-textarea"
									id="notes"
									{...register('customerNotes')}
								/>
								<small>{errors?.notes?.customerNotes}</small>
							</label>
						</Col>
					</Row>

					<div className="button-wrapper">
						<button type="button" className="close" onClick={onClose}>
							Close
						</button>

						<button type="submit" className="save" disabled={isLoading}>
							{isLoading ? <Spinner size="sm" animation="border" /> : 'Save'}
						</button>
					</div>
				</FormWrapper>
			</Modal.Body>
		</Modal>
	);
}

export default AddCustomerForm;
