import DataTable from 'react-data-table-component';
import { ReactComponent as SortIcon } from 'assets/icons/sort.svg';

const customStyles = {
	header: {
		style: {
			minHeight: '56px',
		},
	},
	headRow: {
		style: {
			// borderTopStyle: 'solid',
			// borderTopWidth: '1px',
			background: '#F3F3F3',
			borderRadius: '6px',
			border: 'none',
		},
	},
	headCells: {
		style: {
			fontWeight: '400',
			fontFamily: 'Poppins, sans-serif',
			fontSize: '1rem',
			'&:not(:last-of-type)': {
				// borderRightStyle: 'solid',
			},
		},
	},
	cells: {
		style: {
			fontFamily: 'Poppins, sans-serif',
			fontSize: '1rem',
			fontWeight: '400',
			borderBottom: '1px solid #f1f1f1',
			// '*': {
			// 	borderBottomWidth: '0px',
			// },
			'&:not(:last-of-type)': {
				// borderRightStyle: 'solid',
				// borderRightWidth: '1px',
			},
		},
	},
	rows: {
		style: {
			// backgroundColor: 'rgb(230, 244, 244)',
			border: '0px !important',
			minHeight: '60px',
		},
	},
};

export default function Tables({
	data,
	columns,
	pagination,
	defaultSortFieldId,
}) {
	return (
		<DataTable
			columns={columns}
			data={data?.filter((item) => item)}
			pagination={pagination}
			customStyles={customStyles}
			defaultSortFieldId={defaultSortFieldId}
			sortIcon={<SortIcon className="ms-2" />}
		/>
	);
}
