import { Col, Form, Row } from 'react-bootstrap';
import { Header } from 'style/addEq.styled';
import CustomInput from '../../../components/CustomInput';

export default function Setup({ register, errors }) {
	return (
		<div className="mb-6">
			<Header>
				<h5>Rig Configuration</h5>
			</Header>
			<Row>
				<CustomInput
					label="Tong Rod Transducer Max PSI (U3)"
					name="tong_max_psi"
					type="number"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="Tong Rod Transducer Max Torque (U3)"
					type="number"
					name="tong_max_torque_high"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="Block Travel"
					name="block_travel"
					register={register}
					type="number"
					errors={errors}
					min={0.0}
					max={1.0}
					step={0.1}
				/>
				<CustomInput
					label="Weight Transducer Max PSI (U5 & U7)"
					name="weight_max_psi"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Weight Max (in Klbs)"
					name="max_weight"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Driller Side (U5)"
					name="driller_side"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Offdriller Side (U7)"
					name="offdriller_side"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Air Pressure Max PSI (U6)"
					name="airp_psi"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Tong Pipe Transducer Max PSI (U10)"
					name="pipe_max_psi"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Tong Pipe Transducer Max Torque - Low gear..."
					name="tong_max_torque_low"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Tong Pipe Transducer Max Torque - high gea..."
					name="tong_max_torque_high"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Well Outlet Pressure Transducer MAX PSI (U4)"
					name="well_outlet_psi"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Well Outlet Flow Meter Calibration (U5)"
					name="well_outlet_flow"
					register={register}
					type="number"
					errors={errors}
				/>

				<Col lg={4}>
					<Row>
						<Col lg={6}>
							<Form.Group>
								<Form.Label>Set Min Cycle Values</Form.Label>
								<Form.Control
									placeholder="Enter Min Cycle Values"
									{...register('CycleSettingLower')}
									type="number"
									errors={errors}
								/>
							</Form.Group>
						</Col>
						<Col lg={6}>
							<Form.Group>
								<Form.Label>Set Max Cycle Values</Form.Label>
								<Form.Control
									placeholder="Enter Max Cycle Values"
									{...register('CycleSettingUpper')}
									type="number"
									errors={errors}
								/>
							</Form.Group>
						</Col>
					</Row>
				</Col>

				<CustomInput
					label="Set ZERO Threshold Well Inlet (U1)"
					name="zero_well_inlet"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Set ZERO Threshold Well Outlet (U4)"
					name="zero_well_outlet"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Set Total String Weight (for WOB in Klbs)"
					name="wob"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Set Dead Weight (for WOH in Klbs)"
					name="woh"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Set ZERO Threshold - Air (U6)"
					name="zero_airp_psi"
					register={register}
					type="number"
					errors={errors}
				/>
			</Row>
			<Header>
				<h5>Well Configuration - U1</h5>
			</Header>
			<Row>
				<CustomInput
					label="Well Inlet Pressure Transducer MAX PSI (U1)"
					name="well_inlet_psi_u1"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Well Inlet Flow Meter Calibration (U2)"
					name="well_inlet_flow_u1"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Well outlet Pressure Transducer MAX PSI (U4)"
					name="well_outlet_psi_u1"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Well Outlet Flow Meter Calibration (U5)"
					name="well_outlet_flow_u1"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Set ZERO Threshold Well Inlet (U1)"
					name="zero_well_inlet_u1"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Set ZERO Threshold Well Outlet (U4)"
					name="zero_well_outlet_u1"
					register={register}
					type="number"
					errors={errors}
				/>
			</Row>
			<Header>
				<h5>Chart Y-Axis Max Values</h5>
			</Header>
			<Row>
				<CustomInput
					label="Max Weight (in Klbs)"
					name="chart_rig_max_weight"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="RPM"
					name="chart_rig_max_rpm"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Block Position"
					name="chart_rig_block_position"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Pump Discharge Flow Rate"
					name="chart_rig_pump_flowrate"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Pump Discharge Pressure"
					name="chart_rig_pump_discharge"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Well Discharge Flow Rate"
					name="chart_rig_well_flowrate"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Well Discharge Pressure"
					name="chart_rig_well_discharge"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Max BPM"
					name="chart_rig_max_bpm"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Max GPM"
					name="chart_rig_max_gpm"
					register={register}
					type="number"
					errors={errors}
				/>
			</Row>
		</div>
	);
}
