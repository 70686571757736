import moment from 'moment';

function formatWindDirection(windDirection) {
	const degree = windDirection;
	let cardinalDirection = '';

	if (degree >= 0 && degree < 46) {
		cardinalDirection = 'South';
	} else if (degree >= 46 && degree < 91) {
		cardinalDirection = 'Southwest';
	} else if (degree >= 91 && degree < 136) {
		cardinalDirection = 'West';
	} else if (degree >= 136 && degree < 181) {
		cardinalDirection = 'Northwest';
	} else if (degree >= 181 && degree < 226) {
		cardinalDirection = 'North';
	} else if (degree >= 226 && degree < 271) {
		cardinalDirection = 'Northeast';
	} else if (degree >= 271 && degree < 316) {
		cardinalDirection = 'East';
	} else if (degree >= 316 && degree < 381) {
		cardinalDirection = 'Southeast';
	}
	return cardinalDirection;
}

function getUnixTimestamp(date) {
	return new Date(date).getTime();
}

const formatPhoneNumber = (phoneNumberString = '') => {
	const formattedNumber = phoneNumberString
		?.replace(/\D+/g, '')
		?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
	return formattedNumber;
};

const formatDateTime = (datetime) =>
	moment(datetime).format('MM-DD-YYYY hh:mm:ss a');

const formatDate = (date) => moment(date).format('MM-DD-YYYY');

export {
	formatWindDirection,
	getUnixTimestamp,
	formatPhoneNumber,
	formatDateTime,
	formatDate,
};
