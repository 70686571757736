import React from 'react';
import { FaGasPump } from 'react-icons/fa';

const styles = {
	width: 60,
	height: 25,
};
function GasIcon() {
	return <FaGasPump style={styles} />;
}

export default GasIcon;
