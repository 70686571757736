import { useRigDetail } from 'helpers/contexts/RigDetailProvider';
import PosiStopChart from 'pages/details/charts/PosiStopChart';
import DetailsCard from 'pages/details/DetailsCard';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const POSISTOP_DIAL = ['off', 'on', 'set'];

const Wrapper = styled.div`
	width: fit-content;
	.posi-stop-card {
		padding: 0.5;
	}
`;

export default function Posistop() {
	const { data }: any = useRigDetail();

	const DEFAULT_POSISTOP = {
		active: false,
		flag: '2',
		blockPosition: 0,
		percentOfTravel: 0,
		drawworksRpm: 0,
		warnLight: false,
		limitLight: false,
		posiSwitch: 'off',
		bulb: [],
		// encoder: data?.status?.calibration?.count,
		encoder: data?.positionCalibrationCount,
		lastCalibrationDate: data?.status?.calibration?.date,
	};

	const [posiStopData, setPosiStopData] = useState(DEFAULT_POSISTOP);
	const activeFlag = true;

	const handlePosiStopData = () => {
		const bulbFinder: any = [];
		if (data?.lights?.slips === 1 && bulbFinder.filter((i) => i === 0)) {
			bulbFinder.push(0);
		}

		if (data?.lights?.interlock === 1 && bulbFinder.filter((i) => i === 1)) {
			bulbFinder.push(1);
		}

		if (data?.lights?.rodmanual === 1 && bulbFinder.filter((i) => i === 2)) {
			bulbFinder.push(2);
		}

		if (data?.lights?.rodlockout === 1 && bulbFinder.filter((i) => i === 3)) {
			bulbFinder.push(3);
		}

		setPosiStopData({
			active: data?.status?.status?.online,
			flag: data?.status?.status?.flag,
			blockPosition: data?.positionPercent,
			percentOfTravel: data?.positionPercent,
			drawworksRpm: data?.speedFeetPerMinute,
			warnLight: data?.lights?.warning === 1,
			limitLight: data?.lights?.limit === 1,
			posiSwitch: POSISTOP_DIAL[data?.posistop_dial || 0], // on, off, set,
			bulb: bulbFinder,
			// encoder: data?.status?.calibration?.count,
			encoder: data?.positionCalibrationCount,
			lastCalibrationDate: data?.status?.calibration?.date,
		});
	};

	useEffect(() => {
		if (activeFlag) {
			handlePosiStopData();
		} else {
			// clearInterval(intervalRef.current);
		}
	}, [activeFlag, data]);

	return (
		<Wrapper>
			<DetailsCard className="h-full posi-stop-card" fullHeight>
				<PosiStopChart data={posiStopData || {}} />
			</DetailsCard>
		</Wrapper>
	);
}
