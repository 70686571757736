import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError';
import { updateUserPassword } from 'helpers/api/userManagement';
import { useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import * as yup from 'yup';

/**
 * A React component that renders a modal for changing a user's password.
 *
 * @param {object} props - The component props.
 * @param {number} props.uid - The ID of the user whose password is being changed.
 * @param {boolean} props.show - Whether the modal should be shown.
 * @param {function} props.handleClose - A function to be called when the modal is closed.
 * @returns {JSX.Element} - The rendered modal component.
 */
export default function UserChangePassword({ uid, show, handleClose }) {
	const [loading, setLoading] = useState(false);
	const schema = yup
		.object({
			userid: yup.number().default(parseInt(uid, 10)),
			oldpassword: yup.string().required('Old Password is required'),
			newpassword: yup.string().required('New Password is required'),
			confirm: yup
				.string()
				.required('Please confirm your password.')
				.oneOf([yup.ref('newpassword'), null], "Passwords don't match."),
		})
		.required();

	const { register, handleSubmit, formState, reset } = useForm({
		resolver: yupResolver(schema),
	});

	const { errors } = formState;

	const onSubmit = async (data) => {
		try {
			setLoading(true);
			const { errors: resErrors } = await updateUserPassword(data);
			if (resErrors && resErrors.length > 0) {
				toast.error(resErrors[0].message);
			} else {
				toast.success('Password reset successfully.');
				setLoading(false);
				reset();
				handleClose();
			}
		} catch (error) {
			toast.error(error.message || 'Something went wrong!');
		} finally {
			setLoading(false);
		}
	};

	return (
		<Modal show={show} onHide={handleClose} className="profile-modal" centered>
			<Modal.Body>
				<div className="information-container">
					<span className="information">Change Password</span>
				</div>
				<Form className="d-grid g-1" onSubmit={handleSubmit(onSubmit)}>
					<Form.Group controlId="old-password">
						<Form.Label>Old Password</Form.Label>
						<Form.Control
							type="password"
							placeholder="Enter old password"
							{...register('oldpassword')}
						/>
						<FormError>{errors.oldpassword?.message}</FormError>
					</Form.Group>
					<Form.Group controlId="new-password">
						<Form.Label>New Password</Form.Label>
						<Form.Control
							type="password"
							placeholder="Enter password"
							{...register('newpassword')}
						/>
						<FormError>{errors.newpassword?.message}</FormError>
					</Form.Group>
					<Form.Group controlId="confirm-new-password">
						<Form.Label>Confirm Password</Form.Label>
						<Form.Control
							type="password"
							placeholder="Confirm password"
							{...register('confirm')}
						/>
						<FormError>{errors.confirm?.message}</FormError>
					</Form.Group>

					<div className="button-wrapper ms-auto mt-3">
						<Button
							type="button"
							variant="bordered-green"
							className="me-3"
							onClick={handleClose}
						>
							Close
						</Button>
						<Button type="submit" variant="primary-green">
							{loading ? <Spinner size="sm" animation="border" /> : 'Save'}
						</Button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
