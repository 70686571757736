import { createGlobalStyle } from 'styled-components';

export const GlobalButtons = createGlobalStyle`
    .btn-primary-green {
        background: #00834d;
        border-radius: 12px;
        color: white;
        border: 1px solid ${(props) => props.theme.colors.primary};
        padding: 0.75rem 1.75rem;
        max-width: max-content;
        transition: all 0.2s ease-in-out;

        &:hover, &:focus, &:active {
            background: rgb(0, 168, 89) !important;
            border: 1px solid ${(props) => props.theme.colors.primary};
            color: white !important;
            box-shadow: none;
        }
    }

    .btn-unstyled-green {
        background: transparent;
        color: ${(props) => props.theme.colors.primary};

        &:disabled {
            color: rgb(0, 131, 77);
            border-color: transparent;
        }
        &:hover, &:focus, &:active {
         border-color: transparent;
         box-shadow: none;
        }
    }

    .btn-unstyled-red {
        background: transparent;
        color: ${(props) => props.theme.colors.red};
        &:hover, &:focus, &:active {
         border-color: transparent;
         box-shadow: none;
        }
    }

    .btn-unstyled-yellow {
        color: #EDB230;
        background: transparent;
        &:hover, &:focus, &:active {
         border-color: transparent;
         box-shadow: none;
        }
    }

    .btn-bordered-green {
        background: white;
        border-radius: 12px;
        color: black;
        border: 1px solid ${(props) => props.theme.colors.primary};
        padding: 0.75rem 1.75rem;
        max-width: max-content;
        transition: all 0.2s ease-in-out;

        &:hover, &:focus, &:active {
            background: rgb(0, 168, 89) !important;
            border: 1px solid rgb(0, 168, 89) !important;
            color: white !important;
            box-shadow: none;
        }
    }

    .btn-bordered-red {
        background: white;
        border-radius: 12px;
        color: black;
        border: 1px solid ${(props) => props.theme.colors.red};
        padding: 0.75rem 1.75rem;
        max-width: max-content;
        transition: all 0.2s ease-in-out;

        &:hover, &:focus, &:active {
            background: #ff0000;
            border: 1px solid #ff0000;
            color: white;
            box-shadow: none;
        }
    }

    .btn-transparent {
        color: gray;

        &:focus {
            box-shadow: none !important;
        }
        &:hover, &:focus, &:active {
            border-color: transparent;
            box-shadow: none;
        }
    }

    .btn-transparent-green {
        background: #F3F3F3;
        color: ${(props) => props.theme.colors.primary};

        &:hover, &:focus, &:active {
            box-shadow: none;
        }
    }

    .btn-text-gray {
        color: gray;

        &:hover, &:focus, &:active {
            color: gray;
            border-color: transparent;
            box-shadow: none;
        }
    }

    .text-loader-green {
        color: #00834d !important;
    }
`;
