import { ReactComponent as Right } from 'assets/icons/right-arrow.svg';
import { TableContainer } from 'pages/dashboard/tables.styled';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import useCameraLayout from '../../helpers/hooks/useCameraLayout';
import {
	DataContainer,
	HeaderWrapper,
	SelectionModal,
} from './styles/EquipmentsModal.styled';

export default function DeleteCameraLayoutModal({
	item,
	show,
	setModalShow,
	refetch,
}: {
	item: any;
	show: boolean;
	setModalShow: React.Dispatch<React.SetStateAction<boolean>>;
	refetch: () => void;
}) {
	const { deleteCameraLayout } = useCameraLayout();
	// @ts-ignore
	const handleSubmit = async (id: number) => {
		try {
			await deleteCameraLayout(id);
			setModalShow(false);
			refetch();
		} catch (error) {
			setModalShow(true);
		}
	};
	return (
		<SelectionModal
			size="lg"
			centered
			show={show}
			onHide={() => {
				setModalShow(false);
			}}
			className="profile-modal"
		>
			<Modal.Body>
				<TableContainer className="mt-0">
					<HeaderWrapper className="d-flex">
						<div className="back-container flex items-center">
							<Right />
							<button
								type="button"
								onClick={() => setModalShow(false)}
								style={{ all: 'unset', cursor: 'pointer' }}
							>
								<span className="ms-2">Back</span>
							</button>
						</div>
					</HeaderWrapper>

					<DataContainer>
						<div className="d-flex gap-4 w-full mt-6 items-center justify-center">
							<p className="text-center">
								Are you sure you want to delete layout {item.view_name} ?
							</p>
						</div>
						<Button
							variant="outline-danger"
							className="mt-4 float-end"
							onClick={() => handleSubmit(item.id)}
						>
							Delete
						</Button>
					</DataContainer>
				</TableContainer>
			</Modal.Body>
		</SelectionModal>
	);
}
