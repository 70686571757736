import { useUtils } from 'helpers/contexts/UtilsContext';
import React, { useState } from 'react';
import styled from 'styled-components';
import 'react-image-lightbox/style.css';

const Wrapper = styled.div`
	.lightbox {
		z-index: 1;
		position: absolute;
		inset: 0px;
		border: none;
		background: transparent;
		overflow: hidden;
		border-radius: 0px;
		outline: none;
		padding: 0px;
	}
	.outer-view {
		transition: opacity 300ms ease 0s;
		animation-duration: 300ms;
		animation-direction: reverse;
	}
	.rig-image {
		transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
	}
`;

function ImageViewer() {
	const { image, setImage, isOpen, setIsOpen } = useUtils();
	const [zoom, setZoom] = useState(1);
	const onClose = () => {
		setIsOpen(false);
		setImage();
		setZoom(1);
	};

	const handleZoomIn = () => {
		setZoom((z) => z + 1);
	};

	const handleZoomOut = () => {
		setZoom((z) => (z - 1 === 0 ? 1 : z - 1));
	};

	if (!isOpen) {
		return null;
	}

	return (
		<Wrapper>
			<div
				className="ReactModal__Content ReactModal__Content--after-open lightbox"
				tabIndex="-1"
				role="dialog"
				aria-label="Lightbox"
				aria-modal="true"
			>
				<div
					className="ril-outer ril__outer ril__outerAnimating outer-view "
					tabIndex="-1"
				>
					<div className="ril-inner ril__inner">
						<img
							className="ril-image-current ril__image rig-image"
							src={image}
							alt="rigImage"
							draggable="false"
							style={{
								transform: `translate3d(0px, 0px, 0px) scale3d(${zoom}, ${zoom}, 1)
							`,
							}}
						/>
					</div>
					<div className="ril-toolbar ril__toolbar">
						<ul className="ril-toolbar-left ril__toolbarSide ril__toolbarLeftSide">
							<li className="ril-toolbar__item ril__toolbarItem">
								<span className="ril-toolbar__item__child ril__toolbarItemChild" />
							</li>
						</ul>
						<ul className="ril-toolbar-right ril__toolbarSide ril__toolbarRightSide">
							<li className="ril-toolbar__item ril__toolbarItem">
								<button
									type="button"
									aria-label="Zoom in"
									title="Zoom in"
									className="ril-zoom-in ril__toolbarItemChild ril__builtinButton ril__zoomInButton"
									onClick={handleZoomIn}
								/>
							</li>
							<li className="ril-toolbar__item ril__toolbarItem">
								<button
									type="button"
									aria-label="Zoom out"
									title="Zoom out"
									className={`ril-zoom-out ril__toolbarItemChild ril__builtinButton ril__zoomOutButton ${
										zoom === 1 ? 'ril__builtinButtonDisabled' : ''
									}`}
									disabled={zoom === 1}
									onClick={handleZoomOut}
								/>
							</li>
							<li className="ril-toolbar__item ril__toolbarItem">
								<button
									type="button"
									aria-label="Close lightbox"
									title="Close lightbox"
									onClick={onClose}
									className="ril-close ril-toolbar__item__child ril__toolbarItemChild ril__builtinButton ril__closeButton"
								/>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</Wrapper>
	);
}

export default ImageViewer;
