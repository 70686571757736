/* eslint-disable react/jsx-filename-extension */
import LogList from 'pages/project-logs/LogList';
import DashboardLayout from 'components/Layouts/DashboardLayout';
import useResponsive from 'helpers/hooks/useResponsive';
import LogListMobile from 'pages/project-logs/LogListMobile';

export default function ProjectLogs() {
	const { isMobile } = useResponsive();

	return (
		<DashboardLayout>
			{isMobile ? <LogListMobile /> : <LogList />}
		</DashboardLayout>
	);
}
