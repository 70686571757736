import { isRigOffline } from 'components/EquipmentHandler';
import RigLayout from 'components/Layouts/RigLayout';
import { useRigDetail } from 'helpers/contexts/RigDetailProvider';
import DetailSkeletonLoading from 'pages/details/rig/tabs/Detail/DetailSkeletonLoading';
import Events from 'pages/details/rig/tabs/Detail/detailsTab/Events';
import Messages from 'pages/details/rig/tabs/Detail/detailsTab/Messages';
import Posistop from 'pages/details/rig/tabs/Detail/detailsTab/Posistop';
import RigLocation from 'pages/details/rig/tabs/Detail/detailsTab/RigLocation';
import WeightChart from 'pages/details/rig/tabs/Detail/detailsTab/WeightChart';
import { Col } from 'react-bootstrap';

/**
 * DetailsTab component fetches rig details data using React Query.
 * It displays details in different sections like posistop, weight chart etc.
 * Requires rig id passed via router params.
 * Handles loading state and errors.
 */
export default function DetailsTab() {
	const { data, isLoading }: any = useRigDetail();

	return (
		<RigLayout>
			{isLoading ? (
				<DetailSkeletonLoading />
			) : (
				<div className="flex gap-2 flex-wrap  relative">
					{/* If Righ is offline then showing greyed layout over. */}
					{isRigOffline(data) === true && (
						<div className="absolute z-10 top-0 left-0 bg-gray-700/30 w-full h-full rounded pointer-events-none outline-none cursor-not-allowed flex items-center justify-center" />
					)}
					<Posistop />
					<Col className="d-flex flex-column">
						<WeightChart />
						<RigLocation />
					</Col>
					<Col className="d-flex flex-column">
						<Messages />
						<Events />
					</Col>
				</div>
			)}
		</RigLayout>
	);
}
