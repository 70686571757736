import { rigChartLabels } from 'helpers/const/chartLabels';
import { ChartContainer } from 'pages/details/styled';
import { useState } from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DailyChartGeneral from './DailyChartGeneral';
import LiveChartGeneral from './LiveChartGeneral';

export default function ActivityTong() {
	const [keyPump, setKeyPump] = useState('TORQUE');
	const [daily, setDaily] = useState(false);
	const [selectedDate, setSelectedDate] = useState(new Date());

	const handleDaily = (d) => setDaily(d);

	const chartselecteddate = (date, d) => {
		setSelectedDate(date);
		setDaily(d);
	};
	return (
		<Col lg={12}>
			<ChartContainer>
				<Row className="header">
					<Col className="align-self-center">
						<h1 className="align-self-center">{rigChartLabels.tong}</h1>
					</Col>
					<Col className="change-container">
						<button
							type="button"
							onClick={() => (daily ? handleDaily(false) : handleDaily(true))}
							className="change-span"
						>
							{daily ? 'Live chart' : '24h chart'}
						</button>
						<button type="button">
							<DatePicker
								selected={selectedDate}
								onChange={(date) => chartselecteddate(date, true)}
							/>
						</button>
					</Col>
				</Row>
				<Tabs
					id="controlled-tab-example"
					activeKey={keyPump}
					onSelect={(k: any) => setKeyPump(k)}
					className="mb-3"
				>
					<Tab eventKey="TORQUE" title={rigChartLabels.tong_torque}>
						{daily && keyPump === 'TORQUE' && (
							<DailyChartGeneral
								chart={rigChartLabels.tong_torque_key}
								date={selectedDate}
								subitem={rigChartLabels.tong_torque_key}
							/>
						)}

						{!daily && keyPump === 'TORQUE' && (
							<LiveChartGeneral
								chart={rigChartLabels.tong_torque_key}
								subchart={rigChartLabels.tong_torque}
								datakey={rigChartLabels.tong_torque_key}
							/>
						)}
					</Tab>

					<Tab eventKey="PRESSURE" title={rigChartLabels.tong_psi}>
						{daily && keyPump === 'PRESSURE' && (
							<DailyChartGeneral
								chart={rigChartLabels.tong_psi_key}
								date={selectedDate}
								subitem={rigChartLabels.tong_psi_key}
							/>
						)}

						{!daily && keyPump === 'PRESSURE' && (
							<LiveChartGeneral
								chart={rigChartLabels.tong_psi_key}
								subchart={rigChartLabels.tong_psi}
								datakey={rigChartLabels.tong_psi_key}
							/>
						)}
					</Tab>
				</Tabs>
			</ChartContainer>
		</Col>
	);
}
