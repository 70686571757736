import React, { useEffect, useState } from 'react';

interface ButtonProps {
	children: React.ReactNode;
	variant?: 'primary' | 'delete' | 'secondary' | 'delete-secondary' | null;
	type: 'button' | 'submit';
	onClick?: () => void;
	isLoading?: boolean;
	disabled?: boolean;
	style?: React.CSSProperties;
	className?: string;
}

const Button: React.FC<ButtonProps> = ({
	children,
	variant,
	type,
	style,
	onClick = () => {},
	isLoading = false,
	disabled = false,
	className = '',
}) => {
	const [classChain, setClassChain] = useState<string>('');

	useEffect(() => {
		const borderClassChain = 'border-solid border-[1px]';
		let variantClass = '';

		switch (variant) {
			case 'primary':
				variantClass = `bg-[#00834d] text-white hover:bg-[#00a859] ${borderClassChain} border-[#00834d]`;
				break;
			case 'secondary':
				variantClass = `text-black ${borderClassChain} hover:border-[#00834d]`;
				break;
			case 'delete':
				variantClass = `text-black ${borderClassChain} hover:border-[#ff3333]`;
				break;
			case 'delete-secondary':
				variantClass = `text-[#ff3333] hover:text-[#ff6d6d] text-left p-0`;
				break;
			default:
				variantClass =
					'text-[#00834d] hover:text-[#00a859] p-0 text-left disabled:opacity-[0.65]';
		}

		setClassChain(variantClass);
	}, [variant]);

	const mainClassChain =
		'transition-all rounded-[12px] px-[1.75rem] py-[0.75rem] max-w-max disabled:cursor-not-allowed';

	return (
		<button
			className={`${className} ${classChain} ${mainClassChain}`}
			type={type === 'submit' ? 'submit' : 'button'}
			onClick={onClick}
			disabled={isLoading || disabled}
			style={style}
		>
			{children}
		</button>
	);
};

Button.defaultProps = {
	onClick: () => {},
	isLoading: false,
	disabled: false,
	variant: null,
	style: {},
	className: '',
};

export default Button;
