import { rigChartLabels } from 'helpers/const/chartLabels';
import { ChartContainer } from 'pages/details/styled';
import React from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DailyChartGeneral from './DailyChartGeneral';
import LiveChartGeneral from './LiveChartGeneral';

function ActivityPump() {
	const [keyPump, setKeyPump] = React.useState('GPM');
	const [daily, setDaily] = React.useState(false);
	const [selectedDate, setSelectedDate] = React.useState(new Date());

	const handleDaily = (d) => {
		setDaily(d);
	};

	const chartselecteddate = (date, d) => {
		setSelectedDate(date);
		setDaily(d);
	};

	return (
		<Col lg={12}>
			<ChartContainer>
				<Row className="header">
					<Col className="align-self-center">
						<h1 className="align-self-center">{rigChartLabels.pump}</h1>
					</Col>
					<Col className="change-container">
						<button
							type="button"
							onClick={() => (daily ? handleDaily(false) : handleDaily(true))}
							className="change-span"
						>
							{daily ? 'Live chart' : '24h chart'}
						</button>
						<button type="button">
							<DatePicker
								selected={selectedDate}
								onChange={(date) => chartselecteddate(date, true)}
							/>
						</button>
					</Col>
				</Row>
				<Tabs
					id="controlled-tab-example"
					activeKey={keyPump}
					onSelect={(k: any) => setKeyPump(k)}
					className="mb-3"
				>
					<Tab eventKey="GPM" title={rigChartLabels.pump_flow}>
						{daily && keyPump === 'GPM' && (
							<DailyChartGeneral
								chart="pump_flow"
								date={selectedDate}
								subitem={rigChartLabels.pump_flow}
							/>
						)}

						{!daily && keyPump === 'GPM' && (
							<LiveChartGeneral
								chart="pump_flow"
								subchart={rigChartLabels.pump_flow}
								datakey="pump_flow"
							/>
						)}
					</Tab>

					<Tab eventKey="PSIG" title={rigChartLabels.pump_psi}>
						{daily && keyPump === 'PSIG' && (
							<DailyChartGeneral
								chart="pump_psig"
								date={selectedDate}
								subitem={rigChartLabels.pump_psi}
							/>
						)}

						{!daily && keyPump === 'PSIG' && (
							<LiveChartGeneral
								chart="pump_psig"
								subchart={rigChartLabels.pump_psi}
								datakey="pump_psi"
							/>
						)}
					</Tab>
				</Tabs>
			</ChartContainer>
		</Col>
	);
}

export default ActivityPump;
