import { getEquipmentActivity } from 'helpers/api/equipments';
import { Spinner, Table } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { ActivityContainer } from '../styled';

export default function TableActivityBreakdown({ id, UOM, prettifyCategory }) {
	const { data, isFetching } = useQuery(
		'tableactivity',
		() => getEquipmentActivity(id, false),
		{
			refetchOnMount: true,
			enabled: !!id,
		},
	);

	if (isFetching)
		return (
			<ActivityContainer loader>
				<Spinner animation="border" className="mb-5" />{' '}
			</ActivityContainer>
		);

	if (data?.error)
		return (
			<ActivityContainer>
				<h6 className="text-center mt-5">{data?.error}</h6>
			</ActivityContainer>
		);

	const formatHour = (hour) => (hour.length === 1 ? `0${hour}` : hour);
	const formatMin = (min) => (min === 0 ? '00' : min);

	const MINUTES = [0, 15, 30, 45];
	const HOURS = Object.keys(data?.drawdata);
	const CATEGORY = Object.keys(data?.drawdata?.[0]?.[0]);

	return (
		<Table responsive>
			<thead>
				<tr>
					<th style={{ minWidth: 250 }}>Category</th>
					<th style={{ minWidth: 150 }}>UOM</th>
					{HOURS.map((h) =>
						MINUTES.map((min) => (
							<th key={(h, min)}>
								{formatHour(h)}:{formatMin(min)}
							</th>
						)).reverse(),
					).reverse()}
				</tr>
			</thead>
			<tbody>
				{CATEGORY?.map((item) => (
					<tr>
						<td>{prettifyCategory(item)}</td>
						<td>{UOM[item]}</td>
						{HOURS.map((h) =>
							MINUTES.map((min) => (
								<td key={(h, min)}>{data?.drawdata?.[h]?.[min]?.[item]}</td>
							)).reverse(),
						).reverse()}
					</tr>
				))}
			</tbody>
		</Table>
	);
}
