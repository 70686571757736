/*eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useParams } from 'react-router-dom';
import { getEquipmentChart } from 'helpers/api/equipments';
import moment from 'moment';

const LiveChartGeneral = ({ chart, subchart, datakey }: any) => {
	const { id } = useParams();
	const [min, setMin] = useState(0);
	const [max, setMax] = useState(0);
	const [ticks, setTicks] = useState(4);
	const mountedRef = useRef(false);
	const headerDataRef: any = useRef();
	const headerTimeRef: any = useRef();
	const [InitialChartData, setInitialChartData] = useState<any>([]);

	useEffect(() => {
		mountedRef.current = true;
		return () => {
			mountedRef.current = false;
		};
	}, []);
	useEffect(() => {
		//MinMax values
		switch (chart) {
			case 'position':
				setMin(0);
				setMax(110);
				setTicks(7);
				break;
			case 'drawworks':
				setMin(0);
				setMax(350);
				setTicks(8);
				break;
			case 'swivelrpm':
				setMin(0);
				setMax(200);
				setTicks(5);
				break;
			case 'swiveltorque':
				setMin(0);
				setMax(7500);
				setTicks(5);
				break;
			case 'swivelpressure':
				setMin(0);
				setMax(5000);
				setTicks(5);
				break;
			case 'tongtorque':
				setMin(0);
				setMax(7);
				setTicks(5);
				break;
			case 'tongpressure':
				setMin(0);
				setMax(5);
				setTicks(5);
				break;
			case 'pump_flow':
				setMin(0);
				setMax(10);
				setTicks(5);
				break;
			case 'pump_psig':
				setMin(0);
				setMax(5000);
				setTicks(4);
				break;
			case 'pump_strokes':
				setMin(0);
				setMax(200);
				setTicks(6);
				break;
			case 'pump_suction':
				setMin(0);
				setMax(300);
				setTicks(6);
				break;
			case 'well_flow':
				setMin(0);
				setMax(10);
				setTicks(5);
				break;
			case 'well_psig':
				setMin(0);
				setMax(5000);
				setTicks(4);
				break;
		}
	}, []);

	const getData = async () => {
		const res = await getEquipmentChart(id, Date.now() - 1, Date.now(), chart);
		if (
			res?.data?.equipmentChartData?.charts?.[chart]?.[0]?.[datakey + 'limit']
		) {
			setMax(
				res?.data?.equipmentChartData?.charts?.[chart]?.[0]?.[
					datakey + 'limit'
				],
			);
		}
		return res?.data?.equipmentChartData?.charts?.[chart];
	};

	useEffect(() => {
		if (mountedRef.current) {
			getEquipmentChart(id, Date.now() - 1, Date.now(), chart).then((res) => {
				setInitialChartData(
					chart === 'position' || chart === 'drawworks'
						? res?.data?.equipmentChartData?.[chart]?.[0]?.[datakey]
						: res?.data?.equipmentChartData?.[chart]?.[0]?.[subchart],
				);
			});
		}
	}, []);

	const options = {
		chart: {
			type: 'spline',
			animation: Highcharts.SVGRenderer, // don't animate in old IE
			marginRight: 10,
		},

		time: {
			useUTC: false,
		},

		title: false,
		credits: {
			enabled: false,
		},

		accessibility: {
			announceNewData: {
				enabled: true,
				minAnnounceInterval: 1000,
				announcementFormatter: function (newPoint) {
					if (newPoint) {
						return 'New point added. Value: ' + newPoint.y;
					}
					return false;
				},
			},
		},

		xAxis: {
			type: 'datetime',
			tickPixelInterval: 150,
			dateTimeLabelFormats: {
				second: '%I:%M:%S',
			},
		},

		yAxis: {
			min: min,
			max: max,
			tickAmount: ticks,
			title: {
				text: 'Value',
			},
			plotLines: [
				{
					value: 0,
					width: 1,
					color: '#808080',
				},
			],
		},

		tooltip: {
			headerFormat: '<b>{series.name}</b><br/>',
			pointFormat: '{point.x:%Y-%m-%d %H:%M:%S}<br/>{point.y:.0f}',
		},

		legend: {
			enabled: false,
		},

		exporting: {
			enabled: false,
		},

		series: [
			{
				marker: {
					enabled: false,
				},
				name: subchart,
				data: (function () {
					// generate an array of random data
					var data: any = [],
						time = new Date().getTime(),
						i;
					for (i = -59; i <= 0; i += 1) {
						data.push({
							x: (InitialChartData?.timestamp || time) + i * 1000,
							y: Number(
								datakey === 'block_position' || datakey === 'rpm'
									? InitialChartData
									: InitialChartData?.[datakey] || 0,
							),
						});
					}
					return data;
				})(),
			},
			{
				marker: {
					enabled: false,
				},
				name: subchart,
				data: (function () {
					// generate an array of random data
					var data: any = [],
						time = new Date().getTime(),
						i;
					for (i = -59; i <= 0; i += 1) {
						data.push({
							x: (InitialChartData?.timestamp || time) + i * 1000,
							y: Number(
								datakey === 'block_position' || datakey === 'rpm'
									? InitialChartData
									: InitialChartData?.[datakey] || 1,
							),
						});
					}
					return data;
				})(),
			},
		],
	};

	const chartComponentRef: any = React.useRef(null);

	useEffect(() => {
		const chart = chartComponentRef.current.chart;
		const series = chart.series[0];

		const updateInterval = setInterval(() => {
			getData().then((res) => {
				var x = new Date().getTime(), // current time
					y = Math.round(parseFloat(res?.[0]?.[datakey])) || 0;
				try {
					if (mountedRef.current) {
						headerDataRef.current.innerHTML = y;
						headerTimeRef.current.innerHTML = moment(x).format('HH:mm:ss');
						series.addPoint([x, y], true, true);
						// check if data key exists in subchart
						if (res?.[1] && datakey in res?.[1]) {
							const anotherSeries = chart.series[1];
							anotherSeries.addPoint([x, y], true, true);
						}
					}
				} catch (error) {
					clearInterval(updateInterval);
				}
			});
		}, 1000);

		return () => clearInterval(updateInterval);
	}, []);

	return (
		<>
			<div className="details my-3">
				<div>
					<span className="tag mb-4 me-2">{subchart}:</span>
					<span ref={headerDataRef}>0</span>
					<span className="tag mb-4 ms-4 me-2">Local Timing:</span>
					<span ref={headerTimeRef}>{moment().format('HH:mm:ss')}</span>
				</div>
			</div>

			<HighchartsReact
				ref={chartComponentRef}
				highcharts={Highcharts}
				options={options}
			/>
		</>
	);
};

export default React.memo(LiveChartGeneral);
