import { DashboardContextProvider } from 'pages/dashboard/DashboardContext';
import CamFeed from '../CamFeed';

// eslint-disable-next-line func-names
export default function () {
	return (
		<DashboardContextProvider>
			<CamFeed />
		</DashboardContextProvider>
	);
}
