import PumpLayout from 'components/Layouts/PumpLayout';
import DetailsCard from 'pages/details/DetailsCard';
import { Col, Row } from 'react-bootstrap';

function PumpDocuments() {
	return (
		<table className="table table-bordered" id="rigReport">
			<thead>
				<tr>
					<th>Certification</th>
					<th>Effective Date</th>
					<th>Expiration Date</th>
					<th>Days Left</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>
						<a
							href="certifications/DA0001/DA0001_C08_2021-03-01_2021-08-31"
							target="_blank"
						>
							Cat 3 Inspection
						</a>
					</td>
					<td>03-01-2021</td>
					<td>08-31-2021</td>
					<td style={{ color: 'red' }}>-238</td>
				</tr>
			</tbody>
		</table>
	);
}

function CertificationManager() {
	return (
		<div className="formWrapper">
			<div className="formValidation" style={{ display: 'none' }}>
				<ul />
			</div>
			<form name="certificationUpload" id="certificationUpload" method="POST">
				<div className="formRow">
					<label htmlFor="rignumber" style={{ width: '120px' }}>
						Equipment Number
						<input
							type="text"
							className="textfield"
							id="rignumber"
							name="rignumber"
							value="DA0001"
							disabled
						/>
					</label>
				</div>
				<div className="formRow">
					<label htmlFor="certType" style={{ width: '120px' }}>
						Certification Type
						<select className="dropdown" id="certType" name="certType" required>
							<option className="dropLabel" value="C01|1">
								Weight Indicator
							</option>
							<option className="dropLabel" value="C02|1">
								Tubing Line
							</option>
							<option className="dropLabel" value="C03|1">
								Travelling Block
							</option>
							<option className="dropLabel" value="C04|1">
								Transmission
							</option>
							<option className="dropLabel" value="C05|1">
								Engine
							</option>
							<option className="dropLabel" value="C06|1">
								Line Scale
							</option>
							<option className="dropLabel" value="C07|1">
								Lift Package
							</option>
							<option className="dropLabel" value="C08|1">
								Cat 3 Inspection
							</option>
							<option className="dropLabel" value="C09|5">
								Cat 4 Inspection
							</option>
							<option className="dropLabel" value="C10|2">
								Cat 5 Inspection
							</option>{' '}
						</select>
					</label>
				</div>
				<div className="formRow">
					<label htmlFor="effectiveDate" style={{ width: '120px' }}>
						Effective Date
						<input
							type="date"
							className="textfield"
							id="effectiveDate"
							name="effectiveDate"
							required
						/>
					</label>
				</div>
				<div className="formRow">
					<label htmlFor="expirationDate" style={{ width: '120px' }}>
						Expiration Date
						<input
							type="date"
							className="textfield"
							id="expirationDate"
							name="expirationDate"
							required
						/>
					</label>
				</div>
				<div className="formRow">
					<label htmlFor="certificate" style={{ width: '120px' }}>
						Expiration Date
						<input
							type="file"
							className="textfield"
							id="certificate"
							name="certificate"
							required
						/>
					</label>
				</div>
			</form>
		</div>
	);
}

function PumpDocumentExports() {
	return (
		<div>
			<form id="expReport">
				<div className="formRow">
					<input
						type="submit"
						formAction="docexprpt.php"
						value="Expiration Report"
						style={{ textAlign: 'left', height: '38px' }}
					/>
					<select id="indays" name="indays">
						<option value="30">30 days or less</option>
						<option value="60" selected>
							60 days or less
						</option>
						<option value="90">90 days or less</option>
					</select>
				</div>
				<div className="formRow">
					<input
						type="submit"
						formAction="docsummrpt.php"
						value="Summary Report"
						style={{ textAlign: 'left', height: '38px' }}
					/>
				</div>
			</form>
		</div>
	);
}

export default function PumpDocSys() {
	return (
		<PumpLayout>
			<Row>
				<Col lg={5}>
					<DetailsCard title="Current Certifications">
						<PumpDocuments />
					</DetailsCard>
					<DetailsCard title="Certification Manager">
						<CertificationManager />
					</DetailsCard>
				</Col>
				<Col lg={5}>
					<DetailsCard title="Certification Reports">
						<PumpDocumentExports />
					</DetailsCard>
				</Col>
			</Row>
		</PumpLayout>
	);
}
