import { getToken } from 'helpers/utils/auth-provider';
import client from './apolloClient';
import { PROFILE_QUERY } from '../queries/userQuery';

export const updateUserInfo = (id, data) =>
	client.put(`/users/${id}`, data).then((res) => {
		if (res?.data?.success) {
			return res.data;
		}
		throw Error(res.data.result || 'Something went wrong!');
	});

export const getUserInfo = () =>
	client.query({
		query: PROFILE_QUERY,
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
	});
