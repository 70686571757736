import AuthLayout from 'components/Layouts/AuthLayout';
import { LoginHeader } from './login.styled';
import LoginForm from './LoginForm';

// eslint-disable-next-line func-names
export default function () {
	return (
		// eslint-disable-next-line react/jsx-filename-extension
		<AuthLayout>
			<LoginHeader>Customer Login</LoginHeader>
			<LoginForm />
		</AuthLayout>
	);
}
