import React, { useMemo } from 'react';

const UtilsContext = React.createContext();
UtilsContext.displayName = 'UtilsContext';

function UtilsProvider(props) {
	const [image, setImage] = React.useState();
	const [isOpen, setIsOpen] = React.useState(false);

	const value = useMemo(
		() => ({
			setImage,
			image,
			isOpen,
			setIsOpen,
		}),
		[image, isOpen],
	);

	return <UtilsContext.Provider value={value} {...props} />;
}

function useUtils() {
	const context = React.useContext(UtilsContext);
	return context;
}

export { UtilsProvider, useUtils };
