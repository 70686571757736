import { createGlobalStyle } from 'styled-components';

export const CssUtils = createGlobalStyle`
    .h-auto {
        height: auto;
    }
    .accordion-button {
        transition: box-shadow 0.3s ease-in-out;
        &:focus {
            border-color: transparent;
            box-shadow: none;
        }
    }
    .vertical-align-middle {
        vertical-align: middle;
    }
    .h-90 {
        height: 90vh;
    }
    .h-85 {
        height: 85vh;
    }
    .color-primary {
        color: ${({ theme }) => theme.colors.primary};
    }
`;
