/*eslint-disable */

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';

function DailyChart({ data, subitem }) {
	const computedData = React.useMemo(() => {
		if (data?.length > 0) {
			return ['weight', 'woh', 'wob'].map((item) => {
				return {
					name: item,
					data: data.map((r) => [r?.timestamp, r[item]]),
				};
			});
		}
	}, [data]);

	return (
		<div>
			{computedData ? (
				<HighchartsReact
					highcharts={Highcharts}
					options={{
						title: {
							text: '',
						},
						credits: {
							enabled: false,
						},
						rangeSelector: {
							selected: 4,
						},
						xAxis: {
							labels: {
								enabled: false,
							},
						},
						yAxis: {
							labels: {
								formatter: function () {
									return (this.value > 0 ? ' + ' : '') + this.value;
								},
							},
							plotLines: [
								{
									value: 0,
									width: 2,
									color: 'silver',
								},
							],
						},

						plotOptions: {
							series: {
								compare: 'percent',
								showInNavigator: true,
							},
						},

						tooltip: {
							headerFormat: '<b>{series.name}</b><br/>',
							pointFormat: '{point.x}<br/>{point.y:.0f}',
						},

						chart: {
							zoomType: 'x',
						},

						series: computedData,
					}}
				/>
			) : (
				<span>Loading...</span>
			)}
		</div>
	);
}

export default React.memo(DailyChart);
