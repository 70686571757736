import PumpLayout from 'components/Layouts/PumpLayout';
import ChartActivity from 'pages/details/pump/ChartActivity';
import TableActivity from 'pages/details/rig/tabs/RigActivity/TableActivity';
import { useState } from 'react';

export default function PumpActivity() {
	const [tableView, setTableView] = useState(false);
	const handleView = () => setTableView(!tableView);

	return (
		<PumpLayout>
			<span>Graphs</span>
			<label className="switch">
				<input type="checkbox" onChange={handleView} />
				<span className="slider round" />
			</label>
			<span>Table</span>
			{tableView && <TableActivity />}
			{!tableView && <ChartActivity />}
		</PumpLayout>
	);
}
