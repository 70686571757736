/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import NoData from 'components/NoData';
import { Button, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

const ActionButton = styled(Button)`
	background-color: ${({ theme }) =>
		theme.colors.backgroundDarkGray} !important;
	border-color: ${({ theme }) => theme.colors.backgroundDarkGray} !important;
	color: ${({ theme }) => theme.colors.primary} !important;
	padding: 0.75rem 1rem;
	border-radius: 6px;
	box-shadow: none !important;
`;

const DeleteButton = styled(ActionButton)`
	color: ${({ theme }) => theme.colors.red} !important;
`;

function UserListMobile({ data = [], onDelete, onEdit }) {
	if (data?.length <= 0) return <NoData />;
	return data?.map((item) => (
		<div className="project-list-mobile mb-3" key={item.userid}>
			<Row>
				<Col>
					<div className="display-grid mb-3">
						<span className="tags">Username</span>
						<span className="project-id">
							<div role="button" onClick={onEdit(item)}>
								{item.username}
							</div>
						</span>
					</div>
				</Col>
				<Col>
					<div className="display-grid mb-3">
						<span className="tags">Permission</span>
						<span>{item.permission_level}</span>
					</div>
				</Col>
			</Row>
			<div className="d-flex justify-content-between">
				<Col>
					<div className="display-grid mb-3">
						<span className="tags">First Name</span>
						<span>{item.name}</span>
					</div>
				</Col>
				<Col>
					<div className="display-grid mb-3">
						<span className="tags">Middle name</span>
						<span>{item.middlename || '-'}</span>
					</div>
				</Col>
				<Col>
					<div className="display-grid mb-3">
						<span className="tags">Last name</span>
						<span>{item.lastname}</span>
					</div>
				</Col>
			</div>
			<div className="d-flex gap-3">
				{/* <ActionButton>Password</ActionButton> */}
				<DeleteButton onClick={onDelete(item)}>Delete</DeleteButton>
			</div>
		</div>
	));
}

export default UserListMobile;
