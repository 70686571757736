import GoogleMapReact from 'google-map-react';
import { GOOGLE_MAP_API_KEY } from 'helpers/const';
import { useRigDetail } from 'helpers/contexts/RigDetailProvider';
import { BasicMarker } from 'pages/Mapview/MyMarker';
import DetailsCard from 'pages/details/DetailsCard';

function createMapOptions(maps) {
	return {
		zoomControlOptions: {
			position: maps.ControlPosition.RIGHT_CENTER,
			style: maps.ZoomControlStyle.SMALL,
		},
		mapTypeId: maps.MapTypeId.HYBRID,
		draggable: false,
	};
}

export default function RigLocation() {
	const { data }: any = useRigDetail();

	return (
		<DetailsCard
			className="mb-0 cards cards-full-height flex-grow-1"
			area-hidden="true"
		>
			{data ? (
				<GoogleMapReact
					bootstrapURLKeys={{
						key: GOOGLE_MAP_API_KEY,
						language: 'en',
						region: 'US',
					}}
					// defaultCenter={{
					// 	lat: parseFloat(data?.latitude),
					// 	lng: parseFloat(data?.longitude),
					// }}
					center={{
						lat: parseFloat(data?.latitude || 0.0),
						lng: parseFloat(data?.longitude || 0.0),
					}}
					defaultZoom={16}
					style={{
						contain: 'strict',
						height: `100%`,
						position: 'relative',
						borderRadius: '12px',
						width: '100%',
					}}
					options={createMapOptions}
				>
					<BasicMarker
						lat={data?.latitude || 0.0}
						lng={data?.longitude || 0.0}
						type={data?.status?.type}
					/>
				</GoogleMapReact>
			) : null}
		</DetailsCard>
	);
}
