import { Modal, Form, Button, Accordion, Card } from 'react-bootstrap';
import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormError from 'components/FormError';
import { useAuth } from 'helpers/contexts/AuthContext';
import { resetPassword } from 'helpers/api/userManagement';
import toast from 'react-hot-toast';
import {
	EquimentSelector,
	Yard,
	SelectAllRegion,
	AccordionTitle,
} from 'pages/alert-management/styled';

export default function AlertEquipmentListPopup({
	equipmentList,
	timezones,
	saved,
	show,
	category,
	handleClose,
}) {
	const { user } = useAuth();
	const formData = JSON.parse(localStorage.getItem('userAlerts'));
	let selectEq;
	const schema = yup
		.object({
			userid: yup.string().default(user?.userid),
			oldpassword: yup.string().required('Old Password is required'),
			newpassword: yup.string().required('New Password is required'),
			confirm: yup.string().required(''),
		})
		.required();

	const selectEquipment = (e) => {
		// If e is a serial get the correct values
		try {
			const cat = category.name;
			const btn = e.target.closest('.card');
			const serial = btn.getAttribute('eventkey');
			// Check if it was selected or not
			const selected =
				selectEq !== undefined ? selectEq : !btn.classList.contains('selected');
			// Add alert to alertLisdt for equipment
			// Find or add equipment
			const foundIndex = formData.findIndex((el) => serial === el.serial);
			if (foundIndex === -1) {
				// Add equipment
				formData.push({
					serial,
					alertList: [{ name: cat, sms: true, email: true, push: true }],
				});
			} else {
				// Update equipment
				const aLIndex = formData[foundIndex].alertList.findIndex(
					(al) => al.name === cat,
				);
				if (aLIndex === -1) {
					// Add category
					formData[foundIndex].alertList.push({
						name: cat,
						sms: true,
						email: true,
						push: true,
					});
				} else {
					// if(!selected && formData[foundIndex].alertList.length === 1){
					// 	delete formData[foundIndex];
					// }
					// else{
					formData[foundIndex].alertList[aLIndex] = {
						name: cat,
						sms: selected,
						email: selected,
						push: selected,
					};
					// }
				}
			}
			localStorage.setItem('userAlerts', JSON.stringify(formData));
			// Select or deselect
			btn.classList.remove('selected');
			if (selected) btn.classList.add('selected');
			const eqbtn = document.querySelectorAll(`.card`);
			const eqsel = document.querySelectorAll(`.card.selected`);
			const catsel = document.querySelector(`[data-category='${cat}']`);
			catsel.checked = eqbtn.length === eqsel.length;
		} catch (err) {
			toast.error(`Unable to select equipment: ${err.message}`);
		}
		return true;
	};
	const selectAllYard = (e) => {
		const btns = e.target.closest('.project').nextElementSibling.children[0];
		const serials = btns.querySelectorAll('.card');
		const serialsSelected = btns.querySelectorAll('.selected');
		serials.forEach((el) => {
			// selectEquipment(el.childNodes[0].click(), (serials.length !== serialsSelected.length))
			selectEq = serials.length !== serialsSelected.length;
			el.childNodes[0].click();
		});
		selectEq = undefined;
	};
	const selectAllRegion = (e) => {
		const btns = e.target.nextElementSibling.children[0];
		const serials = btns.querySelectorAll('.card');
		const serialsSelected = btns.querySelectorAll('.selected');
		serials.forEach((el) => {
			// selectEquipment(el.childNodes[0].click(), (serials.length !== serialsSelected.length))
			selectEq = serials.length !== serialsSelected.length;
			el.childNodes[0].click();
		});
		selectEq = undefined;
	};

	return (
		<Modal
			size="xl"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			show={show}
			onHide={handleClose}
			className="profile-modal"
		>
			<Modal.Body>
				<div className="information-container">
					<span className="information">{category.desc}</span>
					<h5>Select Equipment</h5>
				</div>
				<Form
					method="post"
					className="d-grid g-1"
					style={{ maxHeight: '55vh', overflow: 'auto' }}
				>
					<div className="mt-3 alert-list-view">
						{timezones?.map((zone) => (
							<Accordion defaultActiveKey={zone.name} key={zone.name}>
								<Accordion.Item
									eventKey={zone.name}
									key={zone.name}
									className="mt-3"
									style={{ position: 'relative' }}
								>
									<AccordionTitle>
										<Accordion.Header style={{ width: '90%' }}>
											<h6>
												{zone.reg[0]} <small>{zone.name}</small>
											</h6>
										</Accordion.Header>
									</AccordionTitle>
									<SelectAllRegion onClick={selectAllRegion}>
										Select All
									</SelectAllRegion>
									<Accordion.Body className="p-0">
										{/* Project */}
										{zone.yards.map((y) => (
											<React.Fragment key={y}>
												<Yard className="project" onClick={selectAllYard}>
													<div className="d-flex flex-wrap">
														<h5 className="mb-0">{y}</h5>
														{/* <h6 className="project-details">PTS100</h6> */}
													</div>
												</Yard>
												{/* Equipments */}
												<EquimentSelector className="equipmentGroup">
													<Accordion className="equipment">
														{equipmentList
															?.filter((item) => item?.yard?.id === y)
															.map((eq) => {
																const selected = formData?.find((item) => {
																	if (item?.serial === eq?.serial) {
																		const alertList = item?.alertList;
																		return alertList?.find(
																			(al) =>
																				al.name === category.name &&
																				((al.sms && al.sms === true) ||
																					(al.email && al.email === true)),
																		);
																	}
																	return '';
																});
																const selectedClass =
																	selected !== undefined ? 'selected' : '';
																return (
																	<Card
																		eventkey={eq.serial}
																		key={eq.serial}
																		className={selectedClass}
																		onClick={selectEquipment}
																	>
																		<Card.Header>
																			<h6
																				className="align-self-center color-primary toggleLabel"
																				style={{
																					paddingLeft: 2,
																					paddingTop: 2,
																				}}
																			>
																				{eq.serial}
																			</h6>
																		</Card.Header>
																	</Card>
																);
															})}
													</Accordion>
												</EquimentSelector>
											</React.Fragment>
										))}
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						))}
					</div>

					{/* {equipmentList?.map?.((eq) => (
						<div className="px-1" key={eq?.serial}>
							<h5>{eq?.serial}</h5>
						</div>
					))} */}
				</Form>
				<div className="button-wrapper ms-auto mt-3">
					<Button
						type="button"
						variant="bordered-green"
						className="me-3"
						onClick={handleClose}
					>
						Close
					</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
}
