import { DashboardContextProvider } from 'pages/dashboard/DashboardContext';
import Alerts from './Alerts';

// eslint-disable-next-line func-names
export default function () {
	return (
		<DashboardContextProvider>
			<Alerts />
		</DashboardContextProvider>
	);
}
