import DashboardLayout from 'components/Layouts/DashboardLayout';
import ProjectList from 'pages/projectmanagement/project-list/ProjectList';
import ProjectListMobile from 'pages/projectmanagement/project-list/ProjectListMobile';
import useResponsive from 'helpers/hooks/useResponsive';
import React from 'react';

export default function ProjectManagement() {
	const { isMobile } = useResponsive();

	return (
		<DashboardLayout>
			{isMobile ? <ProjectListMobile /> : <ProjectList />}
		</DashboardLayout>
	);
}
