import ChartSelectionForm from './steps/ChartSelectionForm';
import EqSelectionForm from './steps/EqSelectionForm';
import ProjectInfoForm from './steps/ProjectInfoForm';

const stepper = {
	1: {
		component: ProjectInfoForm,
		next: '2',
		back: null,
	},
	2: {
		component: EqSelectionForm,
		next: '3',
		back: '1',
	},
	3: {
		component: ChartSelectionForm,
		next: null,
		back: '2',
	},
};

export const defaultStep = '1';

export default stepper;
