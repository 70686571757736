import AuthLayout from 'components/Layouts/AuthLayout';
import { auth } from 'helpers/api';
import { useEffect, useState } from 'react';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	Input,
	LoginButton,
	LoginFormContainer,
	LoginHeader,
} from './login.styled';

// const ErrorMessage = ({ children }) => (
// 	<small className="text-danger mt-2 d-block">{children}</small>
// );

function CancelAlerts() {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [isCodeLoading, setIsCodeLoading] = useState(false);
	const [formState, setFormState] = useState({
		password1: '',
		password2: '',
	});
	const [code, setCode] = useState('');
	const [code2, setCode2] = useState('');
	const [isVerified, setIsVerified] = useState(false);

	const handleVerify = () => {
		if (!code || !code2) {
			toast.error('Please enter token code.');
			return;
		}
		setIsCodeLoading(true);

		auth
			.validateResetToken({ token: code })
			.then((res) => {
				if (res.error) {
					toast.error(`Error. ${res.error}`);
					return;
				}
				toast.success(res.success);
				setIsVerified(true);
			})
			.catch((err) => {
				toast.error(err?.response?.data?.message || 'Something went wrong.');
			});
		setTimeout(() => {
			setIsCodeLoading(false);
		}, 2000);
	};

	const handleSubmit = () => {
		if (!formState.password1 || !formState.password2) {
			toast.error('Please enter password and reset password.');
			return;
		}
		if (formState.password1 !== formState.password2) {
			toast.error('Password and confirm password must be same.');
			return;
		}
		setIsLoading(true);
		auth
			.newPassword({
				token: code,
				password: formState.password1,
				passwordconfirm: formState.password2,
			})
			.then((res) => {
				if (res.result === 'success') {
					toast.success('Password changed successfully.');
					navigate('/login');
					return;
				}
				toast.error(`Error. ${res.error || 'Something went wrong.'}`);
			})
			.catch((err) => {
				toast.error(err?.response?.data?.message || 'Something went wrong.');
			});
		setTimeout(() => {
			setIsLoading(false);
		}, 2000);
	};
	const handleChange = (e) =>
		setFormState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	const location = useLocation();
	function tget(name) {
		const en = encodeURIComponent(name);
		if (new RegExp(`[?&]${en}=([^&]*)`).exec(location.search)) {
			const n = new RegExp(`[?&]${encodeURIComponent(name)}=([^&]*)`).exec(
				location.search,
			);
			return decodeURIComponent(n[1]);
		}
		return '';
	}
	const searchCode = tget('q');
	const searchCode2 = tget('t');
	useEffect(() => {
		setCode(searchCode);
		setCode2(searchCode2);
	}, [searchCode]);
	return (
		<div>
			<AuthLayout>
				{isVerified ? (
					<>
						<LoginHeader>Change password</LoginHeader>
						<LoginFormContainer onSubmit={(e) => e.preventDefault()}>
							<Row>
								<Col md={12}>
									<Form.Group className="mb-3">
										<Input
											type="password"
											placeholder="Enter Password"
											onChange={handleChange}
											name="password1"
											value={formState.password1}
										/>
										<Input
											className="mt-2"
											type="password"
											placeholder="Confirm Password"
											onChange={handleChange}
											name="password2"
											value={formState.password2}
										/>
									</Form.Group>
								</Col>
							</Row>
							<div className="text-center">
								<Col md={12}>
									<LoginButton
										className="btn btn-block btn-darken mb-3"
										// type="submit"
										disabled={
											isLoading || !formState.password1 || !formState.password2
										}
										onClick={handleSubmit}
									>
										Submit
										<Spinner animation="border" size="sm" hidden={!isLoading} />
									</LoginButton>
								</Col>
							</div>
						</LoginFormContainer>
					</>
				) : (
					<>
						<LoginHeader>Verify token</LoginHeader>
						<LoginFormContainer
							onSubmit={(e) => {
								e.preventDefault();
								handleVerify();
							}}
						>
							<Row>
								<Col md={12}>
									<Form.Group
										className="mb-3"
										style={{ display: 'inline-flex' }}
									>
										<Input
											type="text"
											placeholder="Enter token"
											onChange={(e) => setCode(e.target.value)}
											value={code}
										/>
										<span style={{ fontSize: '2.5rem' }}> - </span>
										<Input
											type="text"
											placeholder="Enter token"
											onChange={(e) => setCode2(e.target.value)}
											value={code2}
										/>
									</Form.Group>
								</Col>
							</Row>
							<div className="text-center">
								<Col md={12}>
									<LoginButton
										className="btn btn-block btn-darken mb-3"
										// type="submit"
										disabled={isCodeLoading || !code}
										onClick={handleVerify}
									>
										Opt-out{' '}
										<Spinner
											animation="border"
											size="sm"
											hidden={!isCodeLoading}
										/>
									</LoginButton>
								</Col>
							</div>
						</LoginFormContainer>
					</>
				)}
			</AuthLayout>
		</div>
	);
}

export default CancelAlerts;
