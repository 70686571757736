import styled from 'styled-components';

const PosiCardWrapper = styled.div`
	//border-radius: 18px;
	background: #ffffff;
	padding: 0.75rem;
	box-shadow: 0px 0px 37.95717px 0px rgba(0, 0, 0, 0.12);
	.posi-container {
		border: 1px solid #f0f0f0;
		border-radius: 14px;
		.posi-info {
			display: flex;
			border-bottom: 1px solid #f0f0f0;
			justify-content: space-between;
			padding: 0.5rem;
		}
		.footer {
			display: flex;
			justify-content: space-between;
			padding: 0.5rem;
		}
	}
`;
const PosiCardHeader = styled.div`
	justify-content: space-between;
	display: flex;

	.vehicle-status {
		font-weight: 600;
		font-size: 0.75rem;
		align-self: center;
		color: #696767;
	}
	.map-button {
		padding: 0rem;
		background: transparent;
		border: none;
		span {
			font-weight: 600;
			color: #696767;
		}
	}
`;
const PosiHeaderWrapper = styled.div`
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	padding-top: 0.5rem;
	display: flex;
	align-items: center;
	gap: 0.25rem;
	border-bottom: 1px solid #f0f0f0;
	img {
		width: 1.25rem;
		height: 1.25rem;
	}
	h1 {
		font-size: 0.75rem;
		font-weight: 600;
	}
`;
const BodyWrapper = styled.div`
	.calibration {
		text-align: left;
		font-size: 0.675rem;
		font-weight: 400;
		color: #696767;
		margin-left: 10px;
	}
	.block-container {
		padding: 0.5rem;
		text-align: center;
		display: grid;
		justify-items: center;
		.graph-container {
			position: relative;
			height: 128px;
			border-radius: 0.5rem;
			contain: content;
			.holder {
				width: 2.25rem;
				height: 128px;
				background: #e2e2e2;
			}
			.filler {
				width: 2.25rem;
				background: #00834d;
				position: absolute;
				bottom: 0;
				transition: bottom 2s ease-in-out;
				height: 0.33rem;
			}
		}
		h1 {
			font-weight: 600;
			font-size: 0.75rem;
			margin-top: 0.5rem;
		}
	}
	.position-percent {
		background: #e2e2e2;
		border-radius: 0.5rem;
		margin-top: 0.5rem;
		width: fit-content;
		padding-left: 2rem;
		padding-right: 2rem;
		margin-left: auto;
		margin-right: auto;
	}
	.position-percent-text {
		font-weight: 600;
		font-size: 0.75rem;
		margin-top: 0.5rem;
		width: fit-content;
		margin-left: auto;
		margin-right: auto;
	}
`;
const WeightChartWrapper = styled.div`
	display: grid;
	width: 100%;
	gap: 0.5rem;
	align-content: flex-start;
	.weight-chart-container {
		border: 1px solid #f0f0f0;
		border-radius: 0.875rem;
		padding-top: 0.125rem;
		padding-bottom: 0.125rem;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		height: fit-content;
		position: relative;
	}
	.clock-pin {
		position: absolute;
		bottom: 61px;
		z-index: 1;
		transition: transform 0.5s ease-in-out;
	}
	.weight-text-container {
		background: #d9d9d9;
		border-radius: 2px;
		width: 48px;
		position: absolute;
		bottom: 2.25rem;
		padding: 0.125rem;
		h6 {
			font-size: 0.75rem;
			font-weight: 600;
			margin-bottom: 0px;
			width: fit-content;
			margin-left: auto;
		}
	}
	.messages-container {
		border: 1px solid #f0f0f0;
		border-radius: 0.875rem;
		padding-top: 0.125rem;
		padding-bottom: 0.125rem;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		.title {
			font-size: 0.75rem;
			font-weight: 600;
		}
		.messages-list {
			display: grid;
			overflow-y: scroll;
			height: 180px;
			p {
				font-size: 0.675rem;
				margin-bottom: 0px;
			}
		}
	}
`;

export {
	PosiCardWrapper,
	PosiCardHeader,
	PosiHeaderWrapper,
	BodyWrapper,
	WeightChartWrapper,
};
