import { ReactComponent as ClockPinIcon } from 'assets/clock-pin.svg';
import LogoMinify from 'assets/icons/logo-minify.png';
import { ReactComponent as PinIcon } from 'assets/icons/pin.svg';
import { ReactComponent as WeightClock } from 'assets/weight-clock.svg';
import Clock from 'pages/details/charts/Clock';
import { Col } from 'react-bootstrap';
import { FooterLights, StatusLight, WarningLights } from './Lights';
import {
	BodyWrapper,
	PosiCardHeader,
	PosiCardWrapper,
	PosiHeaderWrapper,
	WeightChartWrapper,
} from './styles/PosiCard.styled';

export default function PosiCard({
	fullColumn,
	data,
	index,
	handleMapModal,
	handleMapData,
}: {
	fullColumn: number;
	data: any;
	index: number;
	handleMapModal: () => void;
	handleMapData: (map: any) => void;
}) {
	return (
		<Col lg={fullColumn} style={{ padding: '0.25rem' }} index={index}>
			<PosiCardWrapper>
				<PosiCardHeader>
					<span style={{ fontWeight: '600' }}>{data?.serial} </span>
					<span className="vehicle-status">
						{data?.vehicle?.status} {data?.vehicle?.speed} {data?.vehicle?.unit}
					</span>
					<div className="d-flex gap-4">
						<button
							type="button"
							className="map-button"
							aria-label="Click to view map"
							onClick={() => {
								handleMapModal();
								handleMapData(data?.gps);
							}}
						>
							<span>Map</span>
							<PinIcon />
						</button>
					</div>
				</PosiCardHeader>

				<div className="d-flex gap-2">
					{/* Posi */}
					<div className="posi-container">
						<div>
							{/* Header */}
							<PosiHeaderWrapper>
								<img src={LogoMinify} alt="" />
								<h1>
									BLOCK LIMIT CONTROL
									<br /> SYSTEM
								</h1>
							</PosiHeaderWrapper>
							{/* Body */}
							<BodyWrapper style={{ borderBottom: '1px solid #F0F0F0' }}>
								<div style={{ display: 'flex' }}>
									{/* Block Position */}
									<div className="block-container">
										<div className="graph-container">
											<div className="holder" />
											<div
												className="filler"
												style={{
													bottom: `${
														(116 / 100) *
															(data?.data?.[0]?.positionPercent || 0) +
														4
													}px`,
												}}
											/>
										</div>
										<h1 className="block-text">
											BLOCK
											<br /> POSITION
										</h1>
									</div>
									{/* Second Row */}
									<div>
										<div className="position-percent">
											{data?.data?.[0]?.positionPercent}
										</div>
										<h1 className="position-percent-text">Percent of Travel</h1>
										<Clock
											value={data?.data?.[0]?.speedFeetPerMinute}
											prevVal={0}
											newLook
										/>
									</div>
								</div>

								<h6 className="calibration">
									Last Calibration:{' '}
									{data?.data?.[0]?.posistop_dial === 1
										? data?.calibration?.date
										: '-'}
								</h6>
								<h6 className="calibration">
									Encoder Count:{' '}
									{data?.data?.[0]?.posistop_dial === 1
										? data?.calibration?.count
										: '-'}
								</h6>
							</BodyWrapper>
							{/* Posi Info */}
							<div className="posi-info">
								<WarningLights
									title="WARNING"
									on={data?.lights?.warning === 1}
								/>
								<WarningLights title="LIMIT" on={data?.lights?.limit === 1} />
								<StatusLight statusDial={data?.data?.[0]?.posistop_dial} />
							</div>
							{/* Footer */}
							<div className="footer">
								<FooterLights title="BOP" on={data?.lights?.slips === 1} />
								<FooterLights
									title="INTERLOCK"
									on={data?.lights?.interlock === 1}
								/>
								<FooterLights
									title="ROD MANUAL"
									on={data?.lights?.rodmanual === 1}
								/>
								<FooterLights
									title="ROD AUTO"
									on={data?.lights?.rodlockout === 1}
								/>
							</div>
						</div>
					</div>

					<WeightChartWrapper>
						{/* Weight Chart */}
						<div className="weight-chart-container">
							<WeightClock />
							<ClockPinIcon
								className="clock-pin"
								style={{
									transform: `rotate(${
										-222 +
										Math.round(parseFloat(data?.data?.[0]?.weight_on_hook))
									}deg)`,
								}}
							/>

							<div className="weight-text-container">
								<h6>
									{Math.round(parseFloat(data?.data?.[0]?.weight_on_hook)) || 0}
								</h6>
							</div>
						</div>

						{/* Todays Messages */}
						<div className="messages-container">
							<span className="title">Today's Messages</span>
							<div className="messages-list">
								<p style={{ display: 'none' }}>Lorem ipsum</p>
							</div>
						</div>
					</WeightChartWrapper>
				</div>
			</PosiCardWrapper>
		</Col>
	);
}
