/* eslint-disable camelcase */
import { getToken } from 'helpers/utils/auth-provider';
import { PROJECTS_BY_COMPANY_ID_QUERY } from '../queries/projectQuery';
import {
	ADD_USER_MUTATION,
	ASSIGN_USER_TO_EQUIPMENT_OR_PROJECTS,
	DELETE_USER_BY_ID_MUTATION,
	EDIT_USER_MUTATION,
	GET_USER_BY_ID_QUERY,
	GET_USERS_QUERY,
	UPDATE_USER_PASSWORD_MUTATION,
} from '../queries/userQuery';
import client from './apolloClient';
import { apiURL, scriptsURL } from './urlDataBuilders';

const apiURLEndpoint = apiURL();
const scriptsURLEndpoint = scriptsURL();

export const getUserList = () =>
	client.query({
		query: GET_USERS_QUERY,
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
		fetchPolicy: 'network-only',
	});

/**
 * Fetches a user by their ID from the API.
 *
 * @param {string} userid - The ID of the user to fetch.
 * @returns {Promise<any>} - A promise that resolves to the user data.
 */
export const getUserByID = (userid) =>
	client.query({
		query: GET_USER_BY_ID_QUERY,
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
		variables: { userid },
		fetchPolicy: 'network-only',
	});

export const getProjects = () =>
	client.query({
		query: PROJECTS_BY_COMPANY_ID_QUERY,
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
	});
export const editUser = (data, userid) =>
	client.mutate({
		mutation: EDIT_USER_MUTATION,
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
		variables: {
			...data,
			userId: userid,
		},
	});

export const addUser = (userData) => {
	client.mutate({
		mutation: ADD_USER_MUTATION,
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
		variables: userData,
	});
};

/**
 * Updates the assignments for a user.
 *
 * @param {Object} data - The data to update the assignments with.
 * @param {string} userid - The ID of the user to update the assignments for.
 * @returns {Promise<any>} - A promise that resolves to the result of the mutation.
 */
export const updateAssignments = (data, userid) =>
	client.mutate({
		mutation: ASSIGN_USER_TO_EQUIPMENT_OR_PROJECTS,
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
		variables: {
			...data,
			userid,
		},
	});

/**
 * Deletes a user by their ID.
 *
 * @param {string} userId - The ID of the user to delete.
 * @returns {Promise<any>} - A promise that resolves to the result of the mutation.
 */
export const deleteUser = (userId) =>
	client.mutate({
		mutation: DELETE_USER_BY_ID_MUTATION,
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
		variables: { userId },
	});

export const resetPassword = async (data) => {
	const token = getToken();
	const url = `${scriptsURLEndpoint}/users/resetpass.php`;

	const header = {
		method: 'PUT',
		headers: {
			'http-authorization': token || '',
		},
		body: JSON.stringify(data),
	};
	const response = await fetch(url, header);
	const result = await response.json();
	if (result.success) {
		return result;
	}

	throw result.msg;
};

/**
 * Updates the password for a user.
 *
 * @param {Object} data - The data required to update the user's password.
 * @returns {Promise<any>} - A promise that resolves to the result of the mutation.
 */
export const updateUserPassword = (data) =>
	client.mutate({
		mutation: UPDATE_USER_PASSWORD_MUTATION,
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
		variables: {
			updateUserPasswordArgs: { ...data },
		},
	});
