import React, { useEffect, useState } from 'react';
import { SORT } from 'helpers/const/views';
import { ReactComponent as DropdownIcon } from 'assets/icons/dropdown.svg';
import styled from 'styled-components';
import getValue from 'lodash.get';

const SortingIcon = styled(DropdownIcon)`
	display: ${({ sortingmode }) => (sortingmode ? 'initial;' : 'none;')};
	margin-left: 0.25rem;
	transform: ${({ sortingmode }) =>
		sortingmode === 'asc' ? 'rotate(180deg);' : ''};
`;

function SortEnabledHeader({
	onSortData,
	title,
	data,
	field,
	Component = 'th',
	showSortingIcon = true,
}) {
	const [sortingMode, setSortingMode] = useState('asc');
	const handleSort = () => {
		setSortingMode((mode) => (mode === SORT.ASC ? SORT.DESC : SORT.ASC));
	};

	useEffect(() => {
		if (sortingMode) {
			data.sort((firstRecord, secondRecord) => {
				if (
					getValue(firstRecord, field, '')?.toLowerCase() <
					getValue(secondRecord, field, '')?.toLowerCase()
				) {
					return sortingMode === SORT.ASC ? -1 : 1;
				}
				if (
					getValue(firstRecord, field, '')?.toLowerCase() >
					getValue(secondRecord, field, '')?.toLowerCase()
				) {
					return sortingMode === SORT.ASC ? 1 : -1;
				}
				return 0;
			});
			onSortData([...data]);
		}
	}, [sortingMode]);
	return (
		<Component
			role="button"
			onClick={handleSort}
			className="sortable-header-column"
		>
			{title}
			{showSortingIcon && sortingMode && (
				<SortingIcon sortingmode={sortingMode} />
			)}
		</Component>
	);
}

export default SortEnabledHeader;
