import styled from 'styled-components';
import { Form, Button, Accordion } from 'react-bootstrap';

export const LoginContainer = styled.div`
	max-width: 460px;
	margin: ${(props) => (props.$isMobile ? '3rem auto' : '7rem auto')};
`;

export const LogoDiv = styled.div`
	padding: 2rem;
	border-radius: 12px 12px 0px 0px;
	border-bottom: 1px solid #dddddd;

	img {
		width: 140px;
		height: 39px;
	}
`;

export const LoginDiv = styled.div`
	padding: 2rem;
`;

export const LoginHeader = styled.h2`
	font-size: 1.25rem;
	font-weight: 700;
	margin-bottom: 1.5rem;
`;

export const Input = styled(Form.Control)`
	background: ${({ theme }) => theme.colors.backgroundLightGray};
	height: 3.5rem;
	border-radius: 4px;
	border: none;
	padding-left: 0.75rem;
`;

export const LoginButton = styled(Button)`
	background: ${({ theme }) => theme.colors.loginPrimary}!important;
	width: 100%;
	height: 3.5rem;
	border-radius: 8px;
	border: none;
`;

export const Forgot = styled.span`
	color: ${({ theme }) => theme.colors.loginPrimary};
	text-decoration: none;

	&:hover {
		cursor: pointer;
	}
`;

export const TOS = styled(Accordion)`
	box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.05);
	border-radius: 12px !important;
`;

export const TOSItem = styled(Accordion.Item)`
	border: none;
	border-radius: 12px !important;
`;

export const TOSHeader = styled(Accordion.Button)`
	font-size: 1.125rem !important;
	font-weight: 500 !important;
	border-radius: 12px !important;
`;

export const LoginFormContainer = styled(Form)`
	background: white;
`;

export const FormContainer = styled.div`
	background: white;
	margin-bottom: 1.75rem;
	border-radius: 12px;
	filter: drop-shadow(0px 4px 80px rgba(0, 0, 0, 0.05));
`;

export const Note = styled.div`
	margin-top: 1.25rem;

	h1 {
		font-weight: 700;
		font-size: 1rem;
	}

	h2 {
		font-weight: 400;
		font-size: 1rem;
		margin-bottom: 0px;
	}

	img {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}
`;

export const CustomFormCheck = styled(Form.Check)`
	input {
		width: 24px;
		height: 24px;
		padding: 0px;
		cursor: pointer;
	}

	input[type='checkbox'] {
		border: 1px solid ${({ theme }) => theme.colors.loginPrimary} !important;
	}

	input[type='checkbox'] {
		&:checked {
			background-color: ${({ theme }) => theme.colors.loginPrimary};
			border-color: ${({ theme }) => theme.colors.loginPrimary};
		}
	}
`;
