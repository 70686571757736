import styled from 'styled-components';
import { Col, Popover } from 'react-bootstrap';
import dropdown from 'assets/icons/dropdown.svg';
import rightIcon from 'assets/icons/right.svg';

export const DashboardContent = styled.div`
	/* margin: ${(props) =>
		props.$istablet || props.$ismobile ? '0 1rem' : '2rem 2rem 2rem 18rem;'}; */

	.no-result {
		text-align: center;
		align-items: center;
		flex: 1;
		display: flex;
		justify-content: center;
		height: 65vh;

		svg {
			height: 40vh;
		}
	}
`;

export const HeaderContainer = styled.div`
	h1 {
		font-size: 0.813rem;
		font-weight: 500;
		max-width: 470px;
	}

	.search-icon {
		position: absolute;
		margin-left: 1rem;
		margin-top: 0.75rem;
	}

	.search-input {
		max-width: 260px;
		border-radius: 8px;
		background: ${({ theme }) => theme.colors.backgroundLightGray};
		height: 44px;
		font-size: 0.813rem;
		font-weight: 500;
		padding-left: 2.5rem;
	}

	.filters {
		margin-top: 1.5rem;
	}

	.mobile {
		display: flex;
		flex-flow: wrap;
		justify-content: left;
		justify-content: space-between;
		padding-right: 0.75rem;

		.filter-tabs {
			margin: 1rem 0rem;
		}

		.map-search {
			align-self: center;
		}
	}

	.map-search {
		display: flex;
		align-items: center;
		height: 100%;
		background: white;
		box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.04);
		border-radius: 8px;
		padding: 1rem;
		width: fit-content;
		/* width: 270px; */

		h2 {
			font-size: 0.813rem;
			margin-bottom: 0px;
			align-self: center;
			padding-left: 0.4rem;
		}

		button {
			display: flex;
			margin-right: 0.25rem;
			margin-left: 0.25rem;
			background: none;
			color: black;
			border: none;
			padding: 0rem 0.25rem;

			&:hover,
			&:focus {
				background: none;
				border: none;
			}
		}
	}
`;

export const FilterContainer = styled(Col)`
	height: 100%;
	align-self: center;
	display: flex;
	justify-content: right;
	padding-right: 2rem;
	display: flex;

	span {
		font-size: 0.875rem;
		font-weight: 400;
		align-self: center;
		margin-bottom: 0px;
	}

	.filter-dropdown {
		align-self: center;
		/* padding-left: 0.7rem; */
		/* width: 130px; */

		button.btn,
		button.btn:hover,
		button.btn:focus,
		button.btn:active {
			text-transform: capitalize;
			background: none;
			border: none;
			color: black;
			font-size: 0.875rem;
			font-weight: 500;
			margin: 0rem 0.625rem 0rem 0.625rem;
			padding: 0rem 0rem;
			box-shadow: none;
		}

		.dropdown-item {
			color: black;
			font-size: 0.875rem;
			font-weight: 500;
			span {
				margin-left: 1rem;
			}
			&:focus,
			&:active {
				background: #dddddd;
			}
		}

		.dropdown-item.active {
			background: none;

			span {
				margin-left: 4px;
			}

			&:before {
				content: '';
				display: inline-block;
				width: 12px;
				height: 10px;
				background: url(${rightIcon}) no-repeat center;
				position: relative;
				left: -5px;
			}
		}
	}

	.filter-dropdown-first {
		/* padding-right: 150px; */
		border-right: 1px solid #dddddd;
	}

	.dropdown-toggle::after {
		border: none;
		vertical-align: middle;
		width: 15px;
		height: 15px;
		background: url(${dropdown}) no-repeat center;
		transition: transform 0.2s ease-in-out;
	}

	.show {
		.dropdown-toggle {
			&:after {
				transform: rotate(-180deg);
				transition: transform 0.2s ease-in-out;
			}
		}
	}

	.dropdown-menu {
		border: none;
		box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.12);
		border-radius: 8px;
	}

	.filter-tabs {
		padding: 0.25rem;
		align-self: center;
		margin-left: 2rem;
		background: ${({ theme }) => theme.colors.backgroundLightGray};
		border-radius: 11px;

		.nav-link.active {
			background: #ffffff;
			border-radius: 8px;
			border: none;
		}

		button {
			height: 44px;
			width: 44px;
			padding: 0px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
`;

export const MapPopover = styled(Popover.Body)`
	.equipment-id {
		font-size: 0.875rem;
		font-weight: 500;
		color: ${({ theme }) => theme.colors.loginPrimary} !important;
	}

	h3 {
		font-size: 0.875rem;
		font-weight: 600;
		opacity: 0.7;
	}

	h4 {
		font-size: 0.875rem;
		font-weight: normal;
		opacity: 0.7;

		span {
			font-size: 0.875rem;
			font-weight: 500;
			opacity: 1;
			color: #000000;
		}
	}
`;

export const MapPopoverContainer = styled.div`
	.popover {
		filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.14)) !important;
		border: none !important;
	}
	.marker-icon {
		width: 30px;
		height: 30px;
		cursor: pointer;
	}
`;
