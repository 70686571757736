import React from 'react';
import styled from 'styled-components';
import { Row, Col, Form } from 'react-bootstrap';

const Card = styled.div`
	/* overflow-y: scroll; */
	h5 {
		border-bottom: 1px solid #dddddd;
		padding-bottom: 1rem;
		margin-bottom: 1rem;
	}
	input[type='checkbox'] {
		&:checked {
			background-color: ${({ theme }) => theme.colors.loginPrimary};
			border-color: ${({ theme }) => theme.colors.loginPrimary};
		}
	}
	label {
		margin-left: 0.5rem;
		margin-top: 0.25rem;
		vertical-align: middle;
	}
`;

const CustomFormCheck = styled(Form.Check)`
	input {
		width: 24px;
		height: 24px;
		padding: 0px;
	}
`;

function ChartSelection({ onChartsChange, charts }) {
	const chartsData = Array.isArray(charts) ? charts : [];
	const handleCharts = (e) => {
		const { id } = e.target;
		const isExits = chartsData.find((item) => item.graph === id);
		if (isExits) {
			onChartsChange(chartsData.filter((item) => item.graph !== id));
		} else {
			onChartsChange([
				...chartsData,
				{
					graph: id,
					enable: true,
				},
			]);
		}
	};
	const hasChecked = (id) => {
		const isExits = chartsData.find((item) => item.graph === id);
		return isExits;
	};
	return (
		<div className="mt-4">
			<h5>Graph selection</h5>
			<Row className="inputs mt-5">
				<Col lg={3}>
					<Card>
						<div className="radio-container">
							<CustomFormCheck
								className="mb-3"
								type="checkbox"
								id="woh"
								onChange={handleCharts}
								checked={hasChecked('woh')}
								label="Weight on Hook"
							/>
						</div>
						<div className="radio-container">
							<CustomFormCheck
								className="mb-3"
								type="checkbox"
								id="position"
								onChange={handleCharts}
								checked={hasChecked('position')}
								label="Block Position"
							/>
						</div>
						<div className="radio-container">
							<CustomFormCheck
								className="mb-3"
								type="checkbox"
								id="pump_flow_rate"
								onChange={handleCharts}
								checked={hasChecked('pump_flow_rate')}
								label="Pump Flow Rate"
							/>
						</div>
						<div className="radio-container">
							<CustomFormCheck
								className="mb-3"
								type="checkbox"
								id="flow_rate"
								onChange={handleCharts}
								checked={hasChecked('flow_rate')}
								label="Well Flow Rate"
							/>
						</div>
					</Card>
				</Col>
				<Col lg={3}>
					<Card>
						<div className="radio-container">
							<CustomFormCheck
								className="mb-3"
								type="checkbox"
								id="wob"
								onChange={handleCharts}
								checked={hasChecked('wob')}
								label="Weight on Bit"
							/>
						</div>
						<div className="radio-container">
							<CustomFormCheck
								className="mb-3"
								type="checkbox"
								id="swivel_torque"
								onChange={handleCharts}
								checked={hasChecked('swivel_torque')}
								label="Swivel Torque"
							/>
						</div>
						<div className="radio-container">
							<CustomFormCheck
								className="mb-3"
								type="checkbox"
								id="pump_discharge"
								onChange={handleCharts}
								checked={hasChecked('pump_discharge')}
								label="Pump Discharge"
							/>
						</div>
					</Card>
				</Col>
				<Col lg={3}>
					<Card>
						<div className="radio-container">
							<CustomFormCheck
								className="mb-3"
								type="checkbox"
								id="actual_load"
								onChange={handleCharts}
								checked={hasChecked('actual_load')}
								label="Actual Load"
							/>
						</div>
						<div className="radio-container">
							<CustomFormCheck
								className="mb-3"
								type="checkbox"
								id="swivel_rpm"
								onChange={handleCharts}
								checked={hasChecked('swivel_rpm')}
								label="Swivel RPM"
							/>
						</div>
						<div className="radio-container">
							<CustomFormCheck
								className="mb-3"
								type="checkbox"
								id="well_discharge"
								onChange={handleCharts}
								checked={hasChecked('well_discharge')}
								label="Well Discharge"
							/>
						</div>
					</Card>
				</Col>
			</Row>
		</div>
	);
}

export default ChartSelection;
