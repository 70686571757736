import { gql } from '@apollo/client';

export const COMPANY_QUERY = gql`
	query Customer {
		customer {
			cid
			customid
			cname
			caddress1
			caddress2
			ccity
			cstate
			czip
			cphone
			cfax
			logo
			features
		}
	}
`;
