import { Col, Form } from 'react-bootstrap';

const CustomInput = ({
	label,
	register,
	name,
	type = 'text',
	errors = {},
	...rest
}) => (
	<Col lg={4}>
		<Form.Group controlId={label}>
			<Form.Label>{label}</Form.Label>
			<Form.Control
				type={type}
				placeholder={`Enter ${label}`}
				{...register(`${name}`)}
				{...rest}
			/>
			<Form.Text>{errors[name]?.message}</Form.Text>
		</Form.Group>
	</Col>
);

export default CustomInput;
