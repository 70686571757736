import ExcelJS from 'exceljs';
import client from 'helpers/api/apolloClient';
import { POSI_STOP_REPORT_GENERATE_QUERY } from 'helpers/queries/equipmentQuery';
import { getToken } from 'helpers/utils/auth-provider';

export default function usePosiStopReport() {
	const generatePosiStopReport = (params) =>
		client.query({
			query: POSI_STOP_REPORT_GENERATE_QUERY,
			variables: { params },
			context: {
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			},
			fetchPolicy: 'network-only'
		});

	const downloadPosiStopReportExcel = async (
		rows,
		columns,
		fileName,
		sheetName = 'Posi-Stop Events',
	) => {
		try {
			const workbook = new ExcelJS.Workbook();
			const sheet = workbook.addWorksheet(sheetName);

			const newColumns = columns.map((col, idx) => ({
				header: col,
				key: col,
				width: 15,
				// style: { font: { bold: true } },
			}));
			sheet.columns = newColumns;
			rows.forEach((row) => sheet.addRow(row));
			sheet.getRow(1).font = { bold: true };

			// Generate Excel file
			const buffer = await workbook.xlsx.writeBuffer();

			// Create Blob and download
			const blob = new Blob([buffer], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			});
			const url = window.URL.createObjectURL(blob);

			const a = document.createElement('a');
			a.href = url;
			a.download = `${fileName}.xlsx`;
			a.click();

			// Clean up
			window.URL.revokeObjectURL(url);
		} catch (error) {
			console.log('error', error);
		}
	};

	return { generatePosiStopReport, downloadPosiStopReportExcel };
}
