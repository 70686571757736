import { ReactComponent as Right } from 'assets/icons/right-arrow.svg';
import DashboardLayout from 'components/Layouts/DashboardLayout';
import GoogleMapReact from 'google-map-react';
import { multicamfeed } from 'helpers/api';
import { GOOGLE_MAP_API_KEY } from 'helpers/const';
import { BasicMarker } from 'pages/Mapview/MyMarker';
import { DashboardContextProvider } from 'pages/dashboard/DashboardContext';
import React, { useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import useCameraLayout from '../../helpers/hooks/useCameraLayout';
import FullCameraModal from './CameraModal';
import CameraView from './CameraView';
import EquipmentsModal from './EquipmentsModal';
import CamFeedHeader from './Header';
import PosiView from './PosiView';

// TODO: This was not working right!
function createMapOptions(maps) {
	return {
		zoomControlOptions: {
			position: maps.ControlPosition.RIGHT_CENTER,
			style: maps.ZoomControlStyle.SMALL,
		},
		mapTypeId: maps.MapTypeId.HYBRID,
	};
}
function MapModal({ show, hideModal, data }) {
	return (
		<Modal centered show={show} onHide={hideModal} className="profile-modal">
			<Modal.Body style={{ padding: 0 }}>
				{data ? (
					// @ts-ignore
					<GoogleMapReact
						bootstrapURLKeys={{
							// @ts-ignore
							key: GOOGLE_MAP_API_KEY,
							language: 'en',
							region: 'US',
						}}
						defaultCenter={{
							lat: data?.latdd || 0.0,
							lng: data?.londd || 0.0,
						}}
						defaultZoom={16}
						style={{
							contain: 'strict',
							height: `260px`,
							position: 'relative',
							borderRadius: '12px',
							width: '100%',
						}}
						options={createMapOptions}
					>
						<BasicMarker lat={data?.latitude} lng={data?.longitude} />
					</GoogleMapReact>
				) : null}
			</Modal.Body>
		</Modal>
	);
}

export default function CamFeed() {
	const feed = localStorage.getItem('feed-display');
	const [fullColumn, setFullColumn] = useState(4);
	const [show, setModalShow] = useState(false);
	const [cameras, setCameras] = useState<any[]>([]);
	const [fullCamera, setFullCamera] = useState();
	const [showFullCamera, setShowFullCamera] = useState(false);
	const [mapModal, setMapModal] = useState(false);
	const [mapData, setMapData] = useState(null);
	const [view, setView] = useState('cameras');

	const { cameraLayouts, updateCameraLayout } = useCameraLayout();
	const [currentCameraLayout, setCurrentCameraLayout] = useState<any>(null);
	const [isFetchinOnUpdate, setIsFetchinOnUpdate] = useState(false);
	const { id: layoutId } = useParams();

	const { data, isLoading, refetch, isError } = useQuery(
		`multi-cameras-by-id-${layoutId}`,
		() => multicamfeed.getCameras(layoutId),
		{
			enabled: !!layoutId,
			refetchInterval: 5000, // It refetches the data every five seconds.
			refetchIntervalInBackground: true,
			refetchOnWindowFocus: false,
		},
	);
	// This code toggles the visibility of the pop-up by
	// setting the showPopup state variable to the opposite
	// of its current value. It also saves the column display
	// mode to local storage.
	const handleFullView = (cameraData: any) => () => {
		setFullCamera(cameraData);
		setShowFullCamera(true);
	};
	// This code handles the columns of the feed. It sets the amount of columns to display to a number, and then stores that number in local storage.
	const handleCols = (display: number) => {
		setFullColumn(display);
		localStorage.setItem('feed-display', display.toString());
	};
	// this function handles the drag and drop functionality of the cameras
	const handleDragandDrop =
		(item: any) => async (e: React.DragEvent<HTMLDivElement>) => {
			// change position of item in cameras array to the position of the item it was dropped on and vice versa
			const draggedItem = cameras.find(
				(i: any) => i.equipment === e.dataTransfer.getData('eq'),
			);
			const droppedItem = cameras.find(
				(i: any) => i.equipment === item.equipment,
			);
			const draggedIndex = cameras.indexOf(draggedItem);
			const droppedIndex = cameras.indexOf(droppedItem);
			const newCameras = [...cameras];
			newCameras[draggedIndex] = droppedItem;
			newCameras[droppedIndex] = draggedItem;

			// Update the state with the new array
			setCameras(newCameras);
			updateCameraLayout({
				id: currentCameraLayout?.id,
				viewName: currentCameraLayout?.view_name || '',
				selection: newCameras.map((id: { equipment: string }) => id.equipment),
			});
		};
	// handles between posi and camera view
	const handleView = () => () => {
		if (view === 'cameras') {
			setView('posi');
			// handleCols(4);
		} else setView('cameras');
	};
	// handles the map modal
	const handleMapModal = () => {
		setMapModal(!mapModal);
	};
	// TODO: Function to handle map data
	const handleMapData = (map: any) => {
		setMapData(map);
	};

	// This code sets the camera state and handles the columns
	useEffect(() => {
		if (feed !== null) handleCols(parseInt(feed, 10));
	}, [feed]);

	useEffect(() => {
		// only set cameras the first time
		if (
			!isError &&
			!isLoading &&
			data !== null &&
			data?.data?.multiView?.length > 0
		) {
			setCameras(data?.data?.multiView || []);
		}
	}, [data]);

	// change full column when viewport changes
	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth < 1400) {
				setFullColumn(6);
			} else {
				setFullColumn(4);
			}
		};
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (layoutId && cameraLayouts && cameraLayouts.length) {
			setCurrentCameraLayout(
				cameraLayouts.find((item: any) => item?.id === parseInt(layoutId, 10)),
			);
		}
	}, [cameraLayouts, layoutId]);

	// This code renders a Bootstrap spinner if the page is loading.
	// The spinner will be green.
	if (isLoading || isFetchinOnUpdate)
		return (
			<Button
				disabled
				size="lg"
				variant="outline-success"
				style={{ color: '#00834d' }}
			>
				<Spinner
					as="span"
					animation="grow"
					size="sm"
					role="status"
					aria-hidden="true"
				/>{' '}
				Getting cameras, please wait...
			</Button>
		);

	return (
		<DashboardContextProvider>
			<EquipmentsModal
				show={show}
				setModalShow={setModalShow}
				activeEqs={cameras}
				setIsFetchinOnUpdate={setIsFetchinOnUpdate}
				refetch={refetch}
			/>
			<MapModal show={mapModal} hideModal={handleMapModal} data={mapData} />
			<FullCameraModal
				data={fullCamera}
				show={showFullCamera}
				setModalShow={setShowFullCamera}
			/>
			<DashboardLayout>
				<Link
					to="/camera-feed"
					className="text-sm flex items-center gap-2 w-fit"
				>
					<Right /> Back
				</Link>
				<div className="flex justify-between gap-4 mt-4 ">
					<span className="font-bold text-lg ">
						{currentCameraLayout?.view_name}
					</span>
					<CamFeedHeader
						handleCols={handleCols}
						view={view}
						handleView={handleView()}
					/>
				</div>

				{view === 'cameras' ? (
					<CameraView
						data={cameras}
						fullColumn={fullColumn}
						isLoading={isLoading}
						handleDragandDrop={handleDragandDrop}
						handleFullView={handleFullView}
						setModalShow={setModalShow}
					/>
				) : (
					<PosiView
						fullColumn={fullColumn}
						data={data?.data?.multiView}
						handleMapModal={handleMapModal}
						handleMapData={handleMapData}
					/>
				)}
			</DashboardLayout>
		</DashboardContextProvider>
	);
}
