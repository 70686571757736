/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
import { pumpChartLabels } from 'helpers/const/chartLabels';
import { ChartContainer } from 'pages/details/styled';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DailyChartGeneral from './DailyChartGeneral';
import LiveChartGeneral from './LiveChartGeneral';

function ActivityStrokes() {
	const [daily, setDaily] = React.useState(false);
	const [selectedDate, setSelectedDate] = React.useState(new Date());

	const handleDaily = (d) => {
		setDaily(d);
	};

	const chartselecteddate = (date, d) => {
		setSelectedDate(date);
		setDaily(d);
	};

	return (
		<Col lg={12}>
			<ChartContainer>
				<Row className="header">
					<Col className="align-self-center">
						<h1 className="align-self-center">
							{pumpChartLabels.pump_strokes}
						</h1>
					</Col>
					<Col className="change-container">
						<button
							type="button"
							onClick={() => (daily ? handleDaily(false) : handleDaily(true))}
							className="change-span"
						>
							{daily ? 'Live chart' : '24h chart'}
						</button>
						<button type="button">
							<DatePicker
								selected={selectedDate}
								onChange={(date) => chartselecteddate(date, true)}
							/>
						</button>
					</Col>
				</Row>

				{/* <div className="details">
					<div>
						<span className="tag">strokes: </span>{' '}
						<span>{livedata?.data?.charts?.strokes?.[59]?.pump_strokes}</span>
					</div>
				</div> */}

				{daily && (
					<DailyChartGeneral
						chart="pump_strokes"
						date={selectedDate}
						subitem={pumpChartLabels.pump_strokes}
					/>
				)}

				{!daily && (
					<LiveChartGeneral
						chart="pump_strokes"
						subchart={pumpChartLabels.pump_strokes}
						datakey="pump_strokes"
					/>
				)}
			</ChartContainer>
		</Col>
	);
}

export default ActivityStrokes;
