import RigLayout from 'components/Layouts/RigLayout';
import { useState } from 'react';
import ChartActivity from './ChartActivity';
import TableActivity from './TableActivity';

export default function RigActivity() {
	const [tableView, setTableView] = useState(false);
	const handleView = () => setTableView(!tableView);

	return (
		<RigLayout>
			<span>Graphs</span>
			{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
			<label className="switch">
				<input type="checkbox" onChange={handleView} />
				<span className="slider round" />
			</label>
			<span>Table</span>
			{tableView && <TableActivity />}
			{!tableView && <ChartActivity />}
		</RigLayout>
	);
}
