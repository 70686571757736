import { RigDetailContextProvider } from 'helpers/contexts/RigDetailProvider';
import DetailsTab from 'pages/details/rig/tabs/Detail/DetailsTab';

export default function DetailsTabView() {
	return (
		<RigDetailContextProvider>
			<DetailsTab />
		</RigDetailContextProvider>
	);
}
