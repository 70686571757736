import { Row } from 'react-bootstrap';
import PosiCard from './PosiCard';

export default function PosiView({
	fullColumn,
	data,
	handleMapModal,
	handleMapData,
}: {
	fullColumn: number;
	data: any;
	handleMapModal: () => void;
	handleMapData: (map: any) => void;
}) {
	const uniqueSerial = [
		...new Set(data?.map((item2: { serial: string }) => item2.serial)),
	];
	// get the data for each unique serial and combine them
	const uniqueData = uniqueSerial.map((item) => {
		const filtered = data.filter((item2) => item2.serial === item);
		return filtered;
	});

	return (
		<Row>
			{uniqueData?.map((item: any, index: number) => (
				<PosiCard
					fullColumn={fullColumn}
					data={item?.[0]}
					index={index}
					handleMapModal={handleMapModal}
					handleMapData={handleMapData}
				/>
			))}
		</Row>
	);
}
