import dropdown from 'assets/icons/dropdown.svg';
import { Button, Dropdown, Modal, Navbar } from 'react-bootstrap';
import styled from 'styled-components';

interface SidebarContainerProps {
	$iscollapsed: boolean;
}
export const SidebarContainer = styled.div<SidebarContainerProps>`
	/* transition: width 0.3s ease-in-out; */
	width: 16rem;
	${(props: any) => props.$iscollapsed && `width: 4rem;`}
	height: 100%;
	background-color: white;
	position: fixed;
	top: 0;
	z-index: 1;
	box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
	.logo {
		margin: 24px;
		width: 122px;
		height: 34px;
		img {
			height: 100%;
		}
	}
	.image-logo {
		height: 100%;
	}
	.sidebar-nav {
		list-style: none;
		padding-left: 0;
		margin: 2rem 0.25rem;
		li > a {
			margin-top: 0.5rem;
			display: block;
			color: black;
			font-weight: 500;
			border-radius: 0.25rem;
			padding: 0.8rem 1rem;
			&.active,
			&:hover {
				background-color: ${(props) => props.theme.colors.activePrimary};
			}
		}
	}
	.accordion-header {
		button {
			font-weight: 500;
			padding-left: 1rem;

			&:active,
			&:focus {
				box-shadow: none;
			}

			:not(.collapsed) {
				background-color: ${(props) => props.theme.colors.activePrimary};
				color: black;
				box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);

				&:after {
					fill: ${(props) => props.theme.colors.activePrimary};
				}
			}
		}
	}
	.app-version {
		text-align: center;
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 500;
		font-size: 0.55rem;
		position: absolute;
		bottom: 1rem;
		left: ${({ isCollapsed }: any) => (isCollapsed ? `3rem` : `2px`)};
		width: 'max-content';
		color: #313131;

		a {
			border-bottom: 1.5px dashed #00834d;
			text-decoration: none;
			color: #313131;
		}
	}
	.link-title {
		${(props) => props.$iscollapsed && `display: none;`}
	}
`;

interface ProfileContainerProps {
	$iscollapsed: boolean;
}
export const ProfileContainer = styled.div<ProfileContainerProps>`
	border-top: 1px solid #dddddd;
	border-bottom: 1px solid #dddddd;
	padding: 1.25rem 1.5rem;
	${(props: any) => props.$iscollapsed && `padding: 0.65rem;`}
	.expanded-user-menu-info {
		flex: 1;
	}
	display: flex;
	img {
		width: 44px;
		height: 44px;
		border-radius: 100%;
	}
	h2 {
		font-size: 0.875rem;
		font-weight: 500;
		margin-bottom: 0rem;
	}
	h3 {
		font-size: 0.813rem;
		font-weight: 400;
		color: #7b7b7b;
		margin-bottom: 0rem;
	}
	.details {
		align-self: center;
		margin-left: 0.75rem;

		h3 {
			word-break: break-all;
		}
	}

	.dropdown {
		margin-left: auto;
		cursor: pointer;

		&:hover,
		&:focus,
		&:active {
			background: transparent;
			color: black;
		}

		.dropdown-item {
			background: transparent;

			&:hover,
			&:focus,
			&:active {
				background: transparent;
				color: black;
			}
		}
	}

	.show {
		.btn-primary.dropdown-toggle {
			border-color: transparent !important;
			background-color: transparent !important;
		}

		.dropdown-toggle {
			&:after {
				transform: rotate(-180deg);
				transition: transform 0.2s ease-in-out;
			}
		}
	}

	.dropdown-menu {
		transform: translate3d(-140px, 26.5px, 0px) !important;
		border: none;
		box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.12);
		border-radius: 8px;
	}

	.dropdown-toggle {
		all: unset;
		border: none;
		background: none;
		color: black;
		padding: 0px;

		&:after {
			border: none;
			width: 15px;
			height: 15px;
			margin-top: 0.3rem;
			vertical-align: -webkit-baseline-middle;
			background: url(${dropdown}) no-repeat center;
			transition: transform 0.2s ease-in-out;
		}

		&:hover,
		&:focus,
		&:active {
			all: unset;
			box-shadow: none !important;
			border: none;
			background: transparent;
			color: black;
			padding: 0px;
		}
	}
`;

export const SidebarMobileContainer = styled(SidebarContainer)`
	background: white;
	box-shadow: none;
	.logo {
		margin: 24px;
		width: 122px;
		height: 34px;
		img {
			height: 100%;
		}
	}
	.sidebar-nav {
		list-style: none;
		padding-left: 0;
		margin: 2rem 0.25rem;
		li > a {
			display: block;
			color: black;
			font-weight: 500;
			border-radius: 0.25rem;
			padding: 0.8rem 1rem;
			&.active,
			&:hover {
				background-color: ${(props) => props.theme.colors.activePrimary};
			}
		}
	}

	.dropdown-menu {
		transform: translate3d(-140px, -12px, 0px) !important;
		border: none;
		box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.12);
		border-radius: 8px;
	}
`;

export const MobileNav = styled(Navbar)`
	.btn-primary {
		background: ${({ theme }) => theme.colors.backgroundDarkGray};
		height: 2.5rem;
		width: 2.5rem;
		border: none;
		border-radius: 8px;
		padding: 0rem;
		margin-left: auto;
		margin-right: 1rem;

		svg {
			width: 1.25rem;
		}

		&:hover,
		&:focus,
		&:active {
			box-shadow: none;
			border-color: transparent;
			background: ${({ theme }) => theme.colors.backgroundDarkGray};
		}
	}

	.navbar-toggler {
		background: ${({ theme }) => theme.colors.backgroundDarkGray};
		height: 2.5rem;
		width: 2.5rem;
		border: none;
		border-radius: 8px;
		padding: 0rem;
	}

	.navbar-brand {
		margin-left: 0.5rem;
		svg {
			width: 94px;
		}
	}

	.navbar-toggler-icon {
		width: 1.25rem;
	}
`;

export const MenuButton: any = styled(Button)`
	width: 100%;
	max-width: none !important;
	text-align: left;
	margin-top: 0.5rem;
	display: block;
	color: black;
	font-weight: 500;
	border-radius: 0.25rem;
	padding: 0.8rem 1rem;
	color: ${({ theme }) => theme.colors.primary};

	&:hover,
	&:focus,
	&:active {
		color: ${({ theme }) => theme.colors.primary};
		background: #c9efdf;
	}
`;

export const CustomModal = styled(Modal)`
	.modal-content {
		border-radius: 20px;
	}

	.modal-body {
		padding: 3.25rem;
		text-align: center;

		h3 {
			font-family: 'Poppins';
			font-style: normal;
			font-weight: 500;
			font-size: 1.4rem;
			line-height: 2.25rem;
			margin-bottom: 2.5rem;
		}
	}
`;

interface CollapseWrapperProps {
	$isopen: boolean;
}
export const CollapseWrapper = styled.div<CollapseWrapperProps>`
	background: #c9efdf;
	padding: 0.5rem 0.75rem;
	border-radius: 8px 0px 0px 8px;
	margin-left: auto;
	cursor: pointer;
	${(props: any) => props.$isopen && `transform: rotate(180deg);`}
`;

export const DropdownWrapper = styled(Dropdown)`
	.dropdown-menu {
		box-shadow: 0px 4px 40px rgb(0 0 0 / 12%);
		border: 0px;
	}
`;

export const Wrapper = styled.div`
	.resources {
		border: 1px solid transparent;
		&:hover,
		&:focus,
		&:active {
			color: ${({ theme }) => theme.colors.primary};
			/* border: 1px solid ${({ theme }) => theme.colors.primary}; */
			background: #c9efdf;
		}
	}

	.profile-img {
		padding: 0rem;
		background: transparent;
		border: none;
		box-shadow: none;
		&:after,
		&:hover,
		&:focus,
		&:active {
			background: transparent;
			border: none;
			box-shadow: none;
		}
	}
	@media (max-height: 770px) {
		ul {
			max-height: 50vh;
			overflow-y: auto;
			::-webkit-scrollbar {
				width: 2.5px;
				border-radius: 10px;
			}

			/* Track */
			::-webkit-scrollbar-track {
				background: #e1e0e0;
			}

			/* Handle */
			::-webkit-scrollbar-thumb {
				background: #b0b0b0;
			}

			/* Handle on hover */
			::-webkit-scrollbar-thumb:hover {
				background: #555;
			}
		}
	}
`;
