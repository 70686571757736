import { getToken } from 'helpers/utils/auth-provider';
import { getUnixTimestamp } from 'helpers/utils/formatters';
import { CLIENTS_BY_COMPANY_ID_QUERY } from '../queries/clientsQuery';
import { LOGS_BY_PROJECT_ID_QUERY } from '../queries/logsQuery';
import { PIPELINE_TALLY_DATA_BY_PROJECT_ID_QUERY } from '../queries/pipelineTallyDataQuery';
import {
	CHARTS_DATA_BY_PROJECT_ID_QUERY,
	CREATE_MESSAGE_BOARD_FROM_PROJECT_ID_MUTATION,
	DELETE_PROJECT_BY_ID_MUTATION,
	EVENTS_DATA_BY_PROJECT_ID_QUERY,
	PIPE_DAMAGE_CODE_QUERY,
	PROJECTS_BY_COMPANY_ID_QUERY,
	PROJECT_BY_ID_QUERY,
	SMS_DATA_BY_PROJECT_ID_QUERY,
} from '../queries/projectQuery';
import client from './apolloClient';
import { apiURL, scriptsURL } from './urlDataBuilders';

const apiURLEndpoint = apiURL();
const scriptsURLEndpoint = scriptsURL();

export const getProjects = () =>
	client.query({
		query: PROJECTS_BY_COMPANY_ID_QUERY,
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
	});

export const getProjectById = (id) =>
	client.query({
		query: PROJECT_BY_ID_QUERY,
		variables: {
			id,
		},
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
	});

export const getAllProjectLogs = () =>
	client.get('/projects/logs').then((res) => res.data);

export const getProjectLogById = (id) =>
	client.query({
		query: LOGS_BY_PROJECT_ID_QUERY,
		variables: {
			id,
			type: 'projects',
		},
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
	});

// Graphs to call: rigchart, swivelchart, pump, well
// Could be called individually or all by using the pipe (|) symbol as separator
export const getProjectData = (id) => {
	const graphs = 'rigchart|swivelchart|pump|well';
	return client.query({
		query: CHARTS_DATA_BY_PROJECT_ID_QUERY,
		variables: {
			id,
			graphs,
		},
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
	});
};

export const getCustomers = () =>
	client.query({
		query: CLIENTS_BY_COMPANY_ID_QUERY,
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
	});

export const getProjectEvents = (id) =>
	client.query({
		query: EVENTS_DATA_BY_PROJECT_ID_QUERY,
		variables: {
			id,
		},
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
	});
export const getProjectSms = (id) =>
	client.query({
		query: SMS_DATA_BY_PROJECT_ID_QUERY,
		variables: {
			id,
		},
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
	});

export const addProjectSMS = async (data) => {
	const msgId =
		data.projectCustomId + Math.random().toString(36).substring(-18);

	const dataPacket = {
		Body: data.msg,
		MessageSid: msgId,
		From: data.usernumber,
		pageto: 'project',
		project: data.projectid,
		To: 123456,
	};

	return client.mutate({
		mutation: CREATE_MESSAGE_BOARD_FROM_PROJECT_ID_MUTATION,
		variables: {
			messageBoardInput: {
				...dataPacket,
			},
		},
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
	});
};

export const getPipeData = (id) =>
	client.query({
		query: PIPELINE_TALLY_DATA_BY_PROJECT_ID_QUERY,
		variables: {
			id,
		},
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
	});

export const getPipeDamageCodes = () =>
	client.query({
		query: PIPE_DAMAGE_CODE_QUERY,
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
	});

export const getProjectEquipmentList = () =>
	client.get('/equipments/list').then((res) => res.data);

export const deleteProject = async (id) => {
	client.mutate({
		mutation: DELETE_PROJECT_BY_ID_MUTATION,
		variables: {
			deleteProject: {
				id,
			},
		},
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
	});
};

// This is only to autogenerate a custom id if not entered.
// Check for the custom id if it ends with a number, otherwise, increment the value.
// If a company has already a project, a custom id will be autogenerated based on the last project id retrieved.
export const createCustomProjectId = async () => {
	let incrementor;
	let newId;
	let customId;
	let num;
	const companyIdentifier = localStorage.getItem('__auth_company_custom__');

	const userId = localStorage.getItem('__auth_user_id__');
	const companyId = localStorage.getItem('__auth_company_id__');
	const dataURL = `${scriptsURLEndpoint}/projects/checkProjectCustomId.php?uid=${userId}&cid=${companyId}&customid=new`;
	const response = await fetch(dataURL);
	const result = await response.json();
	localStorage.setItem('projectCustomId', result.data);

	if (result.success === 'true') {
		customId = result.data;
		num = customId?.match(/\d/g)?.join('');
		incrementor = +num + 1;
		incrementor = `000000${incrementor}`.slice(-7);
		newId = `${companyIdentifier}${incrementor}`;
	} else {
		// If it is a company first project
		newId = `${companyIdentifier}0000001`;
	}
	return newId.toString();
};

export const projectCustomId = async (val) => {
	const value = val;
	const userId = localStorage.getItem('__auth_user_id__');
	const companyId = localStorage.getItem('__auth_company_id__');

	const dataURL = `${scriptsURLEndpoint}/projects/checkProjectCustomId.php?uid=${userId}&cid=${companyId}&customid=${value}`;
	const response = await fetch(dataURL);
	const result = await response.json();
	localStorage.setItem('projectCustomId', result.data);
	return result;
};

export const exportProjectData = async (data) => {
	let bodyData;
	const daterange = `${getUnixTimestamp(data.project_start) / 1000}|${
		getUnixTimestamp(data.project_end) / 1000
	}`;
	if (data.optdate === 'fulldate') {
		bodyData = {
			token: getToken(),
			projectid: data.projectid,
			range: daterange,
		};
	} else {
		bodyData = {
			token: getToken(),
			projectid: data.projectid,
			date: data.projectDates,
		};
	}
	const dataURL = `https://development.rigseye.com/download.php`;
	const opts = {
		method: 'POST',
		headers: {
			'http-authorization': getToken(),
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		body: JSON.stringify(bodyData),
	};
	const response = await fetch(dataURL, opts);
	const result = await response.json();
	return result;
};

// TODO: Add Project Images to project
export const getProjectImages = async (smsId) => {
	const projectImageURL = `${scriptsURLEndpoint}/projects/getImages.php?id=${smsId}`;
	const opts = {
		headers: {
			'http-authorization': getToken(),
			'Content-Type': 'application/json',
		},
	};
	const response = await fetch(projectImageURL, opts);
	const result = await response.json();
	return result;
};
