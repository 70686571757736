import { getProjectData } from 'helpers/api/projectManagement';
import { projectChartsLabels } from 'helpers/const/chartLabels';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ChartContainer } from 'pages/details/styled';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { useParams } from 'react-router-dom';
import ChartTabsView from './ChartTabs';

const tabList = [
	{ key: 'swivel_rpm', title: projectChartsLabels.swivel_rpm },
	{ key: 'swivel_torque', title: projectChartsLabels.swivel_torque },
];

function SwivelTorquePSIChart() {
	const { id } = useParams();
	const [initialChartData, setInitialChartData] = useState([]);
	const [tabKey, setTabKey] = useState(tabList[0].key);
	const mountedRef = useRef(false);
	const torqueMin = 0;
	const torqueMax = 7500;
	const rpmMin = 0;
	const rpmMax = 200;
	const ticks = 6;

	const getData = async () => {
		const res = await getProjectData(id);
		return res?.data.chartsDataByProjectId;
	};

	useEffect(() => {
		mountedRef.current = true;
		// eslint-disable-next-line no-return-assign
		return () => (mountedRef.current = false);
	}, []);

	useEffect(() => {
		// Checking if the component is mounted and setting the initial data
		if (mountedRef.current) {
			getProjectData(id).then((res) =>
				setInitialChartData(res?.data?.chartsDataByProjectId[2]?.swivel || []),
			);
		}
	}, [mountedRef.current]);

	const mappedData = React.useMemo(
		() =>
			initialChartData.map((swivelInfo) => ({
				marker: {
					enabled: false,
				},
				name: swivelInfo.serial,
				data: (() => {
					// generate an array of random data
					const data = [];
					const time = new Date().getTime();
					let i;

					for (i = -39; i <= 0; i += 1) {
						const obj = swivelInfo?.charts?.swivel?.[0] || {};
						data.push({
							x: time + i * 1000,
							y: obj[tabKey] || 0,
						});
					}
					return data;
				})(),
			})),
		[initialChartData],
	);

	const options = React.useMemo(
		() => ({
			chart: {
				type: 'spline',
				animation: Highcharts.svg, // don't animate in old IE
				marginRight: 10,
			},

			time: {
				useUTC: false,
			},

			title: false,
			credits: {
				enabled: false,
			},

			accessibility: {
				announceNewData: {
					enabled: true,
					minAnnounceInterval: 15000,
					announcementFormatter(newPoint) {
						if (newPoint) {
							return `New point added. Value: ${newPoint.y}`;
						}
						return false;
					},
				},
			},

			xAxis: {
				type: 'datetime',
				tickPixelInterval: 150,
			},

			yAxis: [
				{
					min: tabKey === 'swivel_rpm' ? rpmMin : torqueMin,
					max: tabKey === 'swivel_rpm' ? rpmMax : torqueMax,
					tickAmount: ticks,
					title: {
						text: projectChartsLabels[tabKey],
					},
					plotLines: [
						{
							value: 0,
							width: 1,
							color: '#808080',
						},
					],
				},
			],

			// yAxis: {
			// 	min,
			// 	max,
			// 	tickAmount: ticks,
			// 	title: {
			// 		text: 'Value',
			// 	},
			// 	plotLines: [
			// 		{
			// 			value: 0,
			// 			width: 1,
			// 			color: '#808080',
			// 		},
			// 	],
			// },

			tooltip: {
				headerFormat: '<b>{series.name}</b><br/>',
				pointFormat: '{point.x:%Y-%m-%d %H:%M:%S}<br/>{point.y:.2f}',
			},

			legend: {
				enabled: false,
			},

			exporting: {
				enabled: false,
			},

			series: [
				...mappedData,
				{
					marker: {
						enabled: false,
					},
					name: 'a',
					data: (() => {
						// generate an array of random data
						const data = [];
						const time = new Date().getTime();
						let i;

						for (i = -39; i <= 0; i += 1) {
							data.push({
								x: time + i * 1000,
								y: 0,
								// y: initialChartData?.[0],
							});
						}
						return data;
					})(),
				},
			],
		}),
		[mappedData, tabKey],
	);

	const chartComponentRef = React.useRef(null);

	useEffect(() => {
		const { chart } = chartComponentRef.current;
		// const series = chart.series[0];

		const updateInterval = setInterval(() => {
			getData().then((res) => {
				const swivelChartData = res[2]?.swivel || [];
				const x = new Date().getTime(); // current time
				const y = parseFloat(res);

				try {
					if (mountedRef.current) {
						// headerDataRef.current.innerHTML = y;
						// headerTimeRef.current.innerHTML = moment(x).format('HH:mm:ss');
						if (swivelChartData.length) {
							swivelChartData.forEach((swivelInfo) => {
								const series = chart.series.find(
									(s) => s.name === swivelInfo.serial,
								);
								if (series) {
									const object = swivelInfo?.charts?.swivel?.[0] || {};
									series.addPoint([x, object[tabKey]], true, true);
								}
							});
						} else {
							const series = chart.series[0];
							series.addPoint([x, 0], true, true);
						}
					}
				} catch {
					clearInterval(updateInterval);
				}

				// setData(res?.[0]?.[subchart]);
			});
		}, 2000);

		return () => clearInterval(updateInterval);
	}, []);

	// Waiting for initial data of charts
	if (!initialChartData) {
		return <Spinner size="md" animation="border" />;
	}

	return (
		<div>
			<ChartContainer>
				<Row className="header">
					<Col className="align-self-center">
						<h1 className="align-self-center">
							{projectChartsLabels.swivel_combo}
						</h1>
					</Col>
					<Col className="change-container">
						{/* <button
								type="button"
								onClick={() => handleDailyRPM('drawworks')}
								className="change-span"
							>
								{dailyRPM ? 'Live chart' : '24h chart'}
							</button> */}
						{/* <span>
								<DatePicker
									selected={selectedDate}
									onChange={(date) => chartselecteddate(date, 'suction')}
								/>
							</span> */}
					</Col>
				</Row>

				{/* <div className="details">
                        <div>
                            <span className="tag">Suction: </span>{' '}
                            <span>{livedata?.data?.charts?.suction?.[59]?.pump_suction}</span>
                        </div>
                    </div> */}

				{/* {dailyRPM && (
						<DailyChartGeneral
							data={data?.charts?.suction}
							subitem={pumpChartLabels.pump_suction}
						/>
					)} */}
				<ChartTabsView
					selectedTab={tabKey}
					onChange={setTabKey}
					tabs={tabList}
				/>
				<HighchartsReact
					ref={chartComponentRef}
					highcharts={Highcharts}
					options={options}
				/>
			</ChartContainer>
		</div>
	);
}

export default SwivelTorquePSIChart;
