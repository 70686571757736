import { TableContainer } from 'pages/dashboard/tables.styled';
import { Row, Col, Button, Spinner } from 'react-bootstrap';
import useResponsive from 'helpers/hooks/useResponsive';
import { ReactComponent as Right } from 'assets/icons/right-arrow.svg';
import { Link } from 'react-router-dom';
import React from 'react';
import { useQuery } from 'react-query';
import { getAllProjectLogs } from 'helpers/api/projectManagement';
import { useReactToPrint } from 'react-to-print';
import { UsersContainer } from 'components/Profile/users.styled';

export default function ProjectList() {
	const { isTablet, isMobile } = useResponsive();
	const { data, isFetching } = useQuery('LogList', getAllProjectLogs);
	const componentPrintRef = React.useRef();

	const handlePrint = useReactToPrint({
		content: () => componentPrintRef.current,
	});

	if (isFetching) {
		return (
			<UsersContainer $istablet={isTablet} $ismobile={isMobile}>
				<div
					className="mb-3 flex items-center"
					style={{
						cursor: 'pointer',
						maxWidth: 'fit-content',
					}}
				>
					<Right />
					<Link
						to="/projects"
						style={{
							textDecoration: 'none',
							color: 'black',
							paddingRight: '1.25rem',
							borderRight: '1px solid #dddddd',
						}}
					>
						<span className="ms-2">Back</span>
					</Link>
					<Link
						to="/projects"
						style={{
							textDecoration: 'none',
							color: 'black',
							paddingLeft: '0.75rem',
						}}
					>
						<span className="ms-2" style={{ color: '#00834D' }}>
							Projects
						</span>
						<span> {'>'} Logs</span>
					</Link>
				</div>

				<div className="text-center">
					<Spinner size="md" className="mt-3" animation="border" />
				</div>
			</UsersContainer>
		);
	}

	return (
		<UsersContainer
			isTablet={isTablet}
			isMobile={isMobile}
			ref={componentPrintRef}
		>
			<TableContainer>
				<div
					className="mb-3 flex items-center"
					style={{
						cursor: 'pointer',
						maxWidth: 'fit-content',
					}}
				>
					<Right />
					<Link
						to="/projects"
						style={{
							textDecoration: 'none',
							color: 'black',
							paddingRight: '1.25rem',
							borderRight: '1px solid #dddddd',
						}}
					>
						<span className="ms-2">Back</span>
					</Link>
					<Link
						to="/projects"
						style={{
							textDecoration: 'none',
							color: 'black',
							paddingLeft: '0.75rem',
						}}
					>
						<span className="ms-2" style={{ color: '#00834D' }}>
							Projects
						</span>
						<span> {'>'} Logs</span>
					</Link>
				</div>

				{/* <div className="user-table"> */}
				<div style={{ display: 'flex', textAlign: 'center' }}>
					<div
						style={{ display: 'flex', textAlign: 'center' }}
						className="align-items-center w-100"
					>
						<h1 className="my-4 fs-1 ms-0">All Projects Log</h1>
						<Button
							className="ms-auto align-self-center height-fit-content"
							variant="bordered-green"
							onClick={handlePrint}
						>
							Print
						</Button>
					</div>
				</div>

				<div>
					{data?.map((item) => (
						<Row className="project-list-mobile mb-3" key={item.id}>
							<Col>
								<div className="display-grid mb-3">
									<span className="tags">Time</span>
									<span className="time">
										{item.savetime.replace(' ', ' | ')}
									</span>
								</div>
								<div className="display-grid mb-3">
									<span className="tags">Request</span>
									<span
										className={
											item.request_type === 'DELETE' ? 'delete' : 'create'
										}
									>
										{item.request_type}
									</span>
								</div>
								<div className="display-grid mb-3">
									<span className="tags">Successful</span>
									{item.response_error === '0' ? (
										<div className="successful">True</div>
									) : (
										<div className="fail">False</div>
									)}
								</div>
							</Col>
							<Col>
								<div className="display-grid mb-3">
									<span className="tags">User</span>
									<span>{item.username}</span>
								</div>
								<div className="display-grid mb-3">
									<span className="tags">Query</span>
									<span>{item.requestaction}</span>
								</div>
								{/* <div className="display-grid mb-3">
									<span className="tags">Conditions</span>
									<span>Aug 6, 2021</span>
								</div> */}
							</Col>
						</Row>
					))}
				</div>
				{/* </div> */}
			</TableContainer>
		</UsersContainer>
	);
}
