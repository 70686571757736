/* eslint-disable jsx-a11y/label-has-associated-control */
import { ReactComponent as Right } from 'assets/icons/right-arrow.svg';
import DashboardLayout from 'components/Layouts/DashboardLayout';
import { getEquipmentProfile } from 'helpers/api/equipments';
import useResponsive from 'helpers/hooks/useResponsive';
import AddPump from 'pages/add-equipment/pump';
import AddRig from 'pages/add-equipment/rig';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Wrapper } from 'style/addEq.styled';
import styled from 'styled-components';

const EquipmentSelectionViewWrapper = styled.form`
	background: #ffffff;
	box-shadow: 0px 4px 57px rgba(0, 0, 0, 0.03);
	border-radius: 14px;
	padding: 24px 28px;
	margin-bottom: 2rem;
	margin-top: 1.25rem;
	.title-text {
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 27px;
		color: #000000;
	}
	input {
		width: 24px;
		height: 24px;
		accent-color: #00834d !important;
		cursor: pointer;

		&:hover,
		&:focus {
			outline: none !important;
		}
	}
	.radio-text {
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 160%;
		color: #4b4c52;
		cursor: pointer;
	}
`;

function EquipmentSelectionView({ onChange, value }) {
	return (
		<EquipmentSelectionViewWrapper className="d-flex gap-5">
			<span className="title-text">Select Equipment Type</span>
			<div className="d-flex gap-3">
				<div className="d-flex align-items-center gap-2">
					<input
						type="radio"
						value="rig"
						onChange={onChange}
						checked={value === 'rig'}
						id="rig"
					/>
					<label htmlFor="rig" className="radio-text">
						Rig
					</label>
				</div>
				<div className="d-flex align-items-center gap-2">
					<input
						type="radio"
						value="pump"
						onChange={onChange}
						checked={value === 'pump'}
						id="pump"
					/>
					<label htmlFor="pump" className="radio-text">
						Pump
					</label>
				</div>
			</div>
		</EquipmentSelectionViewWrapper>
	);
}

function EquipmentHeader({ edit = false }) {
	return (
		<div>
			<Link to="/equipments" style={{ all: 'unset' }}>
				<div
					className="mb-1 flex items-center"
					style={{ cursor: 'pointer', maxWidth: 'fit-content' }}
				>
					<Right />
					<button type="button" style={{ all: 'unset' }}>
						<span className="ms-2">Back</span>
					</button>
				</div>
			</Link>
			<h1 className="mt-3">{edit ? 'Edit' : 'Add'} Equipment</h1>
		</div>
	);
}

export default function AddEquipment() {
	const { isTablet, isMobile } = useResponsive();
	const [equipmentType, setEquipmentType] = useState('rig');
	const { id } = useParams();
	const [data, setData] = useState();
	const [editMode, setEditMode] = useState(false);

	const handleEquipmentTypeChange = (e) => {
		setEquipmentType(e.target.value);
		setData((dataInfo) => ({ ...dataInfo, type: e.target.value }));
	};

	const handleEquipmentType = (type) => {
		setEquipmentType(type);
	};

	useEffect(() => {
		if (id) {
			getEquipmentProfile(id).then((res) => {
				setEquipmentType(res?.type);
				setData({ ...(res || {}) });
				setEditMode(true);
			});
		}
	}, [id]);

	return (
		<DashboardLayout>
			<Wrapper isMobile={isMobile} isTablet={isTablet}>
				<EquipmentHeader edit={editMode} />
				<EquipmentSelectionView
					onChange={handleEquipmentTypeChange}
					value={equipmentType}
				/>
				{equipmentType === 'rig' ? (
					<AddRig
						edit={editMode}
						onEquipementTypeLoad={handleEquipmentType}
						data={data}
					/>
				) : (
					<AddPump
						edit={editMode}
						onEquipementTypeLoad={handleEquipmentType}
						data={data}
					/>
				)}
			</Wrapper>
		</DashboardLayout>
	);
}
