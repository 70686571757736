const UOM = {
	cycles: 'ea',
	cycles_min: 'ea/min',
	total_connections: 'ea/min',
	avg_connection_min: 'ea/min',
	block_travel: 'ft',
	avg_block_speed: 'ft/min',
	max_block_speed: 'ft/min',
	avg_rpm: 'RPM',
	max_rpm: 'RPM',
	avg_weight_on_hook: 'lbs x 1000',
	max_weight_on_hook: 'lbs x 1000',
	swivel_avg_rpm: 'RPM',
	swivel_max_rpm: 'RPM',
	torque_avg: 'FT-LBS',
	torque_max: 'FT-LBS',
	max_weight: 'FT-LBS',
	pressure_avg: 'PSI',
	pressure_max: 'PSI',
	swivel_max_psi: 'PSI',
	swivel_avg_psi: 'PSI',
	total_strokes: '',
	total_discharge_volume: '',
	avg_pump_discharge: 'BPM',
	max_pump_discharge: 'BPM',
	avg_pump_discharge_psi: 'PSI',
	max_pump_discharge_psi: 'PSI',
	avg_suction: 'PSIG',
	max_suction: 'PSIG',
};

export default UOM;
