import styled from 'styled-components';

const FooterLightsWrapper = styled.div`
	.status {
		width: 1.25rem;
		height: 1.25rem;
		border-radius: 50%;
		margin-left: auto;
		margin-right: auto;
	}
	span {
		font-size: 0.5rem;
		font-weight: 600;
		display: flex;
		justify-content: center;
		margin-top: 0.125rem;
	}
`;
const WarningLightsWrapper = styled.div`
	.status {
		width: 1.25rem;
		height: 1.25rem;
		border-radius: 50%;
		margin-left: auto;
		margin-right: auto;
	}
	span {
		font-size: 0.675rem;
		font-weight: 600;
		color: #a7a7a7;
		display: flex;
	}
`;
const StatusLightWrapper = styled.div`
	.text-container {
		display: flex;
		justify-content: space-between;
		span {
			font-size: 0.675rem;
			font-weight: 600;
		}
	}
	.lights-container {
		display: flex;
		div {
			width: 1.25rem;
			height: 1.25rem;
			margin-left: auto;
			margin-right: auto;
		}
		.off {
			border-top-left-radius: 50%;
			border-bottom-left-radius: 50%;
			background: #c62021;
		}
		.set {
			background: #d9d9d9;
		}
		.on {
			background: #00834d;
			border-top-right-radius: 50%;
			border-bottom-right-radius: 50%;
		}
	}
`;

export { FooterLightsWrapper, WarningLightsWrapper, StatusLightWrapper };
