import DashboardLayout from 'components/Layouts/DashboardLayout';
import Users from 'pages/usermanagement/Users';
import { DashboardContextProvider } from 'pages/dashboard/DashboardContext';

export default function UserManagement() {
	return (
		<DashboardContextProvider>
			<DashboardLayout>
				<Users />
			</DashboardLayout>
		</DashboardContextProvider>
	);
}
