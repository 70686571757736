import { gql } from '@apollo/client';

export const PROFILE_QUERY = gql`
	query Profile {
		profile {
			userid
			username
			name
			middlename
			lastname
			email
			mobile
			mobileproviderid
			mobile_domain
			notifications
			role
			company
			access
			companyname
			web_access
			downloads
			supervisor
			companyuser
			permission_level
			temperatureUnit
			speedUnit
			roles {
				isDeveloper
				isRigseyeUser
				isAdminUser
				isManagerUser
				isNormalUser
				isCompanyUser
			}
		}
	}
`;

export const GET_USERS_QUERY = gql`
	query users {
		users {
			userid: uid
			username: uname
			name: ufname
			middlename: umname
			lastname: ulname
			email: uemail
			mobile: umobile
			mobileprovider: umobileprovider
			downloads
			supervisor
			temperatureUnit
			speedUnit
			uroleid
			role {
				rtid
				permission_level: rtdesc
				rtvisibility
			}
			notifications: notification_option
		}
	}
`;

export const GET_USER_BY_ID_QUERY = gql`
	query getUserByID($userid: Float!) {
		user(userid: $userid) {
			userid: uid
			username: uname
			name: ufname
			middlename: umname
			lastname: ulname
			email: uemail
			mobile: umobile
			mobileprovider: umobileprovider
			uemailcontact
			ustatus
			uroleid
			ucid
			upage_access
			companyUser
			clientUser
			downloads
			supervisor
			temperatureUnit
			speedUnit
			role {
				rtid
				permission_level: rtdesc
				rtvisibility
			}
			equipments
			projects
			roles
			access
			notifications: notification_option
		}
	}
`;

export const EDIT_USER_MUTATION = gql`
	mutation EditUser(
		$userId: Float
		$username: String!
		$firstName: String!
		$middleName: String
		$lastName: String!
		$email: String!
		$mobileNumber: String
		$mobileProvider: String
		$downloads: String!
		$uroleid: Float!
		$supervisor: String!
		$companyUser: String!
		$temperatureUnit: String!
		$speedUnit: String!
		$notification_option: String!
		$upage_access: String!
	) {
		editUser(
			editUserInput: {
				userId: $userId
				username: $username
				firstName: $firstName
				middleName: $middleName
				lastName: $lastName
				email: $email
				mobileNumber: $mobileNumber
				mobileProvider: $mobileProvider
				downloads: $downloads
				supervisor: $supervisor
				uroleid: $uroleid
				companyUser: $companyUser
				temperatureUnit: $temperatureUnit
				speedUnit: $speedUnit
				notification_option: $notification_option
				upage_access: $upage_access
			}
		) {
			uid
			uname
			ufname
			umname
			ulname
			uemail
			upassword
			nupassword
			umobile
			umobilecontact
			umobileprovider
			uemailcontact
			ustatus
			uroleid
			ucid
			upage_access
			notification_option
			companyUser
			clientUser
			downloads
			supervisor
			temperatureUnit
			speedUnit
			role {
				rtid
				rtdesc
				rtvisibility
			}
			equipments
			projects
			roles
			access
		}
	}
`;

export const ADD_USER_MUTATION = gql`
	mutation AddUser(
		$username: String!
		$firstName: String!
		$middleName: String!
		$lastName: String!
		$password: String!
		$email: String!
		$mobileNumber: String!
		$mobileProvider: String!
		$downloads: String!
		$supervisor: String!
		$uroleid: Float!
		$companyUser: String!
		$temperatureUnit: String!
		$speedUnit: String!
		$notification_option: String!
		$upage_access: String!
	) {
		addUser(
			addUserInput: {
				username: $username
				firstName: $firstName
				middleName: $middleName
				lastName: $lastName
				password: $password
				email: $email
				mobileNumber: $mobileNumber
				mobileProvider: $mobileProvider
				downloads: $downloads
				uroleid: $uroleid
				supervisor: $supervisor
				companyUser: $companyUser
				temperatureUnit: $temperatureUnit
				speedUnit: $speedUnit
				notification_option: $notification_option
				upage_access: $upage_access
			}
		) {
			uid
			uname
			ufname
			umname
			ulname
		}
	}
`;

export const ASSIGN_USER_TO_EQUIPMENT_OR_PROJECTS = gql`
	mutation assign($userid: Int!, $projects: [Int], $equipments: [String]) {
		assign(userid: $userid, projects: $projects, equipments: $equipments) {
			status
			equipments
			projects
		}
	}
`;

export const DELETE_USER_BY_ID_MUTATION = gql`
	mutation deleteUser($userId: Float!) {
		deleteUser(userId: $userId)
	}
`;

export const UPDATE_USER_PASSWORD_MUTATION = gql`
	mutation updateUserPassword(
		$updateUserPasswordArgs: UpdateUserPasswordInput!
	) {
		updateUserPassword(updateUserPasswordArgs: $updateUserPasswordArgs)
	}
`;
