import React, { useState, useEffect } from 'react';
import { Chart } from 'react-google-charts';
import DetailsCard from 'pages/details/DetailsCard';

export function getData(value) {
	const label = 'Str / Min';
	return [
		['Label', 'Value'],
		[label, value],
	];
}

const styles = {
	display: 'inline-block',
	flexFlow: 'row wrap',
	justifyContent: 'center',
	alignItems: 'center',
	textAlign: 'center',
};

function PumpStrokesGauge({ value }) {
	const [data, setData] = useState(getData(value));

	const options = {
		width: 300,
		height: 250,
		minorTicks: 5,
		min: 0,
		max: 200,
	};

	useEffect(() => {
		setData(getData(value));
	}, []);

	return (
		<DetailsCard>
			<div style={styles}>
				<Chart
					chartType="Gauge"
					width="100%"
					height="300px"
					data={data}
					options={options}
				/>
			</div>
		</DetailsCard>
	);
}

export default PumpStrokesGauge;
