import styled from 'styled-components';

const Wrapper = styled.div`
	.status {
		top: 2rem;
		left: 2rem;
		width: 0.75rem;
		height: 0.75rem;
		border-radius: 50%;
	}
	.status-container {
		display: flex;
		align-items: center;
		gap: 0.5rem;
	}
`;

export { Wrapper };
