import { gql } from '@apollo/client';

export const GET_MULTIVIEW_CAMERA_LAYOUT_LIST_QUERY = gql`
	query getMultiViewLayout {
		getMultiViewLayout {
			id
			view_name
			selection
		}
	}
`;

export const UPDATE_MULTIVIEW_CAMERA_LAYOUT_MUTATION = gql`
	mutation updateMultiViewLayout($overViewUpdateArgs: OverViewUpdateArgs!) {
		updateMultiViewLayout(overViewUpdateArgs: $overViewUpdateArgs) {
			success
		}
	}
`;

export const DELETE_MULTIVIEW_CAMERA_LAYOUT_MUTATION = gql`
	mutation deleteMultiViewLayout($id: Float!) {
		deleteMultiViewLayout(id: $id) {
			success
		}
	}
`;

export const GET_MULTIVIEW_CAMERA_QUERY = gql`
	query getMultiView($id: Float!) {
		multiView(id: $id) {
			data {
				distance_above_surface
				posistop_dial
				rodlength
				startDate
				endDate
				customerid
				customerName
				actualLoad
				default_max_value
				default_air_pressure
				activityID
				activityDescription
				acceleratorPosition
				ADFullScale
				ADZero
				alternatorVoltage
				altitude
				air_pressure
				airPressure
				airPressure2
				ambientAirTemp
				batteryVoltage
				barometricPressure
				lockup_timer
				flow_rate
				eventObjects
				supervisorid
				operatorid
				supervisor
				operator
				yardId
				yardNumber
				yardName
				yardCustomId
				regName
				regCustomId
				weightGaugeMax
				timezoneName
				timezoneIdentifier
				rigNumber
				positionPercent
				speedFeetPerMinute
				limitLoad
				limitLoadSet
				tonMiles
				windDirection
				windSpeed
				temp
				humidity
				dms_longitude
				dms_latitude
				longitude
				latitude
				engineSpeed
				vehicleSpeed
				engineTemp
				engineOilPressure
				transmissionTemp
				transmissionOilPressure
				fuelLevel1
				dieselFluid
				instantFuelEconomy
				gearSelected
				gearAttained
				switchedVoltage
				max_value
				powerVoltage
				accelPedalPosition
				percentLoad
				fuelTemp
				engineAirInletTemp
				drivelineEngaged
				torqueConvLockEngaged
				transInputShaftSpeed
				transOutputShaftSpeed
				percentClutchSlip
				totalVehicleHours
				odometer
				totalEngineHours
				totalIdleHours
				totalIdleFuelUsed
				highResVehicleOdometer
				highResVehicleTripodometer
				UTCSeconds
				vehicleOdometer
				vehicleTripodometer
				totalFuelUsed
				totalTripFuelUsed
				averageFuelEconomy
				totalFuelEconomy
				totalPTOHours
				totalPTOFuelUsed
				PTOTripFuelMoving
				PTOTripFuelStationary
				ipAddress
				CANenabled
				ECUenabled
				positionCalibrationCount
				calibratedLowerMiddlePosition
				calibratedUpperMiddlePosition
				calibratedLowerLayerPosition
				calibratedUpperLayerPosition
				upperSecondaryLimitPosition
				lowerSecondaryLimitPosition
				maxLoad
				partsOfLine
				recordedCalibrationUTCTime
				calibrationUTCTime
				engineOilTemp
				fuelRate
				volts
				serverTime
				softwareInfo
				subscriptionInfo
				latestFuelLevel
				latestExhaustFluidLevel
				latestOdometer
				latestEngineHours
				latestFuelUsed
				data
				offdriller_side_psi
				driller_side_psi
				U1
				aux_u1
				U2
				aux_u2
				U3
				aux_u3
				U4
				aux_u4
				U5
				aux_u5
				U6
				aux_u6
				U7
				aux_u7
				U8
				aux_u8
				U9
				aux_u9
				U10
				aux_u10
				U11
				aux_u11
				U12
				aux_u12
				U13
				aux_u13
				U14
				aux_u14
				U15
				aux_u15
				U16
				aux_u16
				U17
				aux_u17
				U18
				aux_u18
				drawerrortime
				type
				sitelevel
				serial
				swivel_attached
				region
				eqcompany
				id
				cell_info
				software_info
				flow_unit
				weight_source
				weight_actual
				wweight_on_bit
				weight_on_bit
				wweight_on_hook
				weight_on_hook
				block_empty_weight
				rig_woh
				rig_wob
				rig_bew
				weight
				weight_max
				weight_max_psi
				tong_torque_high
				tong_torque_low
				tong_gear_type
				driller_side
				offdriller_side
				transducer_range
				pressure_pad_inch
				well_psi_pressure
				well_flow_rate
				well_discharge_temp
				well_inlet_flow_rate
				well_outlet_flow_rate
				well_inlet_pressure
				well_inlet_temp
				zerothreshold_well_inlet_pressure
				zerothreshold_well_discharge
				zerothreshold_tong_torque
				zerothreshold_weight_u5
				zerothreshold_weight_u7
				pump_psi_pressure
				max_discharge_pressure
				max_suction_pressure
				pump_adjusment
				zerothreshold_pump_discharge_pressure
				zerothreshold_pump_suction_pressure
				swivel_torque_range
				default_torque
				default_max_torque
				swivel_torque
				swivel_adjusment
				base_psi
				zerothreshold_swivel_pressure
				project_custom_id
				author
				company
				afe
				well
				well_custom_id
				wellname
				padname
				tubing_size
				measure_unit
				assembly_length
				floor_height
				kb_height
				client
				name
				lease_name
				begins
				ends
				equipments
				company_logo
				project_coordinates
				project_charts
				projectid
				user
				timestamp
				in
				out
				pipe_length
				pipe_code
				pumpdischarge_max
				pumpflow_max
				pumpsuction_max
				wellflow_max
				welldischarge_max
				max_bpm
				max_gpm
				chart_swivel_max_psi
				chart_swivel_max_torque
				chart_swivel_max_rpm
				chart_pump_max_psi
				chart_pump_max_suction
				status {
					type
					serial
					messages
					timezone
					misc {
						yard
						volts
						sitelevel
					}
					gps {
						latdd
						londd
						altitude
						latitude
						longitude
						rawaltitude
					}
					weather {
						temp {
							unit
							value
						}
						wind {
							unit
							speed
							direction {
								abr
								full
							}
						}
					}
					vehicle {
						unit
						speed
						status
					}
					enabled {
						value
					}
					status {
						date
						flag
						color
						value
						online
						message
						utctime
						messageflag
						onlinecolor
						shortmessage
						signal_value
						signal_strength
					}
					region {
						id
						name
						identifier
						yard {
							id
							name
							number
						}
					}
					events {
						date
						time
						value
						xflag
						message
						posi_on
					}
					eventsnew {
						date
						time
						value
						message
					}
					engine {
						value
					}
					camera {
						status
						data
					}
					active {
						value
					}
					yard {
						id
						name
						number
					}
					calibration {
						date
						time
						count
						textcolor
						calibrated
						event
					}
				}
			}
			camera {
				data
				status
			}
			equipment
			lights {
				warning
				limit
				limitLoad
				interlock
				slips
				rodlockout
				rodmanual
			}
			vehicle {
				unit
				speed
				status
			}
			status {
				date
				flag
				color
				value
				online
				message
				utctime
				messageflag
				onlinecolor
				shortmessage
				signal_value
				signal_strength
			}
			misc {
				yard
				volts
				sitelevel
			}
			calibration {
				date
				time
				count
				textcolor
				calibrated
				event
			}
			weather {
				wind {
					unit
					speed
					direction {
						abr
						full
					}
				}
				temp {
					unit
					value
				}
			}
			gps {
				latdd
				londd
				altitude
				latitude
				longitude
				rawaltitude
			}
			yard {
				id
				name
				number
			}
			region {
				id
				name
				identifier
				yard {
					id
					name
					number
				}
			}
			engine {
				value
			}
			serial
			type
			enabled {
				value
			}
			timezone
		}
	}
`;

export const CREATE_MULTIVIEW_CAMERA_MUTATION = gql`
	mutation overViewCreateMutation($viewName: String!, $selection: [String!]!) {
		overViewCreateMutation(
			overViewCreateArgs: { viewName: $viewName, selection: $selection }
		) {
			success
		}
	}
`;
