import React from 'react';

function Celsius() {
	return (
		<span
			style={{ marginLeft: 3, fontSize: 16, fontWeight: 700, color: '#000' }}
		>
			&#8451;
		</span>
	);
}

export default Celsius;
