/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { yupResolver } from '@hookform/resolvers/yup';
import { updateEquipmentInfoRig } from 'helpers/api/equipmentProfile';
import useResponsive from 'helpers/hooks/useResponsive';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

function ErrorMessage({ children }) {
	return <small className="text-danger mt-2 d-block">{children}</small>;
}

export default function ProfilePopup({ data, open, onClose, refetch }) {
	const { id } = useParams();
	const { isMobile } = useResponsive();
	const [loading, setLoading] = useState(false);
	const schema = yup
		.object({
			weight_source: yup.string().required('Weight Source is required.'),
			weight_max: yup.number().required('Weight Max is required.'),
			driller_side: yup.string().required('Driller Side is required.'),
			offdriller_side: yup.string().required('Off Driller Side is required.'),
			rig_bew: yup.number().required('Dead Weight is required.'),
			well_psi_pressure: yup.string().required('PSI Pressure is required.'),
			well_flow_rate: yup.string().required('Well Flow Rate is required.'),
			well_discharge_temp: yup
				.string()
				.required('Well Discharge Temp. is required.'),
			well_inlet_pressure: yup
				.string()
				.required('Well Inlet Pressure is required.'),
			well_inlet_flow_rate: yup
				.string()
				.required('Well Inlet Flow Rate is required.'),
			well_inlet_temp: yup.string().required('Well Inlet Temp. is required.'),
			tong_conversion_id: yup.number(),
		})
		.required();

	const { register, handleSubmit, formState, reset } = useForm({
		resolver: yupResolver(schema),
	});

	const closeModal = () => {
		onClose();
	};

	useEffect(() => {
		if (open === true) {
			reset({
				weight_source: data?.[0]?.weight_source,
				weight_max:
					parseInt(data?.[0]?.weight_max, 10) > 999
						? parseInt(data?.[0]?.weight_max, 10) / 1000
						: parseInt(data?.[0]?.weight_max, 10),
				driller_side: data?.[0]?.driller_side,
				offdriller_side: data?.[0]?.offdriller_side,
				rig_bew: Math.round(data?.[0]?.block_empty_weight),
				well_psi_pressure: data?.[0]?.well_psi_pressure,
				well_flow_rate: data?.[0]?.well_flow_rate,
				well_discharge_temp: data?.[0]?.well_discharge_temp,
				well_inlet_pressure: data?.[0]?.well_inlet_pressure,
				well_inlet_flow_rate: data?.[0]?.well_inlet_flow_rate,
				well_inlet_temp: data?.[0]?.well_inlet_temp,
				tong_conversion_id: data?.[0]?.tong_conversion_id || 0,
			});
		}
	}, [open]);

	const onSubmit = (updatedData) => {
		setLoading(true);
		const maxWeight = updatedData.weight_max;
		const updatedDataWithModifiedFields = {
			...updatedData,
			weight_max: maxWeight > 999 ? maxWeight : maxWeight * 1000,
		};
		updateEquipmentInfoRig({ serial: id, ...updatedDataWithModifiedFields })
			.then(() => {
				toast.success('Changes successfully applied');
				onClose();
				setLoading(false);
				refetch();
			})
			.catch((error) => {
				toast.error(error.response.message);
				setLoading(false);
			});
	};
	const { errors } = formState;

	return (
		<Modal
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			show={open}
			onHide={onClose}
			className="profile-modal"
		>
			<Modal.Body>
				{isMobile ? (
					<div className="information-container">
						<span className="information">Equipment Profile</span>
						<span className="top-right">
							{data?.data?.[0].type.toUpperCase()}{' '}
							<span className="green">{data?.data?.[0].serial ?? '-'}</span>
						</span>
						{/* <span className="top-right">
									Swivel connected{' '}
									<span className="green">
										{data?.data?.[0]?.serial ?? '-'}
									</span>
								</span> */}
					</div>
				) : (
					<div className="information-container">
						<span className="information">Equipment Profile</span>
						<span className="top-right">
							{data?.data?.[0].type.toUpperCase()}{' '}
							<span className="green">{data?.data?.[0].serial ?? '-'}</span>
						</span>
						{/* <span className="top-right">
									Swivel connected{' '}
									<span className="green">
										{data?.data?.[0]?.serial ?? '-'}
									</span>
								</span> */}
					</div>
				)}

				<Form onSubmit={handleSubmit(onSubmit)}>
					<Row className="inputs">
						<Col lg={4}>
							<Form.Group className="mb-3">
								<span>Weight Source</span>

								<select type="text" {...register('weight_source')}>
									<option>Pads</option>
									<option>Load Pin</option>
								</select>
								<ErrorMessage>{errors.weight_source?.message}</ErrorMessage>
							</Form.Group>
						</Col>
						<Col lg={4}>
							<Form.Group className="mb-3">
								<span>Max Weight (in Klbs)</span>

								<input
									type="number"
									{...register('weight_max')}
									min={0}
									onKeyDown={(evt) =>
										['-'].includes(evt.key) && evt.preventDefault()
									}
								/>
								<ErrorMessage>{errors.weight_max?.message}</ErrorMessage>
							</Form.Group>
						</Col>
						<Col lg={4}>
							<Form.Group className="mb-3">
								<span>Driller Side Transducer</span>

								<input
									type="number"
									{...register('driller_side')}
									min={0}
									onKeyDown={(evt) =>
										['-'].includes(evt.key) && evt.preventDefault()
									}
								/>
								<ErrorMessage>{errors.driller_side?.message}</ErrorMessage>
							</Form.Group>
						</Col>
						<Col lg={4}>
							<Form.Group className="mb-3">
								<span>Offdriller Side Transducer</span>

								<input
									type="number"
									{...register('offdriller_side')}
									min={0}
									onKeyDown={(evt) =>
										['-'].includes(evt.key) && evt.preventDefault()
									}
								/>
								<ErrorMessage>{errors.offdriller_side?.message}</ErrorMessage>
							</Form.Group>
						</Col>
						<Col lg={4}>
							<Form.Group className="mb-3">
								<span>Dead Weight (in Klbs)</span>

								<input
									type="number"
									{...register('rig_bew')}
									min={0}
									onKeyDown={(evt) =>
										['-'].includes(evt.key) && evt.preventDefault()
									}
								/>
								<ErrorMessage>{errors.rig_bew?.message}</ErrorMessage>
							</Form.Group>
						</Col>
						<Col lg={4}>
							<Form.Group className="mb-3">
								<span>Well Discharge Pressure</span>

								<input
									type="number"
									{...register('well_psi_pressure')}
									min={0}
									onKeyDown={(evt) =>
										['-'].includes(evt.key) && evt.preventDefault()
									}
								/>

								<ErrorMessage>{errors.well_psi_pressure?.message}</ErrorMessage>
							</Form.Group>
						</Col>
						<Col lg={4}>
							<Form.Group className="mb-3">
								<span>Well Discharge Flow Rate</span>

								<input
									type="number"
									{...register('well_flow_rate')}
									min={0}
									onKeyDown={(evt) =>
										['-'].includes(evt.key) && evt.preventDefault()
									}
								/>

								<ErrorMessage>{errors.well_flow_rate?.message}</ErrorMessage>
							</Form.Group>
						</Col>
						<Col lg={4}>
							<Form.Group className="mb-3">
								<span>Well Discharge Temp.</span>

								<input
									type="number"
									{...register('well_discharge_temp')}
									min={0}
									onKeyDown={(evt) =>
										['-'].includes(evt.key) && evt.preventDefault()
									}
								/>

								<ErrorMessage>
									{errors.well_discharge_temp?.message}
								</ErrorMessage>
							</Form.Group>
						</Col>
						<Col lg={4}>
							<Form.Group className="mb-3">
								<span>Well Inlet Pressure</span>

								<input
									type="number"
									{...register('well_inlet_pressure')}
									min={0}
									onKeyDown={(evt) =>
										['-'].includes(evt.key) && evt.preventDefault()
									}
								/>

								<ErrorMessage>
									{errors.well_inlet_pressure?.message}
								</ErrorMessage>
							</Form.Group>
						</Col>
						<Col lg={4}>
							<Form.Group className="mb-3">
								<span>Well Inlet Flow Rate</span>

								<input
									type="number"
									{...register('well_inlet_flow_rate')}
									min={0}
									onKeyDown={(evt) =>
										['-'].includes(evt.key) && evt.preventDefault()
									}
								/>

								<ErrorMessage>
									{errors.well_inlet_flow_rate?.message}
								</ErrorMessage>
							</Form.Group>
						</Col>
						<Col lg={4}>
							<Form.Group className="mb-3">
								<span>Well Inlet Temp.</span>

								<input
									type="number"
									{...register('well_inlet_temp')}
									min={0}
									onKeyDown={(evt) =>
										['-'].includes(evt.key) && evt.preventDefault()
									}
								/>

								<ErrorMessage>{errors.well_inlet_temp?.message}</ErrorMessage>
							</Form.Group>
						</Col>
						<Col lg={4}>
							<Form.Group className="mb-3">
								<span>Tong Conversion</span>

								<select type="number" {...register('tong_conversion_id')}>
									<option value={0}>No Tong</option>
									{data?.[0]?.tongs.map((tong) => (
										<option value={tong.tongid}>{tong.tongname}</option>
									))}
								</select>
								<ErrorMessage>{errors.weight_source?.message}</ErrorMessage>
							</Form.Group>
						</Col>

						{/* <Col lg={4}>
									<span>Max Inlet Flow Rate</span>
									<button className="calibrate">Calibrate now</button>
								</Col>
								<Col lg={4}>
									<span>Max Inlet Flow Rate</span>
									<button className="calibrate">Calibrate now</button>
								</Col> */}
					</Row>
					<div className="button-wrapper">
						<Button
							type="button"
							variant="bordered-green"
							className="me-3"
							onClick={closeModal}
						>
							Close
						</Button>
						<Button variant="primary-green" type="submit">
							{loading ? <Spinner size="sm" animation="border" /> : 'Save'}
						</Button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
