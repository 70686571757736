import { AuthProvider } from 'helpers/contexts/AuthContext';
import { Toaster } from 'react-hot-toast';
import { GlobalButtons } from 'style/GlobalButtons';
import { GlobalTable } from 'style/GlobalTable';
import { ThemeProvider } from 'styled-components';
import ImageViewer from 'components/ImageViewer';
import { UtilsProvider } from 'helpers/contexts/UtilsContext';
import { CssUtils } from 'style/CssUtils';
import AppRoutes from './AppRoutes';
import { MyGlobalStyle } from './style/GlobalStyles';
import { theme } from './style/theme';
import './index.css';

export default function App() {
	return (
		<ThemeProvider theme={theme}>
			<UtilsProvider>
				<AuthProvider>
					<AppRoutes />
				</AuthProvider>
				<MyGlobalStyle />
				<GlobalButtons />
				<GlobalTable />
				<CssUtils />
				<Toaster
					toastOptions={{
						style: {
							boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
						},
					}}
				/>
				<ImageViewer />
			</UtilsProvider>
		</ThemeProvider>
	);
}
