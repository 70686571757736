import { DashboardContextProvider } from 'pages/dashboard/DashboardContext';
import CameraLayoutList from './CameraLayoutList';

// eslint-disable-next-line func-names
export default function () {
	return (
		<DashboardContextProvider>
			<CameraLayoutList />
		</DashboardContextProvider>
	);
}
