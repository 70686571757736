import { ReactComponent as Right } from 'assets/icons/right-arrow.svg';
import { Link, useParams } from 'react-router-dom';
// import { Row, Col } from 'react-bootstrap';
// import { ReactComponent as More } from 'assets/icons/more.svg';
import { Spinner, Image } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { getProjectById } from 'helpers/api/projectManagement';
import { ProjectInformationContainer } from 'pages/projectmanagement/project.styled';
import Location from './Location';
import DownloadData from './DownloadData';
import ProjectEvents from './ProjectEvents';

export default function ProjectInformation() {
	const { id } = useParams();

	const { data, isFetching } = useQuery(
		'ProjectData',
		() => getProjectById(id),
		{
			refetchIntervalInBackground: false,
			refetchOnWindowFocus: false,
		},
	);
	if (isFetching) {
		return (
			<ProjectInformationContainer>
				<div
					className="mb-3 flex items-center"
					style={{
						cursor: 'pointer',
						maxWidth: 'fit-content',
					}}
				>
					<Link
						to="/projects"
						className=" flex items-center"
						style={{
							textDecoration: 'none',
							color: 'black',
							paddingRight: '1.25rem',
							borderRight: '1px solid #dddddd',
						}}
					>
						<Right />
						<span className="ms-2">Back</span>
					</Link>
					<Link
						to="/projects"
						style={{
							textDecoration: 'none',
							color: 'black',
							paddingLeft: '0.75rem',
						}}
					>
						<span className="ms-2" style={{ color: '#00834D' }}>
							Projects
						</span>
						<span> {'>'} Project Details</span>
					</Link>
				</div>

				<div className="information mt-4 p-3">
					<Spinner size="sm" animation="border" className="m-auto my-3" />
				</div>
			</ProjectInformationContainer>
		);
	}
	return (
		<ProjectInformationContainer>
			<div
				className="mb-3 flex items-center"
				style={{
					cursor: 'pointer',
					maxWidth: 'fit-content',
				}}
			>
				<Link
					to="/projects"
					className="flex items-center"
					style={{
						textDecoration: 'none',
						color: 'black',
						paddingRight: '1.25rem',
						borderRight: '1px solid #dddddd',
					}}
				>
					<Right />
					<span className="ms-2">Back</span>
				</Link>
				<Link
					to="/projects"
					style={{
						textDecoration: 'none',
						color: 'black',
						paddingLeft: '0.75rem',
					}}
				>
					<span className="ms-2" style={{ color: '#00834D' }}>
						Projects
					</span>
					<span> {'>'} Project Details</span>
				</Link>
			</div>

			<div className="information mt-4 p-3">
				<div>
					{data?.data?.projectById?.logo !== 'companyLogoBlank.png' ? (
						<Image
							src={`https://development.rigseye.com/company_logos/${data?.data?.projectById?.logo}`}
							alt="logo"
							title="logo"
							className="default-logo"
						/>
					) : (
						<div className="default-logo">LOGO</div>
					)}
				</div>

				<div className="align-self-center">
					<div className="information-row ms-3">
						<div>
							<span className="tags">Project</span>
							<span className="project-name">
								{data?.data?.projectById?.customer.custom_id || '-'}
							</span>
						</div>
						<div>
							<span className="tags">Customer</span>
							<span className="values">
								{data?.data?.projectById?.customer.cname || '-'}
							</span>
						</div>
						<div>
							<span className="tags">Pad</span>
							<span className="values">
								{data?.data?.projectById?.customer.ppadname || '-'}
							</span>
						</div>
						<div>
							<span className="tags">Start date</span>
							<span className="values">
								{data?.data?.projectById?.customer.pbegdate || '-'}
							</span>
						</div>
						<div>
							<span className="tags">Well ID</span>
							<span className="values mb-0">
								{data?.data?.projectById?.customer.pwellnumber || '-'}
							</span>
						</div>

						<Location />
						<DownloadData project={data} />
						<ProjectEvents />
					</div>
				</div>
			</div>
		</ProjectInformationContainer>
	);
}
