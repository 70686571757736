import { RigDetailContextProvider } from 'helpers/contexts/RigDetailProvider';
import DocSys from 'pages/details/rig/DocSysTab';

const DocSysTabView = () => (
	<RigDetailContextProvider>
		<DocSys />
	</RigDetailContextProvider>
);

export default DocSysTabView;
