import { getProjectData } from 'helpers/api/projectManagement';
import { projectChartsLabels } from 'helpers/const/chartLabels';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ChartContainer } from 'pages/details/styled';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const TabsWrapper = styled.div`
	.nav-item {
		button {
			margin-left: 0;
			margin-right: 1rem;
			padding-bottom: 0.75rem;
			font-weight: 500;
		}
		.active {
			border-bottom: 2px solid #0035bf;
		}
	}
`;

function PumpChart() {
	const { id } = useParams();
	const [pumpKey, setPumpKey] = useState('pump_flow');
	const [initialChartData, setInitialChartData] = useState([]);
	const mountedRef = useRef(false);
	const flowMin = 0;
	const flowMax = 15;
	const psiMin = 0;
	const psiMax = 5000;
	const ticks = 6;

	const getData = async () => {
		const res = await getProjectData(id);
		return res?.data.chartsDataByProjectId;
	};

	useEffect(() => {
		mountedRef.current = true;
		// eslint-disable-next-line no-return-assign
		return () => (mountedRef.current = false);
	}, []);

	useEffect(() => {
		// Checking if the component is mounted and setting the initial data
		if (mountedRef.current) {
			getProjectData(id).then((res) => {
				setInitialChartData(res?.data?.chartsDataByProjectId[1]?.pump || []);
			});
		}
	}, [mountedRef.current]);

	const mappedData = React.useMemo(
		() =>
			initialChartData.map((pumpInfo) => ({
				marker: {
					enabled: false,
				},
				name: pumpInfo.serial,
				data: (() => {
					// generate an array of random data
					const data = [];
					const time = new Date().getTime();
					let i;

					for (i = -39; i <= 0; i += 1) {
						data.push({
							x: time + i * 1000,
							y: pumpInfo?.charts?.pump?.[0][pumpKey],
						});
					}
					return data;
				})(),
			})),
		[initialChartData],
	);

	const options = React.useMemo(
		() => ({
			chart: {
				type: 'spline',
				animation: Highcharts.svg, // don't animate in old IE
				marginRight: 10,
			},

			time: {
				useUTC: false,
			},

			title: false,
			credits: {
				enabled: false,
			},

			accessibility: {
				announceNewData: {
					enabled: true,
					minAnnounceInterval: 15000,
					announcementFormatter(newPoint) {
						if (newPoint) {
							return `New point added. Value: ${newPoint.y}`;
						}
						return false;
					},
				},
			},

			xAxis: {
				type: 'datetime',
				tickPixelInterval: 150,
			},

			// yAxis: [
			// 	{
			// 		min: psiMin,
			// 		max: psiMax,
			// 		tickAmount: ticks,
			// 		title: {
			// 			text: projectChartsLabels.pump_psi,
			// 		},
			// 		plotLines: [
			// 			{
			// 				value: 0,
			// 				width: 1,
			// 				color: '#808080',
			// 			},
			// 		],
			// 	},
			// 	{
			// 		min: flowMin,
			// 		max: flowMax,
			// 		tickAmount: ticks,
			// 		title: {
			// 			text: projectChartsLabels.pump_flow,
			// 		},
			// 		plotLines: [
			// 			{
			// 				value: 0,
			// 				width: 1,
			// 				color: '#808080',
			// 			},
			// 		],
			// 	},
			// ],

			yAxis: {
				min: pumpKey === 'pump_flow' ? flowMin : psiMin,
				max: pumpKey === 'pump_flow' ? flowMax : psiMax,
				tickAmount: ticks,
				title: {
					text: projectChartsLabels[pumpKey],
				},
				plotLines: [
					{
						value: 0,
						width: 1,
						color: '#808080',
					},
				],
			},

			tooltip: {
				headerFormat: '<b>{series.name}</b><br/>',
				pointFormat: '{point.x:%Y-%m-%d %H:%M:%S}<br/>{point.y:.2f}',
			},

			legend: {
				enabled: false,
			},

			exporting: {
				enabled: false,
			},

			series: [...mappedData],
		}),
		[mappedData, pumpKey],
	);

	const chartComponentRef = React.useRef(null);

	useEffect(() => {
		const { chart } = chartComponentRef.current;
		// const series = chart.series[0];

		const updateInterval = setInterval(() => {
			getData().then((data) => {
				const pumpChartData = data[1]?.pump || [];
				const x = new Date().getTime(); // current time

				try {
					if (mountedRef.current) {
						// headerDataRef.current.innerHTML = y;
						// headerTimeRef.current.innerHTML = moment(x).format('HH:mm:ss');
						// series.addPoint([x, y], true, true);
						pumpChartData.forEach((pumpInfo) => {
							const series = chart.series.find(
								(s) => s.name === pumpInfo.serial,
							);
							if (series) {
								const object = pumpInfo?.charts?.pump?.[0];
								series.addPoint([x, object[pumpKey]], true, true);
							}
						});
					}
				} catch {
					clearInterval(updateInterval);
				}

				// setData(res?.[0]?.[subchart]);
			});
		}, 2000);

		return () => clearInterval(updateInterval);
	}, []);

	// Waiting for initial data of charts
	if (!initialChartData) {
		return <Spinner size="md" animation="border" />;
	}

	return (
		<div>
			<ChartContainer>
				<Row className="header">
					<Col className="align-self-center">
						<h1 className="align-self-center">
							{projectChartsLabels.pump_combo}
						</h1>
					</Col>
					<Col className="change-container">
						{/* <button
								type="button"
								onClick={() => handleDailyRPM('drawworks')}
								className="change-span"
							>
								{dailyRPM ? 'Live chart' : '24h chart'}
							</button> */}
						{/* <span>
								<DatePicker
									selected={selectedDate}
									onChange={(date) => chartselecteddate(date, 'suction')}
								/>
							</span> */}
					</Col>
				</Row>

				{/* <div className="details">
                        <div>
                            <span className="tag">Suction: </span>{' '}
                            <span>{livedata?.data?.charts?.suction?.[59]?.pump_suction}</span>
                        </div>
                    </div> */}

				{/* {dailyRPM && (
						<DailyChartGeneral
							data={data?.charts?.suction}
							subitem={pumpChartLabels.pump_suction}
						/>
					)} */}
				<TabsWrapper className="mb-5">
					<Tabs
						id="controlled-tab-example"
						activeKey={pumpKey}
						onSelect={(k) => setPumpKey(k)}
					>
						<Tab eventKey="pump_flow" title={projectChartsLabels.pump_flow} />
						<Tab eventKey="pump_psi" title={projectChartsLabels.pump_psi} />
					</Tabs>
				</TabsWrapper>

				<HighchartsReact
					ref={chartComponentRef}
					highcharts={Highcharts}
					options={options}
				/>
			</ChartContainer>
		</div>
	);
}

export default PumpChart;
