import ActivityPump from 'pages/details/activityCharts/ActivityPump';
import ActivityStrokes from 'pages/details/activityCharts/ActivityStrokes';
import ActivitySuction from 'pages/details/activityCharts/ActivitySuction';
import { Row } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';

export default function ChartActivity() {
	return (
		<Row>
			<ActivitySuction />
			<ActivityStrokes />
			<ActivityPump />
		</Row>
	);
}
