/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Row, Nav } from 'react-bootstrap';
import { Rnd } from 'react-rnd';
import styled from 'styled-components';
import lodashGet from 'lodash.get';
import { CVContainer, CloseButton } from './styles';

const NavWrapper = styled(Row)`
	.cam-nav-view {
		width: 95%;
		margin: 1.3rem;
		margin-bottom: 0 !important;
		padding-bottom: 0 !important;
		.nav-item {
			padding-left: 0.5rem;
			padding-right: 0.5rem;
			margin-right: 0.25rem;
		}
		.nav-item.active {
			border-bottom: 2px solid ${({ theme }) => theme.colors.borderHightLight};
			padding-bottom: 0.75rem;
		}
	}
`;

function CamView(props) {
	const { data, action } = props;
	const [currentCameraIndex, setCurrentCameraIndex] = useState(0);

	const handleCameraSelect = (index) => {
		setCurrentCameraIndex(index);
	};

	const cameraList = !data?.data?.error ? data?.data?.data?.cameraData : [];

	return (
		<Rnd
			default={{
				x: 150,
				y: 205,
			}}
			style={{
				zIndex: 999,
				width: 'auto',
				height: 'auto',
				boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.12)',
				borderRadius: '12px',
				contain: 'paint',
			}}
			bounds="window"
		>
			<CVContainer className="text-end">
				<CloseButton onClick={action}>
					<label>X</label>
				</CloseButton>
				{data && data?.data && !data?.data?.error && (
					<NavWrapper>
						<Nav className="cam-nav-view">
							{cameraList.map((item, index) => (
								<Nav.Item
									role="button"
									key={item.camera_id}
									className={`nav-item ${
										currentCameraIndex === index ? 'active' : ''
									}`}
									onClick={() => handleCameraSelect(index)}
								>
									Camera {index + 1}
								</Nav.Item>
							))}
						</Nav>
					</NavWrapper>
				)}

				<Row className="camera-feed-container">
					{data && data?.data && !data?.data?.error ? (
						<div
							key={`Camera-feed-${currentCameraIndex}`}
							className="camera-feed mx-3 mb-3"
						>
							<img
								src={cameraList?.[currentCameraIndex]?.url}
								alt=""
								title="camera-feed"
							/>
						</div>
					) : null}
				</Row>
			</CVContainer>
		</Rnd>
	);
}

export default CamView;
