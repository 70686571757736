import React from 'react';

function Fahrenheit() {
	return (
		<span
			style={{ marginLeft: 3, fontSize: 16, fontWeight: 700, color: '#000' }}
		>
			&#8457;
		</span>
	);
}

export default Fahrenheit;
