/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable */

import { ReactComponent as Pump } from 'assets/icons/pump.svg';
import { ReactComponent as Rig } from 'assets/icons/rig.svg';
import { ReactComponent as Swivel } from 'assets/icons/swivel.svg';
import { MapPopover, MapPopoverContainer } from 'pages/dashboard/styled';
import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const MyMarker = (props, { text, tooltip }) => {
	const [show, setShow] = React.useState();
	const toggle = () => setShow((prev) => !prev);
	const handleClick = () => {
		toggle();
	};
	return (
		<MapPopoverContainer>
			<OverlayTrigger
				trigger="click"
				placement="top"
				rootClose
				overlay={
					<Popover
						id={`popover-positioned-abc`}
						style={{
							border: 'none',
							filter: 'drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.14))',
						}}
					>
						<MapPopover>
							<h3>{props?.item?.type?.toUpperCase()}</h3>
							<h4>
								{props?.type?.charAt(0)?.toUpperCase() + props?.type?.slice(1)}:{' '}
								<Link
									to={`/rigs/${props.item?.serial}`}
									className="text-decoration-none"
								>
									<span className="equipment-id">{props.item?.serial}</span>
								</Link>
							</h4>
							<h4>
								Status: <span>{props.item?.status?.value}</span>
							</h4>
							<h4>
								Temp:{' '}
								<span>
									{props.item?.weather?.temp?.value}°
									{props.item?.weather?.temp?.unit}
								</span>
							</h4>
							<h4>
								Wind:{' '}
								<span>
									{props.item?.weather?.wind?.speed +
										props.item?.weather?.wind?.unit}
								</span>
							</h4>

							<h4>
								Long:{' '}
								<span>
									{parseFloat(
										props.item?.gps?.londd || props.item?.[9]?.gps?.londd,
									).toFixed(5)}
								</span>
							</h4>
							<h4>
								Lat:{' '}
								<span>{parseFloat(props.item?.gps?.latdd).toFixed(5)}</span>
							</h4>
						</MapPopover>
					</Popover>
				}
			>
				{props.item.type === 'rig' ? (
					<Rig onClick={handleClick} className="marker-icon">
						<span className="circleText" title={tooltip}>
							{text}
						</span>
					</Rig>
				) : props.item.type === 'pump' ? (
					<Pump onClick={handleClick} className="marker-icon">
						<span className="circleText" title={tooltip}>
							{text}
						</span>
					</Pump>
				) : (
					<Swivel onClick={handleClick} className="marker-icon">
						<span className="circleText" title={tooltip}>
							{text}
						</span>
					</Swivel>
				)}
			</OverlayTrigger>
		</MapPopoverContainer>
	);
};

export default MyMarker;

export const BasicMarker = ({ type = '', ...rest }) => {
	const markerStyle = {
		position: 'absolute',
		transform: 'translate(-50%, -50%)',
		zIndex: 1000,
	};

	return (
		<MapPopoverContainer style={markerStyle}>
			{type === 'rig' ? (
				<Rig className="marker-icon" />
			) : type === 'pump' ? (
				<Pump className="marker-icon" />
			) : (
				<Swivel className="marker-icon" />
			)}
		</MapPopoverContainer>
	);
};
