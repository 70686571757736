import { auth } from 'helpers/api';
import { useState } from 'react';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Input, LoginButton, LoginFormContainer } from './login.styled';

function ResetPassword() {
	const [isLoading, setIsLoading] = useState(false);
	const [userid, setUseId] = useState('');
	const [error, setError] = useState();
	const handleSubmit = () => {
		if (!userid) {
			toast.error('Please enter user id/email.');
			return;
		}
		setIsLoading(true);

		try {
			auth
				.resetPassword({ username: userid })
				.then((res) => {
					if (res.errors) {
						toast.error(`Error. ${res.errors[0].message}`);
						return;
					}
					if (res.data?.resetPassword === true)
						toast.success(
							'Success. Please check your email for a link to reset your password.',
						);
					else toast.error('Unable to send reset password email.');
				})
				.catch((err) => {
					toast.error(err?.message || 'Something went wrong.');
				});
			setTimeout(() => setIsLoading(false), 2000);
		} catch (err) {
			toast.error(err?.message || 'Something went wrong.');
		}
	};
	const handleChange = (e) => {
		setUseId(e.target.value);
		setError();
		if (e.target.value.length === 0) {
			setError('Please enter you user id/email.');
		} else if (e.target.value.length < 3 || e.target.value.length > 40) {
			setError('Please check your user id/email.');
		}
	};
	return (
		<div>
			<LoginFormContainer onSubmit={(e) => e.preventDefault()}>
				<Row>
					<Col md={12}>
						<Form.Group className="mb-3">
							<Input
								type="text"
								placeholder="User ID/Email"
								onChange={handleChange}
							/>
							<small style={{ color: 'red' }}>{error}</small>
							{/* <ErrorMessage>{errors.username?.message}</ErrorMessage> */}
						</Form.Group>
					</Col>
				</Row>
				<div className="text-center">
					<Col md={12}>
						<LoginButton
							className="btn btn-block btn-darken mb-3"
							// type="submit"
							disabled={isLoading || !userid || error}
							onClick={handleSubmit}
						>
							Send Code{' '}
							<Spinner animation="border" size="sm" hidden={!isLoading} />
						</LoginButton>
					</Col>
				</div>
				<Link
					to="/"
					className="cursor-pointer text-skin-primary flex gap-2 items-center"
				>
					<FaArrowLeft />
					<span> Go back to login.</span>
				</Link>
			</LoginFormContainer>
		</div>
	);
}

export default ResetPassword;
