import { useAuth } from 'helpers/contexts/AuthContext';
import AddEquipment from 'pages/add-equipment/AddEquipment';
import AddPump from 'pages/add-equipment/pump';
import AddRig from 'pages/add-equipment/rig';
import CreateProject from 'pages/create-project/CreateProject';
import EditProject from 'pages/edit-project/EditProject';
import EquipmentProfiles from 'pages/equipment/EquipmentProfiles';
import ProjectDetail from 'pages/project-details';
import ProjectLogs from 'pages/project-logs/index';
import SingleProjectLogs from 'pages/project-logs/indexSingle';
import ProjectManagement from 'pages/projectmanagement/ProjectList';
import UserManagement from 'pages/usermanagement';
import { Navigate, useLocation } from 'react-router-dom';
import Settings from '../pages/settings/Settings';

const AdminRoutes = [
	// User management
	{
		id: 'user-management',
		path: '/users',
		component: UserManagement,
		exact: true,
		private: true,
	},
	// Equipment management
	{
		id: 'equipment-management',
		path: '/equipments',
		component: EquipmentProfiles,
		exact: true,
		private: true,
	},
	{
		id: 'add-equipment',
		path: '/add-equipment',
		component: AddEquipment,
		exact: true,
		private: true,
	},
	{
		id: 'edit-equipment',
		path: '/edit-equipment/:id',
		component: AddEquipment,
		exact: true,
		private: true,
	},
	{
		id: 'add-rig',
		path: '/add-rig',
		component: AddRig,
		exact: true,
		private: true,
	},
	{
		id: 'edit-rig',
		path: '/edit-rig/:id',
		component: AddRig,
		exact: true,
		private: true,
		edit: true,
	},
	{
		id: 'add-pump',
		path: '/add-pump',
		component: AddPump,
		exact: true,
		private: true,
	},
	{
		id: 'edit-pump',
		path: '/edit-pump/:id',
		component: AddPump,
		exact: true,
		private: true,
		edit: true,
	},
	// Project management
	{
		id: 'project-management',
		path: '/projects',
		component: ProjectManagement,
		exact: true,
		private: true,
	},
	{
		id: 'project-details',
		path: '/projects/:id',
		component: ProjectDetail,
		exact: true,
		private: true,
	},
	{
		id: 'project-logs',
		path: '/projects/logs',
		component: ProjectLogs,
		exact: true,
		private: true,
	},
	{
		id: 'project-logs-single',
		path: '/projects/logs/:id',
		component: SingleProjectLogs,
		exact: true,
		private: true,
	},
	{
		id: 'create-project',
		path: '/create-project',
		component: CreateProject,
		exact: true,
		private: true,
	},
	{
		id: 'edit-project',
		path: '/edit-project/:id',
		component: EditProject,
		exact: true,
		private: true,
	},
	{
		id: 'settings',
		path: '/settings',
		component: Settings,
		exact: true,
		private: true,
	},
];

function ProtectedAdminRoute({ children }) {
	const { user } = useAuth();

	const location = useLocation();
	if (
		user.roles.isAdminUser ||
		user.roles.isManagerUser ||
		user.roles.isDeveloper ||
		user.roles.isRigseyeUser
	) {
		return children;
	}
	return <Navigate to="/dashboard" state={{ from: location }} />;
}

export { ProtectedAdminRoute };
export default AdminRoutes;
