import React from 'react';
import { ReactComponent as SearchIc } from 'assets/icons/Search.svg';

export default function SearchInput({
	onChange,
	placeholder = 'Search',
}: {
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	placeholder?: string;
}) {
	return (
		<div className="relative h-fit min-w-[220px]">
			<SearchIc className="absolute h-[100%] ml-2" />
			<input
				style={{
					paddingLeft: '2rem',
				}}
				className="py-2 pr-2 pl-10 w-full rounded-md text-[0.8125rem] font-[500]"
				type="text"
				placeholder={placeholder}
				aria-label="Search"
				onChange={onChange}
			/>
		</div>
	);
}
SearchInput.defaultProps = {
	placeholder: 'Search',
};
