import AuthLayout from 'components/Layouts/AuthLayout';
import { LoginHeader } from './login.styled';
import ResetPassword from './ResetPassword';

// eslint-disable-next-line func-names
export default function () {
	return (
		// eslint-disable-next-line react/jsx-filename-extension
		<AuthLayout>
			<LoginHeader>Reset Password</LoginHeader>
			<ResetPassword />
		</AuthLayout>
	);
}
