import { Col } from 'react-bootstrap';

const DetailSkeletonLoading = () => (
	<div className="flex gap-2  flex-wrap animate-pulse h-96 relative">
		{/* POSI-STOP */}
		<Col className="bg-gray-100 py-4 p-2 d-flex flex-col gap-4 border rounded-md ">
			<div className="flex items-center gap-4">
				<div className="w-28 h-20 bg-gray-200 clip-polygon" />
				<div className="w-full">
					<div className="h-8 bg-gray-200 rounded-md w-full mb-2" />
					<div className="h-6 w-32 bg-gray-200 rounded-md mb-4" />
				</div>
			</div>

			<div className="flex gap-4 mt-6">
				<div className="bg-gray-200 w-16 h-60 mx-2" />
				<div className="flex flex-col gap-4 items-center mx-6 ">
					<div className="h-16 w-24 bg-gray-200 mb-2 " />
					<div className="h-36 w-36 rounded-full bg-gray-200 mb-2" />
				</div>
			</div>

			<div className="flex gap-4 mt-6 w-full justify-around">
				<div className="w-16 h-16 rounded-full bg-gray-200" />
				<div className="w-16 h-16 rounded-full bg-gray-200" />
				<div className="w-16 h-16 rounded-full bg-gray-200" />
			</div>
		</Col>
		{/* GUAGE & MAP */}
		<Col className="d-flex flex-col gap-4 w-full">
			<div className="h-64 bg-gray-100 flex gap-4 justify-around p-4 items-center">
				<div className="w-36 h-fit aspect-square rounded-full bg-gray-200" />
				<div className="h-44 w-44 min-w-32 min-h-36 rounded-lg bg-gray-200" />
				<div className="w-36 h-fit aspect-square rounded-full bg-gray-200" />
			</div>
			<div className="h-64 w-full rounded-lg bg-gray-100" />
		</Col>
		{/* MESSAGES & EVENTS */}
		<Col className="d-flex flex-col gap-4">
			<div className="h-64 flex-1 space-y-4 items-center bg-gray-100 p-6">
				{[1, 2, 3, 4].map((item) => (
					<div
						key={item}
						className="w-full flex justify-between items-end gap-4"
					>
						<div className="w-full h-8 rounded bg-gray-200" />
						<div className="w-full h-6 rounded bg-gray-200" />
					</div>
				))}
			</div>
			<div className="h-64 flex-1 space-y-4 items-center bg-gray-100 p-6">
				{[1, 2, 3, 4].map((item) => (
					<div
						key={item}
						className="w-full flex justify-between items-end gap-4"
					>
						<div className="w-full h-8 rounded bg-gray-200" />
						<div className="w-full h-6 rounded bg-gray-200" />
					</div>
				))}
			</div>
		</Col>
	</div>
);

export default DetailSkeletonLoading;
