import { RigDetailContextProvider } from 'helpers/contexts/RigDetailProvider';
import RigActivity from './ActivityTab';

export default function RigActivityTabView() {
	return (
		<RigDetailContextProvider>
			<RigActivity />
		</RigDetailContextProvider>
	);
}
