import Button from 'components/Button';
import DashboardLayout from 'components/Layouts/DashboardLayout';
import Tables from 'components/Tables';
import { deleteTong, getTongs } from 'helpers/api/tongs';
import useResponsive from 'helpers/hooks/useResponsive';
import capitalized from 'helpers/utils/capitalize';
import { useState } from 'react';
import { CloseButton, Modal, Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import TongPopup from './TongPopup';

const Wrapper = styled.div`
	/* margin: ${(props) =>
		props.isTablet || props.isMobile ? '0 1rem' : '2rem 2rem 2rem 18rem;'}; */

	h1 {
		font-weight: 700;
		font-family: 'Poppins', sans-serif;
		font-size: 1.75rem;
		margin-top: 0.5rem;
	}

	.search-icon {
		margin-bottom: -0.5rem;
		margin-left: 1rem;
		margin-top: 0.75rem;
		z-index: 99;
	}

	.search-input {
		width: 260px;
		border-radius: 8px;
		background: ${({ theme }) => theme.colors.backgroundLightGray};
		height: 44px;
		font-size: 0.813rem;
		font-weight: 500;
		padding-left: 2.5rem;
		margin-top: -1.5rem;
	}
	.disabled-green {
		color: ${({ theme }) => theme.colors.loginPrimary};
		opacity: 0.65;
	}

	@media (max-width: 450px) {
		.search-input {
			width: 100%;
			margin-bottom: 1.5rem;
		}

		.btn-unstyled-red {
			width: max-content;
			padding: 0rem !important;
			margin: 0rem !important;
		}
	}
`;
const DataWrapper = styled.div`
	background: #ffffff;
	box-shadow: 0px 9px 36px rgba(0, 0, 0, 0.08);
	border-radius: 12px;
	padding: 1rem;
`;
const SelectionModal = styled(Modal)`
	.modal-content {
		padding: 1.5rem;
		border-radius: 20px;
	}

	.close {
		position: absolute;
		top: -2rem;
		right: 0rem;
	}
`;

function Settings() {
	const { isMobile, isTablet } = useResponsive();
	const [showDelete, setShowDelete] = useState(false);
	const [showTongModal, setShowTongModal] = useState(false);
	const [selectedTong, setSelectedTong] = useState(null);
	const [selectedDeleteTong, setSelectedDeleteTong] = useState(null);
	const [loading, setLoading] = useState(false);

	const { data, isLoading, refetch } = useQuery('tongs', getTongs, {
		refetchIntervalInBackground: false,
		refetchOnWindowFocus: false,
	});

	const handleDeletion = (selected) => () => {
		setSelectedDeleteTong(selected);
		setShowDelete(true);
	};

	const handleDelete = async () => {
		try {
			if (selectedDeleteTong?.tongid) {
				setLoading(true);
				const result = await deleteTong(selectedDeleteTong?.tongid);
				if (result?.data?.deleteTong?.success) {
					toast.success('Tong deleted successfully.');
					setShowDelete(false);
					setSelectedDeleteTong(null);
					refetch();
				} else {
					toast.error('Something went wrong.');
				}
				setLoading(false);
			} else {
				toast.error('Please select tong first.');
			}
		} catch (error) {
			toast.error(error.message);
		}
	};

	const handleAddTong = () => {
		setShowTongModal(true);
	};

	const toggleTongModal = (open = false) => {
		setSelectedTong(null);
		setShowTongModal(open);
	};

	const handleEditTong = (row) => {
		// open modal
		setSelectedTong(row);
		setShowTongModal(true);
	};

	const columns = [
		{
			name: 'Name',
			selector: (row) => capitalized(row.tongname),
			sortable: true,
			width: '10%',
		},
		{
			name: 'Type',
			selector: (row) => capitalized(row.tongtype === 'R' ? 'Rod' : 'Tong'),
			sortable: true,
			width: '10%',
		},
		{
			name: 'Air Pressure Max',
			selector: (row) => row.air_pressure_max,
			sortable: true,
			width: '20%',
		},
		{
			name: 'Torque Max 1',
			selector: (row) => row.torque_max_1,
			sortable: true,
			width: '20%',
		},
		{
			name: 'Torque Max 2',
			selector: (row) => row.torque_max_2,
			sortable: true,
			width: '20%',
		},
		{
			name: 'Actions',
			cell: (row) => (
				<div className="flex flex-wrap gap-2">
					<Button type="button" onClick={() => handleEditTong(row)}>
						<FaRegEdit />
					</Button>

					<Button
						type="button"
						variant="delete-secondary"
						className="p-0"
						onClick={handleDeletion(row)}
					>
						<FaRegTrashAlt />
					</Button>
				</div>
			),
		},
	];

	return (
		<DashboardLayout>
			<TongPopup
				data={selectedTong}
				open={showTongModal}
				onClose={toggleTongModal}
				refetch={refetch}
			/>
			<SelectionModal
				show={showDelete}
				onHide={() => setShowDelete(false)}
				centered
			>
				<CloseButton
					className="close"
					variant="white"
					onClick={() => setShowDelete(false)}
				/>
				<Modal.Body className="text-center p-5">
					<h5 className="fw-bold">Delete Equipment</h5>
					<h6 className="fw-normal">
						Are you sure you want to delete{' '}
						<span className="fw-bold">{selectedTong?.tongname}</span>
					</h6>
					<div className="d-flex mt-4 justify-content-center">
						<Button variant="secondary" onClick={() => setShowDelete(false)}>
							Close
						</Button>
						<Button variant="primary" className="mx-2" onClick={handleDelete}>
							{loading ? (
								<Spinner size="sm" animation="border" />
							) : (
								'Yes, delete it!'
							)}
						</Button>
					</div>
				</Modal.Body>
			</SelectionModal>
			<Wrapper isMobile={isMobile} isTablet={isTablet}>
				<h1 className="mb-3">Settings</h1>
				<DataWrapper>
					<div className="flex flex-wrap-reverse mb-3">
						<div className="ml-auto">
							<Button variant="primary" type="button" onClick={handleAddTong}>
								Add Tong
							</Button>
						</div>
					</div>
					<Tables
						data={data?.data?.tongs || []}
						columns={columns}
						progressPending={isLoading}
						progressComponent={
							<Spinner animation="border" variant="primary" size="md" />
						}
						pagination
					/>
				</DataWrapper>
			</Wrapper>
		</DashboardLayout>
	);
}

export default Settings;
