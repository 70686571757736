import { ReactComponent as HelpIcon } from 'assets/icons/help.svg';
import { ReactComponent as Right } from 'assets/icons/right-arrow.svg';
import SearchInput from 'components/SearchInput';
import TooltipHandler from 'components/TooltipHandler';
import { useDashboard } from 'pages/dashboard/DashboardContext';
import { TableContainer } from 'pages/dashboard/tables.styled';
import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import useCameraLayout from '../../helpers/hooks/useCameraLayout';
import {
	DataContainer,
	FilterContainer,
	HeaderWrapper,
	SelectionModal,
} from './styles/EquipmentsModal.styled';

export default function EquipmentsModal({
	show,
	setModalShow,
	activeEqs,
	setIsFetchinOnUpdate,
	refetch,
}: {
	show: boolean;
	setModalShow: React.Dispatch<React.SetStateAction<boolean>>;
	activeEqs: any[];
	setIsFetchinOnUpdate: React.Dispatch<React.SetStateAction<boolean>>;
	refetch: () => void;
}) {
	const { id } = useParams();

	// @ts-ignore
	const { equipments, isLoading, timezones } = useDashboard();
	const [eqs, setEqs] = useState<string[]>([]);
	const [filtered, setFiltered] = useState([]);
	const [stopEquipmentRefetch, setStopEquipmentRefetch] = useState(false);
	const [, setSearch] = useState('');
	const [uniqueYards, setUniqueYards] = useState<string[]>([]);
	const [selectedRegion, setSelectedRegion] = useState('');
	const { cameraLayouts, updateCameraLayout } = useCameraLayout();

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setSearch(value);
		if (value === '') {
			setFiltered(equipments);
		} else {
			const filteredItems = equipments.filter(
				(item: any) =>
					item?.equip_data?.serial
						?.toLowerCase()
						.includes(value.toLowerCase()) ||
					item?.equip_data?.region?.name
						?.toLowerCase()
						.includes(value.toLowerCase()) ||
					item?.equip_data?.region?.id
						?.toLowerCase()
						.includes(value.toLowerCase()),
			);
			setFiltered(filteredItems);
		}
	};
	const handleTimezone = (itemId: string) => () => {
		setSearch('');
		if (itemId === '') {
			setFiltered(equipments);
			setSelectedRegion('All');
		} else {
			const filteredItems = equipments.filter(
				(item: {
					equip_data: { region: { id: string }; serial: string | string[] };
				}) =>
					item?.equip_data?.region?.id?.toLowerCase() === itemId.toLowerCase(),
			);
			setFiltered(filteredItems);
			setSelectedRegion(itemId);
		}
	};

	const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
		const item = e.target.id.toUpperCase();
		if (eqs.includes(item)) {
			setEqs((prev) => prev.filter((eq) => eq !== item));
		} else {
			setEqs((prev) => [...prev, item]);
		}
	};
	const handleSubmit = async () => {
		if (id) {
			const current: any = cameraLayouts.find(
				(camLayout: any) => parseInt(camLayout.id, 10) === parseInt(id, 10),
			);
			setModalShow(false);
			await updateCameraLayout({
				id: parseInt(id, 10),
				viewName: current ? current?.view_name : '',
				selection: eqs,
			});
			setIsFetchinOnUpdate(true);
			await refetch();
			setIsFetchinOnUpdate(false);
		}
	};
	useEffect(() => {
		if (activeEqs && activeEqs.length > 0) {
			setEqs(activeEqs?.map((item) => item.equipment));
		}
	}, [activeEqs]);

	useEffect(() => {
		if (equipments && !stopEquipmentRefetch) {
			setFiltered(equipments);
			setStopEquipmentRefetch(true);
		}
	}, [equipments]);
	useEffect(() => {
		const unique2 = [
			...new Set(
				filtered?.map(
					(item: { equip_data: { region: { yard: { name: any } } } }) =>
						item?.equip_data.region?.yard?.name,
				),
			),
		];
		setUniqueYards(unique2);
	}, [filtered]);

	if (isLoading) {
		return (
			<Modal
				size="lg"
				centered
				show={show}
				onHide={() => setModalShow(false)}
				className="profile-modal"
			>
				<Modal.Body>
					<Spinner
						animation="border"
						size="sm"
						className="mx-auto"
						style={{ display: 'block' }}
					/>
				</Modal.Body>
			</Modal>
		);
	}

	return (
		<SelectionModal
			size="xl"
			centered
			show={show}
			onHide={() => {
				setModalShow(false);
				setStopEquipmentRefetch(false);
			}}
			className="profile-modal"
		>
			<Modal.Body>
				<TableContainer className="mt-0">
					<HeaderWrapper className="d-flex">
						<div className="back-container flex items-center">
							<Right />
							<button
								type="button"
								onClick={() => setModalShow(false)}
								style={{ all: 'unset', cursor: 'pointer' }}
							>
								<span className="ms-2">Back</span>
							</button>
						</div>
						<div className="d-flex gap-2 justify-content-between align-items-center">
							<TooltipHandler
								content="Search based on Equipment Serial, Region Name, or Region ID"
								placement="bottom"
							>
								<HelpIcon />
							</TooltipHandler>
							<SearchInput
								placeholder="Search Equipment"
								onChange={handleSearch}
							/>
						</div>
					</HeaderWrapper>

					<h1 className="mb-4 assignment-header ">Cameras</h1>

					<div className="d-flex gap-4">
						<FilterContainer>
							<button
								type="button"
								className="all-button"
								key={Math.random()}
								onClick={handleTimezone('')}
							>
								<span>All</span>
							</button>
							{timezones?.map(
								(zone: { name: string; id: string }, index: string) => (
									<button
										type="button"
										className="timezone-button"
										style={{
											paddingBottom:
												zone.name === timezones[timezones.length - 1].name
													? '0.5rem'
													: '0.25rem',
											borderBottom:
												zone.name === timezones[timezones.length - 1].name
													? 'none'
													: '1px solid #D3D3D3',
											borderLeft:
												zone.name === selectedRegion
													? '3px solid #00834d'
													: 'none',
										}}
										// eslint-disable-next-line react/no-array-index-key
										key={index}
										onClick={handleTimezone(zone.name)}
									>
										<span>{zone.name}</span>
									</button>
								),
							)}
						</FilterContainer>

						<DataContainer>
							{uniqueYards?.map((yard, yardInedx) => (
								// eslint-disable-next-line react/no-array-index-key
								<div className="mb-2 zone-container" key={`${yard}_${yardInedx}`}>
									<div className="zone-title">{yard}</div>
									<div className="d-flex flex-wrap gap-4">
										{filtered
											?.filter(
												(item: {
													equip_data: { region: { yard: { name: any } } };
												}) =>
													item?.equip_data?.region?.yard?.name?.toLowerCase() ===
													yard?.toLowerCase(),
											)
											?.map(
												(
													eqItem: {
														equip_data: {
															serial: string;
															camera: { data: any };
															region: {
																yard: { id: string; name: string };
																name: string;
																id: string;
															};
															yard: { id: string; name: string };
														};
													},
													index,
												) => (
													<>
														{/* eslint-disable-next-line react/no-array-index-key */}
														<div key={`${eqItem?.equip_data?.serial}_${index}`}>
															<label
																htmlFor={eqItem?.equip_data?.serial?.toLowerCase()}
																className='cursor-pointer'
															>
																<input
																	type="checkbox"
																	className="mb-0"
																	id={eqItem?.equip_data?.serial?.toLowerCase()}
																	defaultChecked={eqs?.includes(
																		eqItem?.equip_data?.serial,
																	)}
																	onChange={handleCheckbox}
																	name={eqItem?.equip_data?.yard?.id}
																/>
																<span className="checkmark" />
																<h1
																	className="mt-1 ml-8"
																	style={{ marginLeft: '2rem' }}
																>
																	{eqItem?.equip_data?.serial}
																</h1>
															</label>
															<div
																style={{
																	justifyContent: 'space-between',
																}}
															>
																{eqItem?.equip_data?.camera?.data &&
																	Object.keys(
																		eqItem.equip_data?.camera?.data,
																	)?.map((key, index2) => (
																		<Col
																			lg={3}
																			// eslint-disable-next-line react/no-array-index-key
																			key={`${key}_${index2}`}
																			className="assignment-item mb-2"
																		>
																			<label htmlFor={key?.toLowerCase()} className='cursor-pointer'>
																				<input
																					type="checkbox"
																					className="mb-0"
																					id={key?.toLowerCase()}
																					defaultChecked={eqs?.includes(key)}
																					name={eqItem?.equip_data?.yard?.id}
																					onChange={handleCheckbox}
																				/>
																				<span className="checkmark" />
																				<span
																					className="equipment-id ms-35"
																					style={{
																						fontSize: '0.875rem',
																					}}
																				>
																					{key}
																				</span>
																			</label>
																		</Col>
																	))}
															</div>
														</div>
													</>
												),
											)}
									</div>
								</div>
							))}

							<Button
								variant="primary-green"
								className="mt-4 float-end"
								onClick={handleSubmit}
							>
								Save
							</Button>
						</DataContainer>
					</div>
				</TableContainer>
			</Modal.Body>
		</SelectionModal>
	);
}
