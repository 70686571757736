import { Row } from 'react-bootstrap';
import { Header } from 'style/addEq.styled';
import CustomInput from '../../../components/CustomInput';

export default function Setup({ register, errors }) {
	return (
		<div className="mb-6">
			<Header>
				<h5>Pump Configuration</h5>
			</Header>
			<Row>
				<CustomInput
					label="Targets Per Rotation"
					name="pump_torque"
					register={register}
					type="number"
					errors={errors}
				/>

				<CustomInput
					label="Displacement Per Rotation (in Gallons)"
					name="pump_flow"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Transducer Discharge Max PSI"
					name="pump_discharge"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Transducer Suction Max PSI"
					name="pump_suction"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Air Pressure Transducer Max PSI"
					name="pump_airp"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Lockup Timer (in Minutes)"
					name="pump_lockup_timer"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Set ZERO Threshold Discharge Pressure"
					name="zero_pump_discharge"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Set ZERO Threshold Suction Pressure"
					name="zero_pump_suction"
					type="number"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="Set ZERO Threshold Air Pressure"
					name="zero_pump_airp"
					register={register}
					type="number"
					errors={errors}
				/>
			</Row>
			<Header>
				<h5>Chart Y-Axis Max Values</h5>
			</Header>
			<Row>
				<CustomInput
					label="Flow Rate"
					name="chart_pump_flowrate"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Strokes per minute"
					name="chart_pump_strokespermin"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Discharge Pressure"
					name="chart_pump_max_psi"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Suction Pressure"
					name="chart_pump_max_suction"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Max BPM"
					name="chart_pump_max_bpm"
					register={register}
					type="number"
					errors={errors}
				/>
				<CustomInput
					label="Max GPM"
					name="chart_pump_max_gpm"
					register={register}
					type="number"
					errors={errors}
				/>
			</Row>
		</div>
	);
}
