import React from 'react';
import { useReactToPrint } from 'react-to-print';
import { CardContainer } from './styled';

function DetailsCard({
	fullHeight,
	title,
	title2,
	body,
	object,
	children,
	travel,
	latitude,
	longitude,
	print,
	noalign,
	threeContainer,
	engine,
	justifyLeft,
	dFlex,
	...rest
}: any) {
	const componentRef = React.useRef(null);

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<CardContainer
			ref={componentRef}
			$fullheight={!!fullHeight}
			$align={noalign}
			$threecontainer={threeContainer}
			$engine={engine}
			{...rest}
		>
			{title && (
				<div className="header">
					<span className="head-title">{title}</span>
					<div>
						{print ? (
							<button className="clickable" type="button" onClick={handlePrint}>
								Print
							</button>
						) : (
							object
						)}
					</div>
				</div>
			)}
			<div
				className={`${dFlex && 'd-flex'} text-center card-content ${
					justifyLeft && 'justify-content-left text-align-left'
				}`}
			>
				{children}
			</div>
		</CardContainer>
	);
}

export default DetailsCard;
