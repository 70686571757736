import useResponsive from 'helpers/hooks/useResponsive';
import {
	Button,
	Col,
	Dropdown,
	DropdownButton,
	Row,
	Tab,
	Tabs,
} from 'react-bootstrap';
import { ReactComponent as Map } from 'assets/icons/map.svg';
import { ReactComponent as List } from 'assets/icons/list.svg';
import { ReactComponent as SearchIc } from 'assets/icons/Search.svg';
import { VIEWS } from 'helpers/const/views';
import React from 'react';
import { EQUIPMENTS, CONNECTIONS } from 'components/DashboardConsts';
import { FilterContainer, HeaderContainer } from './styled';
import { useDashboard } from './DashboardContext';

function DashboadHeader() {
	const { isMobile } = useResponsive();
	const { view, changeView, filterState, setFilterState } = useDashboard();
	const [activeConn, setActiveConn] = React.useState('All');
	const [activeEq, setActiveEq] = React.useState('All');

	const handleTab = (key) => changeView(key);
	const handleSearch = (e) => {
		setFilterState((prev) => ({ ...prev, search: e.target.value }));
	};
	const handleEQ = (equip) => () => {
		setFilterState((prev) => ({ ...prev, type: equip.value }));
		setActiveEq(equip.title);
	};
	const handleConnection = (conn) => () => {
		setFilterState((prev) => ({ ...prev, connection: conn.value }));
		setActiveConn(conn.title);
	};
	return (
		<HeaderContainer>
			<Row className="filters">
				{!isMobile && (
					<>
						{view === VIEWS.LIST && (
							<Col lg={4}>
								<SearchIc className="search-icon" />
								<input
									className="form-control form-control-sm search-input"
									type="text"
									placeholder="Search equipment"
									aria-label="Search"
									onChange={handleSearch}
									value={filterState.search}
								/>
							</Col>
						)}
						{view === VIEWS.MAP && (
							<Col lg={4}>
								<div className="map-search">
									{EQUIPMENTS.map((eq) =>
										eq.title === 'All' ? null : (
											<Button
												variant="transparent"
												key={eq.title}
												onClick={handleEQ(eq)}
											>
												{eq.icon}
												<h2>{eq.title}</h2>
											</Button>
										),
									)}
								</div>
							</Col>
						)}
					</>
				)}
				<FilterContainer md={12} lg={8} className={isMobile ? 'mobile' : ''}>
					{isMobile ? (
						<div className="d-flex">
							<span className="text-capitalize">filter by:</span>
							<DropdownButton
								className="filter-dropdown filter-dropdown-first"
								title={filterState.type || 'All equipment'}
							>
								{EQUIPMENTS.map((eq) => (
									<Dropdown.Item
										className={activeEq === eq.title ? 'active' : ''}
										key={eq.title}
										onClick={handleEQ(eq)}
									>
										<span>{eq.title}</span>
									</Dropdown.Item>
								))}
							</DropdownButton>
							<DropdownButton
								className="filter-dropdown"
								title={filterState.connection || 'Connection'}
							>
								{CONNECTIONS.map((con) => (
									<Dropdown.Item
										className={activeConn === con.title ? 'active' : ''}
										key={con.title}
										onClick={handleConnection(con)}
									>
										<span>{con.title}</span>
									</Dropdown.Item>
								))}
							</DropdownButton>
						</div>
					) : (
						<>
							<span className="text-capitalize">filter by:</span>
							<DropdownButton
								className="filter-dropdown filter-dropdown-first"
								title={filterState.type || 'All equipment'}
							>
								{EQUIPMENTS.map((eq) => (
									<Dropdown.Item
										className={activeEq === eq.title ? 'active' : ''}
										key={eq.title}
										onClick={handleEQ(eq)}
									>
										<span>{eq.title}</span>
									</Dropdown.Item>
								))}
							</DropdownButton>
							<DropdownButton
								className="filter-dropdown"
								title={filterState.connection || 'Connection'}
							>
								{CONNECTIONS.map((con) => (
									<Dropdown.Item
										className={activeConn === con.title ? 'active' : ''}
										key={con.title}
										onClick={handleConnection(con)}
									>
										<span>{con.title}</span>
									</Dropdown.Item>
								))}
							</DropdownButton>
						</>
					)}

					<div className="d-flex">
						{isMobile && view === VIEWS.LIST ? (
							<div className="d-flex align-items-center w-100 flex-wrap mt-2">
								<div className="me-auto w-75">
									<SearchIc className="search-icon" />
									<input
										className="form-control form-control-sm search-input"
										type="text"
										placeholder="Search equipment"
										aria-label="Search"
										onChange={handleSearch}
										value={filterState.search}
									/>
								</div>
								<Tabs
									defaultActiveKey="list"
									className="filter-tabs"
									onSelect={handleTab}
								>
									<Tab.Pane eventKey={VIEWS.LIST} title={<List />} />
									<Tab.Pane eventKey={VIEWS.MAP} title={<Map />} />
								</Tabs>
							</div>
						) : (
							<Tabs
								defaultActiveKey="list"
								className="filter-tabs"
								onSelect={handleTab}
							>
								<Tab.Pane eventKey={VIEWS.LIST} title={<List />} />
								<Tab.Pane eventKey={VIEWS.MAP} title={<Map />} />
							</Tabs>
						)}
						{isMobile && view === VIEWS.MAP ? (
							<div className="map-search h-auto ms-5">
								{EQUIPMENTS.map((eq) =>
									eq.title === 'All' ? null : (
										<Button
											variant="transparent"
											key={eq.title}
											onClick={handleEQ(eq)}
										>
											{eq.icon}
											<h2>{eq.title}</h2>
										</Button>
									),
								)}
							</div>
						) : null}
					</div>
				</FilterContainer>
			</Row>
		</HeaderContainer>
	);
}

export default DashboadHeader;
