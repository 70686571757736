import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Form, Modal, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { addProjectSMS } from 'helpers/api/projectManagement';
import styled from 'styled-components';

const AddButton = styled(Button)`
	border-radius: 0px 12px 12px 0px;
`;

export default function ProjectMessagesBoard() {
	const [modalShow, setModalShow] = useState();
	const { id } = useParams();

	const handleAddMessage = (e) => {
		e.preventDefault();
		toast.promise(
			addProjectSMS({ msg: e.target.message.value, projectid: id }),
			{
				loading: 'Adding message...',
				success: (res) => {
					setModalShow(false);
					if (res.data) {
						return 'Message added';
					}
					return 'Error adding message';
				},
				error: (err) => err.error || 'Error adding message',
			},
		);
	};
	return (
		<>
			<button
				type="button"
				className="green ms-auto"
				onClick={() => setModalShow(true)}
			>
				View messages
			</button>
			<Modal
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={modalShow}
				onHide={() => setModalShow(false)}
				className="profile-modal"
			>
				<Modal.Body>
					<div className="information-container">
						<span className="information">Project message board</span>
					</div>

					<Row className="inputs my-3">
						<div className="no-message">
							<span>No message found</span>
						</div>
					</Row>

					<div className="button-wrapper d-flex message-board">
						<Form className="w-100" onSubmit={handleAddMessage}>
							<div className="d-flex w-100">
								<input id="message" />

								<AddButton
									type="submit"
									className="me-3"
									variant="primary-green"
								>
									Add
								</AddButton>
							</div>
						</Form>

						<Button
							type="button"
							variant="bordered-green"
							onClick={() => setModalShow(false)}
						>
							Close
						</Button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}
