/*eslint-disable */
import React, { useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useParams } from 'react-router-dom';
import { getEquipmentChart } from 'helpers/api/equipments';
import moment from 'moment';
import { rigChartLabels } from 'helpers/const/chartLabels';

const LiveWeight = () => {
	const { id } = useParams();

	const headerTimeRef = useRef();
	const headerWeightRef = useRef();
	const headerHookRef = useRef();
	const headerBitRef = useRef();
	const mountedRef = useRef(false);
	const [InitialChartData, setInitialChartData] = React.useState([]);

	const getData = async () => {
		const res = await getEquipmentChart(
			id,
			Date.now() - 1,
			Date.now(),
			'weight',
		);
		return res?.data?.equipmentChartData.charts?.weight;
	};

	const chartComponentRef = React.useRef(null);

	React.useEffect(() => {
		mountedRef.current = true;
		return () => (mountedRef.current = false);
	}, []);

	React.useEffect(() => {
		const chart = chartComponentRef.current.chart;
		const series = chart.series[0];
		const seriesHook = chart.series[1];
		const seriesBit = chart.series[2];

		const updateInterval = setInterval(() => {
			getData().then((res) => {
				var x = new Date().getTime(), // current time
					y = Math.round(parseFloat(res?.[0]?.weight)),
					yHook = Math.round(parseFloat(res?.[0]?.woh)),
					yBit = Math.round(parseFloat(res?.[0]?.wob));

				try {
					if (mountedRef.current) {
						series.addPoint([x, y], true, true);
						seriesHook.addPoint([x, yHook], true, true);
						seriesBit.addPoint([x, yBit], true, true);
						headerHookRef.current.innerHTML = yHook || 0;
						headerBitRef.current.innerHTML = yBit || 0;
						headerWeightRef.current.innerHTML = y || 0;
						headerTimeRef.current.innerHTML = moment(x).format('HH:mm:ss');
					}
				} catch {
					clearInterval(updateInterval);
				}
			});
		}, 1000);

		return () => clearInterval(updateInterval);
	}, []);

	React.useEffect(() => {
		if (mountedRef.current) {
			getEquipmentChart(id, Date.now() - 1, Date.now(), 'weight').then(
				(res) => {
					setInitialChartData(res?.data?.equipmentChartData?.charts?.weight?.[0]);
				},
			);
		}
	}, []);

	const options = {
		chart: {
			type: 'spline',
			animation: Highcharts.svg, // don't animate in old IE
			marginRight: 10,
		},

		time: {
			useUTC: false,
		},

		title: false,
		credits: {
			enabled: false,
		},

		accessibility: {
			announceNewData: {
				enabled: true,
				minAnnounceInterval: 1000,
				announcementFormatter: function (newPoint) {
					if (newPoint) {
						return 'New point added. Value: ' + newPoint.y;
					}
					return false;
				},
			},
		},

		xAxis: {
			type: 'datetime',
			tickPixelInterval: 150,
			dateTimeLabelFormats: {
				second: '%I:%M:%S',
			},
		},

		yAxis: {
			min: 0,
			max: 350,
			tickAmount: 8,
			title: {
				text: 'Value',
			},
			plotLines: [
				{
					value: 0,
					width: 1,
					color: '#808080',
				},
			],
		},

		tooltip: {
			headerFormat: '<b>{series.name}</b><br/>',
			pointFormat: '{point.x:%Y-%m-%d %H:%M:%S}<br/>{point.y:.0f}',
		},

		legend: {
			enabled: true,
		},

		exporting: {
			enabled: false,
		},

		series: [
			{
				marker: {
					enabled: false,
				},
				name: 'Total Weight',
				data: (function () {
					// generate an array of random data
					var data = [],
						time = new Date().getTime(),
						i;
					for (i = -59; i <= 0; i += 1) {
						data.push({
							x: (InitialChartData?.timestamp || time) + i * 1000,
							y: Math.round(InitialChartData?.weight),
						});
					}
					return data;
				})(),
			},

			{
				marker: {
					enabled: false,
				},
				name: 'Weight on Hook',
				data: (function () {
					// generate an array of random data
					var data = [],
						time = new Date().getTime(),
						i;

					for (i = -59; i <= 0; i += 1) {
						data.push({
							x: (InitialChartData?.timestamp || time) + i * 1000,
							y: Math.round(InitialChartData?.woh),
						});
					}
					return data;
				})(),
			},

			{
				marker: {
					enabled: false,
				},
				name: 'Weight on Bit',
				data: (function () {
					// generate an array of random data
					var data = [],
						time = new Date().getTime(),
						i;

					for (i = -59; i <= 0; i += 1) {
						data.push({
							x: (InitialChartData?.timestamp || time) + i * 1000,
							y: Math.round(InitialChartData?.wob),
						});
					}
					return data;
				})(),
			},
		],
	};

	return (
		<>
			<div className="details my-3">
				<div>
					<span className="tag mb-4 me-2">{rigChartLabels.weight_on_hook}</span>
					<span ref={headerHookRef} className="me-4">
						0
					</span>
					<span className="tag mb-4 me-2">{rigChartLabels.weight_on_bit}:</span>
					<span ref={headerBitRef} className="me-4">
						0
					</span>
					<span className="tag mb-4 me-2">{rigChartLabels.total_weight}:</span>
					<span ref={headerWeightRef}>0</span>

					<span className="tag mb-4 ms-4 me-2">Local Timing:</span>
					<span ref={headerTimeRef}>{moment().format('HH:mm:ss')}</span>
				</div>
			</div>

			<HighchartsReact
				ref={chartComponentRef}
				highcharts={Highcharts}
				options={options}
			/>
		</>
	);
};

export default React.memo(LiveWeight);
