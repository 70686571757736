import Button from 'components/Button';
import SearchInput from 'components/SearchInput';
import Tables from 'components/Tables';
import { getUserList } from 'helpers/api/userManagement';
import useResponsive from 'helpers/hooks/useResponsive';
import { TableContainer } from 'pages/dashboard/tables.styled';
import React, { useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';
import { useQuery } from 'react-query';
import { UsersContainer } from '../../components/Profile/users.styled';
import DeleteUser from './DeleteUser';
import UserForm from './UserForm';
import UserListMobile from './UserListMobile';

export default function Users() {
	const { isTablet, isMobile } = useResponsive();
	const [filteredUsers, setFilteredUsers] = useState([]);
	const [deleteUserData, setDeleteUserData] = useState({});
	const [editUserData, setEditUserData] = useState();
	const [showDelete, setShowDelete] = useState(false);
	const [showUser, setShowUser] = useState(false);
	const [editMode, setEditMode] = useState(false);

	const { data, isLoading, refetch } = useQuery(
		'userList',
		() => getUserList(),
		{
			refetchInterval: 20000,
			refetchIntervalInBackground: true,
			refetchOnWindowFocus: false,
		},
	);

	const handleShowDelete = (item) => () => {
		setDeleteUserData(item);
		setShowDelete(true);
	};
	const handleCloseDelete = () => setShowDelete(false);
	const handleShowEdit = (item) => () => {
		setEditMode(true);
		setEditUserData(item);
		setShowUser(true);
	};

	const handleSearch = (e) => {
		setFilteredUsers(
			data?.data?.users.filter(
				(item) =>
					item?.name?.includes(e.target.value) ||
					item?.username?.includes(e.target.value) ||
					item?.middlename?.includes(e.target.value) ||
					item?.lastname?.includes(e.target.value) ||
					item?.role?.permission_level?.includes(e.target.value),
			),
		);
	};
	useMemo(() => {
		setFilteredUsers(data?.data?.users);
	}, [data]);

	const columns = React.useMemo(
		() => [
			{
				name: 'Username',
				cell: (row) => (
					<Button type="button" onClick={handleShowEdit(row)}>
						{row.username}
					</Button>
				),
				selector: (row) => row.username,
				sortable: true,
			},
			{
				name: 'First name',
				selector: (row) => row.name,
				sortable: true,
			},
			{
				name: 'Middle Name',
				selector: (row) => row.middlename,
				sortable: true,
			},
			{
				name: 'Last Name',
				selector: (row) => row.lastname,
				sortable: true,
			},
			{
				name: 'Permission',
				selector: (row) => row.role?.permission_level,
				sortable: true,
			},
			{
				name: 'Actions',
				cell: (row) => (
					<div className="flex gap-2">
						<Button type="button" onClick={handleShowEdit(row)}>
							<FaRegEdit />
						</Button>
						<Button
							type="button"
							variant="delete-secondary"
							onClick={handleShowDelete(row)}
						>
							<FaRegTrashAlt />
						</Button>
					</div>
				),
			},
		],
		[],
	);

	// User popup related
	const handleModalShow = () => {
		setEditMode(false);
		setEditUserData();
		setShowUser(true);
	};
	const handleModalClose = () => {
		setShowUser(false);
	};

	if (isLoading) {
		return (
			<UsersContainer $istablet={isTablet} $ismobile={isMobile}>
				<TableContainer>
					<h1 className="assignment-header mb-5">User Management</h1>

					<div className="user-table">
						<Spinner
							size="md"
							className="mx-auto"
							style={{ display: 'block' }}
							animation="border"
						/>
					</div>
				</TableContainer>
			</UsersContainer>
		);
	}

	return (
		<UsersContainer $istablet={isTablet} $ismobile={isMobile}>
			{/* user, update, handleClose, showDelete */}
			<DeleteUser
				user={deleteUserData}
				update={refetch}
				handleClose={handleCloseDelete}
				showDelete={showDelete}
			/>
			{/* person, update, show, handleClose */}
			{showUser && (
				<UserForm
					person={editUserData}
					update={refetch}
					show={showUser}
					handleClose={handleModalClose}
					edit={editMode}
				/>
			)}
			<h1 className="mb-2 m-0">User Management</h1>

			<TableContainer className="mt-0">
				<div className={`user-table ${isMobile && 'mobile-user-table'}`}>
					<div className="flex header items-center mb-3">
						<SearchInput placeholder="Search users" onChange={handleSearch} />
						<div className="ml-auto">
							<Button variant="primary" type="button" onClick={handleModalShow}>
								Add User
							</Button>
						</div>
					</div>
					{isMobile ? (
						<UserListMobile
							data={filteredUsers}
							onDelete={handleShowDelete}
							onEdit={handleShowEdit}
						/>
					) : (
						<Tables columns={columns} data={filteredUsers} pagination />
					)}
				</div>
			</TableContainer>
		</UsersContainer>
	);
}
