import { ReactComponent as Rig } from 'assets/icons/rig.svg';
import { ReactComponent as Pump } from 'assets/icons/pump.svg';
// import { ReactComponent as Swivel } from 'assets/icons/swivel.svg';

export const EQUIPMENTS = [
	{ title: 'All', value: '', icon: '' },
	{ title: 'Rigs', value: 'rig', icon: <Rig /> },
	{ title: 'Pumps', value: 'pump', icon: <Pump /> },
	// { title: 'Swivels', value: 'swivel', icon: <Swivel /> },
];
export const CONNECTIONS = [
	{ title: 'All', value: '' },
	{ title: 'Online', value: 'Online' },
	{ title: 'Offline', value: 'Offline' },
	{ title: 'Not activated', value: 'not-activated' },
];
