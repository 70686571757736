import { gql } from '@apollo/client';

export const ALERT_FUNCTION_LIST_QUERY = gql`
	query AlertFunctions {
		alertFunctions {
			id
			identifier
			name
			description
			tags
			functions
			available
		}
	}
`;

export const USER_ALERTS_QUERY = gql`
	query UserAlerts {
		userAlerts {
			serial
			alertList {
				name
				sms
				email
			}
		}
	}
`;

export const SAVE_USER_ALERT_MUTATION = gql`
	mutation saveAlert($alerts: [UserAlertInput!]!) {
		saveAlert(alerts: $alerts) {
			success
			message
		}
	}
`;
