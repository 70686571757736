import { useMediaQuery } from 'react-responsive';

export default function useResponsive() {
	const isDesktop = useMediaQuery({ minWidth: 1200 });
	const isNotLargeDesktop = useMediaQuery({ maxWidth: 1430 });
	const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
	const isMobile = useMediaQuery({ maxWidth: 767 });
	const isNotMobile = useMediaQuery({ minWidth: 768 });
	const isXLDesktop = useMediaQuery({ minWidth: 1850 });

	return {
		isDesktop,
		isNotLargeDesktop,
		isTablet,
		isMobile,
		isNotMobile,
		isXLDesktop,
	};
}
