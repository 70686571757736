import { Col, Form, Row } from 'react-bootstrap';
import { Header } from 'style/addEq.styled';
import CustomInput from '../../../components/CustomInput';

export default function Information({ register, errors }) {
	return (
		<div className="mb-6">
			<Header>
				<h5>Equipment Information</h5>
			</Header>
			<Row>
				<CustomInput
					label="Rigseye ID"
					name="serial"
					register={register}
					placeholder="Enter ID"
					errors={errors}
				/>
				<Col lg={4}>
					<Form.Group>
						<Form.Label>Subscription Type</Form.Label>
						<Form.Select {...register('subscriptiontype')}>
							<option disabled value="">
								Select Subscription Type
							</option>
							<option value="full">Full</option>
							<option value="lite">Lite</option>
						</Form.Select>
					</Form.Group>
				</Col>
				<Col lg={4}>
					<Form.Group>
						<Form.Label>Flow Unit</Form.Label>
						<Form.Select {...register('flow_unit')}>
							<option disabled value="">
								Select UoM
							</option>
							<option value="GPM">GPM</option>
						</Form.Select>
					</Form.Group>
				</Col>
				<CustomInput
					label="VIN"
					name="VIN"
					register={register}
					errors={errors}
				/>

				<Col lg={4}>
					<Form.Group>
						<Form.Label>Registration State</Form.Label>
						<Form.Select {...register('State')}>
							<option value="">Select registration state</option>
						</Form.Select>
					</Form.Group>
				</Col>
				<CustomInput
					label="Registration State No."
					name="StateReg"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="Registration No."
					name="Registration"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="License Plate"
					name="LicPlate"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="Engine Make"
					name="EngineMake"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="Engine Model"
					name="EngineModel"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="Engine Rating"
					name="EngineRating"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="Engine Serial"
					name="EngineSerial"
					register={register}
				/>
				<CustomInput
					label="Transmission"
					name="Transmission"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="Yard Assigned"
					name="yard"
					register={register}
					type="number"
					errors={errors}
				/>
			</Row>
			<Header>
				<h5>Software Information</h5>
			</Header>
			<Row>
				<CustomInput
					label="DA CPU"
					name="dacpu"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="DA Engine"
					name="daengine"
					register={register}
					errors={errors}
				/>

				<CustomInput
					label="DA Wifi"
					name="dawifi"
					register={register}
					errors={errors}
				/>

				<CustomInput
					label="AUX-A CPU"
					name="auxacpu"
					register={register}
					errors={errors}
				/>

				<CustomInput
					label="Camera ID"
					name="camId"
					register={register}
					errors={errors}
				/>

				<CustomInput
					label="Camera Software"
					name="camSoftware"
					register={register}
					errors={errors}
				/>
			</Row>
			<Header>
				<h5>Cell & Box Information</h5>
			</Header>
			<Row>
				<CustomInput
					label="Service Provider"
					name="service_provider"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="Cell Number"
					name="cell_number"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="MEID"
					name="meid"
					register={register}
					errors={errors}
				/>

				<CustomInput
					label="DA Box Serial Number"
					name="da_boxnum"
					register={register}
					errors={errors}
				/>
				<CustomInput
					label="AUX-A Box Serial Number"
					name="aux_a_boxnum"
					register={register}
					errors={errors}
				/>
			</Row>
		</div>
	);
}
