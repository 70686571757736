export default function FuelChart({ needlePos, rate }) {
	return (
		<svg
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			width="296"
			height="95"
		>
			<desc>Created with Highstock 2.0.3</desc>
			<defs>
				<clipPath id="highcharts-1">
					<rect x="0" y="0" width="276" height="70" />
				</clipPath>
			</defs>
			<rect
				x="0"
				y="0"
				width="296"
				height="95"
				strokeWidth="0"
				fill="#FFFFFF"
				className=" highcharts-background"
			/>
			<path
				fill="#C02316"
				d="M 207.58625309081552 33.055393700276724 A 131.25 131.25 0 0 1 240.71491087729294 57.09947362358152 L 236.29991512123138 61.523308212934765 A 125 125 0 0 0 204.74881246744337 38.62418447645403 Z"
			/>
			<g className="highcharts-grid" />
			<g className="highcharts-axis">
				<path
					fill="none"
					d="M 59.61165235168157 61.611652351681556 L 52.540584539816095 54.54058453981608"
					stroke="#A0A0A0"
					strokeWidth="1"
					opacity="1"
				/>
				<path
					fill="none"
					d="M 74.52684346344087 48.87287570313157 L 68.64899094051614 40.7827057593821"
					stroke="#A0A0A0"
					strokeWidth="1"
					opacity="1"
				/>
				<path
					fill="none"
					d="M 91.25118753255666 38.62418447645402 L 86.71128253516119 29.714119234570333"
					stroke="#A0A0A0"
					strokeWidth="1"
					opacity="1"
				/>
				<path
					fill="none"
					d="M 109.37287570313158 31.117935463105795 L 106.28270575938211 21.60737030015426"
					stroke="#A0A0A0"
					strokeWidth="1"
					opacity="1"
				/>
				<path
					fill="none"
					d="M 128.44569186997114 26.538957425607776 L 126.88134721956884 16.662074019656387"
					stroke="#A0A0A0"
					strokeWidth="1"
					opacity="1"
				/>
				<path
					fill="none"
					d="M 148 25 L 148 15"
					stroke="#A0A0A0"
					strokeWidth="1"
					opacity="1"
				/>
				<path
					fill="none"
					d="M 167.55430813002886 26.538957425607776 L 169.11865278043118 16.662074019656387"
					stroke="#A0A0A0"
					strokeWidth="1"
					opacity="1"
				/>
				<path
					fill="none"
					d="M 186.62712429686843 31.11793546310581 L 189.7172942406179 21.607370300154287"
					stroke="#A0A0A0"
					strokeWidth="1"
					opacity="1"
				/>
				<path
					fill="none"
					d="M 204.74881246744337 38.62418447645403 L 209.28871746483884 29.714119234570347"
					stroke="#A0A0A0"
					strokeWidth="1"
					opacity="1"
				/>
				<path
					fill="none"
					d="M 221.47315653655915 48.87287570313157 L 227.35100905948389 40.7827057593821"
					stroke="#A0A0A0"
					strokeWidth="1"
					opacity="1"
				/>
				<path
					fill="none"
					d="M 236.38834764831844 61.61165235168157 L 243.45941546018392 54.540584539816095"
					stroke="#A0A0A0"
					strokeWidth="1"
					opacity="1"
				/>
				<path
					fill="none"
					d="M 236.38834764831844 61.61165235168157 L 243.45941546018392 54.540584539816095"
					stroke="#C0D0E0"
					strokeWidth="2"
					opacity="1"
				/>
				<path
					fill="none"
					d="M 148 25 L 148 15"
					stroke="#C0D0E0"
					strokeWidth="2"
					opacity="1"
				/>
				<path
					fill="none"
					d="M 59.61165235168157 61.611652351681556 L 52.540584539816095 54.54058453981608"
					stroke="#C0D0E0"
					strokeWidth="2"
					opacity="1"
				/>
				<text
					x="148"
					textAnchor="middle"
					transform="translate(0,0)"
					className="highcharts-yaxis-title"
					style={{ color: '#707070', fill: '#707070' }}
					visibility="visible"
					y="67.5"
				>
					<tspan>{rate || 0} gal/hr</tspan>
					<tspan x="148" dy="16">
						Fuel Rate
					</tspan>
				</text>
				<path
					fill="none"
					d="M 59.61165235168157 61.611652351681556 A 125 125 0 0 1 236.29991512123138 61.523308212934765 M 148 150 A 0 0 0 0 0 148 150 "
					stroke="#C0D0E0"
					strokeWidth="1"
					visibility="visible"
				/>
			</g>
			<g className="highcharts-series-group">
				<g
					className="highcharts-series highcharts-tracker"
					visibility="visible"
					transform="translate(10,10) scale(1 1)"
					clipPath="url(#highcharts-1)"
				>
					<path
						fill="black"
						d="M -12.5 -1.5 L 87.5 -1.5 125 -0.5 125 0.5 87.5 1.5 -12.5 1.5 z"
						transform={`translate(138,140) rotate(${needlePos} 0 0)`}
					/>
					<circle
						cx="0"
						cy="0"
						r="5"
						fill="black"
						transform="translate(138,140)"
					/>
				</g>
				<g
					className="highcharts-markers"
					visibility="visible"
					transform="translate(10,10) scale(1 1)"
					clipPath="none"
				/>
			</g>
			<g className="highcharts-legend">
				<g>
					<g />
				</g>
			</g>
			<g className="highcharts-axis-labels highcharts-yaxis-labels">
				<text
					x="52.540584539816095"
					textAnchor="middle"
					style={{
						width: '78px',
						color: '#606060',
						cursor: 'default',
						fontSize: '11px',
						fill: '#606060',
					}}
					transform="translate(0,0) rotate(-45 52.540584539816095 54.54058453981608)"
					y="54.54058453981608"
					opacity="1"
				>
					0
				</text>
				<text
					x="148"
					textAnchor="middle"
					style={{
						width: '78px',
						color: '#606060',
						cursor: 'default',
						fontSize: '11px',
						fill: '#606060',
					}}
					transform="translate(0,0)"
					y="15"
					opacity="1"
				>
					5
				</text>
				<text
					x="243.45941546018392"
					textAnchor="middle"
					style={{
						width: '78px',
						color: '#606060',
						cursor: 'default',
						fontSize: '11px',
						fill: '#606060',
						transform:
							'translate(0,0) rotate(45 243.45941546018392 54.540584539816095)',
						y: '54.540584539816095',
						opacity: '1',
					}}
				>
					10
				</text>
			</g>
		</svg>
	);
}
