import FullPageSpinner from 'components/FullPageSpinner';
import PumpLayout from 'components/Layouts/PumpLayout';
import {
	getEquipmentChartByAmount,
	getEquipmentDetails,
} from 'helpers/api/equipments';
import Messages from 'pages/details/pump/detailsTab/Messages';
import PumpLocation from 'pages/details/pump/detailsTab/PumpLocation';
import PumpFlowGauge from 'pages/details/pump/gauges/PumpFlowGauge';
import PumpPSIGGauge from 'pages/details/pump/gauges/PumpPSIGGauge';
import PumpStrokesGauge from 'pages/details/pump/gauges/PumpStrokesGauge';
import { Col, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

export default function DetailsTab() {
	const { id } = useParams();
	const graphs = 'suction|pump|strokes';
	let { data } = useQuery('detailsPump', () => getEquipmentDetails(id), {
		enabled: !!id,
		refetchInterval: 2000,
		refetchIntervalInBackground: false,
		refetchOnWindowFocus: false,
	});
	if (data?.data) {
		data = data?.data?.equipmentData;
	}

	const values: any = useQuery(
		'chartsPump',
		() => getEquipmentChartByAmount(id, graphs),
		{
			enabled: !!id,
			refetchInterval: 2000,
			refetchIntervalInBackground: false,
			refetchOnWindowFocus: false,
		},
	);

	if (!data) return <FullPageSpinner />;

	return (
		<PumpLayout>
			<Row>
				<Col className="d-flex flex-column">
					<PumpLocation data={data?.[0]} />
				</Col>
				<Col className="d-flex flex-column">
					<Messages data={data?.[0]} />
				</Col>
			</Row>
			<Row>
				<Col className="d-flex flex-column">
					<PumpStrokesGauge value={values.data?.strokes?.[0].pump_strokes} />
				</Col>
				<Col className="d-flex flex-column">
					<PumpFlowGauge
						unit={values.data?.pump?.[0].flow_unit}
						value={values.data?.pump?.[0].pump_flow}
					/>
				</Col>
				<Col className="d-flex flex-column">
					<PumpPSIGGauge value={values.data?.pump?.[0].pump_discharge} />
				</Col>
			</Row>
		</PumpLayout>
	);
}
