import {
	CREATE_MULTIVIEW_CAMERA_MUTATION,
	DELETE_MULTIVIEW_CAMERA_LAYOUT_MUTATION,
	GET_MULTIVIEW_CAMERA_LAYOUT_LIST_QUERY,
	GET_MULTIVIEW_CAMERA_QUERY,
	UPDATE_MULTIVIEW_CAMERA_LAYOUT_MUTATION,
} from 'helpers/queries/multiviewCameraQuery';
import { getToken } from 'helpers/utils/auth-provider';
import client from './apolloClient';

export default {
	getCamerasLayoutList: () =>
		client.query({
			query: GET_MULTIVIEW_CAMERA_LAYOUT_LIST_QUERY,
			context: {
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			},
			fetchPolicy: 'network-only',
		}),
	deleteCameraLayout: (id) =>
		client.mutate({
			mutation: DELETE_MULTIVIEW_CAMERA_LAYOUT_MUTATION,
			context: {
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			},
			variables: {
				id,
			},
		}),
	updateCameraLayout: (data) =>
		client.mutate({
			mutation: UPDATE_MULTIVIEW_CAMERA_LAYOUT_MUTATION,
			context: {
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			},
			variables: {
				overViewUpdateArgs: data,
			},
		}),
	getCameras: (layoutId) =>
		client.query({
			query: GET_MULTIVIEW_CAMERA_QUERY,
			context: {
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			},
			variables: {
				id: parseInt(layoutId, 10),
			},
			fetchPolicy: 'network-only',
		}),
	addCameras: async (data) => {
		const result = await client.mutate({
			mutation: CREATE_MULTIVIEW_CAMERA_MUTATION,
			context: {
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			},
			variables: data,
		});
		return result.data.createMultiView;
	},
};
