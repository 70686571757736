import { gql } from '@apollo/client';

export const LOGS_BY_PROJECT_ID_QUERY = gql`
	query ApiLogsByProjectId($id: String!, $type: String!) {
		apiLogsByProjectId(id: $id, type: $type) {
			id
			savetime
			response_error
			userid
			companyid
			request_type
			requestid
			requestaction
			query
			request_data
			response
			userdata
			environment
		}
	}
`;

export const CREATE_LOG_MUTATION = gql`
	mutation CreateLog(
		$savetime: String!
		$response_error: String!
		$request_type: String!
		$requestid: String!
		$requestaction: String!
		$query: String!
		$request_data: String!
		$response: String!
		$userdata: String!
		$environment: String!
	) {
		createLog(
			createLogInput: {
				savetime: $savetime
				response_error: $response_error
				request_type: $request_type
				requestid: $requestid
				requestaction: $requestaction
				query: $query
				request_data: $request_data
				response: $response
				userdata: $userdata
				environment: $environment
			}
		) {
			id
		}
	}
`;
