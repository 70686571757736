import {
	LOGIN_MUTATION,
	LOGOUT_QUERY,
	RESET_PASSWORD_MUTATION,
	SET_NEW_PASSWORD_MUTATION,
} from '../queries/authQuery';
import { PROFILE_QUERY } from '../queries/userQuery';
import { getToken } from '../utils/auth-provider';
import client from './apolloClient';

export default {
	login: (data) =>
		client.mutate({
			mutation: LOGIN_MUTATION,
			variables: { loginInput: data },
		}),
	logoutUser: () =>
		client.query({
			query: LOGOUT_QUERY,
			context: {
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			},
			fetchPolicy: 'network-only',
		}),
	getCurrentUser: () =>
		client.query({
			query: PROFILE_QUERY,
			context: {
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			},
			fetchPolicy: 'network-only',
		}),
	resetPassword: ({ username }) =>
		client.mutate({
			mutation: RESET_PASSWORD_MUTATION,
			variables: { username },
		}),

	validateResetToken: ({ token }) =>
		client
			.post(`/users/passwordtokenvalidation&token=${token}`)
			.then((res) => res.data),
	newPassword: ({ token, password }) =>
		client.mutate({
			mutation: SET_NEW_PASSWORD_MUTATION,
			variables: {
				token,
				password,
			},
		}),
};
