const certOptions = [
	{ id: 1, category: 'Weight Indicator', code: 'C01', validity: 1 },
	{ id: 2, category: 'Tubing Line', code: 'C02', validity: 1 },
	{ id: 3, category: 'Travelling Block', code: 'C03', validity: 1 },
	{ id: 4, category: 'Transmission', code: 'C04', validity: 1 },
	{ id: 5, category: 'Engine', code: 'C05', validity: 1 },
	{ id: 6, category: 'Line Scale', code: 'C06', validity: 1 },
	{ id: 7, category: 'Lift Package', code: 'C07', validity: 1 },
	{ id: 8, category: 'Cat 3 Inspection', code: 'C08', validity: 1 },
	{ id: 9, category: 'Cat 4 Inspection', code: 'C09', validity: 5 },
	{ id: 10, category: 'Cat 5 Inspection', code: 'C10', validity: 2 },
];

export default certOptions;
