/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { yupResolver } from '@hookform/resolvers/yup';
import { createTong, updateTong } from 'helpers/api/tongs';
import useResponsive from 'helpers/hooks/useResponsive';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import * as yup from 'yup';

function ErrorMessage({ children }) {
	return <small className="text-danger mt-2 d-block">{children}</small>;
}

export default function TongPopup({ data, open, onClose, refetch }) {
	const { isMobile } = useResponsive();
	const [loading, setLoading] = useState(false);
	const [selectedTongType, setSelectedTongType] = useState('');

	const schema = yup
		.object({
			tongname: yup.string().required('Tong name is required'),
			tongtype: yup.string().required('Tong type is required'),
			air_pressure_max: yup.number().required('Max PSI is required'),
			torque_max_1: yup.number().required('Torque max 1 is required'),
			torque_max_2: yup.number().when('tongtype', {
				is: 'rod',
				then: () => yup.number().required('Torque max 2 is required for Rod'),
				otherwise: () => yup.number().nullable(),
			}),
		})
		.required();

	const { register, handleSubmit, formState, reset, watch } = useForm({
		resolver: yupResolver(schema),
	});

	const closeModal = () => {
		onClose();
	};

	useEffect(() => {
		if (open === true) {
			reset({
				tongname: data?.tongname,
				tongtype: data?.tongtype === 'R' ? 'rod' : 'tubing',
				air_pressure_max: data?.air_pressure_max,
				torque_max_1: data?.torque_max_1,
				torque_max_2: data?.tongtype === 'R' ? data?.torque_max_2 : null,
			});
			setSelectedTongType(data?.tongtype === 'R' ? 'rod' : 'tubing');
		}
	}, [open, data, reset]);

	const onSubmit = (formData) => {
		setLoading(true);
		if (data?.tongid) {
			updateTong({
				tongid: data.tongid,
				...formData,
				torque_max_2:
					formData?.tongtype === 'rod' ? Number(formData?.torque_max_2) : null,
			})
				.then(() => {
					toast.success('Updated Tong');
					setLoading(false);
					reset();
					refetch();
					closeModal();
				})
				.catch((error) => {
					toast.error(error.response.message);
					setLoading(false);
				});
		} else {
			createTong({
				...formData,
				torque_max_2:
					formData?.tongtype === 'rod' ? Number(formData?.torque_max_2) : null,
			})
				.then(() => {
					toast.success('Created Tong');
					setLoading(false);
					reset();
					refetch();
					closeModal();
				})
				.catch((error) => {
					toast.error(error.response.message);
					setLoading(false);
				});
		}
	};
	const { errors } = formState;

	// Watch the tongtype field
	const watchedTongType = watch('tongtype', selectedTongType);

	useEffect(() => {
		setSelectedTongType(watchedTongType);
	}, [watchedTongType]);

	return (
		<div className="profile-container">
			<Modal
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={open}
				onHide={onClose}
				className="profile-modal"
			>
				<Modal.Body>
					{isMobile ? (
						<div className="information-container">
							<span className="information">Tong</span>
						</div>
					) : (
						<div className="information-container">
							<span className="information">Tong</span>
						</div>
					)}

					<Form onSubmit={handleSubmit(onSubmit)}>
						<Row className="inputs">
							<Col lg={6}>
								<Form.Group className="mb-3">
									<span>Tong Name</span>
									<input type="text" {...register('tongname')} />
									<ErrorMessage>{errors.tongname?.message}</ErrorMessage>
								</Form.Group>
							</Col>
							<Col lg={6}>
								<Form.Group className="mb-3">
									<span>Tong Type</span>
									<select
										{...register('tongtype')}
										onChange={(e) => setSelectedTongType(e.target.value)}
									>
										<option value="rod">Rod</option>
										<option value="tubing">Tubing</option>
									</select>
									<ErrorMessage>{errors.tongtype?.message}</ErrorMessage>
								</Form.Group>
							</Col>
							<Col lg={6}>
								<Form.Group className="mb-3">
									<span>Air Pressure Max</span>
									<input type="text" {...register('air_pressure_max')} />
									<ErrorMessage>
										{errors.air_pressure_max?.message}
									</ErrorMessage>
								</Form.Group>
							</Col>
							<Col lg={6}>
								<Form.Group className="mb-3">
									<span>Torque Max 1</span>
									<input type="text" {...register('torque_max_1')} />
									<ErrorMessage>{errors.torque_max_1?.message}</ErrorMessage>
								</Form.Group>
							</Col>
							{selectedTongType === 'rod' && (
								<Col lg={6}>
									<Form.Group className="mb-3">
										<span>Torque Max 2</span>
										<input type="text" {...register('torque_max_2')} />
										<ErrorMessage>{errors.torque_max_2?.message}</ErrorMessage>
									</Form.Group>
								</Col>
							)}
						</Row>

						<div className="button-wrapper">
							<Button
								type="button"
								variant="bordered-green"
								className="me-3"
								onClick={closeModal}
							>
								Close
							</Button>
							<Button variant="primary-green" type="submit">
								{loading ? <Spinner size="sm" animation="border" /> : 'Save'}
							</Button>
						</div>
					</Form>
				</Modal.Body>
			</Modal>
		</div>
	);
}
