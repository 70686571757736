import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Form } from 'react-bootstrap';

const Card = styled.div`
	/* overflow-y: scroll; */
	h5 {
		border-bottom: 1px solid #dddddd;
		padding-bottom: 1rem;
		margin-bottom: 1rem;
	}
	input[type='checkbox'] {
		&:checked {
			background-color: ${({ theme }) => theme.colors.loginPrimary};
			border-color: ${({ theme }) => theme.colors.loginPrimary};
		}
	}
	label {
		margin-left: 0.5rem;
		margin-top: 0.25rem;
		vertical-align: middle;
	}
`;

const CustomFormCheck = styled(Form.Check)`
	input {
		width: 24px;
		height: 24px;
		padding: 0px;
	}
`;

function ChartSelectionForm({ submit, prev, formData, update }) {
	const [charts, setCharts] = useState([]);

	const handleCharts = (e) => {
		const { id } = e.target;
		const isExits = charts.find((item) => item.graph === id);
		if (isExits) {
			setCharts((prevData) => prevData.filter((item) => item.graph !== id));
			update({
				charts: charts.filter((item) => item.graph !== id),
			});
		} else {
			setCharts([
				...charts,
				{
					graph: id,
					enable: true,
				},
			]);
			update({
				charts: [
					...charts,
					{
						graph: id,
						enable: true,
					},
				],
			});
		}
	};

	const onSubmit = (e) => {
		e.preventDefault();
		submit({
			eq_data: JSON.stringify(formData.eq_data),
			peqarry: JSON.stringify(formData.peqarry),
			charts: JSON.stringify(formData.charts),
		});
	};

	return (
		<Form onSubmit={onSubmit}>
			<h5>Graph selection</h5>
			<Row className="inputs mt-5">
				<Col lg={3}>
					<Card>
						<div className="radio-container">
							<CustomFormCheck
								className="mb-3"
								type="checkbox"
								id="woh"
								onChange={handleCharts}
								label="Weight on Hook"
							/>
						</div>
						<div className="radio-container">
							<CustomFormCheck
								className="mb-3"
								type="checkbox"
								id="position"
								onChange={handleCharts}
								label="Block Position"
							/>
						</div>
						<div className="radio-container">
							<CustomFormCheck
								className="mb-3"
								type="checkbox"
								id="pump_flow_rate"
								onChange={handleCharts}
								label="Pump Flow Rate"
							/>
						</div>
						<div className="radio-container">
							<CustomFormCheck
								className="mb-3"
								type="checkbox"
								id="flow_rate"
								onChange={handleCharts}
								label="Well Flow Rate"
							/>
						</div>
					</Card>
				</Col>
				<Col lg={3}>
					<Card>
						<div className="radio-container">
							<CustomFormCheck
								className="mb-3"
								type="checkbox"
								id="wob"
								onChange={handleCharts}
								label="Weight on Bit"
							/>
						</div>
						<div className="radio-container">
							<CustomFormCheck
								className="mb-3"
								type="checkbox"
								id="swivel_torque"
								onChange={handleCharts}
								label="Swivel Torque"
							/>
						</div>
						<div className="radio-container">
							<CustomFormCheck
								className="mb-3"
								type="checkbox"
								id="pump_discharge"
								onChange={handleCharts}
								label="Pump Discharge"
							/>
						</div>
					</Card>
				</Col>
				<Col lg={3}>
					<Card>
						<div className="radio-container">
							<CustomFormCheck
								className="mb-3"
								type="checkbox"
								id="actual_load"
								onChange={handleCharts}
								label="Actual Load"
							/>
						</div>
						<div className="radio-container">
							<CustomFormCheck
								className="mb-3"
								type="checkbox"
								id="swivel_rpm"
								onChange={handleCharts}
								label="Swivel RPM"
							/>
						</div>
						<div className="radio-container">
							<CustomFormCheck
								className="mb-3"
								type="checkbox"
								id="well_discharge"
								onChange={handleCharts}
								label="Well Discharge"
							/>
						</div>
					</Card>
				</Col>
			</Row>
			<Row className="process">
				<button type="button" className="alt-button ms-auto" onClick={prev}>
					Previous
				</button>
				<button type="submit" className="primary-button">
					Create Project
				</button>
			</Row>
		</Form>
	);
}

export default ChartSelectionForm;
