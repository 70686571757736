import { getEquipmentChartDate } from 'helpers/api/equipments';
import { pumpChartLabels } from 'helpers/const/chartLabels';
import moment from 'moment';
import { ChartContainer } from 'pages/details/styled';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useParams } from 'react-router-dom';
import DailyChartGeneral from './DailyChartGeneral';
import LiveChartGeneral from './LiveChartGeneral';

function ActivitySuction() {
	const [dailyRPM, setDailyRPM] = useState(false);
	// const [selectedRPM, setselectedRPM] = useState(false);
	const [data, setData] = useState<any>([]);
	const [selectedDate, setSelectedDate] = useState(new Date());

	const { id } = useParams();

	const handleDailyRPM = (equipment) => {
		if (dailyRPM === false)
			getEquipmentChartDate(id, moment(), equipment).then((res) => {
				setData(res?.data?.equipmentChartData);
			});

		if (dailyRPM === true) setDailyRPM(!dailyRPM);
	};

	const chartselecteddate = (date, equipment) => {
		setSelectedDate(date);
		getEquipmentChartDate(id, date, equipment).then((res) => {
			setData(res?.data?.equipmentChartData);
		});

		if (equipment === 'drawworks') {
			setDailyRPM(true);
			// setselectedRPM(true);
		}
	};

	return (
		<Col lg={12}>
			<ChartContainer>
				<Row className="header">
					<Col className="align-self-center">
						<h1 className="align-self-center">
							{pumpChartLabels.pump_suction}
						</h1>
					</Col>
					<Col className="change-container">
						<button
							type="button"
							onClick={() => handleDailyRPM('drawworks')}
							className="change-span"
						>
							{dailyRPM ? 'Live chart' : '24h chart'}
						</button>
						<span>
							<DatePicker
								selected={selectedDate}
								onChange={(date) => chartselecteddate(date, 'suction')}
							/>
						</span>
					</Col>
				</Row>

				{/* <div className="details">
					<div>
						<span className="tag">Suction: </span>{' '}
						<span>{livedata?.data?.charts?.suction?.[59]?.pump_suction}</span>
					</div>
				</div> */}

				{dailyRPM && (
					<DailyChartGeneral
						data={data?.charts?.suction}
						subitem={pumpChartLabels.pump_suction}
					/>
				)}

				{!dailyRPM && (
					<LiveChartGeneral
						chart="pump_suction"
						subchart={pumpChartLabels.pump_suction}
						datakey="pump_suction"
					/>
				)}
			</ChartContainer>
		</Col>
	);
}

export default ActivitySuction;
