import { useMutation } from '@apollo/client';
import { ReactComponent as Right } from 'assets/icons/right-arrow.svg';
import DashboardLayout from 'components/Layouts/DashboardLayout';
import { getToken } from 'helpers/utils/auth-provider';
import { CreateProjectContainer } from 'pages/projectmanagement/project.styled';
import React from 'react';
import { Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { CREATE_PROJECT_MUTATION } from '../../helpers/queries/projectQuery';
import stepper, { defaultStep } from './stepper';

const INITIAL_STATE = {
	pname: '',
	pbegdate: '',
	penddate: '',
	pprojectid: '',
	pwellid: '',
	pclient: '',
	pcoordinates: '',
	pwellname: '',
	pwellnumber: '',
	ppadname: '',
	pafe: '',
	ptubingsize: '',
	punit: '',
	passembly_length: '',
	pfloor_height: '',
	pkb_height: '',
	charts: [],
	eq_data: [],
};

function CreateProject() {
	const navigate = useNavigate();
	const [activeStep, setActiveStep] = React.useState(defaultStep);

	const next = () => {
		const nextStep = stepper[activeStep].next;
		if (nextStep) setActiveStep(nextStep);
	};
	const prev = () => {
		const prevStep = stepper[activeStep].back;
		if (prevStep) setActiveStep(prevStep);
	};

	const [formData, setFormData] = React.useState(INITIAL_STATE);

	const updateFormState = (data) => {
		setFormData((prevState) => ({ ...prevState, ...data }));
	};

	const [createProject] = useMutation(CREATE_PROJECT_MUTATION, {
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
	});

	const submit = (data) => {
		updateFormState(data);
		const payload = { ...formData, ...data };
		try {
			createProject({
				variables: {
					...payload,
					clientusers: [],
				},
			});
			toast.success('Project created successfully!');
			navigate('/projects');
		} catch (error) {
			toast.error(error?.response?.data?.message || 'Something went wrong!');
		}
	};
	const StepComponent = React.useMemo(() => {
		const step = stepper[activeStep];
		if (!step) {
			return null;
		}
		return step.component;
	}, [activeStep]);

	const stepProps = { next, prev, submit, formData, update: updateFormState };
	return (
		<DashboardLayout>
			<CreateProjectContainer>
				<div className="main-content">
					<ProjectMHeader />
					<Row style={{ borderBottom: '1px solid #dddddd' }} className="mb-5">
						<h1 className="assignment-header my-3">
							Create new project{' '}
							<span className="step-view">({activeStep}/3)</span>
						</h1>
					</Row>

					<StepComponent {...stepProps} />
				</div>
			</CreateProjectContainer>
		</DashboardLayout>
	);
}

function ProjectMHeader() {
	return (
		<div className="mb-3 create-project-header flex items-center">
			<Link to="/projects" className="dashboard-back-link flex items-center">
				<Right />
				<span className="ms-2">Back</span>
			</Link>
			<Link to="/projects" className="project-link">
				<span className="ms-2" style={{ color: '#00834D' }}>
					Projects
				</span>
				<span> {'>'} Create project</span>
			</Link>
		</div>
	);
}

export default CreateProject;
