import { useAuth } from 'helpers/contexts/AuthContext';
import Alerts from 'pages/alert-management';
import CamFeed from 'pages/camera-feed';
import CamDetailFeed from 'pages/camera-feed/detail';
import Dashboard from 'pages/dashboard';
import DetailsTabPump from 'pages/details/pump/DetailsTabPump';
import PumpDocSys from 'pages/details/pump/DocSysTab';
import PumpActivity from 'pages/details/pump/PumpActivity';
import CameraTabView from 'pages/details/rig/tabs/Camera';
import DetailsTabView from 'pages/details/rig/tabs/Detail';
import DocSysTabView from 'pages/details/rig/tabs/DocSys';
import EngineTabView from 'pages/details/rig/tabs/Engine';
import RigActivityTabView from 'pages/details/rig/tabs/RigActivity';
import { Navigate, useLocation } from 'react-router-dom';

const PrivateRoutes = [
	// Dashboard
	{
		id: 'dashboard',
		path: '/dashboard',
		component: Dashboard,
		exact: true,
		private: true,
	},
	// Routes for rigs
	{
		id: 'rig-details',
		path: '/rigs/:id',
		component: DetailsTabView,
		exact: false,
		private: true,
	},
	{
		id: 'rig-activity',
		path: '/rigs/:id/charts',
		component: RigActivityTabView,
		exact: false,
		private: true,
	},
	{
		id: 'rig-engine',
		path: '/rigs/:id/engine',
		component: EngineTabView,
		exact: false,
		private: true,
	},
	{
		id: 'rig-camera',
		path: '/rigs/:id/camera',
		component: CameraTabView,
		exact: false,
		private: true,
	},
	{
		id: 'rig-docsys',
		path: '/rigs/:id/docsys',
		component: DocSysTabView,
		exact: false,
		private: true,
	},
	// Routes for pumps
	{
		id: 'pump-details',
		path: '/pumps/:id',
		component: DetailsTabPump,
		exact: false,
		private: true,
	},
	{
		id: 'pump-activity',
		path: '/pumps/:id/charts',
		component: PumpActivity,
		exact: false,
		private: true,
	},
	{
		id: 'pump-docsys',
		path: '/pumps/:id/docsys',
		component: PumpDocSys,
		exact: false,
		private: true,
	},
	{
		id: 'alert-management',
		path: '/alerts',
		component: Alerts,
		exact: false,
		private: true,
	},
	{
		id: 'camera-feed',
		path: '/camera-feed',
		component: CamFeed,
		exact: false,
		private: true,
	},
	{
		id: 'camera-feed-details',
		path: '/camera-feed/:id',
		component: CamDetailFeed,
		exact: false,
		private: true,
	},
];

function ProtectedRoute({ children }) {
	const auth = useAuth();
	const location = useLocation();
	if (!auth.user) {
		return <Navigate to="/" state={{ from: location }} />;
	}
	return children;
}

export { ProtectedRoute };
export default PrivateRoutes;
