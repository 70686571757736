import { RigDetailContextProvider } from 'helpers/contexts/RigDetailProvider';
import EngineGauges from 'pages/details/rig/tabs/Engine/EngineGauges';

const EngineTabView = () => (
	<RigDetailContextProvider>
		<EngineGauges />
	</RigDetailContextProvider>
);

export default EngineTabView;
