// you shouldn't have to implement something like this in your own app
const localStorageKey = '__auth_provider_token__';
const localStorageRefreshKey = '__auth_provider_refresh_token__';
const localStorageCompanyKey = '__auth_company_id__';
const localStorageCompanyIDKey = '__auth_company_custom__';
const localStorageUserId = '__auth_user_id__';
export const LOCAL_STORAGE_LOGIN_REMEMBER_ME_KEY = '__login_remember_me__';

function getToken() {
	// to retrieve the user's token.
	return window.localStorage.getItem(localStorageKey);
}
function setToken(token) {
	// to set the user's token.
	window.localStorage.setItem(localStorageKey, token);
}
function setRefreshToken(token) {
	// to set the user's refresh token.
	window.localStorage.setItem(localStorageRefreshKey, token);
}
function getRefreshToken() {
	// to retrieve the user's refresh token.
	return window.localStorage.getItem(localStorageRefreshKey);
}
function setCompanyId(company) {
	return window.localStorage.setItem(localStorageCompanyKey, company);
}
function setCompanyCustom(customId) {
	return window.localStorage.setItem(localStorageCompanyIDKey, customId);
}
function setUserId(user) {
	return window.localStorage.setItem(localStorageUserId, user);
}

async function removeToken() {
	const loginRememberMe = window.localStorage.getItem(
		LOCAL_STORAGE_LOGIN_REMEMBER_ME_KEY,
	);
	window.localStorage.clear();
	window.localStorage.setItem(
		LOCAL_STORAGE_LOGIN_REMEMBER_ME_KEY,
		loginRememberMe,
	);
	// window.localStorage.removeItem(localStorageKey);
}

// an auth provider wouldn't use your client, they'd have their own
// so that's why we're not just re-using the client
// const authURL = process.env.REACT_APP_AUTH_URL

export {
	getRefreshToken,
	getToken,
	localStorageKey,
	removeToken,
	setCompanyCustom,
	setCompanyId,
	setRefreshToken,
	setToken,
	setUserId,
};
