import { ReactComponent as Right } from 'assets/icons/right-arrow.svg';
import { getProjects, updateAssignments } from 'helpers/api/userManagement';
import { useDashboard } from 'pages/dashboard/DashboardContext';
import { TableContainer } from 'pages/dashboard/tables.styled';
import { useEffect, useState, useTransition } from 'react';
import { Button, Form, Modal, Spinner, Tab, Tabs } from 'react-bootstrap';
import toast from 'react-hot-toast';
import Equipments from './Assignments/Equipments';
import Projects from './Assignments/Projects';

const AssignmentTabs = {
	EQUIPMENT: 'equipment',
	PROJECTS: 'projects',
};

// TODO: If projects assignment is empty only send the equipment if they change, otherwise, don't send anything
// TODO: Compare the equipment assignment to check for any change. Do the same for projects.

export default function Assignments({ table, user, update, setUser }) {
	const { equipments, timezones } = useDashboard();
	const [show, setModalShow] = useState(false);
	const [key, setKey] = useState('equipment');
	const [loading, setLoading] = useState(true);
	const [postApiLoading, setPostApiLoading] = useState(false);
	const [availableProjs, setAvailableProjs] = useState();
	const [eqs, setEqs] = useState([]);
	const [projs, setProjs] = useState([]);
	const [, startTransition] = useTransition();
	const [isLoadingProjects, setIsLoadingProjects] = useState(false);
	const handleShow = () => {
		setModalShow(true);
		setLoading(false);
	};

	const handleSelectAll = () => {
		setEqs(equipments?.map((e) => e?.equip_data?.serial));
		startTransition(() => {
			setProjs(availableProjs?.map((proj) => proj.pid));
		});
	};

	/**
	 * Deselects all equipments and projects in the Assignments modal.
	 */
	const handleDeselectAll = () => {
		setEqs([]);
		startTransition(() => {
			setProjs([]);
		});
	};

	useEffect(() => {
		setIsLoadingProjects(true);
		getProjects().then((res) => {
			const { data } = res;
			setAvailableProjs(data.projectsByCustomerId);
			setIsLoadingProjects(false);
		});
	}, []);

	const handleSubmit = (e) => {
		e.preventDefault();
		setPostApiLoading(true);
		let payload = {};
		if (AssignmentTabs.EQUIPMENT === key) {
			payload = { equipments: eqs };
		} else {
			payload = {
				projects: projs.filter((id) => id).map((proId) => parseInt(proId, 10)),
			};
		}
		if (Object.keys(payload).length) {
			updateAssignments({ ...payload }, user.userid)
				.then(async (res) => {
					setUser({
						...user,
						projects:
							res.data.assign.projects?.map((proId) => ({
								project: proId,
							})) || [],
						equipments:
							res?.data?.assign?.equipments?.map((eq) => ({
								equipment: eq,
							})) || [],
					});

					setPostApiLoading(false);
					if (res.data.assign.status) {
						setModalShow(false);
						toast.success('Assignments updated successfully');
						await update();
					} else {
						toast.error('Something went wrong');
					}
				})
				.catch((res) => {
					setPostApiLoading(false);
					toast.error(res.message);
				});
		}
	};

	useEffect(() => {
		getProjects().then((res) => {
			const { data } = res;
			setAvailableProjs(data.projectsByCustomerId);
		});
	}, []);

	return (
		<>
			{table ? (
				<td className="equipment-id">
					<Button
						style={{ all: 'unset', cursor: 'pointer' }}
						variant="bordered-green"
						type="button"
						onClick={handleShow}
					>
						Assignments
					</Button>
				</td>
			) : (
				<Button type="button" variant="bordered-green" onClick={handleShow}>
					Assignments
				</Button>
			)}

			<Modal
				size="lg"
				centered
				show={show}
				onHide={() => setModalShow(false)}
				className="profile-modal"
			>
				<Modal.Body>
					<TableContainer>
						<div
							className="mb-3 flex items-center"
							style={{ cursor: 'pointer', maxWidth: 'fit-content' }}
						>
							<Right />
							<button
								type="button"
								onClick={() => setModalShow(false)}
								style={{ all: 'unset' }}
							>
								<span className="ms-2">Back</span>
							</button>
						</div>

						<h1 className="mb-4 assignment-header ">
							Assignments for {user?.username} {user?.permission_level}
						</h1>

						<Form onSubmit={handleSubmit}>
							<Tabs
								id="controlled-tab-example"
								activeKey={key}
								onSelect={(k) => setKey(k)}
								className="mb-3"
							>
								<Tab
									eventKey={AssignmentTabs.EQUIPMENT}
									title="EQUIPMENT"
									className="pt-3"
								>
									<Equipments
										setEqs={setEqs}
										eqs={eqs}
										user={user}
										equipments={equipments}
										timezones={timezones}
									/>
								</Tab>

								<Tab eventKey={AssignmentTabs.PROJECTS} title="PROJECTS">
									<Projects
										loading={loading}
										availableProjs={availableProjs}
										projs={projs}
										setProjs={setProjs}
										user={user}
										isLoadingProjects={isLoadingProjects}
									/>
								</Tab>
							</Tabs>

							<div>
								{equipments && equipments.length > 0 && (
									<>
										<button
											type="button"
											className="selection green"
											onClick={handleSelectAll}
										>
											Select All
										</button>
										<button
											type="button"
											className="selection green ms-3"
											onClick={handleDeselectAll}
										>
											Deselect All
										</button>
									</>
								)}
								<div className="button-wrapper">
									<Button
										type="button"
										variant="bordered-green"
										className="me-3"
										onClick={() => setModalShow(false)}
									>
										Close
									</Button>
									<Button
										type="button"
										variant="primary-green"
										onClick={handleSubmit}
									>
										{postApiLoading ? (
											<Spinner size="sm" animation="border" />
										) : (
											'Save'
										)}
									</Button>
								</div>
							</div>
						</Form>
					</TableContainer>
				</Modal.Body>
			</Modal>
		</>
	);
}
