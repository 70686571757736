import { Wrapper } from './styles/PosiHelp.styled';

export default function PosiHelp() {
	return (
		<Wrapper>
			<div className="status-container">
				<div
					className="status"
					style={{
						background: '#00834D',
					}}
				/>
				<div className="text">Posi-Stop On</div>
			</div>
			<div className="status-container">
				<div
					className="status"
					style={{
						background: 'red',
					}}
				/>
				<div className="text">Posi-Stop Off</div>
			</div>
			<div className="status-container">
				<div
					className="status"
					style={{
						background: 'grey',
					}}
				/>
				<div className="text">Posi-Stop Offline</div>
			</div>
		</Wrapper>
	);
}
