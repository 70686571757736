/* eslint-disable consistent-return */
import { getEquipmentChartDate } from 'helpers/api/equipments';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import { useParams } from 'react-router-dom';

function DailyChartGeneral({ chart, subitem, date, datakey = '' }: any) {
	const { id } = useParams();
	const [data, setData] = React.useState([]);

	React.useEffect(() => {
		getEquipmentChartDate(id, date, chart).then((res) =>
			setData(res?.data?.equipmentChartData?.charts?.[chart]),
		);
	}, [chart, subitem, date]);
	// (computedData);

	const options = {
		type: 'line',
		title: false,
		subtitle: false,
		credits: {
			enabled: false,
		},
		chart: {
			zoomType: 'x',
		},
		series: [
			{
				name: subitem,
				showInLegend: false,
				color: 'rgba(0,131,77,1)',
				data: data?.map((we: any) => [
					new Date(we?.timestamp * 1000),
					parseFloat(we[chart] || we[datakey]),
				]),
			},
		],
		yAxis: {
			title: false,
			min: 0,
		},
		xAxis: {
			labels: {
				enabled: true,
			},
		},
		tooltip: {
			headerFormat: '<b>{series.name}</b><br/>',
			pointFormat: '{point.x}<br/>{point.y:.2f}',
		},
	};

	// (options);

	return (
		<div>
			{data ? (
				<HighchartsReact highcharts={Highcharts} options={options} />
			) : (
				<span>Loading ....</span>
			)}
		</div>
	);
}

export default DailyChartGeneral;
