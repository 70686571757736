import React from 'react';
import { Accordion, Card, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
	status,
	cameraStatus,
	posiStopStatus,
} from 'components/EquipmentHandler';
import NoData from 'components/NoData';
import { DeactiveButton } from 'style/GlobalStyles';
import capitalize from 'helpers/utils/capitalize';
import { TableContainer } from './tables.styled';
import { useDashboard } from './DashboardContext';

export default function MobileListView() {
	const { equipments, timezones, filteredResult } = useDashboard();
	const data = filteredResult || equipments;

	if (!equipments)
		return (
			<div className="no-result">
				<div>
					<Spinner animation="border" size="lg" />
				</div>
			</div>
		);

	if (equipments?.length === 0)
		return (
			<div className="no-result">
				<div>No equipments to be shown</div>
			</div>
		);

	if (filteredResult?.length === 0) {
		return <NoData />;
	}

	return (
		<TableContainer>
			{timezones?.map((zone) => (
				<Accordion defaultActiveKey={zone.name} key={zone.name}>
					<Accordion.Item eventKey={zone.name} key={zone.name} className="mt-3">
						<Accordion.Header>
							{zone.reg[0]} <small>{zone.name}</small>
						</Accordion.Header>
						<Accordion.Body>
							{zone.yards.map((y) => (
								<React.Fragment key={y}>
									<h2>{y}</h2>
									{data
										.filter((item) => item?.yard?.id === y)
										.map((eqItem) => (
											<Link
												to={
													eqItem.type === 'pump' || eqItem.type === 'swivel'
														? `/pumps/${eqItem.serial}`
														: `/rigs/${eqItem.serial}`
												}
												key={eqItem.serial}
												className={
													eqItem.status?.online ? '' : 'pointer-events-none'
												}
												onClick={(e) => {
													// eslint-disable-next-line no-unused-expressions
													eqItem?.status?.value === 'Not Activated' &&
														e.preventDefault();
												}}
											>
												<Card className="mobile">
													<div className="d-flex">
														<div>
															<span className="equipment-id">
																{eqItem.serial}
															</span>
														</div>
														<div className="ms-auto">
															<DeactiveButton>
																{capitalize(eqItem.type)}
															</DeactiveButton>
														</div>
													</div>

													<div className="d-flex mt-3">
														<span>{status(eqItem)}</span>

														<span className="mx-3">
															{cameraStatus(eqItem, true)}
														</span>

														<span>{posiStopStatus(eqItem)}</span>
													</div>
													{/* </div> */}
												</Card>
											</Link>
										))}
								</React.Fragment>
							))}
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			))}
		</TableContainer>
	);
}
