import { useMutation } from '@apollo/client';
import { ReactComponent as Right } from 'assets/icons/right-arrow.svg';
import DashboardLayout from 'components/Layouts/DashboardLayout';
import { getProjectById } from 'helpers/api/projectManagement';
import { GOOGLE_MAP_API_KEY } from 'helpers/const';
import { getToken } from 'helpers/utils/auth-provider';
import { showError } from 'helpers/utils/misc';
import { CreateProjectContainer } from 'pages/projectmanagement/project.styled';
import { useEffect, useRef, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import MapPicker from 'react-google-map-picker';
import toast from 'react-hot-toast';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { EDIT_PROJECT_BY_ID_MUTATION } from '../../helpers/queries/projectQuery';
import ChartSelection from './ChartSelection';
import EqSelection from './EqSelection';
import ProjectInfo from './ProjectInfo';

const DefaultLocation = { lat: 44.045213, lng: -123.081602 };

const INITIAL_STATE = {
	pname: '',
	pbegdate: '',
	penddate: '',
	pprojectid: '',
	pwellid: '',
	pclient: '',
	pcoordinates: `${DefaultLocation.lat}, ${DefaultLocation.lng}`,
	pwellname: '',
	pwellnumber: '',
	ppadname: '',
	pafe: '',
	ptubingsize: '',
	punit: '',
	passembly_length: '',
	pfloor_height: '',
	pkb_height: '',
	charts: [],
	eq_data: [],
	peqarry: [],
};

function ProjectMHeader() {
	return (
		<div
			className="mb-3 flex items-center"
			style={{
				cursor: 'pointer',
				maxWidth: 'fit-content',
			}}
		>
			<Right />
			<Link
				to="/dashboard"
				style={{
					textDecoration: 'none',
					color: 'black',
					paddingRight: '1.25rem',
					borderRight: '1px solid #dddddd',
				}}
			>
				<span className="ms-2">Back</span>
			</Link>
			<Link
				to="/projects"
				style={{
					textDecoration: 'none',
					color: 'black',
					paddingLeft: '0.75rem',
				}}
			>
				<span className="ms-2" style={{ color: '#00834D' }}>
					Projects
				</span>
				<span> {'>'} Edit project</span>
			</Link>
		</div>
	);
}

function MapComponent({ cordinates, onChange }) {
	if (!cordinates || typeof cordinates !== 'string') {
		return null;
	}
	const [lat, lng] = cordinates.trim().split(',');
	return (
		<MapPicker
			defaultLocation={{
				lat: lat ? Number(lat) : 44.045213,
				lng: lng ? Number(lng) : -123.081602,
			}}
			zoom={4}
			mapTypeId="roadmap"
			style={{ height: 300, maxWidth: 725, borderRadius: 12 }}
			onChangeLocation={onChange}
			apiKey={GOOGLE_MAP_API_KEY}
		/>
	);
}

export default function EditProject() {
	const { id } = useParams();
	const mountedRef = useRef(false);
	const [showMap, setShowMap] = useState(false);
	const [formData, setFormData] = useState(INITIAL_STATE);
	const navigate = useNavigate();

	const [editProjectById] = useMutation(EDIT_PROJECT_BY_ID_MUTATION, {
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
	});

	useEffect(() => {
		mountedRef.current = true;
		return () => {
			mountedRef.current = false;
		};
	});
	useEffect(() => {
		if (mountedRef.current) {
			setShowMap(true);
		}
	}, []);
	const updateFormState = (data) => {
		setFormData((prevState) => ({ ...prevState, ...data }));
	};

	const handleCordsMapChange = (lat, lng) => {
		updateFormState({ pcoordinates: `${lat},${lng}` });
	};
	const onChartSelectionChange = (data) => {
		updateFormState({ charts: data });
	};
	const onEqChange = (data) => {
		updateFormState(data);
	};
	const submit = (e) => {
		e.preventDefault();

		const editProjectData = {
			...formData,
			eq_data: JSON.stringify(formData.eq_data || []),
			charts: JSON.stringify(formData.charts || []),
			peqarry: JSON.stringify(formData.peqarry || []),
			clientusers: [],
			id: parseInt(id, 10),
		};
		try {
			editProjectById({
				variables: {
					...editProjectData,
				},
			});
			toast.success('Project edited successfully!');
			navigate('/projects');
		} catch (error) {
			toast.error(error.msg || 'Error editing entry');
		}
	};

	useEffect(() => {
		getProjectById(id)
			.then((d) => {
				if (d.error) {
					showError(d.error);
					navigate('/projects');
					return;
				}
				const data = d?.data?.projectById;
				const getChartData = () => {
					let tmp = [];
					try {
						tmp = JSON.parse(data?.charts);
						if (!Array.isArray(tmp)) {
							tmp = [];
						}
					} catch (e) {
						throw new Error(e);
					}
					return tmp;
				};

				setFormData({
					pname: data?.pname,
					pbegdate: data?.pbegdate,
					penddate: data?.penddate,
					pprojectid: data?.pprojectid,
					pwellid: data?.pwellid,
					pclient: data?.pclient,
					pcoordinates:
						data?.project_coordinates ||
						`${DefaultLocation.lat}, ${DefaultLocation.lng}`,
					pwellname: data?.pwellname,
					pwellnumber: data?.pwellnumber,
					ppadname: data?.ppadname,
					pafe: data?.pafe,
					ptubingsize: data?.ptubingsize,
					punit: data?.punit,
					passembly_length: data?.passembly_length,
					pfloor_height: data?.pfloor_height,
					pkb_height: data?.pkb_height,
					charts: getChartData(),
					eq_data: data?.eq_data,
				});
			})
			.catch((err) => {
				showError(err.response?.data.message || err.toString());
			});
	}, []);

	return (
		<DashboardLayout>
			<CreateProjectContainer>
				<div className="main-content">
					<ProjectMHeader />

					<div>
						<h1 className="assignment-header my-3">
							Edit Project {formData.pprojectid}
						</h1>
						<hr className="mb-5" />
					</div>

					<div>
						<ProjectInfo formData={formData} update={updateFormState} />
						<div className="my-4">
							{showMap ? (
								<MapComponent
									cordinates={formData.pcoordinates}
									onChange={handleCordsMapChange}
								/>
							) : null}
						</div>
						<EqSelection formData={formData} update={onEqChange} />
						<ChartSelection
							charts={formData.charts}
							onChartsChange={onChartSelectionChange}
						/>
						<Row className="process">
							<Button onClick={submit} className="primary-button ms-auto">
								Save
							</Button>
						</Row>
					</div>
				</div>
			</CreateProjectContainer>
			<div
				style={{
					paddingBottom: '100px',
				}}
			/>
		</DashboardLayout>
	);
}
