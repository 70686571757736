import styled, { createGlobalStyle } from 'styled-components';
import { Button } from 'react-bootstrap';

export const MyGlobalStyle = createGlobalStyle`

	.error {
		color: red;
		text-transform: capitalize;
	}
	body {
		margin: 0;
		font-family: 'Poppins', sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		background: #FCFCFC;
	}

  	code {
    	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      		monospace;
  	}

	/* .main-content{
		margin:2rem 2rem 2rem 18rem;
	} */

	@media (max-width: 992px) {
		.main-content{
			margin:1rem 1rem;
		}
	}
	#offcanvasNavbar{
		width: 80%;
		max-width: 295px;
	}
	/* .btn.btn-primary{
		background: ${({ theme }) => theme.colors.loginPrimary};
	} */

	/* Slider */

	.switch {
		position: relative;
		display: inline-block;
		width: 46px;
		height: 24px;
		vertical-align: middle;
		margin: 0rem 0.5rem;
	}

	.switch input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: white;
		-webkit-transition: 0.4s;
		transition: 0.4s;
		border: 1px solid #969697;
	}

	.slider:before {
		position: absolute;
		content: '';
		height: 18px;
		width: 18px;
		left: 4px;
		bottom: 2px;
		background: #969697;
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}

	input:checked + .slider {
		background-color: ${({ theme }) => theme.colors.loginPrimary};
		border: 1px solid white;
	}

	input:checked + .slider::before {
		background: white;
	}

	input:focus + .slider {
		box-shadow: 0 0 1px white;
	}

	input:checked + .slider:before {
		-webkit-transform: translateX(24px);
		-ms-transform: translateX(24px);
		transform: translateX(18px);
	}

	/* Rounded sliders */
	.slider.round {
		border-radius: 34px;
	}

	.slider.round:before {
		border-radius: 50%;
	}

	span {
		vertical-align: middle;
	}

	/* End of Slider */

	.g-1{
		gap: .5rem;
	}
	.pointer{
		cursor: pointer;
	}

	.primary-button {
			background: #00834d;
			border-radius: 12px;
			color: white;
			border: none;
			padding: 0.75rem 1.75rem;
			margin-right: 1rem;
			max-width: max-content;
			/* margin-left: auto; */
			&:hover {
				background: #00a859;
				color: white;
			}
		}

	.alt-button {
		background: white;
		border-radius: 12px;
		color: black;
		border: 1px solid #00834d;
		padding: 0.75rem 1.75rem;
		margin-right: 1rem;
		max-width: fit-content;
		/* margin-left: auto; */
	}

	.profile-modal {
		input, select, textarea {
			background: #F3F3F3;
			border-radius: 4px;
			border: none;
			height: 48px;
			padding: 0.75rem 1.125rem;
			width: 100%;
			/* margin-bottom: 1.5rem; */
		}

		.alt-button {
			margin-left: auto;
		}

		.no-message {
			display: grid;
			justify-content: center;
			height: 300px;
			align-content: center;

			span {
				font-style: normal;
				font-weight: 400;
				font-size: 1.25rem;
				opacity: 0.4;
			}
		}


		span {
			font-size: 0.875rem;
		}

		.modal-body {
			padding: 2.25rem;
		}

		.modal-content {
			border-radius: 20px;
		}

		.information {
			font-weight: 600;
			font-size: 1.5rem;
			line-height: 2.25rem;
			/* margin-bottom: 1.5rem; */
		}

		.serial {
			letter-spacing: 0.1em;
			font-weight: 600;
			font-size: 16px;
			padding: 0.5rem 0.875rem;
			background: #F2F2F2;
			border-radius: 4px;
		}

		.inputs {
			margin: 2.5rem 0rem;
		}

		.information-container {
			margin-bottom: 1.5rem;
		}

		.top-right {
			float: right;
			width: fit-content;
			font-weight: 400;
			font-size: 1rem;
		}

		.green {
			color: ${({ theme }) => theme.colors.loginPrimary};
			font-weight: 600;				
		}

		.use-value {
			color: ${({ theme }) => theme.colors.loginPrimary};
			font-weight: 600;
			font-size: 1rem;
			margin-left: 0.5rem;
		}

		.load {
			font-size: 1rem;
		}

		.calibrate {
			background: #FFFFFF;
			border: 1px solid ${({ theme }) => theme.colors.loginPrimary};
			color: ${({ theme }) => theme.colors.loginPrimary};
			box-sizing: border-box;
			border-radius: 4px;
			height: 40px;
			padding: 0.5rem 1.25rem;
		}

		.selection {
			border: none;
			background: transparent;
			justify-self: middle;
		}

		.button-wrapper {
			float: right;

			.close {
				border: 1px solid ${({ theme }) => theme.colors.loginPrimary};
				background: white;
				height: 56px;
				padding-left: 2rem;
				padding-right: 2rem;
				border-radius: 12px;
				margin-right: 1.25rem;
			}

			.save {
				border: none;
				background: ${({ theme }) => theme.colors.loginPrimary};
				color: white;
				height: 56px;
				padding-left: 2rem;
				padding-right: 2rem;
				border-radius: 12px;
			}
		}

		.message-board {
			float: none;

			input {
				height: 56px;
				border-radius: 12px 0px 0px 12px;
			}

			.save {
				border-radius: 0px 12px 12px 0px;
			}
		}

		h4 {
			font-size: 1rem;
			line-height: 100%;
			font-weight: bold;
		}

		.upload-image-drag {
			height: 202px;
			background: #F3F3F3;
			border: 1px dashed #000000;
			box-sizing: border-box;
			border-radius: 4px;
			display: grid;
			justify-content: center;
			align-items: center;
			padding: 1rem;

			.heading {
				font-family: 'Poppins';
				font-style: normal;
				font-weight: 400;
				font-size: 1.125rem !important;
				text-align: center;
			}

			p {
				text-align: center;
				font-family: 'Poppins';
				font-style: normal;
				font-weight: 400;
				font-size: 0.875rem;
				opacity: 0.5;
			}
		}

		.upload-image-drag {
			/* height: 100px; */
			background: white;
			border: 1px dashed ${({ theme }) => theme.colors.loginPrimary};
			box-sizing: border-box;
			border-radius: 4px;
			display: grid;
			justify-content: center;
			align-items: center;
			padding: 1rem;

			.heading {
				font-family: 'Poppins';
				font-style: normal;
				font-weight: 400;
				font-size: 1rem !important;
				text-align: center;
			}
		}

		.react-datepicker-wrapper {
			width: 100%;

			.react-datepicker__input-container {
				width: 100%;
			}

			.date-picker {
				width: 100%;
				height: 54px;
				border: none;
			}
		}

		@media (max-width: 450px) {
			.row {
				/* * {
					width: 50%;
				} */

				input, select, .calibrate {
					width: 100%;
				}

				input[type=radio] {
					width: 1.5rem;
				}
			}

			.message-board {
				display: block !important;

				.save {
					margin: 0px !important;
				}

				.close {
					float: right;
					margin-right: 0rem;
				}
			}

			.modal-body {
				padding: 1.5rem;
			}

			.calibrate {
				float: right;
			}

			.top-right {
				float: left;
			}

			.information-container {
				margin-bottom: 2.5rem;
				display: grid;

				.serial {
					max-width: fit-content;
					margin: 0.5rem 0rem;
				}
			}

			.load-container {
				width: 100%;
				margin-top: 1.5rem;
			}

			.button-wrapper {
				.save {
					margin-top: 1rem;
				}

				* {
					margin: 0.25rem;
				}
			}

			/* .calibrate-container {
				margin-top: 1rem;
			} */
		}
	}

	.checkbox-container {
		display: flex;
		flex-wrap: wrap;

		input {
			border: 1px solid #131313;
			box-sizing: border-box;
			border-radius: 4px;
			height: 24px;
			width: 24px;
			margin-right: 0.5rem;
		}

		span {
			margin-right: 1.5rem;

		}
	}

	.modal {
		backdrop-filter: blur(5px);
	}

	.display-grid {
		display: grid;
	}

	.h-86 {
		height: 86vh ;
	}

	.justify-items-center {
		justify-items: center;
	}

	.vertical-align-top {
		vertical-align: top;
	}

	.primary-button, .alt-button, .close, .save {
		transition: 0.2s ease-in-out !important;
		&:focus, &:hover {
			transform: scale(1.05) !important;
		}
	}

	.minw-100 {
		min-width: 100%;
	}

	.height-fit-content {
		height: fit-content;
	}

	.btn-close {
		z-index: 99;
	}

	/* Checkbox */
	/* Hide the browser's default checkbox */
	label input[type="checkbox"] {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
	}

	/* Create a custom checkbox */
	.checkmark {
	position: absolute;
	height: 25px;
	width: 25px;
	background-color: white;
	border: 1px solid #131313;
	border-radius: 4px;
	}

	/* On mouse-over, add a grey background color */
	label:hover input ~ .checkmark {
	background-color: #ccc;
	}

	/* When the checkbox is checked, add a blue background */
	label input:checked ~ .checkmark {
	background-color: #00834D;
	border: none;
	}

	/* Create the checkmark/indicator (hidden when not checked) */
	.checkmark:after {
	content: "";
	position: absolute;
	display: none;
	}

	/* Show the checkmark when checked */
	label input:checked ~ .checkmark:after {
	display: block;
	}

	/* Style the checkmark/indicator */
	label .checkmark:after {
	left: 9px;
	top: 4px;
    width: 8px;
    height: 14px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	}

	.ms-35 {
		margin-left: 2rem !important;
	}

	.g-1 {
		gap: 1rem 0rem;
	}

	.gray-text {
		color: #828282;
	}

	.align-self-center {
		align-self: center !important;
	}

	.cursor-pointer {
		cursor: pointer;
	}

	.mb-6 {
		margin-bottom: 6rem;
	}

	.justify-content-left {
		justify-content: left !important;
	}

	.text-align-left {
		text-align: left !important;
	}

	.gap-1{
		gap: 1rem !important;
	}

	input, select {
		&:focus, &:active, &:focus-visible {
			box-shadow: none !important;
			outline: 1px solid #14B573 !important;
		}
	}

	.form-control {
		appearance: auto;
	}

	::-webkit-scrollbar {
		width: 10px;
		border-radius: 10px;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #DDDDDD;
		border-radius: 10px;

	}

	input {
		border: 1px solid transparent !important;
		background: #F3F3F3;

		&:hover {
			border: 1px solid #BEBEBE !important;
			background: #F7F7F7;
		}

		&:disabled {
			opacity: 0.6 !important;
		}
	}
`;

export const WarningButton = styled(Button)`
	background-color: ${({ theme }) => theme.colors.warning};
	color: ${({ theme }) => theme.colors.warningText};
	border: none;
	font-size: 0.813rem;
	padding: 0.25rem 0.438rem;
	font-weight: 500;
	width: fit-content;
	box-shadow: none;
	transition: 0s;

	&:hover,
	&:focus,
	&:active {
		background-color: ${({ theme }) => theme.colors.warning};
		color: ${({ theme }) => theme.colors.warningText};
		border: none;
		cursor: default;
		box-shadow: none !important;
	}
`;

export const SuccessButton = styled(Button)`
	color: ${({ theme }) => theme.colors.successText};
	background-color: ${({ theme }) => theme.colors.success};
	border: none;
	font-size: 0.813rem;
	padding: 0.25rem 0.438rem;
	font-weight: 500;
	width: fit-content;
	box-shadow: none;
	transition: 0s;

	&:hover,
	&:focus,
	&:active {
		color: ${({ theme }) => theme.colors.successText};
		background-color: ${({ theme }) => theme.colors.success};
		border: none;
		cursor: default;
		box-shadow: none !important;
	}
`;

export const DeactiveButton = styled(Button)`
	color: ${({ theme }) => theme.colors.deactiveText};
	background-color: ${({ theme }) => theme.colors.deactive};
	border: none;
	font-size: 0.813rem;
	padding: 0.25rem 0.438rem;
	font-weight: 500;
	width: fit-content;
	box-shadow: none;
	transition: 0s;

	&:hover,
	&:focus,
	&:active {
		color: ${({ theme }) => theme.colors.deactiveText};
		background-color: ${({ theme }) => theme.colors.deactive};
		border: none;
		cursor: default;
		box-shadow: none !important;
	}
`;
