import { yupResolver } from '@hookform/resolvers/yup';
import { updateEquipmentInfoPump } from 'helpers/api/equipmentProfile';
import useResponsive from 'helpers/hooks/useResponsive';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

function ErrorMessage({ children }) {
	return <small className="text-danger mt-2 d-block">{children}</small>;
}

export default function ProfilePopup({ data, open, onClose }) {
	const { id } = useParams();
	const { isMobile } = useResponsive();
	const [loading, setLoading] = useState(false);

	const schema = yup
		.object({
			pump_adjusment: yup.string().required('Pump adjustment is required.'),
			flow_rate: yup.string().required('Flow rate is required.'),
			flow_unit: yup.string().required('Flow unit is required.'),
			max_suction_pressure: yup
				.string()
				.required('Max suction pressure is required.'),
			max_discharge_pressure: yup
				.string()
				.required('Max discharge pressure is required.'),
			air_pressure: yup.string().required('Air pressure is required.'),
			zerothreshold_pump_discharge_pressure: yup
				.string()
				.required('Zero threshold discharge is required.'),
			zerothreshold_pump_suction_pressure: yup
				.string()
				.required('Zero threshold suction is required.'),
		})
		.required();

	const { register, handleSubmit, formState, reset } = useForm({
		resolver: yupResolver(schema),
	});

	const closeModal = () => {
		onClose();
	};

	useEffect(() => {
		if (open === true) {
			reset({
				pump_adjusment: data?.data?.[0]?.pump_adjusment,
				flow_rate: data?.data?.[0]?.flow_rate,
				flow_unit: data?.data?.[0]?.flow_unit,
				max_suction_pressure: data?.data?.[0]?.max_suction_pressure,
				max_discharge_pressure: data?.data?.[0]?.max_discharge_pressure,
				air_pressure: data?.data?.[0]?.air_pressure,
				zerothreshold_pump_discharge_pressure:
					data?.data?.[0]?.zerothreshold_pump_discharge_pressure,
				zerothreshold_pump_suction_pressure:
					data?.data?.[0]?.zerothreshold_pump_suction_pressure,
			});
		}
	}, [open]);

	const onSubmit = (updatedData) => {
		setLoading(true);
		updateEquipmentInfoPump(id, updatedData)
			.then(() => {
				toast.success('Changes successfully applied');
				setLoading(false);
			})
			.catch((error) => {
				toast.error(error.response.message);
				setLoading(false);
			});
	};
	const { errors } = formState;

	return (
		<div className="profile-container">
			<Modal
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={open}
				onHide={onClose}
				className="profile-modal"
			>
				<Modal.Body>
					{isMobile ? (
						<div className="information-container">
							<span className="information">Equipment Profile</span>
							<span className="top-right">
								{data?.data?.[0].type.toUpperCase()}{' '}
								<span className="green">{data?.data?.[0].serial ?? '-'}</span>
							</span>
						</div>
					) : (
						<div className="information-container">
							<span className="information">Equipment Profile</span>
							<span className="top-right">
								{data?.data?.[0].type.toUpperCase()}{' '}
								<span className="green">{data?.data?.[0].serial ?? '-'}</span>
							</span>
						</div>
					)}

					<Form onSubmit={handleSubmit(onSubmit)}>
						<Row className="inputs">
							<Col lg={6}>
								<Form.Group className="mb-3">
									<span>Targets per Rotation</span>

									<input type="text" {...register('pump_adjusment')} />
									<ErrorMessage>{errors.pump_adjusment?.message}</ErrorMessage>
								</Form.Group>
							</Col>
							<Col lg={6}>
								<Form.Group className="mb-3">
									<span>Displacement per Rotation</span>

									<input type="text" {...register('flow_rate')} />
									<ErrorMessage>{errors.flow_rate?.message}</ErrorMessage>
								</Form.Group>
							</Col>
							<Col lg={6}>
								<Form.Group className="mb-3">
									<span>Transducer Suction Max PSI</span>

									<input type="text" {...register('max_suction_pressure')} />
									<ErrorMessage>
										{errors.max_suction_pressure?.message}
									</ErrorMessage>
								</Form.Group>
							</Col>
							<Col lg={6}>
								<Form.Group className="mb-3">
									<span>Transducer Discharge Max PSI</span>

									<input type="text" {...register('max_discharge_pressure')} />
									<ErrorMessage>
										{errors.max_discharge_pressure?.message}
									</ErrorMessage>
								</Form.Group>
							</Col>
							<Col lg={6}>
								<Form.Group className="mb-3">
									<span>Air Transducer Suction Max PSI</span>

									<input type="text" {...register('air_pressure')} />
									<ErrorMessage>{errors.air_pressure?.message}</ErrorMessage>
								</Form.Group>
							</Col>
							<Col lg={6}>
								<Form.Group className="mb-3">
									<span>Unit of Measure</span>

									<select {...register('flow_unit')}>
										<option>BPM</option>
										<option>GPM</option>
									</select>

									{/* <input
											type="text"
											{...register('well_psi_pressure')}
										/> */}

									<ErrorMessage>{errors.flow_unit?.message}</ErrorMessage>
								</Form.Group>
							</Col>

							<div className="mb-3">
								<h4>Zero Threshold</h4>
							</div>

							<Col lg={4}>
								<Form.Group className="mb-3">
									<span>Zero Threshold Discharge</span>

									<input
										type="text"
										{...register('zerothreshold_pump_discharge_pressure')}
									/>

									<ErrorMessage>
										{errors.zerothreshold_pump_discharge_pressure?.message}
									</ErrorMessage>
								</Form.Group>
							</Col>
							<Col lg={4}>
								<Form.Group className="mb-3">
									<span>Zero Threshold Suction</span>

									<input
										type="text"
										{...register('zerothreshold_pump_suction_pressure')}
									/>

									<ErrorMessage>
										{errors.zerothreshold_pump_suction_pressure?.message}
									</ErrorMessage>
								</Form.Group>
							</Col>
						</Row>

						<div className="button-wrapper">
							<Button
								type="button"
								variant="bordered-green"
								className="me-3"
								onClick={closeModal}
							>
								Close
							</Button>
							<Button variant="primary-green" type="submit">
								{loading ? <Spinner size="sm" animation="border" /> : 'Save'}
							</Button>
						</div>
					</Form>
				</Modal.Body>
			</Modal>
		</div>
	);
}
