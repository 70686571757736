import styled from 'styled-components';

export const Header = styled.div`
	background: #2c2c2c;
	border-radius: 4px;
	padding: 0.75rem;
	margin: 2rem 0rem;

	h5 {
		color: white;
		margin: 0rem;
		font-size: 1rem;
		font-weight: 500;
	}
`;
export const Wrapper = styled.div`
	/* margin: ${(props) =>
		props.isTablet || props.isMobile ? '0 1rem' : '2rem 2rem 2rem 18rem;'}; */

	ul {
		border: none;
		border-bottom: 1px solid #e6e6e6;
	}

	button {
		border: none;
		box-shadow: none;
		padding-right: 2rem;
		padding-left: 2rem;
		box-shadow: none;
		transition: 0s ease-in-out;
		color: #9aa2ac;

		&:hover,
		&:active {
			border: none;
			box-shadow: none;
		}
	}

	.nav-link.active {
		background-color: transparent;
		border: none;
		border-bottom: 3px solid #0035bf;
	}

	.row {
		gap: 1rem 0rem;
	}

	input,
	select {
		background-color: #f3f3f3;
		border-radius: 4px;
		border: none;
		padding: 12px 12px 12px 18px;
	}

	h1 {
		font-family: 'Poppins', sans-serif;
		font-style: normal;
		font-weight: 700;
		font-size: 1.75rem;
		text-transform: capitalize;
	}

	@media (max-width: 450px) {
		li {
			button {
				padding-left: 0.5rem;
				padding-right: 0.5rem;
			}
		}
	}
`;
export const Footer = styled.div`
	background: #ffffff;
	box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
	width: 100vw;
	position: fixed;
	bottom: 0px;
	left: 0px;
	padding: 0.5rem 3rem;

	button {
		color: white;
		transition: 0.3s ease-in-out;
	}
`;
