import { gql } from '@apollo/client';

export const PIPELINE_TALLY_DATA_BY_PROJECT_ID_QUERY = gql`
	query PipeTallyDataByprojectId($id: String!) {
		pipeTallyDataByprojectId(id: $id) {
			total_joints_in_hole
			total_joints_out_hole
			total_project_joints
			cumulative_depth
			data {
				id
				projectid
				tally_in
				tally_out
				pipe_length
				damage_code
			}
		}
	}
`;

export const ADD_PIPE_TALLY_DATA_MUTATION = gql`
	mutation AddPipeTallyData(
		$projectid: String!
		$datetime: String!
		$tally_in: String!
		$tally_out: String!
		$pipe_length: String!
		$damage_code: String!
	) {
		addPipeTallyData(
			pipeTallyDataInput: {
				projectid: $projectid
				datetime: $datetime
				tally_in: $tally_in
				tally_out: $tally_out
				pipe_length: $pipe_length
				damage_code: $damage_code
			}
		) {
			projectid
			userid
			datetime
			tally_in
			tally_out
			pipe_length
			damage_code
		}
	}
`;
