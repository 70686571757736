import { ReactComponent as Right } from 'assets/icons/right-arrow.svg';
import DetailsLayout from 'components/Layouts/DashboardLayout';
import DetailsInfo from 'pages/details/pump/Header';
import { DetailsContainer } from 'pages/details/styled';
import { Nav } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled(DetailsContainer)`
	.back-button {
		padding-bottom: 0.75rem;
		margin-right: 1rem;
	}
	.back-link {
		margin-right: 0.75rem;
		color: ${({ theme }) => theme.colors.black};
		text-decoration: none;
	}
	.nav-view {
		margin-bottom: 1.3rem !important;
		margin-top: 1.3rem;
		flex: 1;

		a {
			margin-right: 1.5rem;
		}
	}
	.cards-full-height {
		min-height: 262px;
		height: auto;
	}
`;
function PumpLayout({ children }) {
	const { id } = useParams();
	const location = useLocation().pathname;

	return (
		<DetailsLayout>
			<Wrapper>
				<DetailsInfo />
				<div className="d-flex align-items-center">
					<div className="d-flex align-items-center back-button">
						<Right />
						<Link to="/dashboard" className="back-link">
							<span className="ms-2">BACK</span>
						</Link>
					</div>
					<Nav className="nav-view">
						<Nav.Item>
							<Link
								to={`/pumps/${id}`}
								className={location === `/pumps/${id}` ? 'active' : ''}
							>
								DETAILS
							</Link>
						</Nav.Item>
						<Nav.Item>
							<Link
								to={`/pumps/${id}/charts`}
								className={location === `/pumps/${id}/charts` ? `active` : ' '}
							>
								ACTIVITY TABLE + GRAPHS
							</Link>
						</Nav.Item>
						{/* <Nav.Item>
							<Link
								to={`/rigs/${id}/engine`}
								className={location === `/rigs/${id}/engine` ? 'active' : null}
							>
								ENGINE
							</Link>
						</Nav.Item> */}
						<Nav.Item>
							<Link
								style={{ pointerEvents: 'none' }}
								to={`/pumps/${id}/docsys`}
								className={location === `/pumps/${id}/docsys` ? 'active' : ''}
							>
								DOCSYS
							</Link>
						</Nav.Item>
					</Nav>
				</div>
				{children}
			</Wrapper>
		</DetailsLayout>
	);
}

export default PumpLayout;
