import Login from 'pages/login';
import Reset from 'pages/login/resetIndex';
import ChangePassword from 'pages/login/ChangePass';
import CancelAlerts from 'pages/login/CancelAlerts';
import Page404 from 'pages/page404';

const PublicRoutes = [
	{
		id: 'login',
		path: '/',
		component: Login,
		exact: true,
		private: false,
	},
	{
		id: 'reset',
		path: '/forgot',
		component: Reset,
		exact: true,
		private: false,
	},
	{
		id: 'changePassword',
		path: '/reset',
		component: ChangePassword,
		exact: true,
		private: false,
	},
	{
		id: 'page404',
		path: '/404',
		component: Page404,
		exact: true,
		private: false,
	},
	{
		id: 'cancelalerts',
		path: '/cancel',
		component: CancelAlerts,
		exact: true,
		private: false,
	},
];

export default PublicRoutes;
