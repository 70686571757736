import { ReactComponent as NoDataImage } from 'assets/img/noData.svg';

export default function NoData() {
	return (
		<div className="text-center d-grid justify-content-center mt-5 align-content-center">
			<NoDataImage />
			<span className="mt-3 fw-bold">No Data Found</span>
		</div>
	);
}
