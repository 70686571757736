import { getEquipmentActivity } from 'helpers/api/equipments';
import { Spinner, Table } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { ActivityContainer } from '../styled';

export default function TableActivityHourly({ id, UOM, prettifyCategory }) {
	const { data, isFetching } = useQuery(
		'tableactivityhourly',
		() => getEquipmentActivity(id, true),
		{
			refetchOnMount: true,
			enabled: !!id,
		}
	);

	if (isFetching)
		return (
			<ActivityContainer loader>
				<Spinner animation="border" className="mb-5" />{' '}
			</ActivityContainer>
		);

	if (data?.error)
		return (
			<ActivityContainer>
				<h6 className="text-center mt-5">{data?.error}</h6>
			</ActivityContainer>
		);

	const HOURS = Object.keys(data?.drawdata);
	const CATEGORY = Object.keys(data?.drawdata?.[0]);

	return (
		<Table responsive>
			<thead>
				<tr>
					<th style={{ minWidth: 250 }}>Category</th>
					<th style={{ minWidth: 150 }}>UOM</th>
					{HOURS.map((h) => <th key={h}>{h}:00</th>).reverse()}
				</tr>
			</thead>
			<tbody>
				{CATEGORY?.map((item) => (
					<tr key={item}>
						<td>{prettifyCategory(item)}</td>
						<td>{UOM[item]}</td>
						{HOURS.map((h) => (
							<td key={h}>{data?.drawdata?.[h]?.[item]}</td>
						)).reverse()}
					</tr>
				))}
			</tbody>
		</Table>
	);
}
