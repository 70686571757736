import { getProjectEquipmentList } from 'helpers/api/projectManagement';
import useResponsive from 'helpers/hooks/useResponsive';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import styled from 'styled-components';

const Card = styled.div`
	background: #ffffff;
	box-shadow: 0px 4px 73px rgba(0, 0, 0, 0.06);
	border-radius: 8px;
	padding: 1.25rem;
	height: 600px;
	overflow-y: auto;

	input[type='date'] {
		max-width: 160px;
	}

	input[type='checkbox'] {
		&:checked {
			background-color: ${({ theme }) => theme.colors.loginPrimary};
			border-color: ${({ theme }) => theme.colors.loginPrimary};
		}
	}
	input[type='radio'] {
		&:checked {
			background-color: ${({ theme }) => theme.colors.loginPrimary};
			border-color: ${({ theme }) => theme.colors.loginPrimary};
		}
	}
`;

const CustomFormCheck = styled(Form.Check)`
	input {
		width: 24px;
		height: 24px;
		padding: 0px;
	}
`;

function EqSelection({ formData, update }) {
	const [selectedRig, setSelectedRig] = useState([]);
	const [selectedPumps, setSelectedPumps] = useState([]);
	const { isMobile } = useResponsive();
	const { data, isLoading } = useQuery(
		'projectEquipments',
		getProjectEquipmentList,
	);

	useEffect(() => {
		setSelectedRig(formData?.eq_data?.rigs);
		setSelectedPumps(formData?.eq_data?.pumps);
	}, [formData]);

	const handleSelectedRig = (e) => {
		const { value } = e.target;
		setSelectedRig([
			{
				serial: value,
			},
		]);
	};
	const handleSelectedPumps = (e) => {
		const { value, checked } = e.target;
		if (checked) {
			setSelectedPumps([
				...selectedPumps,
				{
					serial: value,
				},
			]);
		} else {
			const newSelectedPumps = selectedPumps.filter(
				(pump) => pump.serial !== value,
			);
			setSelectedPumps(newSelectedPumps);
		}
	};
	const handleDateChange = (type) => (e) => {
		const { id, name, value } = e.target;
		// Set selected rig date to selectedRig array
		if (type === 'rig') {
			setSelectedRig((prevState) =>
				prevState.map((rig) => {
					if (rig.serial === id) {
						return {
							...rig,
							[name]: value,
						};
					}
					return rig;
				}),
			);
		} else {
			setSelectedPumps((prevState) =>
				prevState.map((pump) => {
					if (pump.serial === id) {
						return {
							...pump,
							[name]: value,
						};
					}
					return pump;
				}),
			);
		}
	};

	const onSubmit = (e) => {
		e.preventDefault();
		const selectedEquipment = [...selectedPumps, ...selectedRig];
		const selectedEquipmentSerials = selectedEquipment?.map((eq) => eq.serial);
		update({
			eq_data: selectedEquipment,
			peqarry: selectedEquipmentSerials,
		});
	};

	if (isLoading) {
		// empty ui no equipments
		return false;
	}

	return (
		<Form onSubmit={onSubmit}>
			<h5 className="m-4 mb-3">Select a rig and up to two pumps</h5>
			<Row>
				<Col lg={6}>
					<h5>Rigs</h5>
					<Card>
						{data
							?.filter((eq) => eq.eq_type === 'rig')
							.map((eq) => (
								<div
									className={isMobile ? 'mb-3' : 'd-flex mb-3'}
									key={eq.serial}
								>
									<Form.Group
										key={eq.serial}
										controlId={`${eq.id}`}
										className="d-flex"
									>
										<CustomFormCheck
											type="radio"
											label={eq.eq_name}
											name="rig"
											className="mt-2"
											value={eq.serial}
											onChange={handleSelectedRig}
											defaultChecked={formData?.eq_data?.rigs?.some(
												(formEq) =>
													formEq.serial.toString() === eq.serial.toString(),
											)}
										/>
										<Form.Label className="ps-2 align-self-center mb-0">
											{eq.serial}
										</Form.Label>
									</Form.Group>
									<Form.Control
										type="date"
										name="begdate"
										id={eq.serial}
										className={isMobile ? 'mt-2' : 'ms-auto'}
										disabled={
											!selectedRig?.find((item) => item.serial === eq.serial)
										}
										defaultValue={
											selectedRig?.find((item) => item.serial === eq.serial)
												?.start
										}
										min={formData.pbegdate}
										max={formData.penddate}
										onChange={handleDateChange('rig')}
									/>
									<Form.Control
										type="date"
										name="enddate"
										id={eq.serial}
										className={isMobile ? 'mt-2' : 'ms-3'}
										disabled={
											!selectedRig?.find((item) => item.serial === eq.serial)
										}
										defaultValue={
											selectedRig?.find((item) => item.serial === eq.serial)
												?.end
										}
										min={formData.pbegdate}
										max={formData.penddate}
										onChange={handleDateChange('rig')}
									/>
								</div>
							))}
					</Card>
				</Col>
				<Col lg={6}>
					<h5>Pumps</h5>
					<Card>
						{data
							?.filter((eq) => eq.eq_type === 'pump')
							.map((eq) => (
								<div
									className={isMobile ? 'mb-3' : 'd-flex mb-3'}
									key={eq.serial}
								>
									<Form.Group
										key={eq.serial}
										controlId={`${eq.id}`}
										className="d-flex"
									>
										<CustomFormCheck
											type="checkbox"
											label={eq.eq_name}
											name="rig"
											className="mt-2"
											value={eq.serial}
											checked={
												!!selectedPumps?.find((i) => i.serial === eq.serial)
											}
											disabled={
												selectedPumps?.length >= 2 &&
												!selectedPumps?.find((i) => i.serial === eq.serial)
											}
											onChange={handleSelectedPumps}
										/>
										<Form.Label className="ps-2 align-self-center mb-0">
											{eq.serial}
										</Form.Label>
									</Form.Group>
									<Form.Control
										type="date"
										name="begdate"
										id={eq.serial}
										className={isMobile ? 'mt-2' : 'ms-auto'}
										disabled={
											!selectedPumps?.find((item) => item.serial === eq.serial)
										}
										defaultValue={
											selectedPumps?.find((item) => item.serial === eq.serial)
												?.start
										}
										min={formData.pbegdate}
										max={formData.penddate}
										onChange={handleDateChange('pump')}
									/>
									<Form.Control
										type="date"
										name="enddate"
										id={eq.serial}
										className={isMobile ? 'mt-2' : 'ms-3'}
										disabled={
											!selectedPumps?.find((item) => item.serial === eq.serial)
										}
										defaultValue={
											selectedPumps?.find((item) => item.serial === eq.serial)
												?.end
										}
										min={formData.pbegdate}
										max={formData.penddate}
										onChange={handleDateChange('pump')}
									/>
								</div>
							))}
					</Card>
				</Col>
			</Row>
			<div className="mt-4">
				<Button variant="primary" type="button" onClick={onSubmit}>
					Save
				</Button>
			</div>
		</Form>
	);
}

export default EqSelection;
