import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import styled from 'styled-components';

const TabsWrapper = styled.div`
	.nav-item {
		button {
			margin-left: 0;
			margin-right: 1rem;
			padding-bottom: 0.75rem;
			font-weight: 500;
		}
		.active {
			border-bottom: 2px solid #0035bf;
		}
	}
`;

function ChartTabs({ tabs, onChange, selectedTab }) {
	return (
		<TabsWrapper className="mb-5">
			<Tabs
				id="controlled-tab-example"
				activeKey={selectedTab}
				onSelect={(k) => onChange(k)}
			>
				{tabs.map((tab) => (
					<Tab key={tab.key} eventKey={tab.key} title={tab.title} />
				))}
			</Tabs>
		</TabsWrapper>
	);
}

export default ChartTabs;
