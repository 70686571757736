import React, { useEffect } from 'react';
import { Accordion, Col, Row, Spinner } from 'react-bootstrap';

export default function Equipments({
	setEqs,
	eqs,
	user,
	equipments,
	timezones,
}) {
	const handleCheckbox = (e) => {
		const item = e.target.id;
		if (eqs.includes(item)) {
			setEqs((prev) => prev.filter((eq) => eq !== item));
		} else {
			setEqs((prev) => [...prev, item]);
		}
	};
	const toggleYards = (y) => (e) => {
		const eqItems = equipments
			?.filter((eq) => eq?.equip_data?.yard?.id === y)
			.map((eqi) => eqi?.equip_data.serial);
		if (e.target.checked && eqItems.length > 0) {
			setEqs((prev) => [...prev, ...eqItems]);
		} else {
			setEqs((prev) => prev?.filter((eq) => !eqItems.includes(eq)));
		}
	};

	useEffect(() => {
		setEqs(user?.equipments?.map((eq) => eq.equipment));
	}, [user]);

	if (typeof equipments === 'undefined') {
		return (
			<Spinner
				animation="border"
				size="sm"
				className="mx-auto"
				style={{ display: 'block' }}
			/>
		);
	}

	return timezones?.map((zone) => (
		<Accordion key={zone.name}>
			<Accordion.Item eventKey={zone.name} key={zone.name} className="mb-3">
				<Accordion.Header>
					{zone.reg[0]} <small>{zone.name}</small>
				</Accordion.Header>
				<Accordion.Body className="p-0">
					{zone.yards.map((y) => (
						<React.Fragment key={y}>
							<Row className="assignment-row-container">
								<Col lg={12} className="assignment-row">
									<label htmlFor={y} className="cursor-pointer">
										<input
											type="checkbox"
											className="mb-0"
											id={y}
											style={{ width: '16px', height: '16px' }}
											onChange={toggleYards(y)}
										/>
										<span className="checkmark" />
										<h1 className="ms-35 mt-1">{y}</h1>
									</label>
									{/* <small>PTS100</small> */}
								</Col>
							</Row>

							<Row className="assignment-row-container my-3 gap-2">
								{equipments
									.filter((item) => item?.equip_data?.yard?.id === y)
									.map((eqItem) => (
										<Col
											lg={3}
											key={eqItem?.equip_data?.serial}
											className="assignment-item"
										>
											<label
												htmlFor={eqItem?.equip_data?.serial}
												className="cursor-pointer"
											>
												<input
													type="checkbox"
													className="mb-0"
													id={eqItem?.equip_data?.serial}
													checked={eqs.includes(eqItem?.equip_data?.serial)}
													name={eqItem?.equip_data?.yard?.id}
													style={{ width: '16px', height: '16px' }}
													onChange={handleCheckbox}
												/>
												<span className="checkmark" />
												<span className="equipment-id ms-35">
													{eqItem?.equip_data?.serial}
												</span>
											</label>
										</Col>
									))}
							</Row>
						</React.Fragment>
					))}
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	));
}
