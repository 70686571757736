import { gql } from '@apollo/client';

export const CLIENTS_BY_COMPANY_ID_QUERY = gql`
	query ClientsByCustomerId {
		clientsByCustomerId {
			clid
			clcid
			clname
		}
	}
`;

export const ADD_CLIENTS_DATA_MUTATION = gql`
	mutation AddClientsData(
		$name: String!
		$address1: String!
		$address2: String!
		$city: String!
		$state: String!
		$zipcode: String!
		$contact: String!
		$phone: String!
		$fax: String!
		$email: String!
		$notes: String!
	) {
		addClientsData(
			clientsInput: {
				name: $name
				address1: $address1
				address2: $address2
				city: $city
				state: $state
				zipcode: $zipcode
				contact: $contact
				phone: $phone
				fax: $fax
				email: $email
				notes: $notes
			}
		) {
			clid
			clcustomid
			clname
			claddress1
			claddress2
			clcity
			clstate
			clzip
			clcontact
			clfax
			clemail
			clnotes
			clcid
		}
	}
`;
