import { ReactComponent as Col2Icon } from 'assets/icons/2-cols.svg';
import { ReactComponent as Col3Icon } from 'assets/icons/3-cols.svg';
import { ReactComponent as Col4Icon } from 'assets/icons/4-cols.svg';
import { ReactComponent as PosiStop } from 'assets/icons/posistop.svg';
import useResponsive from 'helpers/hooks/useResponsive';
import styled from 'styled-components';

const Layout = styled.section`
	text-align: right;
	padding-bottom: 0.5em;
	display: flex;
	justify-content: flex-end;
	button {
		font-size: small;
		background-color: #e5e5e500;
		border: 0px solid black;
		border-left: 1px solid black;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		height: 1.75em;
		display: flex;
		gap: 0.25rem;
		svg {
			width: 1.5em;
			height: 1.5em;
		}
	}
`;

export default function CamFeedHeader({
	handleCols,
	view,
	handleView,
}: // viewPosi,
{
	handleCols: (display: number) => void;
	view: string;
	handleView: () => void;
	// viewPosi: boolean;
}) {
	const { isXLDesktop } = useResponsive();

	return (
		<Layout>
			<button
				title="Toggle Posi-Stop Display"
				style={{ border: 'none' }}
				type="button"
				onClick={handleView}
			>
				{view === 'cameras' ? 'PosiStop' : 'Cameras'}
				<PosiStop />
			</button>
			<button title="2 Columns" type="button" onClick={() => handleCols(6)}>
				2 Cols <Col2Icon />
			</button>
			<button title="3 Columns" type="button" onClick={() => handleCols(4)}>
				3 Cols <Col3Icon />
			</button>
			{/* {!viewPosi && ( */}
			{isXLDesktop && (
				<button title="4 Columns" type="button" onClick={() => handleCols(3)}>
					4 Cols <Col4Icon />
				</button>
			)}
			{/* )} */}
		</Layout>
	);
}
