import DashboardLayout from 'components/Layouts/DashboardLayout';
import Dashboard from './Dashboard';
import { DashboardContextProvider } from './DashboardContext';

// eslint-disable-next-line func-names
export default function () {
	return (
		// eslint-disable-next-line react/jsx-filename-extension
		<DashboardLayout>
			<DashboardContextProvider>
				<Dashboard />
			</DashboardContextProvider>
		</DashboardLayout>
	);
}
