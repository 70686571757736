import { ReactComponent as Calendar404 } from 'assets/img/404calendar.svg';
import { useRigDetail } from 'helpers/contexts/RigDetailProvider';
import DetailsCard from 'pages/details/DetailsCard';
import styled from 'styled-components';

const EventsWrapper = styled(DetailsCard)`
	.no-message-text {
		color: ${({ theme }) => theme.colors.gray3};
		font-size: 0.857rem;
	}
	.date {
		font-size: 0.857rem;
	}
	.data {
		font-size: 0.857rem;
	}
`;

export default function Events() {
	const { data }: any = useRigDetail();

	return data?.status?.eventsnew?.length === 0 ? (
		<EventsWrapper
			className="mb-0 cards cards-full-height flex-grow-1"
			title="Today's Rig Events"
			print
			noalign
		>
			<div className="d-grid">
				<Calendar404 className="event-404" />
				<span className="text-center mt-3 no-message-text">No Events</span>
			</div>
		</EventsWrapper>
	) : (
		<EventsWrapper
			className="cards cards-full-height flex-grow-1"
			title="Today's Rig Events"
			print
			noalign
			style={{ overflowY: 'scroll' }}
		>
			{data?.status?.eventsnew?.map((msg) => (
				<div key={msg.time}>
					<span className="date">{msg.date.split(' ')[1]}</span>
					<span className="data">{msg.message}</span>
				</div>
			))}
		</EventsWrapper>
	);
}
