import { useEffect } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';

export default function Projects({
	projs,
	setProjs,
	availableProjs,
	user,
	isLoadingProjects,
}) {
	/**
	 * Handles changes to the list of selected projects.
	 *
	 * When a project checkbox is checked, the project ID is added to the `projs` state.
	 * When a project checkbox is unchecked, the project ID is removed from the `projs` state.
	 * The `projs` state is updated to contain a unique set of project IDs.
	 *
	 * @param {React.ChangeEvent<HTMLInputElement>} e - The change event from the project checkbox.
	 */
	const handleProjectCheckbox = (e) => {
		let newProList = [...projs, parseInt(e.target.id, 10)];
		if (e.target.checked === false) {
			newProList = newProList.filter(
				(pid) => pid !== parseInt(e.target.id, 10),
			);
		}
		setProjs([...new Set(newProList)]);
	};

	useEffect(() => {
		setProjs(user?.projects?.map((project) => project.project) || []);
	}, [user]);

	if (isLoadingProjects) {
		return (
			<div className="text-center mt-5">
				<Spinner
					animation="border"
					size="sm"
					className="mx-auto mt-4"
					style={{ display: 'block' }}
				/>
				<p className="mt-3">Loading projects...</p>
			</div>
		);
	}

	return (
		<div className="mb-5 mt-4">
			<Row className="assignment-row-container">
				{availableProjs?.map((item) => (
					<Col lg={12} key={item.pid} className="assignment-row mb-3">
						<label htmlFor={item.pid} className="d-flex cursor-pointer">
							<input
								type="checkbox"
								className="mb-0"
								id={item.pid}
								name={item.pid}
								style={{ width: '16px', height: '16px' }}
								checked={projs?.includes(item.pid)}
								onChange={handleProjectCheckbox}
							/>
							<span className="checkmark" />
							<div className="d-flex ms-35 mt-1">
								<h1 className="m-0">{item.pprojectid}</h1>
								<small>{item.pname}</small>
							</div>
						</label>
					</Col>
				))}
			</Row>
		</div>
	);
}
