import { ReactComponent as AlertIcon } from 'assets/icons/alert-management.svg';
import { ReactComponent as CompanyIcon } from 'assets/icons/company.svg';
import { ReactComponent as EquipmentIcon } from 'assets/icons/equipment.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/home.svg';
import LogoMinify from 'assets/icons/logo-minify-new.svg';
import Logo from 'assets/icons/new-logo.svg';
import { ReactComponent as ProjectIcon } from 'assets/icons/projectmanagement.svg';
import { ReactComponent as ReportIcon } from 'assets/icons/reports.svg';
import { ReactComponent as ResourcesIcon } from 'assets/icons/resources.svg';
import { ReactComponent as ChevronIcon } from 'assets/icons/right-arrow.svg';
import { ReactComponent as SettingIcon } from 'assets/icons/settings.svg';
import { ReactComponent as UserIcon } from 'assets/icons/usermanagement.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/video.svg';
import GeneralProfile from 'components/Profile/GeneralProfile';
import TooltipHandler from 'components/TooltipHandler';
import { useAuth } from 'helpers/contexts/AuthContext';
import useResponsive from 'helpers/hooks/useResponsive';
import metadata from 'metadata/metadata.json';
import Company from 'pages/company/CompanyModal';
import ReportsForm from 'pages/reports/ReportsForm';
import React, { useState } from 'react';
import {
	Button,
	Dropdown,
	DropdownButton,
	Image,
	Modal,
	Overlay,
	Spinner,
} from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import CustomNavLink from './CustomNavLink';
import {
	CollapseWrapper,
	CustomModal,
	DropdownWrapper,
	MenuButton,
	ProfileContainer,
	SidebarContainer,
	Wrapper,
} from './sidebar.styled';

const alertManagementRoute = {
	icon: <AlertIcon />,
	title: 'Alerts',
	toLink: '/alerts',
	disable: false,
};

const LINKS = [
	{ icon: <HomeIcon />, title: 'Home', toLink: '/dashboard', disable: false },
];

const ADMIN_LINKS = [
	{ icon: <UserIcon />, title: 'Users', toLink: '/users', disable: false },
	{
		icon: <EquipmentIcon />,
		title: 'Equipment',
		toLink: '/equipments',
		disable: false,
	},
	{ ...alertManagementRoute },
	{
		icon: <ProjectIcon />,
		title: 'Projects',
		toLink: '/projects',
		disable: false,
	},
	{
		icon: <VideoIcon />,
		title: 'Security Camera View',
		toLink: '/camera-feed',
		disable: false,
	},
	{
		icon: <SettingIcon />,
		title: 'Settings',
		toLink: '/settings',
		disable: false,
	},
];

const resources = {
	icon: <ResourcesIcon />,
	title: 'Resources',
	href: 'https://posi-stop.com/support.php',
	disable: false,
};

export function LogoutConfirmation({
	show,
	logout,
	close,
	isLogoutLoading = false,
}) {
	const { isMobile } = useResponsive();

	return (
		<CustomModal show={show} centered onHide={close}>
			<Modal.Body>
				<h3>Are you sure you want to logout?</h3>
				<Button variant="bordered-green" className="mx-2" onClick={close}>
					Go back
				</Button>
				<Button
					variant="primary-green"
					className={`mx-2 ${isMobile && 'my-3'}`}
					onClick={logout}
					disabled={isLogoutLoading}
				>
					Yes, logout{' '}
					{isLogoutLoading && <Spinner size="sm" animation="border" />}
				</Button>
			</Modal.Body>
		</CustomModal>
	);
}

function Sidebar({ isCollapsed, handleCollapse }) {
	const { logout, user } = useAuth();
	const [showReportModal, setShowReportModal] = useState(false);
	const [showCompanyModal, setShowCompanyModal] = useState(false);
	const [logoutModal, setLogoutModal] = useState(false);
	const [mobileDropdown, setMobileDropdown] = useState(false);
	const [profileModal, setProfileModal] = useState(false);
	const location = useLocation();
	const dropDownRef: any = React.useRef();
	const [expandedUserMenu, setExpandedUserMenu] = useState(false);
	const [isLogoutLoading, setIsLogoutLoading] = useState(false);

	const handleOpenProfileModal = () => setProfileModal(true);
	const handleCloseProfileModal = () => setProfileModal(false);
	const handleOpenShowReportModal = () => setShowReportModal(true);
	const handleCloseShowReportModal = () => setShowReportModal(false);
	const handleOpenShowCompanyModal = () => setShowCompanyModal(true);
	const handleCloseShowCompanyModal = () => setShowCompanyModal(false);
	const handleOpenLogoutModal = () => setLogoutModal(true);
	const handleCloseLogoutModal = () => setLogoutModal(false);

	/**
	 * Handles the logout process for the user.
	 * This function is called when the user confirms they want to log out. It sets the `isLogoutLoading` state to true, calls the `logout` function to log the user out, closes the logout modal, and then sets the `isLogoutLoading` state back to false.
	 * @param {React.SyntheticEvent} e - The event object passed to the function.
	 */
	const handleLogout = async (e) => {
		e.preventDefault();
		setIsLogoutLoading(true);
		await logout();
		handleCloseLogoutModal();
		setIsLogoutLoading(false);
	};

	const AdminAccess =
		user.roles.isAdminUser ||
		user.roles.isManagerUser ||
		user.roles.isRigseyeUser ||
		user.roles.isDeveloper;

	return (
		<Wrapper>
			<LogoutConfirmation
				logout={handleLogout}
				show={logoutModal}
				close={handleCloseLogoutModal}
				isLogoutLoading={isLogoutLoading}
			/>
			<ReportsForm
				show={showReportModal}
				handleClose={handleCloseShowReportModal}
				multi
			/>
			<Company
				show={showCompanyModal}
				handleClose={handleCloseShowCompanyModal}
			/>
			<GeneralProfile
				show={profileModal}
				handleClose={handleCloseProfileModal}
			/>

			<SidebarContainer $iscollapsed={isCollapsed}>
				<div className="d-flex align-items-center">
					<Link to="/dashboard">
						<div
							className={`flex justify-content-between ${
								isCollapsed && 'm-3 w-[2rem]'
							}`}
						>
							<Image
								src={isCollapsed ? LogoMinify : Logo}
								title="rigseye"
								alt="rigseye"
								className={`${
									isCollapsed ? 'w-[44px]' : 'w-[80%] pl-[1.5rem] py-3'
								}`}
							/>
						</div>
					</Link>
					<TooltipHandler
						content={`${isCollapsed ? 'Extend' : 'Collapse'} the sidebar`}
						placement="bottom"
					>
						<CollapseWrapper onClick={handleCollapse} $isopen={isCollapsed}>
							<ChevronIcon />
						</CollapseWrapper>
					</TooltipHandler>
				</div>
				<ProfileContainer $iscollapsed={isCollapsed}>
					<Button
						className="profile-img"
						onClick={() =>
							isCollapsed
								? setMobileDropdown(true)
								: setExpandedUserMenu(!expandedUserMenu)
						}
						ref={dropDownRef}
					>
						<Image src="/images/profile.png" alt="profile" title="profile" />
					</Button>

					{isCollapsed && (
						<Overlay
							target={dropDownRef.current}
							show={mobileDropdown}
							placement="right-start"
							onHide={() => setMobileDropdown(false)}
							rootClose
						>
							{({ ...props }) => (
								<div
									{...props}
									style={{
										zIndex: 2,
										...props.style,
									}}
								>
									<DropdownWrapper show>
										<Dropdown.Menu>
											<Dropdown.Item onClick={handleOpenProfileModal}>
												Profile
											</Dropdown.Item>
											<Dropdown.Item onClick={handleOpenLogoutModal}>
												Logout
											</Dropdown.Item>
										</Dropdown.Menu>
									</DropdownWrapper>
								</div>
							)}
						</Overlay>
					)}
					{!isCollapsed && (
						<div
							className="d-flex justify-content-between expanded-user-menu-info cursor-pointer"
							onClick={() => setExpandedUserMenu(!expandedUserMenu)}
						>
							<div className="details">
								<h2>{`${user.name} ${user.middlename} ${user.lastname}`}</h2>
								<h3>{user.username}</h3>
							</div>
							<DropdownButton title="" show={expandedUserMenu}>
								<Dropdown.Item onClick={handleOpenProfileModal}>
									Profile
								</Dropdown.Item>
								<Dropdown.Item onClick={handleOpenLogoutModal}>
									Logout
								</Dropdown.Item>
							</DropdownButton>
						</div>
					)}
				</ProfileContainer>
				<ul className="sidebar-nav">
					{LINKS.map((alink) => (
						<CustomNavLink
							{...alink}
							key={alink.title}
							href={alink.toLink}
							active={location.pathname === alink.toLink}
							disable={alink.disable}
							icon={alink.icon}
							isCollapsed={isCollapsed}
						/>
					))}

					{!AdminAccess && (
						<CustomNavLink
							title={alertManagementRoute.title}
							toLink={alertManagementRoute.toLink}
							href={alertManagementRoute.toLink}
							active={location.pathname === alertManagementRoute.toLink}
							disable={alertManagementRoute.disable}
							icon={alertManagementRoute.icon}
							isCollapsed={isCollapsed}
						/>
					)}

					{AdminAccess &&
						ADMIN_LINKS.map((alink) => (
							<CustomNavLink
								{...alink}
								key={alink.title}
								href={alink.toLink}
								active={location.pathname === alink.toLink}
								disable={alink.disable}
								isCollapsed={isCollapsed}
							/>
						))}
					{AdminAccess &&
						(isCollapsed ? (
							<>
								{/* <TooltipHandler content="Company" placement="right"> */}
								<MenuButton
									variant="transparent"
									onClick={handleOpenShowCompanyModal}
								>
									<CompanyIcon />
									<span className="ms-2 link-title">Company</span>
								</MenuButton>
								{/* </TooltipHandler> */}
								<TooltipHandler content="Reports" placement="right">
									<MenuButton
										variant="transparent"
										onClick={handleOpenShowReportModal}
									>
										<ReportIcon />
										<span className="ms-2 link-title">Reports</span>
									</MenuButton>
								</TooltipHandler>
							</>
						) : (
							<>
								<TooltipHandler content="Company" placement="right">
									<MenuButton
										variant="transparent"
										onClick={handleOpenShowCompanyModal}
										className="d-flex"
									>
										<CompanyIcon />
										<span className="ms-2 link-title">Company</span>
									</MenuButton>
								</TooltipHandler>

								<TooltipHandler content="Reports" placement="right">
									<MenuButton
										variant="transparent"
										onClick={handleOpenShowReportModal}
										className="d-flex"
									>
										<ReportIcon />
										<span className="ms-2 link-title">Reports</span>
									</MenuButton>
								</TooltipHandler>
							</>
						))}

					<CustomNavLink
						{...resources}
						icon={resources.icon}
						title={resources.title}
						key={resources.title}
						href={resources.href}
						toLink={resources.href}
						disable={resources.disable}
						isCollapsed={isCollapsed}
						active={false}
					/>
				</ul>
				{isCollapsed ? (
					<div className="app-version">
						<h6>RigsEye {metadata.buildTag}</h6>
					</div>
				) : (
					<div className="app-version">
						<h4>RigsEye {metadata.buildTag}</h4>
					</div>
				)}
			</SidebarContainer>
		</Wrapper>
	);
}
export default Sidebar;
