// import moment from 'moment';
import { getToken } from 'helpers/utils/auth-provider';
import { scriptsURL } from './urlDataBuilders';

const scriptsURLEndpoint = scriptsURL();

export const getEquipmentDocuments = async (id) => {
	const token = getToken();
	const url = `${scriptsURLEndpoint}/docsys/get_documents.php?equipment=${id}`;

	const header = {
		headers: {
			'http-authorization': token || '',
		},
	};
	const response = await fetch(url, header);
	const result = await response.json();
	return result;
};

export const addEquipmentDocument = async (id, data) => {
	const token = getToken();
	const url = `${scriptsURLEndpoint}/docsys/add_document.php`;

	const values = {
		equipment: id,
		document: data,
	};

	const header = {
		headers: {
			'http-authorization': token || '',
		},
		body: JSON.stringify(values),
	};
	const response = await fetch(url, header);
	const result = await response.json();
	return result;
};
