import { Spinner } from 'react-bootstrap';
import { getPipeData } from 'helpers/api/projectManagement';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { CardContainer } from 'pages/projectmanagement/project.styled';
import { CSVLink } from 'react-csv';
import styled from 'styled-components';
import { PipeTallyTableContainer } from 'pages/project-details/styles/pipetally.styled';
import AddJointEntry from './AddJointEntry';

const Wrapper = styled(CardContainer)``;

export default function PipeTallyData() {
	const { id } = useParams();
	const headers = [
		{ label: 'ID', key: 'id' },
		{ label: 'User', key: 'userid' },
		{ label: 'Date Time', key: 'datetime' },
		{ label: 'Damage Code', key: 'damage_code' },
		{ label: 'Tally in', key: 'tally_in' },
		{ label: 'Tally out', key: 'tally_out' },
		{ label: 'Pipe length', key: 'pipe_length' },
	];

	const { data, isFetching, refetch } = useQuery(
		'PipeData',
		() => getPipeData(id),
		{
			refetchIntervalInBackground: false,
			refetchOnWindowFocus: false,
		},
	);

	if (isFetching) {
		return (
			<CardContainer>
				<div className="header">
					<h2>Pipe Tally Data</h2>
				</div>
				<div className="no-result" style={{ opacity: 1 }}>
					<Spinner size="sm" animation="border" className="m-auto" />
				</div>
			</CardContainer>
		);
	}

	if (data?.data?.pipeTallyDataByprojectId?.data?.length === 0) {
		return (
			<CardContainer>
				<div className="header">
					<h2>Pipe Tally Data</h2>
					<AddJointEntry />
					{/* <ExportData /> */}
				</div>
				<div className="no-result">
					<span>No data found</span>
				</div>
			</CardContainer>
		);
	}
	return (
		<Wrapper>
			<div className="header">
				<h2>Pipe Tally Data</h2>
				<span style={{ marginLeft: 10 }}>
					<small>
						TJIH: {data?.data?.pipeTallyDataByprojectId?.total_joints_in_hole}
					</small>
				</span>
				<span style={{ marginLeft: 10 }}>
					<small>
						TJOH: {data?.data?.pipeTallyDataByprojectId?.total_joints_out_hole}
					</small>
				</span>
				<span style={{ marginLeft: 10 }}>
					<small>
						TJ: {data?.data?.pipeTallyDataByprojectId?.total_project_joints}
					</small>
				</span>
				<span style={{ marginLeft: 10 }}>
					<small>
						CD: {data?.data?.pipeTallyDataByprojectId?.cumulative_depth} Ft.
					</small>
				</span>
				<AddJointEntry refetch={refetch} />
				{data?.data?.pipeTallyDataByprojectId?.data && (
					<CSVLink
						headers={headers}
						data={data?.data?.pipeTallyDataByprojectId.data}
						className="green ms-3 text-decoration-none"
					>
						Export Data
					</CSVLink>
				)}
			</div>
			<PipeTallyTableContainer responsive className="mt-1">
				<table style={{ width: '100%' }}>
					<thead>
						<tr>
							<th>User</th>
							<th>Date/Time</th>
							<th>Damage Code</th>
							<th>Tally in</th>
							<th>Tally out</th>
							<th>Pipe Length</th>
						</tr>
					</thead>
					<tbody>
						{data?.data?.pipeTallyDataByprojectId?.data?.map((row) => (
							<tr key={row.id}>
								<td>{row.userid}</td>
								<td>{row.datetime}</td>
								<td>{row.damage_code}</td>
								<td>{row.tally_in}</td>
								<td>{row.tally_out}</td>
								<td>{row.pipe_length}</td>
							</tr>
						))}
					</tbody>
				</table>
			</PipeTallyTableContainer>
		</Wrapper>
	);
}
