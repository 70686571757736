import React from 'react';
import { Modal } from 'react-bootstrap';
import CameraDetails from './CameraDetails';

export default function FullCameraModal({
	data,
	show,
	setModalShow,
}: {
	data: any;
	show: boolean;
	setModalShow: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	return (
		<Modal
			size="lg"
			centered
			show={show}
			onHide={() => {
				setModalShow(false);
			}}
			className="profile-modal"
		>
			<Modal.Body style={{ padding: 0 }}>
				<CameraDetails data={data} />
			</Modal.Body>
		</Modal>
	);
}
