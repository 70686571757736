import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError';
import { getPipeDamageCodes } from 'helpers/api/projectManagement';
import { useAuth } from 'helpers/contexts/AuthContext';
import { getToken } from 'helpers/utils/auth-provider';
import { formatDateTime } from 'helpers/utils/formatters';
import React from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { ADD_PIPE_TALLY_DATA_MUTATION } from '../../helpers/queries/pipelineTallyDataQuery';

export default function AddJointEntry({ refetch }) {
	const [modalShow, setModalShow] = React.useState(false);
	// If true tally in = 0, tally out = 1
	const [pipeLogic, setPipeLogic] = React.useState(false);
	const [pipeDamageCode, setPipeDamageCode] = React.useState([]);
	const { user } = useAuth();
	const { id } = useParams();
	React.useEffect(() => {
		getPipeDamageCodes().then((res) =>
			setPipeDamageCode(res.data.pipeDamageCodes),
		);
	}, []);

	const handlePipeLogic = (e) => {
		if (e.target.value === '0') setPipeLogic(true);
		else setPipeLogic(false);
	};

	const schema = yup
		.object({
			userid: yup.string().default(user.username),
			projectid: yup.string().default(id),
			datetime: yup.string(),
			tally_in: yup.string(),
			tally_out: yup.string(),
			pipe_length: yup.string().required('Pipe length is required'),
			damage_code: yup.string(),
		})
		.required();

	const { register, handleSubmit, formState } = useForm({
		resolver: yupResolver(schema),
	});

	const [addPipeTallyData] = useMutation(ADD_PIPE_TALLY_DATA_MUTATION, {
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
	});

	const addEntry = async (data) => {
		const tempTallyOut = pipeLogic ? '1' : '0';
		const tempTallyIn = pipeLogic ? '0' : '1';

		const datapacket = {
			...data,
			datetime: formatDateTime(new Date().getTime()),
			tally_in: tempTallyIn,
			tally_out: tempTallyOut,
		};
		try {
			await addPipeTallyData({
				variables: {
					...datapacket,
				},
			});
			toast.success('Entry added successfully');
			setModalShow(false);
			refetch();
		} catch (error) {
			toast.error(error.msg || 'Error adding entry');
		}
	};

	const { errors } = formState;
	return (
		<>
			<button
				type="button"
				className="green ms-auto"
				onClick={() => setModalShow(true)}
			>
				Add joint entry
			</button>
			<Modal
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={modalShow}
				onHide={() => setModalShow(false)}
				className="profile-modal"
			>
				<Modal.Body>
					<div className="information-container">
						<span className="information">Add Joint Entry</span>
					</div>

					<Form onSubmit={handleSubmit(addEntry)}>
						<Row className="inputs">
							<Col lg={6}>
								<label htmlFor="tally_in" className="w-100">
									In*
									<select onChange={handlePipeLogic}>
										<option value="-1" disabled>
											--Select a value--
										</option>
										<option value="1">1</option>
										<option value="0" defaultChecked>
											0
										</option>
									</select>
								</label>
							</Col>
							<Col lg={6}>
								<label htmlFor="tally_out" className="w-100">
									Out
									<select disabled id="tally_out">
										<option value="-1" disabled>
											--Select a value--
										</option>
										<option value="0" selected={!pipeLogic}>
											0
										</option>
										<option value="1" selected={pipeLogic}>
											1
										</option>
									</select>
								</label>
							</Col>
							<Col lg={6}>
								<label htmlFor="pipe_length" className="w-100">
									Pipe Length
									<input
										type="number"
										step="0.01"
										id="pipe_length"
										{...register('pipe_length')}
										min={0}
										onKeyDown={(evt) =>
											['-'].includes(evt.key) && evt.preventDefault()
										}
									/>
								</label>
								<FormError>{errors?.pipe_length?.message}</FormError>
							</Col>
							<Col lg={6}>
								<label htmlFor="damage_code" className="w-100">
									Damage Code
									<select {...register('damage_code')} id="damage_code">
										{pipeDamageCode?.map((damageCode) => (
											<option key={damageCode.id} value={damageCode.code}>
												{damageCode.description}
											</option>
										))}
									</select>
								</label>
							</Col>
						</Row>

						<div className="button-wrapper">
							<Button
								type="button"
								variant="bordered-green"
								className="me-3"
								onClick={() => setModalShow(false)}
							>
								Close
							</Button>

							<Button type="submit" variant="primary-green">
								Save
							</Button>
						</div>
					</Form>
				</Modal.Body>
			</Modal>
		</>
	);
}
