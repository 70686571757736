import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Form, Modal, Spinner, Button, Image } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDropzone } from 'react-dropzone';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useAuth } from 'helpers/contexts/AuthContext';
import states from 'helpers/const/us-states.json';
import { formatPhoneNumber } from 'helpers/utils/formatters';
import { company } from 'helpers/api';
import FormError from 'components/FormError';
import styled from 'styled-components';

const Wrapper = styled.div`
	background: #ffffff;
	border-radius: 12px;
	/* margin-top: 1.75rem; */
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1.25rem 1.5rem;
	height: auto;

	.row {
		gap: 1rem 0rem;
	}

	input,
	select {
		background: #f3f3f3;
		border-radius: 4px;
		border: none;
		height: 48px;
		padding: 0.75rem 1.125rem;
		width: 100%;
	}

	.inputs {
		margin-top: 0rem;
		margin-bottom: 0rem;
		label {
			width: 100%;
		}
	}

	.process {
		position: fixed;
		bottom: 0px;
		right: 0px;
		background: white;
		box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
		padding: 1.125rem;
		width: 104vw;

		.primary-button {
			background: #00834d;
			border-radius: 12px;
			color: white;
			border: none;
			padding: 0.75rem 1.75rem;
			margin-right: 1rem;
			max-width: fit-content;
			/* margin-left: auto; */
		}

		.alt-button {
			background: white;
			border-radius: 12px;
			color: black;
			border: 1px solid #00834d;
			padding: 0.75rem 1.75rem;
			margin-right: 1rem;
			max-width: fit-content;
			margin-left: auto;
		}
	}

	.green {
		cursor: pointer;
		font-family: Poppins;
		font-style: normal;
		font-weight: 500;
		font-size: 0.875rem;
		color: #00834d;
		border: none;
		background: none;
	}
`;

export default function Company({ show, handleClose }) {
	const [isLoading, setIsLoading] = useState(false);
	const { handleToken } = useAuth();
	const [companyInfo, setCompanyInfo] = useState({});
	const [companyLogo, setCompanyLogo] = useState('');
	const [mobileNumber, setMobileNumber] = useState('');
	const [faxNumber, setFaxNumber] = useState('');

	const companyId = localStorage.getItem('__auth_company_id__');

	const onDrop = useCallback((acceptedFiles) => {
		// eslint-disable-next-line no-console
		setCompanyLogo(acceptedFiles[0]);
	}, []);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		maxFiles: 1,
	});

	const schema = yup
		.object({
			customid: yup.string().max(50, 'Must be 50 characters or less'),
			cname: yup.string().max(50, 'Must be 50 characters or less'),
			caddress1: yup.string().max(50, 'Must be 50 characters or less'),
			caddress2: yup.string().max(50, 'Must be 50 characters or less'),
			ccity: yup.string().max(50, 'Must be 50 characters or less'),
			cstate: yup.string().max(50, 'Must be 50 characters or less'),
			czip: yup.string().max(50, 'Must be 50 characters or less'),
			cphone: yup.string().max(15, 'Must be 15 characters or less'),
			cfax: yup.string().max(15, 'Must be 15 characters or less'),
			logo: yup.string().max(50, 'Must be 50 characters or less'),
		})
		.shape({
			logo: yup.mixed().test({
				message: 'Please provide image only',
				test: (file, context) => {
					console.log(file);
					const isValid = (file[0]?.type || '').startsWith('image/');
					if (!isValid) context?.createError();
					return isValid;
				},
			}),
		})
		.required();

	const { register, handleSubmit, formState, reset, setValue } = useForm({
		resolver: yupResolver(schema),
	});

	const fetchCompanyInfo = useCallback(() => {
		company.getCompanyInfo().then((res) => {
			const data = res.data.customer;
			const companyImg = `https://development.rigseye.com/company_logos/${data?.logo}`;
			setCompanyLogo(companyImg);
			setCompanyInfo(data);
		});
	}, []);

	const onSubmit = (body) => {
		setIsLoading(true);
		const promise = company.updateCompanyInfo(companyId, body);
		toast.promise(promise, {
			loading: 'Updating company info...',
			success: () => {
				setIsLoading(false);
				fetchCompanyInfo();
				handleClose();
				handleToken();
				return 'Company info updated successfully';
			},
			error: (error) => {
				setIsLoading(false);
				return error?.response?.message || 'Error updating company info';
			},
		});
	};

	const handleMobileNumberChange = (e) => {
		const { value } = e.target;
		const getSimplePhoneNumber = (number = '') => number.replace(/\D+/g, '');
		const phone = getSimplePhoneNumber(value);
		setValue('cphone', phone);
		setMobileNumber(value);
	};

	const handleFaxNumberChange = (e) => {
		const { value } = e.target;
		const getSimplePhoneNumber = (number = '') => number.replace(/\D+/g, '');
		const fax = getSimplePhoneNumber(value);
		setValue('cfax', fax);
		setFaxNumber(value);
	};

	const { errors } = formState;

	useEffect(() => {
		if (show) {
			setMobileNumber(companyInfo.phone);
			setFaxNumber(companyInfo.fax);
			reset(companyInfo);
			setMobileNumber(companyInfo.cphone);
			setFaxNumber(companyInfo.cfax);
		}
	}, [companyInfo, show]);

	useEffect(fetchCompanyInfo, [fetchCompanyInfo]);

	const inputProps = { register, errors };

	const INPUTS = [
		{ id: 'customid', label: 'Company ID', disabled: true },
		{ id: 'cname', label: 'Name', disabled: false },
		{ id: 'caddress1', label: 'Address', disabled: false },
		{ id: 'caddress2', label: 'Address 2', disabled: false },
		{ id: 'ccity', label: 'City', disabled: false },
	];

	return (
		<Modal
			show={show}
			centered
			className="profile-modal p-0"
			size="lg"
			onHide={handleClose}
		>
			<Wrapper>
				<Row>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Row className="inputs">
							<div className="information-container">
								<span className="information mt-0">Company Management</span>
							</div>
							<Col lg={12} className="mb-4">
								<Image
									src={companyLogo}
									title="logo"
									alt="logo"
									className="w-25"
									width={250}
								/>
							</Col>
							{INPUTS.map((input) => (
								<CustomInput
									{...inputProps}
									key={input.id}
									label={input.label}
									name={input.id}
									id={input.id}
									disabled={input.disabled}
								/>
							))}
							<Col lg={6} sm={12} className="mb-3">
								<span>State</span>
								<select {...register('cstate')}>
									{states.map((state) => (
										<option value={state.abbreviation} key={state.abbreviation}>
											{state.name}
										</option>
									))}
								</select>
							</Col>
							<Col lg={4} sm={12} className="mb-3">
								<label htmlFor="zip">
									Zip Code
									<input {...register('czip')} id="zip" />
									<FormError>{errors?.zip?.message}</FormError>
								</label>
							</Col>
							<Col lg={4} sm={12} className="mb-3">
								<label htmlFor="phone">
									Phone
									<input
										value={formatPhoneNumber(mobileNumber)}
										onChange={handleMobileNumberChange}
										id="phone"
										maxLength={10}
									/>
									<FormError>{errors?.phone?.message}</FormError>
								</label>
							</Col>
							<Col lg={4} sm={12} className="mb-3">
								<label htmlFor="fax">
									Fax
									<input
										id="fax"
										onChange={handleFaxNumberChange}
										value={formatPhoneNumber(faxNumber)}
										maxLength={10}
									/>
									<FormError>{errors?.fax?.message}</FormError>
								</label>
							</Col>

							<Col lg={12}>
								<div {...getRootProps()} className="p-0">
									<input {...getInputProps()} />
									<div className="upload-image-drag">
										<span className="heading">
											<span className="green">Click to select</span> or Drag
											image
										</span>
										<p>
											TIP: While on the file explorer, you can select multiple
											images in a row using Shift key or select specific images
											while pressing Ctrl key
										</p>
									</div>
								</div>
							</Col>
							<div className="d-flex">
								<Button
									variant="bordered-green"
									className="me-3 ms-auto"
									onClick={handleClose}
								>
									Close
								</Button>
								<Button type="submit" variant="primary-green">
									{isLoading ? (
										<Spinner size="sm" animation="border" />
									) : (
										'Save'
									)}
								</Button>
							</div>
						</Row>
					</Form>
				</Row>
			</Wrapper>
		</Modal>
	);
}

function CustomInput({ register, errors, id, label, disabled }) {
	return (
		<Col lg={6} sm={12} className="mb-3">
			<label htmlFor={id}>
				{label}
				<input {...register(id)} disabled={disabled} id={id} />
				<FormError>{errors?.[id]?.message}</FormError>
			</label>
		</Col>
	);
}
