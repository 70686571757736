import { ReactComponent as EngineIcon } from 'assets/img/engine.svg';
import RigLayout from 'components/Layouts/RigLayout';
import TooltipHandler from 'components/TooltipHandler';
import { ExhaustFluidIcon, GasIcon } from 'components/symbols/';
import { useRigDetail } from 'helpers/contexts/RigDetailProvider';
import useResponsive from 'helpers/hooks/useResponsive';
import DetailsCard from 'pages/details/DetailsCard'; // DetailsCard
import DrillerGauge from 'pages/details/charts/DrillerGauges';
import FuelChart from 'pages/details/charts/FuelChart';
import Off from 'pages/details/rig/ignition-assets/ignitionSwitchOff.png';
import On from 'pages/details/rig/ignition-assets/ignitionSwitchOn.png';
import { Col, Image, ProgressBar, Row } from 'react-bootstrap';
import styled from 'styled-components';

const FuelIndicator = styled.div`
	display: flex;
	max-width: 100%;
	margin-left: 1%;

	span {
		margin-left: auto;
		margin-right: auto;
	}
`;
const SvgWrapper = styled.div`
	svg {
		transform: scale(1.8);
	}

	@media (max-width: 450px) {
		svg {
			transform: scale(1);
		}
	}
`;
const GearWrapper = styled.div`
	font-size: 1.75rem;
	font-weight: 500;
	height: 85%;
	font-family: 'Poppins', sans-serif;
`;

type GearShiftPropsType = {
	pos: string;
};
const GearShift = styled.div<GearShiftPropsType>`
	position: absolute;
	background: #00834d;
	border-radius: 31px;
	width: 51px;
	height: 26px;
	margin-left: -16px;
	transition: all 0.3s ease-in-out;

	${(props: any) => props?.pos === 'D' && `margin-top: 4px;`}
	${(props: any) => props?.pos === 'N' && `margin-top: 46px;`}
	${(props: any) => props?.pos === 'R' && `margin-top: 90px;`}
`;
const GearGraph = styled.div`
	background: #e6e6e6;
	border-radius: 22px;
	width: 16px;
	height: 120px;
`;
const BarChartWrapper = styled.div`
	.progress-bar {
		background-color: ${({ theme }) => theme.colors.loginPrimary};
	}
`;
const TableWrapper = styled.div`
	.tags {
		font-weight: 500;
		font-size: 0.875rem;
		opacity: 0.4;
	}
	.values {
		font-weight: 600;
		font-size: 1rem;
	}
`;
const EngineStatus = styled.div`
	background: radial-gradient(
		50% 50% at 50% 50%,
		#92e100 0%,
		rgba(139, 224, 0, 0.83) 100%
	);
	box-shadow: 0px 10.6842px 28.0645px #95e20c;
	height: 58px;
	width: 58px;
	border-radius: 100%;
	/* background: radial-gradient(
		50% 50% at 50% 50%,
		#c8c8c8 0%,
		rgba(202, 202, 202, 0.83) 100%
	);
	box-shadow: 0px 10.6842px 28.0645px #b3b3b3;
	background: radial-gradient(
		50% 50% at 50% 50%,
		#ff0505 0%,
		rgba(254, 30, 30, 0.83) 100%
	);
	box-shadow: 0px 10.6842px 28.0645px #ee0000; */
`;

function setMinMax(min, max, value) {
	let result = value;
	if (value < min) result = min;
	if (value > max) result = max;
	if (value === 9999) result = 0;
	return Math.round(result);
}

const IgnitionSwitch = ({ data }) => {
	// <Image src={Ignition} height={150} />
	const ECUenabled = data?.ECUenabled;
	const engineSpeed = data?.engineSpeed;
	if (ECUenabled && engineSpeed > 0) {
		return (
			<Image
				src={On}
				className="md2:w-[140px] md2:h-[140px] w-[150px] h-[150px]"
			/>
		);
	}
	return (
		<Image
			src={Off}
			className="md2:w-[140px] md2:h-[140px] w-[150px] h-[150px]"
		/>
	);
};

export default function EngineGauges() {
	const { data }: any = useRigDetail();
	const engineMaxValue = {
		rpm: 3000,
		oilPressure: 100,
		coolantTemp: 250,
		airPressure: 150,
		oilTemp: 250,
		transmissionTemp: 250,
		mph: 160,
	};
	const { isMobile } = useResponsive();
	// console.log(data, data?.[0]?.latestExhaustFluidLevel);

	return (
		<RigLayout>
			<Row>
				<Col lg={7}>
					<DetailsCard fullHeight engine title="Engine Information">
						<div className="flex flex-wrap gap-4 justify-center">
							<div className="align-self-center grid items-center">
								<SvgWrapper className="flex justify-center items-center justify-self-center w-[252px] h-[252px]">
									{/* Data key: engineSpeed */}
									<DrillerGauge
										min={0}
										max={engineMaxValue.rpm}
										value={setMinMax(0, 3000, data?.[0]?.engineSpeed)}
										title="RPM"
										measure="rpm"
									/>
								</SvgWrapper>
								<div style={{ marginTop: '5rem' }}>
									{/* Data key: fuelRate */}
									{/* Algorithm 0-10, start from -135 till -45 */}
									<FuelChart
										needlePos={-(data?.[0]?.fuelRate || 0) - 135}
										rate={data?.[0]?.fuelRate || 0}
									/>
								</div>
							</div>
							<div style={{ display: 'flex', flexWrap: 'wrap' }}>
								<Col lg={6}>
									{/* Data key: engineOilPressure */}
									<DrillerGauge
										min={0}
										max={engineMaxValue.oilPressure}
										value={setMinMax(0, 600, data?.[0]?.engineOilPressure)}
										title="Oil"
										measure="psi"
									/>
									{/* Data key: airPressure */}
									<DrillerGauge
										min={0}
										max={engineMaxValue.airPressure}
										value={setMinMax(0, 200, data?.[0]?.airPressure)}
										title="Air System"
										measure="psi"
									/>
									{/* Data key: vehicleSpeed */}
									<DrillerGauge
										min={0}
										max={engineMaxValue.mph}
										value={setMinMax(0, 100, data?.[0]?.vehicleSpeed)}
										title="MPH"
										measure="mph"
									/>
								</Col>
								<Col lg={6}>
									{/* Data key: engineTemp */}
									<DrillerGauge
										min={0}
										max={engineMaxValue.coolantTemp}
										value={setMinMax(0, 250, data?.[0]?.engineTemp)}
										title="Coolant Temp"
										measure="°F"
										fontSize={11.5}
									/>
									{/* Data key: engineOilTemp */}
									<DrillerGauge
										min={0}
										max={engineMaxValue.oilTemp}
										value={setMinMax(0, 500, data?.[0]?.engineOilTemp)}
										title="Oil Temp"
										measure="°F"
									/>
									{/* Data key: transmissionTemp */}
									<DrillerGauge
										min={0}
										max={engineMaxValue.transmissionTemp}
										value={setMinMax(0, 350, data?.[0]?.transmissionTemp)}
										title="Trans. Temp"
										measure="°F"
									/>
								</Col>
							</div>
						</div>

						<Row className="my-5">
							<BarChartWrapper>
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<ExhaustFluidIcon />
									<ProgressBar
										now={data?.[0]?.latestExhaustFluidLevel}
										label="hi"
										style={{ width: '100%' }}
										visuallyHidden
									/>
								</div>
								<FuelIndicator>
									<span>E</span>
									<span>1/4</span>
									<span>1/2</span>
									<span>3/4</span>
									<span>F</span>
								</FuelIndicator>
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<GasIcon />
									<ProgressBar
										now={data?.[0]?.latestFuelLevel}
										label="hi"
										style={{ width: '100%' }}
										visuallyHidden
									/>
								</div>
							</BarChartWrapper>
						</Row>
					</DetailsCard>
				</Col>
				<Col lg={5}>
					{/* <Row> */}
					<Row>
						<Col lg={6}>
							<DetailsCard engine threeContainer title="" className="mb-3">
								<IgnitionSwitch data={data?.[0]} />
							</DetailsCard>
						</Col>
						<Col lg={6}>
							<DetailsCard engine threeContainer title="" className="mb-3 !p-0">
								{/* <h6>Engine Warning Light</h6> */}
								<div className="d-flex align-items-center flex-wrap justify-center gap-2">
									{/* <h6 className="text-start align-self-center me-3">
										Engine warning light
									</h6> */}
									<TooltipHandler
										content={
											<>
												<h6>Engine Air Filter Restriction Lamp</h6>
												<h6
													style={{
														opacity: 0.34,
														fontSize: '0.8rem',
													}}
												>
													7 Jul, 2022 | 12:26:07 PM
												</h6>
											</>
										}
									>
										<EngineIcon className="mx-3" />
									</TooltipHandler>

									<TooltipHandler content={<h6>Lockup Timer</h6>}>
										<EngineStatus className="mx-3" />
									</TooltipHandler>
								</div>
							</DetailsCard>
						</Col>
					</Row>

					<DetailsCard
						engine
						threeContainer
						title="Drive/Gear Information"
						justifyLeft
						dFlex
					>
						<div className="d-flex w-100">
							<TableWrapper>
								{/* Data key drivetrain: drivelineEngaged */}
								{/* Data key gear selected: gearSelected */}
								{/* Data key gear attained: gearAttained */}
								<table cellPadding="10px">
									<tbody>
										<tr>
											<td className="tags ps-0">Drive Train:</td>
											<td className="values">{data?.[0]?.drivelineEngaged}</td>
										</tr>
										<tr>
											<td className="tags ps-0">Gear Selected:</td>
											<td className="values">{data?.[0]?.gearSelected}</td>
										</tr>
										<tr>
											<td className="tags ps-0">Gear Attained:</td>
											<td className="values">{data?.[0]?.gearAttained}</td>
										</tr>
									</tbody>
								</table>
							</TableWrapper>
							{!isMobile ? (
								<div className="flex gap-4 items-center ms-auto me-5">
									<div>
										{/* Data key: gearSelected */}
										<GearGraph>
											<GearShift pos={data?.gearSelected === 0 ? 'N' : 'D'} />
										</GearGraph>
									</div>
									<div>
										<GearWrapper>
											<div>D</div>
											<div>N</div>
											<div>R</div>
										</GearWrapper>
									</div>
								</div>
							) : (
								<div className="ms-auto me-5 d-flex">
									<Col sm={6} md={6} lg={6}>
										{/* Data key: gearSelected */}
										<GearGraph>
											<GearShift
												pos={data?.[0]?.gearSelected === 0 ? 'N' : 'D'}
											/>
										</GearGraph>
									</Col>

									<Col sm={6} md={6} lg={6} className="ms-5">
										<GearWrapper>
											<div>D</div>
											<div>N</div>
											<div>R</div>
										</GearWrapper>
									</Col>
								</div>
							)}
						</div>
					</DetailsCard>

					<DetailsCard engine threeContainer title="Miscellaneous" justifyLeft>
						<TableWrapper>
							{/* Data key odometer: latestOdometer */}
							{/* Data key gear engine hours: latestEngineHours */}
							{/* Data key gear daily fuel: latestFuelUsed */}
							<table cellPadding="10px">
								<tbody>
									<tr>
										<td className="tags ps-0">Odometer</td>
										<td className="values">{data?.[0]?.latestOdometer}</td>
										<td className="tags">Miles</td>
									</tr>
									<tr>
										<td className="tags ps-0">Last Eng. Hours Read</td>
										<td className="values">{data?.[0]?.totalEngineHours}</td>
										<td className="tags">Hours</td>
									</tr>
									<tr>
										<td className="tags ps-0">Daily Fuel Consumption</td>
										<td className="values">{data?.[0]?.latestFuelUsed}</td>
										<td className="tags">Gallons</td>
									</tr>
								</tbody>
							</table>
						</TableWrapper>
					</DetailsCard>
				</Col>
			</Row>
		</RigLayout>
	);
}
