import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError';
import ResetPassword from 'components/Profile/ResetPassword';
import mobileProviders from 'helpers/const/mobileProviders.json';
import { useAuth } from 'helpers/contexts/AuthContext';
import { getToken } from 'helpers/utils/auth-provider';
import { formatPhoneNumber } from 'helpers/utils/formatters';
import { useEffect, useState } from 'react';
import {
	Button,
	Col,
	Form,
	InputGroup,
	Modal,
	Row,
	Spinner,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import * as yup from 'yup';
import YupPassword from 'yup-password';
import { EDIT_USER_MUTATION } from '../../helpers/queries/userQuery';

const FormWrapper = styled(Form)`
	.password-view {
		input {
			border-radius: 4px !important;
		}
		.password-show-button {
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
		}
	}
`;

YupPassword(yup); // extend yup

export default function UserForm({ show, handleClose }) {
	const { user, handleToken } = useAuth();
	const [isLoading, setIsLoading] = useState(false);
	const [notificationAccess, setNotificationAccess] = useState([]);
	const [mobileNumber, setMobileNumber] = useState('');
	const [passwordModal, setPasswordModal] = useState(false);

	const [editUser] = useMutation(EDIT_USER_MUTATION, {
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
		fetchPolicy: 'network-only',
	});

	const schema = yup
		.object({
			username: yup.string().required('Username is required'),
			firstName: yup.string().required('First name is required.'),
			middleName: yup.string(),
			lastName: yup.string().required('Last name is required'),
			email: yup.string().required('Email is required'),
			mobileNumber: yup.string(),
			mobileProvider: yup.string(),
			temperatureUnit: yup.string().default('fahrenheit'),
			downloads: yup.string(),
			supervisor: yup.string(),
			companyUser: yup.string(),
			uroleid: yup.number(),
			speedUnit: yup.string().default('miles'),
		})
		.required();

	const { register, handleSubmit, formState, reset, setValue } = useForm({
		resolver: yupResolver(schema),
	});

	const handleNotificationAccess = () => {
		const selectedNotificationOptions: any = document.querySelectorAll(
			'input[name=notification_option]:checked',
		);
		notificationAccess.length = 0;

		const newListOfNoticiations: any = [];
		selectedNotificationOptions.forEach((n: any) => {
			newListOfNoticiations.push(n.value);
		});
		setNotificationAccess(newListOfNoticiations);
	};

	// Edit handlers
	const handleMobileProvider = () => {
		const i = mobileProviders.filter(
			(item) => item.mpname === user.mobileprovider,
		);
		return i?.[0]?.mpid;
	};

	useEffect(() => {
		if (user) {
			setMobileNumber(user.mobile);
			reset({
				username: user.username,
				firstName: user.name,
				middleName: user.middlename,
				lastName: user.lastname,
				email: user.email,
				mobileNumber: user.mobile,
				mobileProvider: handleMobileProvider(),
				downloads: user.downloads,
				supervisor: user.supervisor,
				companyUser: user.companyuser,
				temperatureUnit: user.temperatureUnit,
				speedUnit: user.speedUnit,
				uroleid: user.role,
			});
		}
	}, [user, show]);

	const handleOpenPasswordModal = () => setPasswordModal(true);
	const handleClosePasswordModal = () => setPasswordModal(false);

	const onSubmit = async (data) => {
		setIsLoading(true);
		const userData = {
			...data,
			notification_option: JSON.stringify(notificationAccess),
			upage_access:
				user.access && user.access > 0 ? JSON.stringify(user.access) : '[]',
		};
		try {
			const { errors } = await editUser({
				variables: {
					...userData,
				},
			});
			setIsLoading(false);
			if (errors && errors.length > 0) {
				toast.error(errors[0].message || 'User updated successfully!');
			} else {
				handleToken(() => {
					// Set new response details to current user.
					handleClose();
					toast.success('User updated successfully!');
				});
			}
		} catch (error: any) {
			setIsLoading(false);
			if (
				error.toString().indexOf('Duplicate entry') !== -1 &&
				error.toString().indexOf("key 'uemail'") !== -1
			) {
				toast.error('Email already exists!');
			}
			toast.error(error.message || 'Something went wrong!');
		}
	};

	// Phone number formatting
	const handleMobileNumberChange = (e) => {
		const { value } = e.target;
		const getSimplePhoneNumber = (number = '') => number.replace(/\D+/g, '');
		const phone = getSimplePhoneNumber(value);
		setValue('mobileNumber', phone);
		setMobileNumber(value);
	};

	const { errors } = formState;

	return (
		<Modal
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			show={show}
			onHide={handleClose}
			className="profile-modal p-0"
		>
			<Modal.Body>
				<div className="information-container">
					<span className="information">User Profile</span>
				</div>

				<FormWrapper onSubmit={handleSubmit(onSubmit)}>
					<Row className="inputs">
						<Col md={6} sm={12} className="mb-3">
							<Form.Group>
								<Form.Label>Username</Form.Label>
								<Form.Control type="text" {...register('username')} disabled />
								<FormError>{errors.username?.message}</FormError>
							</Form.Group>
						</Col>
						<Col md={6} sm={12} className="mb-3">
							<Form.Group>
								<Form.Label>Password</Form.Label>
								{/* <InputGroup className="password-view">
									<Form.Control
										type={passwordShown ? 'text' : 'password'}
										name="password"
										{...register('password')}
										disabled
									/>
									<Button
										variant="transparent-green"
										id="button-addon2"
										onClick={handlePasswordShow}
										className="password-show-button"
									>
										<EyeIcon className="password-show" />
									</Button>
								</InputGroup>
								<FormError>{errors.password?.message}</FormError> */}
								<InputGroup className="password-view">
									<Button
										variant="unstyled-green"
										className="me-3 p-0"
										onClick={handleOpenPasswordModal}
									>
										Change Password
									</Button>
								</InputGroup>
							</Form.Group>
						</Col>
						<Col md={6} sm={12} className="mb-3">
							<Form.Group controlId="firstName">
								<Form.Label>First Name</Form.Label>
								<Form.Control type="text" {...register('firstName')} />
								<FormError>{errors.firstName?.message}</FormError>
							</Form.Group>
						</Col>
						<Col md={6} sm={12} className="mb-3">
							<Form.Group controlId="middleName">
								<Form.Label>Middle Name</Form.Label>
								<Form.Control type="text" {...register('middleName')} />
								<FormError>{errors.middleName?.message}</FormError>
							</Form.Group>
						</Col>
						<Col md={6} sm={12} className="mb-3">
							<Form.Group controlId="lastName">
								<Form.Label>Last Name</Form.Label>
								<Form.Control type="text" {...register('lastName')} />
								<FormError>{errors.lastName?.message}</FormError>
							</Form.Group>
						</Col>
						<Col md={6} sm={12} className="mb-3">
							<Form.Group controlId="email">
								<Form.Label>Email</Form.Label>
								<Form.Control type="email" {...register('email')} />
								<FormError>{errors.email?.message}</FormError>
							</Form.Group>
						</Col>
						<Col md={6} sm={12} className="mb-3">
							<Form.Group controlId="mobileNumber">
								<Form.Label htmlFor="tel">
									Mobile Number (USA & Canada only)
								</Form.Label>
								<Form.Control
									type="tel"
									id="tel"
									value={formatPhoneNumber(mobileNumber)}
									onChange={handleMobileNumberChange}
									maxLength={10}
								/>
								<FormError>{errors.mobileNumber?.message}</FormError>
							</Form.Group>
						</Col>
						<Col md={6} sm={12} className="mb-3">
							<Form.Group controlId="mobileProvider">
								<Form.Label htmlFor="mobileProvider" className="cursor-pointer">
									Mobile Provider
								</Form.Label>
								<Form.Control
									as="select"
									id="mobileProvider"
									{...register('mobileProvider')}
								>
									{mobileProviders.map((provider) => (
										<option value={provider.mpid} key={provider.mpid}>
											{provider.mpname}
										</option>
									))}
								</Form.Control>
								<FormError>{errors.mobileProvider?.message}</FormError>
							</Form.Group>
						</Col>
					</Row>

					<div className="mb-4 mt-2">
						<h4>Report Settings</h4>
					</div>

					<Row>
						<Col md={6} sm={12} className="mb-3">
							<Form.Group controlId="temperatureUnit">
								<Form.Label>Temperature Unit</Form.Label>
								<Form.Control as="select" {...register('temperatureUnit')}>
									<option value="fahrenheit">Fahrenheit</option>
									<option value="celsius">Celsius</option>
								</Form.Control>
								<FormError>{errors.temperatureUnit?.message}</FormError>
							</Form.Group>
						</Col>
						<Col md={6} sm={12} className="mb-3">
							<Form.Group controlId="speedUnit">
								<Form.Label>Speed Unit</Form.Label>
								<Form.Control as="select" {...register('speedUnit')}>
									<option value="miles">Miles Per Hour (mph)</option>
									<option value="kilometers">
										Kilometers per Hour (km / hour)
									</option>
								</Form.Control>
								<FormError>{errors.temperatureUnit?.message}</FormError>
							</Form.Group>
						</Col>
					</Row>

					<div className="mb-4">
						<h4>Notification Option</h4>
					</div>

					<div className="checkbox-container mb-4 gap-1">
						<label htmlFor="email_notification" className="cursor-pointer">
							<input
								type="checkbox"
								id="email_notification"
								value="email"
								name="notification_option"
								onChange={handleNotificationAccess}
								defaultChecked={user?.notifications?.includes('email')}
							/>
							<span className="checkmark" />
							<span className="ms-35">Email</span>
						</label>

						<label htmlFor="phone_notification" className="cursor-pointer">
							<input
								type="checkbox"
								id="phone_notification"
								value="phone"
								name="notification_option"
								onChange={handleNotificationAccess}
								defaultChecked={user?.notifications?.includes('phone')}
							/>
							<span className="checkmark" />
							<span className="ms-35">Phone</span>
						</label>

						<label htmlFor="push_notification" className="cursor-pointer">
							<input
								type="checkbox"
								id="push_notification"
								value="push"
								name="notification_option"
								onChange={handleNotificationAccess}
								defaultChecked={user?.notifications?.includes('push')}
							/>
							<span className="checkmark" />
							<span className="ms-35">Push Notification</span>
						</label>
					</div>

					<div className="button-wrapper">
						<Button
							type="button"
							variant="bordered-green"
							className="me-3"
							onClick={handleClose}
						>
							Close
						</Button>

						<Button type="submit" variant="primary-green" disabled={isLoading}>
							{isLoading ? <Spinner size="sm" animation="border" /> : 'Save'}
						</Button>
					</div>
				</FormWrapper>

				{/**
				 * Put the reset for out side of parent form.
				 * Otherwise parent form will be auto submitted twice.
				 */}
				<ResetPassword
					show={passwordModal}
					handleClose={handleClosePasswordModal}
				/>
			</Modal.Body>
		</Modal>
	);
}
