import Button from 'components/Button';
import DashboardLayout from 'components/Layouts/DashboardLayout';
import SearchInput from 'components/SearchInput';
import Tables from 'components/Tables';
import { deleteEquipment, getEquipments } from 'helpers/api/equipments';
import useResponsive from 'helpers/hooks/useResponsive';
import capitalized from 'helpers/utils/capitalize';
import { useEffect, useState } from 'react';
import { CloseButton, Modal, Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
	/* margin: ${(props) =>
		props.isTablet || props.isMobile ? '0 1rem' : '2rem 2rem 2rem 18rem;'}; */

	h1 {
		font-weight: 700;
		font-family: 'Poppins', sans-serif;
		font-size: 1.75rem;
		margin-top: 0.5rem;
	}

	.search-icon {
		margin-bottom: -0.5rem;
		margin-left: 1rem;
		margin-top: 0.75rem;
		z-index: 99;
	}

	.search-input {
		width: 260px;
		border-radius: 8px;
		background: ${({ theme }) => theme.colors.backgroundLightGray};
		height: 44px;
		font-size: 0.813rem;
		font-weight: 500;
		padding-left: 2.5rem;
		margin-top: -1.5rem;
	}
	.disabled-green {
		color: ${({ theme }) => theme.colors.loginPrimary};
		opacity: 0.65;
	}

	@media (max-width: 450px) {
		.search-input {
			width: 100%;
			margin-bottom: 1.5rem;
		}

		.btn-unstyled-red {
			width: max-content;
			padding: 0rem !important;
			margin: 0rem !important;
		}
	}
`;
const DataWrapper = styled.div`
	background: #ffffff;
	box-shadow: 0px 9px 36px rgba(0, 0, 0, 0.08);
	border-radius: 12px;
	padding: 1rem;
`;
const SelectionModal = styled(Modal)`
	.modal-content {
		padding: 1.5rem;
		border-radius: 20px;
	}

	.close {
		position: absolute;
		top: -2rem;
		right: 0rem;
	}
`;

function EquipmentProfiles() {
	const navigate = useNavigate();
	const { isMobile, isTablet } = useResponsive();
	const [showDelete, setShowDelete] = useState(false);
	const [selectedEquipment, setSelectedEquipment] = useState(null);
	const handleShowDelete = () => setShowDelete(true);
	const handleCloseDelete = () => setShowDelete(false);
	const [filteredEquipments, setFilteredEquipments] = useState([]);
	// TODO: This below line sometimes causes issues
	const { data, isLoading, refetch } = useQuery('equipments', getEquipments, {
		refetchIntervalInBackground: false,
		refetchOnWindowFocus: false,
	});
	const handleSelected = (selected) => () => {
		setSelectedEquipment(selected);
		handleShowDelete();
	};
	const handleDelete = async () => {
		try {
			if (selectedEquipment?.serial) {
				const result = await deleteEquipment(selectedEquipment?.serial);
				if (result.success) {
					toast.success(result.message);
					handleCloseDelete();
					refetch();
				} else {
					toast.error(result.message);
				}
			} else {
				toast.error('Please select equipment first.');
			}
		} catch (error) {
			toast.error(error.message);
		}
	};
	const handleSearch = (e) => {
		setFilteredEquipments(
			data?.data?.equipmentStatus?.filter((equipment) =>
				equipment.serial.toLowerCase().includes(e.target.value.toLowerCase()),
			),
		);
	};
	const handleAddEquipment = () => {
		navigate('/add-equipment');
	};
	useEffect(() => {
		if (data?.data?.equipmentStatus) {
			setFilteredEquipments(
				data?.data?.equipmentStatus.map((es) => ({ ...es.equip_data })),
			);
		}
	}, [data]);
	const columns = [
		{
			name: 'Serial #',
			cell: (row) => {
				if (row?.enabled?.value) {
					return (
						<Link
							to={
								row.type === 'pump'
									? `/pumps/${row.serial}`
									: `/rigs/${row.serial}`
							}
						>
							<Button>{row.serial}</Button>
						</Link>
					);
				}
				return <Button disabled>{row.serial}</Button>;
			},
			selector: (row) => row.serial,
			sortable: true,
			width: '20%',
		},
		{
			name: 'Type',
			selector: (row) => capitalized(row.type),
			sortable: true,
			width: '10%',
		},
		{
			name: 'Yard',
			selector: (row) => row.yard.id,
			sortable: true,
			width: '20%',
		},
		{
			name: 'Enabled',
			selector: (row) => row.status?.value,
			sortable: true,
		},
		{
			name: 'Actions',
			cell: (row) => (
				<div className="flex flex-wrap gap-2">
					<Link to={`/edit-equipment/${row.serial}`} className="flex">
						<Button type="button">
							<FaRegEdit />
						</Button>
					</Link>
					<Button
						type="button"
						variant="delete-secondary"
						className="p-0"
						onClick={handleSelected(row)}
					>
						<FaRegTrashAlt />
					</Button>

					{row?.enabled?.value ? (
						<Link
							to={`/${row?.type}s/${row?.serial}/docsys`}
							className="align-self-center"
						>
							<Button>Docsys</Button>
						</Link>
					) : (
						<Button disabled>Docsys</Button>
					)}
				</div>
			),
		},
	];

	return (
		<DashboardLayout>
			<SelectionModal show={showDelete} onHide={handleCloseDelete} centered>
				<CloseButton
					className="close"
					variant="white"
					onClick={handleCloseDelete}
				/>
				<Modal.Body className="text-center p-5">
					<h5 className="fw-bold">Delete Equipment</h5>
					<h6 className="fw-normal">
						Are you sure you want to delete{' '}
						<span className="fw-bold">{selectedEquipment?.serial}</span>
					</h6>
					<div className="d-flex mt-4 justify-content-center">
						<Button variant="secondary" onClick={handleCloseDelete}>
							Close
						</Button>
						<Button variant="primary" className="mx-2" onClick={handleDelete}>
							Yes, delete it!
						</Button>
					</div>
				</Modal.Body>
			</SelectionModal>
			<Wrapper isMobile={isMobile} isTablet={isTablet}>
				<h1 className="mb-3">Equipment Management</h1>
				<DataWrapper>
					<div className="flex flex-wrap-reverse mb-3">
						<SearchInput
							placeholder="Search equipments"
							onChange={handleSearch}
						/>
						<div className="ml-auto">
							<Button
								variant="primary"
								type="button"
								onClick={handleAddEquipment}
							>
								Add Equipment
							</Button>
						</div>
					</div>
					<Tables
						data={filteredEquipments || []}
						columns={columns}
						progressPending={isLoading}
						progressComponent={
							<Spinner animation="border" variant="primary" size="md" />
						}
						pagination
					/>
				</DataWrapper>
			</Wrapper>
		</DashboardLayout>
	);
}

export default EquipmentProfiles;
