import styled from 'styled-components';

const Wrapper = styled.div`
	/* 225 × 222  */
	width: 225px;
	height: 222px;
	position: relative;
	img {
		width: 100%;
		height: 100%;
	}
	.line {
		position: absolute;
		left: 0;
		top: ${(props) => (props.$maxValue === 240 ? '20px' : '0')};
		right: 0;
		bottom: 0;
		z-index: 9;
		transform: ${(props) => `rotate(${props.$value}deg)`};
	}
	.value {
		position: absolute;
		color: white;
		left: 65px;
		top: ${(props) => (props.$maxValue === 240 ? '130px' : '124px')};
		width: 96px;
		font-size: 13px;
		font-weight: 600;
		text-align: right;
		padding: 0 4px;
	}
`;
function WeightMeter({ value, maxValue = 300 }) {
	const defaultValue = Number.isNaN(value) ? 0 : value;
	const rotation = (defaultValue / maxValue) * 275;
	const weightImg =
		maxValue === 240
			? '/images/weight-gauge-240.svg'
			: '/images/weight-gauge-300.png';
	return (
		<Wrapper $maxValue={maxValue} $value={rotation || 0}>
			<img src={weightImg} alt="" title="weight-gauge" />
			<div className="line" style={{ transition: 'all 1s ease-in-out' }}>
				<img src="/images/line.png" alt="" title="line" />
			</div>
			<span className="value">{parseInt(defaultValue, 10)}</span>
		</Wrapper>
	);
}

export default WeightMeter;
