import styled from 'styled-components';
import TooltipHandler from 'components/TooltipHandler';

const PosiStopContainer = styled.div`
	width: 12px;
	height: 100px;
	cursor: help;
	/* background-color: red; */

	.blockpos-chart {
		margin: auto;
		width: 12px;
		height: 100px;
		box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.25) inset;
		background: rgb(205, 205, 205);
		position: relative;
		background: radial-gradient(
			circle,
			rgba(160, 158, 158, 0.7) 0%,
			rgba(114, 114, 113, 0.7) 100%
		);

		.filler {
			transition: all 4s ease-in-out;
			transform: all 0.3s;
			margin: 2px;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			width: 8px;
			height: 4px;
			background-color: rgba(77, 77, 77, 0.7);
			/* background-color: red; */
		}
	}

	&.active {
		/* background: rgb(255, 226, 225); */
		background: radial-gradient(
			circle,
			rgba(255, 226, 225, 1) 0%,
			rgba(187, 153, 152, 1) 100%
		);
		position: relative;

		.filler {
			transition: all 4s ease-in-out;
			transform: all 0.3s;
			margin: 2px;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			width: 8px;
			height: 4px;
			background-color: #b42123;
		}
	}
`;

export default function PosiStopChart({ data }: { data: any }) {
	const { flag } = data?.status || {};
	let activeFlag = true;

	if (typeof data === 'undefined') {
		return (
			<PosiStopContainer>
				<div className="blockpos-chart">
					<div
						className="filler"
						style={{
							bottom: `${((100 - 20) / 100) * 0}px`,
						}}
					/>
				</div>
			</PosiStopContainer>
		);
	}

	switch (flag) {
		case '0':
		case '9':
			activeFlag = false;
			break;
		case '5':
			activeFlag = false;
			break;
		default:
			activeFlag = true;
			break;
	}

	return (
		<TooltipHandler
			content={`${data?.data?.[0]?.positionPercent?.toFixed(0)}%`}
			placement="right"
		>
			<PosiStopContainer
				className={`blockpos-chart ${activeFlag ? 'active' : 'not'}`}
			>
				<div
					className="filler"
					style={{
						bottom: `${
							((100 - 20) / 100) * (data?.data?.[0]?.positionPercent || 0)
						}px`,
					}}
				/>
			</PosiStopContainer>
		</TooltipHandler>
	);
}
