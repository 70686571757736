import DetailsCard from 'pages/details/DetailsCard';
import { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';

export function getData(unit, value) {
	let correctedValue = value;
	if (unit === 'BPM' && value > 15) {
		correctedValue = 5;
	}
	return [
		['Label', 'Value'],
		[unit, correctedValue],
	];
}

const styles: any = {
	display: 'inline-block',
	flexFlow: 'row wrap',
	justifyContent: 'center',
	alignItems: 'center',
	textAlign: 'center',
};

function PumpFlowGauge({ unit, value }: any) {
	const [data, setData] = useState(getData(unit, value));

	const options = {
		width: 300,
		height: 250,
		minorTicks: 5,
		min: 0,
		max: unit === 'BPM' ? 15 : 200,
	};

	useEffect(() => {
		setData(getData(unit, value));
	}, []);

	return (
		<DetailsCard>
			<div style={styles}>
				<Chart
					chartType="Gauge"
					width="100%"
					height="300px"
					data={data}
					options={options}
				/>
			</div>
		</DetailsCard>
	);
}

export default PumpFlowGauge;
