import Button from 'components/Button';
import { deleteUser } from 'helpers/api/userManagement';
import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';

export default function DeleteUser({ user, update, handleClose, showDelete }) {
	const [isLoading, setIsLoading] = React.useState(false);

	const handleSubmit = async () => {
		try {
			setIsLoading(true);
			const { errors: resErrors } = await deleteUser(user.userid);
			if (resErrors && resErrors.length > 0) {
				toast.error(resErrors[0].message || 'Error deleting user.');
			} else {
				setIsLoading(false);
				handleClose();
				update();
				toast.success('User deleted successfully.');
			}
		} catch (error) {
			toast.error(error.message || 'Something went wrong!');
			setIsLoading(false);
		}
	};

	return (
		<Modal size="md" show={showDelete} centered className="profile-modal">
			<Modal.Body>
				<h2
					className="text-center"
					style={{ fontSize: '1.5rem', lineHeight: '2.6875rem' }}
				>
					Are you sure you want to delete user {user.username}?
				</h2>

				<div className="flex gap-2 justify-center">
					<Button type="button" variant="secondary" onClick={handleClose}>
						Cancel
					</Button>
					<Button type="button" variant="primary" onClick={handleSubmit}>
						{isLoading ? (
							<Spinner size="sm" animation="border" />
						) : (
							'Yes, delete it!'
						)}
					</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
}
