import styled from 'styled-components';

export const CVContainer = styled.div`
	background-color: ${({ theme }) => theme.colors.white};
	height: inherit;
	.camera-feed-container {
		cursor: pointer;
		width: 100%;
		height: inherit;
		.camera-feed {
			width: 99%;
			height: calc(100% - 1rem);
			img {
				width: 100%;
				height: inherit;
				box-shadow: 0px 33px 100px rgba(0, 0, 0, 0.05);
				border-radius: 12px;
				margin-top: 1rem;
				pointer-events: none;
			}
		}
	}
`;

export const CloseButton = styled.div`
	label {
		cursor: pointer;
		position: absolute;
		top: 0;
		right: 5px;
		bottom: 5px;
		height: 30px;
		width: 30px;
		-webkit-transition: all 200ms ease-in-out;
		transition: all 200ms ease-in-out;
	}
`;
