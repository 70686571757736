import React from 'react';
import { Image, Spinner } from 'react-bootstrap';

export default function FullPageSpinner() {
	return (
		<div
			style={{
				display: 'flex',
				width: '100vw',
				height: '100vh',
				position: 'absolute',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<div>
				<Image src="/logo.png" width={140} title="logo" alt="logo" />
			</div>
			<Spinner animation="border" className="mt-4" />
		</div>
	);
}
