/* eslint-disable react/no-unstable-nested-components */
import NoData from 'components/NoData';
import SearchInput from 'components/SearchInput';
import Tables from 'components/Tables';
import { deleteProject, getProjects } from 'helpers/api/projectManagement';
import useResponsive from 'helpers/hooks/useResponsive';
import moment from 'moment';
import { TableContainer } from 'pages/dashboard/tables.styled';
import { useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { UsersContainer } from '../../../components/Profile/users.styled';

export default function ProjectList() {
	const { isTablet, isMobile } = useResponsive();
	const [showDelete, setShowDelete] = useState(false);
	const [projs, setProjs] = useState();
	const [projectToBeDeleted, setProjectToBeDeleted] = useState();
	const [filteredProjects, setFilteredProjects] = useState([]);

	useEffect(() => {
		getProjects().then((res) => {
			const { data } = res;
			setProjs(data.projectsByCustomerId);
			setFilteredProjects(data.projectsByCustomerId);
		});
	}, []);

	const handleShowDelete = (project) => {
		setProjectToBeDeleted(project);
		setShowDelete(true);
	};

	const refetchProjects = () => {
		getProjects().then((res) => {
			const { data } = res;
			setProjs(data.projectsByCustomerId);
		});
	};

	const handleDelete = () => {
		const promise = deleteProject({ id: projectToBeDeleted.pid });
		toast.promise(promise, {
			loading: () => 'Deleting project...',
			success: (res) => {
				refetchProjects();
				return res.message || 'Project deleted successfully!';
			},
			error: (err) => err?.response?.data?.message || err.toString(),
		});
		setShowDelete(false);
		deleteProject({ id: projectToBeDeleted.id });
	};

	const handleSearch = (e) => {
		const value = e.target.value?.toLowerCase();
		setFilteredProjects(
			projs?.filter(
				(item) =>
					item.pprojectid?.toLowerCase()?.includes(value) ||
					item.pname?.toLowerCase()?.includes(value) ||
					item.pclient?.toLowerCase()?.includes(value),
			),
		);
	};

	const handleFilter = (status) => () => {
		if (status === 'All') {
			setFilteredProjects(projs);
		} else if (status === 'Active') {
			setFilteredProjects(
				projs?.filter((item) => moment(item.penddate).diff(moment()) >= 0),
			);
		} else if (status === 'Inactive') {
			setFilteredProjects(
				projs?.filter((item) => moment(item.penddate).diff(moment()) <= 0),
			);
		}
	};

	if (!projs) {
		return (
			<UsersContainer
				$istablet={isTablet}
				$ismobile={isMobile}
				style={{ height: '100vh' }}
			>
				<TableContainer className="h-100">
					<h1 className="assignment-header">Project Management</h1>
					{/* <div
						className="mb-3"
						style={{
							cursor: 'pointer',
							maxWidth: 'fit-content',
						}}
					>
						<Right />
						<Link to="/dashboard">
							<span className="ms-2">Back</span>
						</Link>
					</div> */}

					{/* <h1 className="assignment-header">Project Management</h1> */}

					<div className="d-grid align-items-center justify-items-center h-75">
						<Spinner
							size="md"
							animation="border"
							className="mx-auto mt-5"
							style={{ display: 'grid' }}
						/>
					</div>
				</TableContainer>
			</UsersContainer>
		);
	}

	const columns = [
		{
			name: 'PROJECT ID',
			cell: (row) => (
				<div className="project-id w-fit-content">
					<Link to={`/projects/${row.pid}`}>{row.pprojectid || row.pname}</Link>
				</div>
			),
			selector: (row) => row.pprojectid || row.pname,
			sortable: true,
		},
		{
			name: 'CUSTOMER',
			selector: (row) => row.pclient,
			sortable: true,
		},
		{
			name: 'PAD NAME',
			selector: (row) => row.ppadname,
			sortable: true,
		},
		{
			name: 'WELL NAME',
			selector: (row) => row.pwellname,
			sortable: true,
		},
		{
			name: 'START DATE',
			selector: (row) => row.pbegdate,
			sortable: true,
		},
		{
			name: 'END DATE',
			selector: (row) => row.penddate,
			sortable: true,
		},
		{
			name: 'ACTIONS',
			cell: (row) => (
				<div>
					<Button variant="unstyled-green">
						<Link to={`/edit-project/${row.pid}`}>Edit</Link>
					</Button>
					<Button variant="unstyled-green">
						<Link to={`/projects/logs/${row.pid}`}>Logs</Link>
					</Button>
					<Button variant="unstyled-red" onClick={() => handleShowDelete(row)}>
						Delete
					</Button>
				</div>
			),
			sortable: false,
			width: '18%',
		},
	];
	return (
		<UsersContainer $istablet={isTablet} $ismobile={isMobile}>
			<Modal size="md" show={showDelete} centered className="profile-modal">
				<Modal.Body>
					<h2
						className="text-center"
						style={{ fontSize: '1.5rem', lineHeight: '2.6875rem' }}
					>
						Are you sure you want to delete project ‘
						{projectToBeDeleted?.pprojectid}
						’?
					</h2>

					<div className="text-center mt-5">
						<Button
							type="button"
							variant="bordered-green"
							className="me-3"
							onClick={() => setShowDelete(false)}
						>
							Cancel
						</Button>
						<Button
							type="button"
							variant="primary-green"
							onClick={handleDelete}
						>
							Yes, delete it!
						</Button>
					</div>
				</Modal.Body>
			</Modal>
			<h1 className="assignment-header">Project Management</h1>
			<TableContainer>
				<div className="user-table">
					<div className="flex items-center mb-3">
						<SearchInput placeholder="Search project" onChange={handleSearch} />

						<div className="ml-auto flex">
							<Link to="/create-project">
								<Button className="mr-3" variant="primary-green">
									Create Project
								</Button>
							</Link>
							<Link to="/projects/logs">
								<Button className="" variant="bordered-green">
									Logs
								</Button>
							</Link>
						</div>
					</div>
					<div className="d-flex mb-3 float-start">
						<Button
							variant="text-gray"
							className="ms-auto"
							onClick={handleFilter('All')}
						>
							All
						</Button>
						<Button
							variant="text-gray"
							className="ms-auto"
							onClick={handleFilter('Active')}
						>
							Active
						</Button>
						<Button
							variant="text-gray"
							className="ms-auto"
							onClick={handleFilter('Inactive')}
						>
							Ended
						</Button>
					</div>
					<Tables
						data={filteredProjects || []}
						columns={columns}
						progressComponent={
							<Spinner animation="border" variant="primary" size="md" />
						}
						pagination
					/>
					{/* {filteredProjects?.length === 0 && <NoData />} */}
					{filteredProjects?.length === 0 && <NoData />}
				</div>
			</TableContainer>
		</UsersContainer>
	);
}
