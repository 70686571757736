import {
	FooterLightsWrapper,
	WarningLightsWrapper,
	StatusLightWrapper,
} from './styles/Lights.styled';

export function FooterLights({ title, on }: { title: string; on: boolean }) {
	return (
		<FooterLightsWrapper>
			<div>
				<div
					className="status"
					style={{
						background: on ? '#abff00' : '#E2E2E2',
						boxShadow: on ? '0px 0px 10px 2px #89ff00' : 'none',
					}}
				/>
			</div>
			<span
				style={{
					color: on ? 'black' : '#A7A7A7',
				}}
			>
				{title}
			</span>
		</FooterLightsWrapper>
	);
}
export function WarningLights({ title, on }: { title: string; on: boolean }) {
	let shadow = 'none';

	if (on && title === 'LIMIT') {
		shadow = '0px 0px 4px 4px rgba(198, 32, 33, 0.7)';
	} else if (on && title === 'WARNING') {
		shadow = '0px 0px 4px 4px rgba(223, 180, 27, 0.7)';
	}

	return (
		<WarningLightsWrapper>
			<span>{title}</span>
			<div>
				<div
					className="status"
					style={{
						background: title === 'LIMIT' ? '#C62021' : '#DFB41B',
						boxShadow: shadow,
					}}
				/>
			</div>
		</WarningLightsWrapper>
	);
}
export function StatusLight({ statusDial }: { statusDial: number }) {
	const POSISTOP_DIAL = ['off', 'on', 'set'];
	// POSISTOP_DIAL[data?.posistop_dial || 0]
	const status = POSISTOP_DIAL[statusDial || 0];

	return (
		<StatusLightWrapper>
			<div className="text-container">
				<span
					style={{
						color: status === 'off' ? 'black' : '#A7A7A7',
					}}
				>
					Off
				</span>
				<span
					style={{
						color: status === 'set' ? 'black' : '#A7A7A7',
					}}
				>
					Set
				</span>
				<span
					style={{
						color: status === 'on' ? 'black' : '#A7A7A7',
					}}
				>
					On
				</span>
			</div>
			<div className="lights-container">
				<div
					className="off"
					style={{
						opacity: status === 'off' ? '1' : '0.4',
					}}
				/>
				<div
					className="set"
					style={{
						opacity: status === 'set' ? '1' : '0.4',
					}}
				/>
				<div
					className="on"
					style={{
						opacity: status === 'on' ? '1' : '0.4',
					}}
				/>
			</div>
		</StatusLightWrapper>
	);
}
