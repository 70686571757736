export const worldClockTimezones = [
	{ value: 'Etc/GMT+12', label: 'GMT-12:00' },
	{ value: 'Pacific/Midway', label: 'GMT-11:00' },
	{ value: 'Pacific/Honolulu', label: 'GMT-10:00' },
	{ value: 'America/Anchorage', label: 'GMT-09:00' },
	{ value: 'America/Los_Angeles', label: 'GMT-08:00' },
	{ value: 'America/Denver', label: 'GMT-07:00' },
	{ value: 'America/Chicago', label: 'GMT-06:00' },
	{ value: 'America/New_York', label: 'GMT-05:00' },
	{ value: 'America/Caracas', label: 'GMT-04:30' },
	{ value: 'America/Halifax', label: 'GMT-04:00' },
	{ value: 'America/St_Johns', label: 'GMT-03:30' },
	{ value: 'America/Argentina/Buenos_Aires', label: 'GMT-03:00' },
	{ value: 'America/Noronha', label: 'GMT-02:00' },
	{ value: 'Atlantic/Azores', label: 'GMT-01:00' },
	{ value: 'UTC', label: 'GMT' },
	{ value: 'Europe/Lisbon', label: 'GMT+01:00' },
	{ value: 'Europe/Paris', label: 'GMT+02:00' },
	{ value: 'Europe/Moscow', label: 'GMT+03:00' },
	{ value: 'Asia/Tehran', label: 'GMT+03:30' },
	{ value: 'Asia/Dubai', label: 'GMT+04:00' },
	{ value: 'Asia/Kabul', label: 'GMT+04:30' },
	{ value: 'Asia/Karachi', label: 'GMT+05:00' },
	{ value: 'Asia/Kolkata', label: 'GMT+05:30' },
	{ value: 'Asia/Kathmandu', label: 'GMT+05:45' },
	{ value: 'Asia/Dhaka', label: 'GMT+06:00' },
	{ value: 'Asia/Yangon', label: 'GMT+06:30' },
	{ value: 'Asia/Bangkok', label: 'GMT+07:00' },
	{ value: 'Asia/Shanghai', label: 'GMT+08:00' },
	{ value: 'Asia/Tokyo', label: 'GMT+09:00' },
	{ value: 'Australia/Darwin', label: 'GMT+09:30' },
	{ value: 'Australia/Sydney', label: 'GMT+10:00' },
	{ value: 'Pacific/Noumea', label: 'GMT+11:00' },
	{ value: 'Pacific/Auckland', label: 'GMT+12:00' },
	{ value: 'Pacific/Tongatapu', label: 'GMT+13:00' },
];
