import RigWeightBlockChart from './charts/RigWeightBlockChart';
import SwivelTorquePSIChart from './charts/SwivelTorquePSIChart';
import PumpChart from './charts/PumpChart';
import WellChart from './charts/WellChart';

// TODO: Fetch data from this component and pass to each chart once API is ready.
export default function ProjectCharts() {
	return (
		<div>
			<RigWeightBlockChart />
			<SwivelTorquePSIChart />
			<PumpChart />
			<WellChart />
		</div>
	);
}
