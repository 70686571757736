import React from 'react';
import dieselIcon from 'assets/icons/dieselfluid.png';

const styles = {
	width: 60,
};
function ExhaustFluidIcon() {
	return (
		<span style={styles}>
			<img
				style={{ height: 30 }}
				src={dieselIcon}
				alt="Diesel exhaust fluid icon"
			/>
		</span>
	);
}

export default ExhaustFluidIcon;
