import { getProjectEquipmentList } from 'helpers/api/projectManagement';
import useResponsive from 'helpers/hooks/useResponsive';
import React, { useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import styled from 'styled-components';

const Card = styled.div`
	background: #ffffff;
	box-shadow: 0px 4px 73px rgba(0, 0, 0, 0.06);
	border-radius: 8px;
	padding: 1.25rem;
	height: 600px;
	overflow-y: auto;

	input[type='date'] {
		max-width: 160px;
	}

	input[type='checkbox'] {
		&:checked {
			background-color: ${({ theme }) => theme.colors.loginPrimary};
			border-color: ${({ theme }) => theme.colors.loginPrimary};
		}
	}
	input[type='radio'] {
		&:checked {
			background-color: ${({ theme }) => theme.colors.loginPrimary};
			border-color: ${({ theme }) => theme.colors.loginPrimary};
		}
	}
`;

const CustomFormCheck = styled(Form.Check)`
	input {
		width: 24px;
		height: 24px;
		padding: 0px;
	}
`;

export default function EqSelectionForm({ next, prev, formData, update }) {
	const [selectedRig, setSelectedRig] = React.useState([]);
	const [selectedPumps, setSelectedPumps] = React.useState([]);
	const { isMobile } = useResponsive();
	const { data, isLoading } = useQuery(
		'projectEquipments',
		getProjectEquipmentList
	);

	const handleSelectedRig = (e) => {
		const { value } = e.target;
		setSelectedRig([
			{
				serial: value,
			},
		]);
	};
	const handleSelectedPumps = (e) => {
		const { value, checked } = e.target;
		if (checked) {
			setSelectedPumps([
				...selectedPumps,
				{
					serial: value,
				},
			]);
		} else {
			const newSelectedPumps = selectedPumps.filter(
				(pump) => pump.serial !== value
			);
			setSelectedPumps(newSelectedPumps);
		}
	};
	const handleDateChange = (type) => (e) => {
		const { id, name, value } = e.target;
		// Set selected rig date to selectedRig array
		if (type === 'rig') {
			setSelectedRig((prevState) =>
				prevState.map((rig) => {
					if (rig.serial === id) {
						return {
							...rig,
							[name]: value,
						};
					}
					return rig;
				})
			);
		} else {
			setSelectedPumps((prevState) =>
				prevState.map((pump) => {
					if (pump.serial === id) {
						return {
							...pump,
							[name]: value,
						};
					}
					return pump;
				})
			);
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const selectedEquipment = [...selectedPumps, ...selectedRig];
		const selectedEquipmentSerials = selectedEquipment?.map((eq) => eq.serial);
		update({
			eq_data: selectedEquipment,
			peqarry: selectedEquipmentSerials,
		});
		next();
	};

	useEffect(() => {
		setSelectedRig(
			// data?.filter((e) => e.eq_type === "rig" && formData?.eq_data?.map((eq) => eq.serial === e.serial))
			formData?.eq_data?.filter((e) =>
				data?.find((eq) => eq.eq_type === 'rig' && eq?.serial === e?.serial)
			)
		);
		setSelectedPumps(
			formData?.eq_data?.filter((e) =>
				data?.find((eq) => eq.eq_type === 'pump' && eq?.serial === e?.serial)
			)
		);
	}, [formData]);

	if (isLoading) {
		return <div>Loading...</div>;
	}

	return (
		<Form onSubmit={handleSubmit}>
			<Row>
				<Col lg={6}>
					<h5>Rigs</h5>
					<Card>
						{data
							?.filter((eq) => eq.eq_type === 'rig')
							.map((eq) => (
								<div
									className={isMobile ? 'mb-3' : 'd-flex mb-3'}
									key={eq.serial}
								>
									<Form.Group
										key={eq.serial}
										controlId={`${eq.id}`}
										className="d-flex"
									>
										<CustomFormCheck
											type="radio"
											label={eq.eq_name}
											name="rig"
											className="mt-2"
											value={eq.serial}
											checked={
												!!selectedRig?.find((i) => i.serial === eq.serial)
											}
											onChange={handleSelectedRig}
										/>
										<Form.Label className="ps-2 align-self-center mb-0">
											{eq.serial}
										</Form.Label>
									</Form.Group>
									<Form.Control
										type="date"
										name="begdate"
										id={eq.serial}
										className={isMobile ? 'mt-2' : 'ms-auto'}
										disabled={
											!selectedRig.find((item) => item.serial === eq.serial)
										}
										defaultValue={
											selectedRig?.find((item) => item.serial === eq.serial)
												?.begdate
										}
										min={formData.pbegdate}
										max={formData.penddate}
										onChange={handleDateChange('rig')}
									/>
									<Form.Control
										type="date"
										name="enddate"
										id={eq.serial}
										className={isMobile ? 'mt-2' : 'ms-3'}
										disabled={
											!selectedRig.find((item) => item.serial === eq.serial)
										}
										defaultValue={
											selectedRig?.find((item) => item.serial === eq.serial)
												?.enddate
										}
										min={formData.pbegdate}
										max={formData.penddate}
										onChange={handleDateChange('rig')}
									/>
								</div>
							))}
					</Card>
				</Col>
				<Col lg={6}>
					<h5>Pumps</h5>
					<Card>
						{data
							?.filter((eq) => eq.eq_type === 'pump')
							.map((eq) => (
								<div
									className={isMobile ? 'mb-3' : 'd-flex mb-3'}
									key={eq.serial}
								>
									<Form.Group
										key={eq.serial}
										controlId={`${eq.id}`}
										className="d-flex"
									>
										<CustomFormCheck
											type="checkbox"
											label={eq.eq_name}
											name="rig"
											className="mt-2"
											value={eq.serial}
											disabled={
												selectedPumps.length >= 2 &&
												!selectedPumps.find((i) => i.serial === eq.serial)
											}
											checked={
												!!selectedPumps?.find((i) => i.serial === eq.serial)
											}
											onChange={handleSelectedPumps}
										/>
										<Form.Label className="ps-2 align-self-center mb-0">
											{eq.serial}
										</Form.Label>
									</Form.Group>
									<Form.Control
										type="date"
										name="begdate"
										id={eq.serial}
										className={isMobile ? 'mt-2' : 'ms-auto'}
										disabled={
											!selectedPumps.find((item) => item.serial === eq.serial)
										}
										defaultValue={
											selectedPumps?.find((item) => item.serial === eq.serial)
												?.begdate
										}
										min={formData.pbegdate}
										max={formData.penddate}
										onChange={handleDateChange('pump')}
									/>
									<Form.Control
										type="date"
										name="enddate"
										id={eq.serial}
										className={isMobile ? 'mt-2' : 'ms-3'}
										disabled={
											!selectedPumps.find((item) => item.serial === eq.serial)
										}
										defaultValue={
											selectedPumps?.find((item) => item.serial === eq.serial)
												?.enddate
										}
										min={formData.pbegdate}
										max={formData.penddate}
										onChange={handleDateChange('pump')}
									/>
								</div>
							))}
					</Card>
				</Col>
				<Row className="process">
					<button type="button" className="alt-button ms-auto" onClick={prev}>
						Previous
					</button>
					<button type="submit" className="primary-button">
						Next
					</button>
				</Row>
			</Row>
		</Form>
	);
}
