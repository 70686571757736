import { gql } from '@apollo/client';

export const PROJECTS_BY_COMPANY_ID_QUERY = gql`
	query ProjectsByCustomerId {
		projectsByCustomerId {
			pid
			puid
			pcid
			pcoordinates
			paltitude
			pradius
			pname
			pprojectid
			pbegdate
			penddate
			peqarry
			pwellid
			pwellnumber
			pclient
			pleasename
			pwellname
			ppadname
			punit
			pafe
			ptubingsize
			pdatapoints
			passembly_length
			pfloor_height
			pkb_height
			charts
			customer {
				cid
				customid
				cname
				caddress1
				caddress2
				ccity
				cstate
				czip
				cphone
				cfax
				logo
				features
			}
			equipment_project_dates {
				epid
				epequipment_serial
				epproject_id
				epstart_date
				epend_date
				epstandby
			}
		}
	}
`;

export const PROJECT_BY_ID_QUERY = gql`
	query ProjectById($id: String!) {
		projectById(id: $id) {
			pid
			puid
			pcid
			pcoordinates
			paltitude
			pradius
			pname
			pprojectid
			pbegdate
			penddate
			peqarry
			pwellid
			pwellnumber
			pclient
			pleasename
			pwellname
			ppadname
			punit
			pafe
			ptubingsize
			pdatapoints
			passembly_length
			pfloor_height
			pkb_height
			charts
			customer {
				cid
				customid
				cname
				caddress1
				caddress2
				ccity
				cstate
				czip
				cphone
				cfax
				logo
				features
			}
			equipment_project_dates {
				epid
				epequipment_serial
				epproject_id
				epstart_date
				epend_date
				epstandby
			}
		}
	}
`;

export const DELETE_PROJECT_BY_ID_MUTATION = gql`
	mutation DeleteProjectById($id: ID!) {
		deleteProject(id: $id)
	}
`;

export const CREATE_PROJECT_MUTATION = gql`
	mutation CreateProject(
		$pname: String!
		$pbegdate: String!
		$penddate: String!
		$pprojectid: String!
		$pwellid: String!
		$pclient: String!
		$peqarry: String
		$pcoordinates: String!
		$pwellname: String!
		$pwellnumber: String!
		$ppadname: String!
		$pafe: String!
		$ptubingsize: String!
		$punit: String!
		$passembly_length: Float!
		$pfloor_height: Float!
		$pkb_height: Float!
		$charts: String!
		$eq_data: String!
		$clientusers: String!
	) {
		createProject(
			projectInput: {
				pname: $pname
				pbegdate: $pbegdate
				penddate: $penddate
				pprojectid: $pprojectid
				pwellid: $pwellid
				pclient: $pclient
				peqarry: $peqarry
				pcoordinates: $pcoordinates
				pwellname: $pwellname
				pwellnumber: $pwellnumber
				ppadname: $ppadname
				pafe: $pafe
				ptubingsize: $ptubingsize
				punit: $punit
				passembly_length: $passembly_length
				pfloor_height: $pfloor_height
				pkb_height: $pkb_height
				charts: $charts
				eq_data: $eq_data
				clientusers: $clientusers
			}
		)
	}
`;

export const EDIT_PROJECT_BY_ID_MUTATION = gql`
	mutation EditProjectById(
		$id: Float!
		$pname: String!
		$pbegdate: String!
		$penddate: String!
		$pprojectid: String!
		$pwellid: String!
		$pclient: String!
		$peqarry: String
		$pcoordinates: String!
		$pwellname: String!
		$pwellnumber: String!
		$ppadname: String!
		$pafe: String!
		$ptubingsize: String!
		$punit: String!
		$passembly_length: Float!
		$pfloor_height: Float!
		$pkb_height: Float!
		$charts: String!
		$eq_data: String!
		$clientusers: String!
	) {
		editProject(
			editProjectInput: {
				id: $id
				pname: $pname
				pbegdate: $pbegdate
				penddate: $penddate
				pprojectid: $pprojectid
				pwellid: $pwellid
				pclient: $pclient
				peqarry: $peqarry
				pcoordinates: $pcoordinates
				pwellname: $pwellname
				pwellnumber: $pwellnumber
				ppadname: $ppadname
				pafe: $pafe
				ptubingsize: $ptubingsize
				punit: $punit
				passembly_length: $passembly_length
				pfloor_height: $pfloor_height
				pkb_height: $pkb_height
				charts: $charts
				eq_data: $eq_data
				clientusers: $clientusers
			}
		)
	}
`;

export const SMS_DATA_BY_PROJECT_ID_QUERY = gql`
	query SMSDataByProjectId($id: String!) {
		smsDataByProjectId(projectId: $id) {
			smsid
			smsnumber
			smsto
			smsissuecode
			smsmsgid
			smsresponseid
			smsstatus
			smslastaction
		}
	}
`;

export const CREATE_MESSAGE_BOARD_FROM_PROJECT_ID_MUTATION = gql`
	mutation CreateMessageBoardFromProjectId(
		$createMessageBoardArgs: CreateMessageBoardArgs!
	) {
		createMessageBoard(createMessageBoardArgs: $createMessageBoardArgs) {
			mbid
		}
	}
`;

export const EVENTS_DATA_BY_PROJECT_ID_QUERY = gql`
	query EventsDataByProjectId($id: String!) {
		eventsDataByProjectId(id: $id) {
			rigs {
				date
				time
				value
				xflag
				message
				posi_on
			}
			swivels {
				date
				time
				value
				xflag
				message
				posi_on
			}
			pumps {
				date
				time
				value
				xflag
				message
				posi_on
			}
		}
	}
`;

export const PIPE_DAMAGE_CODE_QUERY = gql`
	query PipeDamageCodes {
		pipeDamageCodes {
			id
			code
			description
		}
	}
`;

export const CHARTS_DATA_BY_PROJECT_ID_QUERY = gql`
	query ChartsDataByProjectId($id: String!, $graphs: String!) {
		chartsDataByProjectId(id: $id, graphs: $graphs) {
			charts {
				drawworks
				weight
				position
				swivelrpm
				swiveltorque
				swivelpressure
				pump_flow
				pump_psig
				well_flow
				well_psig
			}
			coords {
				info
			}
			status {
				connection
			}
		}
	}
`;
