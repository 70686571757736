import { TableContainer } from 'pages/dashboard/tables.styled';
import { Row, Col, Button, Modal, Spinner } from 'react-bootstrap';
import useResponsive from 'helpers/hooks/useResponsive';
import { ReactComponent as Right } from 'assets/icons/right-arrow.svg';
import { Link, useParams } from 'react-router-dom';
import React from 'react';
import { getProjectLogById } from 'helpers/api/projectManagement';
import { useQuery } from 'react-query';
import NoData from 'components/NoData';
import { useReactToPrint } from 'react-to-print';
import { UsersContainer } from 'components/Profile/users.styled';

export default function SingleProjectLogListMobile() {
	const { isTablet, isMobile } = useResponsive();
	const [showDelete, setShowDelete] = React.useState(false);

	const { id } = useParams();
	const { data, isFetching } = useQuery('SingleProjectLog', () =>
		getProjectLogById(id),
	);

	const componentPrintRef = React.useRef();

	const handlePrint = useReactToPrint({
		content: () => componentPrintRef.current,
	});

	if (isFetching) {
		return (
			<UsersContainer $istablet={isTablet} $ismobile={isMobile}>
				<div
					className="mb-3 flex items-center"
					style={{
						cursor: 'pointer',
						maxWidth: 'fit-content',
					}}
				>
					<Right />
					<Link
						to="/projects"
						style={{
							textDecoration: 'none',
							color: 'black',
							paddingRight: '1.25rem',
							borderRight: '1px solid #dddddd',
						}}
					>
						<span className="ms-2">Back</span>
					</Link>
					<Link
						to="/projects"
						style={{
							textDecoration: 'none',
							color: 'black',
							paddingLeft: '0.75rem',
						}}
					>
						<span className="ms-2" style={{ color: '#00834D' }}>
							Projects
						</span>
						<span> {'>'} Logs</span>
					</Link>
				</div>

				{/* <h1 className="my-4">Project {id} Log</h1> */}

				<div className="text-center">
					<Spinner size="md" className="mt-3" animation="border" />
				</div>
			</UsersContainer>
		);
	}

	if (data?.error) {
		return (
			<UsersContainer
				isTablet={isTablet}
				isMobile={isMobile}
				style={{ height: '60vh' }}
				className="d-grid"
			>
				<div
					className="mb-3 flex items-center"
					style={{
						cursor: 'pointer',
						maxWidth: 'fit-content',
					}}
				>
					<Right />
					<Link
						to="/projects"
						style={{
							textDecoration: 'none',
							color: 'black',
							paddingRight: '1.25rem',
							borderRight: '1px solid #dddddd',
						}}
					>
						<span className="ms-2">Back</span>
					</Link>
					<Link
						to="/projects"
						style={{
							textDecoration: 'none',
							color: 'black',
							paddingLeft: '0.75rem',
						}}
					>
						<span className="ms-2" style={{ color: '#00834D' }}>
							Projects
						</span>
						<span> {'>'} Logs</span>
					</Link>
				</div>

				<NoData />
			</UsersContainer>
		);
	}

	return (
		<UsersContainer $istablet={isTablet} $ismobile={isMobile}>
			<Modal size="md" show={showDelete} centered className="profile-modal">
				<Modal.Body>
					<h2
						className="text-center"
						style={{ fontSize: '1.5rem', lineHeight: '2.6875rem' }}
					>
						Are you sure you want to delete project ‘COMBOTEST’?
					</h2>

					<div className="text-center mt-5">
						<button type="button" className="alt-button">
							Cancel
						</button>
						<button
							type="button"
							className="primary-button"
							onClick={() => setShowDelete(false)}
						>
							Yes, delete it!
						</button>
					</div>
				</Modal.Body>
			</Modal>

			<TableContainer ref={componentPrintRef}>
				<div
					className="mb-3 flex items-center"
					style={{
						cursor: 'pointer',
						maxWidth: 'fit-content',
					}}
				>
					<Right />
					<Link
						to="/projects"
						style={{
							textDecoration: 'none',
							color: 'black',
							paddingRight: '1.25rem',
							borderRight: '1px solid #dddddd',
						}}
					>
						<span className="ms-2">Back</span>
					</Link>
					<Link
						to="/projects"
						style={{
							textDecoration: 'none',
							color: 'black',
							paddingLeft: '0.75rem',
						}}
					>
						<span className="ms-2" style={{ color: '#00834D' }}>
							Projects
						</span>
						<span> {'>'} Logs</span>
					</Link>
				</div>

				{/* <div className="text-center">
					<Spinner size="md" className="mt-3" animation="border" />
				</div> */}

				{/* <div className="user-table"> */}
				<div
					style={{ display: 'flex', textAlign: 'center' }}
					className="align-items-center w-100"
				>
					<h1 className="my-4 fs-1 ms-0">Project {id} Log</h1>
					<Button
						className="alt-button ms-auto align-self-center height-fit-content"
						onClick={handlePrint}
					>
						Print
					</Button>
				</div>

				{data?.data?.apiLogsByProjectId?.map((item) => (
					<Row className="project-list-mobile mb-3">
						<Col>
							<div className="display-grid mb-3">
								<span className="tags">Time</span>
								<span className="time">
									{item.savetime.replace(' ', ' | ')}
								</span>
							</div>
							<div className="display-grid mb-3">
								<span className="tags">Request</span>
								<span>{item.request_type}</span>
							</div>

							<div className="display-grid mb-3">
								<span className="tags">Successful</span>
								{item.response_error === '0' ? (
									<div className="successful">True</div>
								) : (
									<div className="fail">False</div>
								)}
							</div>
						</Col>
						<Col>
							<div className="display-grid mb-3">
								<span className="tags">User</span>
								<span>{item.username}</span>
							</div>
							<div className="display-grid mb-3">
								<span className="tags">Query</span>
								<span>{item.requestaction}</span>
							</div>
							{/* <div className="display-grid mb-3">
								<span className="tags">Conditions</span>
								<span>Aug 6, 2021</span>
							</div> */}
						</Col>
					</Row>
				))}

				{/* <Row className="project-list-mobile mb-3">
					<Col>
						<div className="display-grid mb-3">
							<span className="tags">Time</span>
							<span className="project-id">
								<Link to="/">COMBOTEST</Link>
							</span>
						</div>
						<div className="display-grid mb-3">
							<span className="tags">Request</span>
							<span>Aug 6, 2021</span>
						</div>

						<div className="display-grid mb-3">
							<span className="tags">Successful</span>
							<div className="successful">False</div>
						</div>
					</Col>
					<Col>
						<div className="display-grid mb-3">
							<span className="tags">User</span>
							<span>EPA Shop</span>
						</div>
						<div className="display-grid mb-3">
							<span className="tags">Query</span>
							<span>First well</span>
						</div>
						<div className="display-grid mb-3">
							<span className="tags">Conditions</span>
							<span>Aug 6, 2021</span>
						</div>
					</Col>
				</Row> */}
				{/* </div> */}
			</TableContainer>
		</UsersContainer>
	);
}
