/* eslint-disable no-nested-ternary */
import {
	Modal,
	Button,
	Spinner,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';
import React, { useState } from 'react';
import styled from 'styled-components';
import { getProjectEvents } from 'helpers/api/projectManagement';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { ReactComponent as EventsIcon } from 'assets/icons/events.svg';

const EventWrapper = styled.div`
	padding: 1.5rem;
	border-radius: 8px;
	border: 1px solid ${({ theme }) => theme.colors.gray2};
	gap: 1rem;

	.tags {
		letter-spacing: 0.02em;
		text-transform: uppercase;
		color: ${({ theme }) => theme.colors.lightGray};
	}
	div {
		flex: 1;
	}
`;

function EventView({ message, date, value }) {
	return (
		<EventWrapper className="mb-2 d-flex justify-content-around flex-wrap">
			<div className="d-grid">
				<span className="tags">Date</span>
				<span>{date?.split(' ')[0]}</span>
			</div>
			<div className="d-grid">
				<span className="tags">Time</span>
				<span>{date?.split(' ')[1]}</span>
			</div>
			<div className="d-grid">
				<span className="tags">Message</span>
				<span>{message}</span>
			</div>
			<div className="d-grid">
				<span className="tags">Value</span>
				<span>{value}</span>
			</div>
		</EventWrapper>
	);
}

export default function ProjectEvents() {
	const [modalShow, setModalShow] = useState(false);
	const { id } = useParams();

	const handleShow = () => setModalShow(true);

	const { data, isFetching } = useQuery(
		'ProjectEvents',
		() => getProjectEvents(id),
		{
			refetchIntervalInBackground: false,
			refetchOnWindowFocus: false,
		}
	);

	const dataList = [
		...(data?.pumps || []),
		...(data?.rigs || []),
		...(data?.swivels || []),
	];

	return (
		<>
			<OverlayTrigger
				placement="top"
				trigger="hover"
				overlay={
					<Tooltip id="tooltip-top">
						<span>View Events</span>
					</Tooltip>
				}
			>
				<EventsIcon
					onClick={handleShow}
					className="ms-4 align-self-center cursor-pointer"
				/>
			</OverlayTrigger>

			<Modal
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={modalShow}
				onHide={() => setModalShow(false)}
				className="profile-modal"
			>
				<Modal.Body>
					<div className="information-container">
						<span className="information">Project event board</span>
					</div>

					{isFetching ? (
						<div className="text-center">
							<Spinner animation="border" size="md" />
						</div>
					) : dataList?.length > 0 ? (
						<div className="mb-4">
							{dataList.map((eventList) => {
								if (Array.isArray(eventList)) {
									eventList.map((subEvent) => (
										<EventView {...subEvent} key={`${subEvent.time}`} />
									));
								}

								return <EventView {...eventList} key={eventList.time} />;
							})}
						</div>
					) : (
						<div className="text-center gray-text my-5">
							<span className="fs-5">No events found</span>
						</div>
					)}

					<div className="button-wrapper">
						<Button
							type="button"
							variant="bordered-green"
							className="me-0"
							onClick={() => setModalShow(false)}
						>
							Close
						</Button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}
