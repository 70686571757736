/* eslint-disable react/jsx-no-constructed-context-values */
import { getActiveAlerts } from 'helpers/api/alerts';
import { getEquipments } from 'helpers/api/equipments';
import { VIEWS } from 'helpers/const/views';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';

const DashboardContext = React.createContext(null);

function DashboardContextProvider({ children }) {
	const [view, setView] = React.useState(VIEWS.LIST);
	const equipmentsQuery = useQuery('equipments', getEquipments, {
		refetchInterval: 10000,
		refetchIntervalInBackground: false,
		refetchOnWindowFocus: false,
	});
	const alertQuery = useQuery('alert', getActiveAlerts, {
		refetchInterval: 10000,
		refetchIntervalInBackground: false,
		refetchOnWindowFocus: false,
	});
	const now = new Date();
	const actalert = JSON.parse(localStorage.getItem(`activeAlerts`)) || [];
	if (
		alertQuery &&
		alertQuery != null &&
		alertQuery.data &&
		alertQuery.data != null &&
		alertQuery.data.notifications != null &&
		alertQuery.data.notifications.length
	) {
		alertQuery.data.notifications.forEach((alert) => {
			const aid = alert.id;
			if (actalert.length > 0) {
				const inarr = actalert.filter((a2) => a2.id === aid);
				if (!inarr) {
					const objAlert = { id: aid, time: now.getTime() };
					actalert.push(objAlert);
				}
			} else {
				Notification.requestPermission().then((perm) => {
					if (perm === 'granted') {
						const msg = `${alert.name} for ${alert.serial}`;
						const noti = new Notification('Push Notification', {
							body: msg,
							icon: '/static/media/logo-minify.9b4bfbcf117da3539112.png',
						});
					}
				});
				const objAlert = { id: aid, time: now.getTime() };
				actalert.push(objAlert);
			}
		});
	}
	if (actalert && actalert != null) {
		let x = 0;
		actalert.forEach((alert) => {
			if (now.getTime() - alert.time > 120000) {
				actalert.splice(x, 1);
			}
			x += 1;
		});
		if (actalert.length === 0) {
			localStorage.removeItem('activeAlerts');
		} else {
			localStorage.setItem(`activeAlerts`, JSON.stringify(actalert));
		}
	}
	const changeView = React.useCallback((type) => setView(type), []);

	const [filterState, setFilterState] = React.useState({
		search: '',
		connection: '',
		type: '',
	});

	const equipments = equipmentsQuery?.data?.data?.equipmentStatus;
	const filteredResult = useMemo(() => {
		if (!equipments) {
			return [];
		}
		const { search, connection, type } = filterState;

		const connectionCond = ({ equip_data : eq}) => {
			if (connection === 'not-activated') {
				return eq.status.value === 'Not Activated';
			}
			if (connection === 'Online') {
				return eq.status.value === 'Online';
			}
			if (connection === 'Offline') {
				return eq.status.value === 'Offline';
			}
			return true;
		};

		const filteredEquipments = equipments
			?.filter?.(
				(equipment) =>
					equipment?.equip_serial
						?.toLowerCase()
						.includes(search?.toLowerCase()) &&
					connectionCond(equipment) &&
					equipment?.equip_data?.type
						?.toLowerCase()
						.includes(type?.toLowerCase()),
			)
			?.map((eq) => ({
				...eq.equip_data,
			}));
		return filteredEquipments;
	}, [equipments, filterState]);

	const timezones = useMemo(() => {
		const eqRegions =
			filteredResult?.length > 0
				? [...new Set(filteredResult?.map((eq) => eq.region.id))]
				: [];

		return eqRegions.map((region) => {
			const eqs = filteredResult?.filter((eq) => eq.region.id === region);

			const y = eqs.map((eq) => eq.yard.id);

			const regName = eqs.map((eq) => eq.yard.name);

			return { reg: regName, name: region, yards: [...new Set(y)] };
		});
	}, [filteredResult]);

	return (
		<DashboardContext.Provider
			value={{
				view,
				changeView,
				equipments,
				timezones,
				filterState,
				setFilterState,
				filteredResult,
			}}
		>
			{children}
		</DashboardContext.Provider>
	);
}
function useDashboard() {
	const context = React.useContext(DashboardContext);
	if (context === undefined) {
		throw new Error(`useDashboard must be used within a DashboardProvider`);
	}
	return context;
}

export { DashboardContext, DashboardContextProvider, useDashboard };
