import { gql } from '@apollo/client';

export const NOTIFICATION_QUERY = gql`
	query Notifications {
		notifications {
			id
			user
			rignumber
			event
			type
			lastoccurence
			emailtoken
			mobile
			log
			alert_functions {
				id
				identifier
				name
				description
				tags
				functions
				available
			}
		}
	}
`;