import useResponsive from 'helpers/hooks/useResponsive';
import MobileListView from './MobileListView';
import ListView from './ListView';

export default function ListViewIndex() {
	const { isMobile } = useResponsive();

	if (isMobile) {
		return <MobileListView />;
	}

	return <ListView />;
}
