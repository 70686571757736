const rigChartLabels = {
	weight: 'Weight in 1000 Pounds',
	weight_on_hook: 'WOH',
	weight_on_bit: 'WOB',
	block_empty: 'Dead Weight',
	max_weight: 'Max Weight',
	total_weight: 'Total',
	block_travel: 'Block Position %',
	block_position: 'Position',
	drawworks: 'Drawworks RPM',
	drawworks_rpm: 'RPM',
	swivel: 'Swivel',
	tong: 'Tong',
	tong_rpm: 'RPM',
	tong_rpm_key: 'tongrpm',
	tong_torque: 'Torque',
	tong_torque_key: 'tongtorque',
	tong_psi: 'Pressure',
	tong_psi_key: 'tongpressure',
	swivel_rpm: 'RPM',
	swivel_torque: 'Torque',
	swivel_psi: 'Pressure',
	pump: 'Pump Discharge',
	pump_flow: 'Flow Rate',
	pump_psi: 'Pressure',
	well: 'Well Discharge',
	well_flow: 'Flow Rate',
	well_psi: 'Pressure',
};

const pumpChartLabels = {
	pump_flow: 'Flow Rate',
	pump_psi: 'Pressure',
	pump_suction: 'Suction',
	pump_strokes: 'Strokes per Minute',
};

const projectChartsLabels = {
	rig_weight_combo: 'Weight & Block Position',
	rig_weight: 'Weight in 1000 Pounds',
	rig_block: 'Block Position',
	swivel_combo: 'Swivel RPM & Torque',
	swivel_rpm: 'RPM',
	swivel_torque: 'Torque',
	pump_combo: 'Pump Discharge',
	pump_flow: 'Pump Flow Rate',
	pump_psi: 'Pump PSIG',
	well_combo: 'Well Discharge',
	well_flow: 'Well Flow Rate',
	well_psi: 'Well PSIG',
};

export { rigChartLabels, pumpChartLabels, projectChartsLabels };
