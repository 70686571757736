import UOM from 'helpers/const/activityUOM';
import capitalize from 'helpers/utils/capitalize';
import { ActivityContainer } from 'pages/details/styled';
import TableActivityBreakdown from 'pages/details/tableActivity/TableActivityBreakdown';
import TableActivityHourly from 'pages/details/tableActivity/TableActivityHourly';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

function TableActivity() {
	const [hourly, setHourly] = React.useState(true);
	const { id } = useParams();

	const handleDaily = () => {
		setHourly(!hourly);
	};

	const prettifyCategory = (str) => {
		let a = str;
		a = a.replaceAll('_', ' ');
		a = capitalize(a);
		return a;
	};

	return (
		<ActivityContainer>
			<div className="table-container">
				<Row>
					<Col lg={6}>
						<h1>Drawworks Activity Table</h1>
					</Col>
					<Col lg={6} className="change-container">
						<button type="button" onClick={handleDaily} className="change">
							{hourly ? 'Set to 15 minutes' : 'Set to Hour'}
						</button>
					</Col>
				</Row>

				{hourly ? (
					<TableActivityHourly
						id={id}
						UOM={UOM}
						prettifyCategory={prettifyCategory}
					/>
				) : (
					<TableActivityBreakdown
						id={id}
						UOM={UOM}
						prettifyCategory={prettifyCategory}
					/>
				)}
			</div>
		</ActivityContainer>
	);
}

export default TableActivity;
