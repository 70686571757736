import { getToken } from 'helpers/utils/auth-provider';
import {
	TONGS_QUERY,
	CREATE_TONG_MUTATION,
	UPDATE_TONG_BY_ID_MUTATION,
	DELETE_TONG_BY_ID_MUTATION,
} from '../queries/tongsQuery';
import client from './apolloClient';

export const getTongs = () =>
	client.query({
		query: TONGS_QUERY,
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
		fetchPolicy: 'network-only',
	});

export const createTong = (data) =>
	client.mutate({
		mutation: CREATE_TONG_MUTATION,
		variables: {
			tongArgs: { ...data },
		},
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
	});

export const updateTong = (data) =>
	client.mutate({
		mutation: UPDATE_TONG_BY_ID_MUTATION,
		variables: {
			tongArgs: { ...data },
		},
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
	});

export const deleteTong = (id) =>
	client.mutate({
		mutation: DELETE_TONG_BY_ID_MUTATION,
		variables: { tongId: id },
		context: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
	});
