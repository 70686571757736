/* eslint-disable react/no-unstable-nested-components */
import { Button, Spinner } from 'react-bootstrap';
import useResponsive from 'helpers/hooks/useResponsive';
import { ReactComponent as Right } from 'assets/icons/right-arrow.svg';
import { Link, useParams } from 'react-router-dom';
import { getProjectLogById } from 'helpers/api/projectManagement';
import { useQuery } from 'react-query';
import NoData from 'components/NoData';
import React from 'react';
import { useReactToPrint } from 'react-to-print';
import { LogContainer } from 'pages/projectmanagement/project.styled';
import Tables from 'components/Tables';

export default function SingleProjectLogList() {
	const { isTablet, isMobile } = useResponsive();
	const { id } = useParams();
	const { data, isFetching } = useQuery('SingleProjectLog', () =>
		getProjectLogById(id),
	);

	const componentPrintRef = React.useRef();

	const handlePrint = useReactToPrint({
		content: () => componentPrintRef.current,
	});

	const columns = [
		{
			name: 'TIME',
			cell: (row) => row?.savetime?.replace(' ', ' | '),
			selector: (row) => row.savetime,
			sortable: true,
		},
		{
			name: 'USER',
			selector: (row) => row?.username,
			sortable: true,
		},
		{
			name: 'REQUEST',
			selector: (row) => row?.request_type,
			cell: (row) => (
				<span className={row?.request_type === 'DELETE' ? 'delete' : 'create'}>
					{row?.request_type}
				</span>
			),
			sortable: true,
		},
		{
			name: 'SUCCESSFUL',
			selector: (row) => row?.response_error,
			cell: (row) => {
				if (row?.response_error === '0') {
					return <div className="successful">True</div>;
				}
				return <div className="fail">False</div>;
			},
			sortable: true,
		},
		{
			name: 'QUERY',
			selector: (row) => row?.requestaction,
			sortable: true,
		},
	];

	if (isFetching) {
		return (
			<LogContainer isTablet={isTablet} isMobile={isMobile}>
				<div
					className="mb-3 flex items-center"
					style={{
						cursor: 'pointer',
						maxWidth: 'fit-content',
					}}
				>
					<Right />
					<Link
						to="/projects"
						style={{
							textDecoration: 'none',
							color: 'black',
							paddingRight: '1.25rem',
							borderRight: '1px solid #dddddd',
						}}
					>
						<span className="ms-2">Back</span>
					</Link>
					<Link
						to="/projects"
						style={{
							textDecoration: 'none',
							color: 'black',
							paddingLeft: '0.75rem',
						}}
					>
						<span className="ms-2" style={{ color: '#00834D' }}>
							Projects
						</span>
						<span> {'>'} Logs</span>
					</Link>
				</div>

				<div className="text-center h-86 d-grid align-items-center justify-items-center">
					<Spinner size="md" className="mt-3" animation="border" />
				</div>
			</LogContainer>
		);
	}

	if (data?.error) {
		return (
			<LogContainer
				isTablet={isTablet}
				isMobile={isMobile}
				className="d-grid"
				style={{ height: '60vh' }}
			>
				<div
					className="mb-5 flex items-center"
					style={{
						cursor: 'pointer',
						maxWidth: 'fit-content',
					}}
				>
					<Right />
					<Link
						to="/projects"
						style={{
							textDecoration: 'none',
							color: 'black',
							paddingRight: '1.25rem',
							borderRight: '1px solid #dddddd',
						}}
					>
						<span className="ms-2">Back</span>
					</Link>
					<Link
						to="/projects"
						style={{
							textDecoration: 'none',
							color: 'black',
							paddingLeft: '0.75rem',
						}}
					>
						<span className="ms-2" style={{ color: '#00834D' }}>
							Projects
						</span>
						<span> {'>'} Logs</span>
					</Link>
				</div>
				<NoData />
			</LogContainer>
		);
	}

	return (
		<LogContainer isTablet={isTablet} isMobile={isMobile}>
			<div
				className="mb-3 flex items-center"
				style={{
					cursor: 'pointer',
					maxWidth: 'fit-content',
				}}
			>
				<Right />
				<Link
					to="/projects"
					style={{
						textDecoration: 'none',
						color: 'black',
						paddingRight: '1.25rem',
						borderRight: '1px solid #dddddd',
					}}
				>
					<span className="ms-2">Back</span>
				</Link>
				<Link
					to="/projects"
					style={{
						textDecoration: 'none',
						color: 'black',
						paddingLeft: '0.75rem',
					}}
				>
					<span className="ms-2" style={{ color: '#00834D' }}>
						Projects
					</span>
					<span> {'>'} Logs</span>
				</Link>
			</div>

			<div className="d-flex">
				<h1 className="my-4">Project {id} Log</h1>
				<Button
					className="alt-button ms-auto align-self-center height-fit-content"
					onClick={handlePrint}
				>
					Print
				</Button>
			</div>

			<Tables
				columns={columns}
				data={data?.data?.apiLogsByProjectId || []}
				ref={componentPrintRef}
				pagination
			/>
		</LogContainer>
	);
}
