import GoogleMapReact from 'google-map-react';
import { GOOGLE_MAP_API_KEY } from 'helpers/const';
import useResponsive from 'helpers/hooks/useResponsive';
import { useDashboard } from 'pages/dashboard/DashboardContext';
import MyMarker from './MyMarker';

export default function MapView() {
	const { equipments, filteredResult }: any = useDashboard();
	const data = filteredResult || equipments;
	const { isMobile } = useResponsive();
	return (
		<GoogleMapReact
			bootstrapURLKeys={{
				// remove the key if you want to fork
				key: GOOGLE_MAP_API_KEY,
				language: 'en',
				region: 'US',
			}}
			defaultCenter={{ lat: 40.9279045, lng: -89.6487255 }}
			defaultZoom={4}
			style={{
				contain: 'strict',
				marginTop: '2rem',
				height: `calc(100vh - ${isMobile ? ' 250px' : '200px'})`,
				position: 'relative',
				borderRadius: '12px',
			}}
		>
			{data?.map((item) =>
				item?.gps.latdd && item?.gps.londd ? (
					<MyMarker
						type={item.type}
						lat={item.gps.latdd}
						lng={item.gps.londd}
						item={item}
					/>
				) : null,
			)}
		</GoogleMapReact>
	);
}
