import { yupResolver } from '@hookform/resolvers/yup';
import { auth } from 'helpers/api';
import { useAuth } from 'helpers/contexts/AuthContext';
import { LOCAL_STORAGE_LOGIN_REMEMBER_ME_KEY } from 'helpers/utils/auth-provider';
import { useEffect } from 'react';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import {
	CustomFormCheck,
	Forgot,
	Input,
	LoginButton,
	LoginFormContainer,
} from './login.styled';

const schema = yup
	.object({
		username: yup
			.string()
			.required('Username is required.')
			.max(100, 'Please check the length'),
		password: yup
			.string()
			.required('Password is required.')
			.max(100, 'Please check the length'),
	})
	.required();

function LoginForm() {
	const { login, isLoading, user, logout } = useAuth();
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({
		resolver: yupResolver(schema),
	});

	const getRememberMeCredentials = () => {
		try {
			const crypt = window.atob(
				localStorage.getItem(LOCAL_STORAGE_LOGIN_REMEMBER_ME_KEY) || '',
			);
			if (crypt) {
				const credentials = JSON.parse(crypt);
				if (credentials && credentials.rememberMe === true) {
					reset(credentials);
				}
			}
		} catch (err) {
			toast.error(err.message);
		}
	};

	useEffect(() => {
		getRememberMeCredentials();
		if (user) {
			auth
				.getCurrentUser()
				.then(() => {
					navigate('/dashboard');
				})
				.catch(() => {
					logout();
					navigate('/');
				});
		}
	}, [user]);

	const onSubmit = (data) => {
		try {
			let rememberedData = '';
			if (data && data.rememberMe === true) {
				rememberedData = JSON.stringify(data);
			}
			localStorage.setItem(
				LOCAL_STORAGE_LOGIN_REMEMBER_ME_KEY,
				window.btoa(rememberedData),
			);

			login({
				uname: data.username,
				upassword: data.password,
			});
		} catch (err) {
			toast.error(err.message);
		}
	};

	const ErrorMessage = ({ children }) => (
		<small className="text-danger mt-2 d-block">{children}</small>
	);

	return (
		<div>
			{/* <NoticeMessage>
					Notice: Site is under maintenance.
				</NoticeMessage>
				<br /> */}
			<LoginFormContainer onSubmit={handleSubmit(onSubmit)}>
				<Row>
					<Col md={12}>
						<Form.Group className="mb-3">
							<Input
								type="text"
								placeholder="User ID"
								autoComplete="username"
								{...register('username')}
							/>
							<ErrorMessage>{errors.username?.message}</ErrorMessage>
						</Form.Group>
					</Col>
					<Col md={12}>
						<Form.Group className="mb-3">
							<Input
								type="password"
								placeholder="Password"
								autoComplete="current-password"
								{...register('password')}
							/>
							<ErrorMessage>{errors.password?.message}</ErrorMessage>
						</Form.Group>
					</Col>
					<Col md={12} className="d-flex my-2">
						<CustomFormCheck
							type="checkbox"
							name="rememberMe"
							id="rememberMe"
							className=""
							{...register('rememberMe')}
						/>
						<Form.Label
							htmlFor="rememberMe"
							className="ps-2 pt-1 align-self-center mb-0 cursor-pointer"
						>
							Remember me
						</Form.Label>
					</Col>
				</Row>
				<div className="text-center">
					<Col md={12}>
						<a href="/dashboard">
							<LoginButton
								className="btn btn-block btn-darken my-3"
								type="submit"
								disabled={isLoading}
							>
								Login{' '}
								<Spinner animation="border" size="sm" hidden={!isLoading} />
							</LoginButton>
						</a>
					</Col>
					<Link to="/forgot" style={{ color: '#00834d' }}>
						<Forgot>Forgot password?</Forgot>
					</Link>
				</div>
			</LoginFormContainer>
		</div>
	);
}

// function NoticeMessage({ children }) {
// 	return (
// 		<small
// 			className="alert-info mt-1 p-2 d-block"
// 			style={{ borderRadius: '10px' }}
// 		>
// 			{children}
// 		</small>
// 	);
// }

export default LoginForm;
