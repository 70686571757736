import AuthLayout from 'components/Layouts/AuthLayout';
import { auth } from 'helpers/api';
import { useState } from 'react';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	Input,
	LoginButton,
	LoginFormContainer,
	LoginHeader,
} from './login.styled';

// const ErrorMessage = ({ children }) => (
// 	<small className="text-danger mt-2 d-block">{children}</small>
// );

function ChangePassword() {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [formState, setFormState] = useState({
		password1: '',
		password2: '',
	});
	const token = new URLSearchParams(useLocation().search).get('token');

	const handleSubmit = () => {
		if (!formState.password1 || !formState.password2) {
			toast.error('Please enter password and reset password.');
			return;
		}
		if (formState.password1 !== formState.password2) {
			toast.error('Password and confirm password must be same.');
			return;
		}
		if (!token) {
			toast.error('Please visit a valid link to reset your password.');
			return;
		}
		setIsLoading(true);
		auth
			.newPassword({
				token,
				password: formState.password1,
				passwordconfirm: formState.password2,
			})
			.then((res) => {
				if (res.data.setNewPassword) {
					toast.success('Password changed successfully.');
					navigate('/');
					return;
				}
				toast.error(`Error. ${res.error || 'Something went wrong.'}`);
			})
			.catch((err) => {
				toast.error(err?.response?.data?.message || 'Something went wrong.');
			});
		setTimeout(() => {
			setIsLoading(false);
		}, 2000);
	};
	const handleChange = (e) =>
		setFormState((prev) => ({ ...prev, [e.target.name]: e.target.value }));

	return (
		<div>
			<AuthLayout>
				<>
					<LoginHeader>Change password</LoginHeader>
					<LoginFormContainer onSubmit={(e) => e.preventDefault()}>
						<Row>
							<Col md={12}>
								<Form.Group className="mb-3">
									<Input
										type="password"
										placeholder="Enter Password"
										onChange={handleChange}
										name="password1"
										value={formState.password1}
									/>
									<Input
										className="mt-2"
										type="password"
										placeholder="Confirm Password"
										onChange={handleChange}
										name="password2"
										value={formState.password2}
									/>
								</Form.Group>
							</Col>
						</Row>
						<div className="text-center">
							<Col md={12}>
								<LoginButton
									className="btn btn-block btn-darken mb-3"
									// type="submit"
									disabled={
										isLoading || !formState.password1 || !formState.password2
									}
									onClick={handleSubmit}
								>
									Submit
									<Spinner animation="border" size="sm" hidden={!isLoading} />
								</LoginButton>
							</Col>
						</div>
					</LoginFormContainer>
				</>
			</AuthLayout>
		</div>
	);
}

export default ChangePassword;
