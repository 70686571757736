import { getEquipmentChartDate } from 'helpers/api/equipments';
import { rigChartLabels } from 'helpers/const/chartLabels';
import moment from 'moment';
import { ChartContainer } from 'pages/details/styled';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useParams } from 'react-router-dom';
import DailyChart from './DailyChart';
import LiveWeight from './LiveWeight';

function ActivityWeightChart() {
	const { id } = useParams();
	const [dailyWeight, setDailyWeight] = React.useState(false);
	const [data, setData] = React.useState<any>([]);
	const [selectedDate, setSelectedDate] = React.useState(new Date());

	const handleDailyWeight = (equipment) => {
		if (dailyWeight === false)
			getEquipmentChartDate(id, moment(), equipment).then((res) => {
				setData(res?.data?.equipmentChartData);
			});

		setDailyWeight(!dailyWeight);
	};

	const chartselecteddate = (date, equipment) => {
		setSelectedDate(date);
		getEquipmentChartDate(id, date, equipment).then((res) => {
			setData(res?.data.equipmentChartData);
		});

		if (equipment === 'weight') {
			setDailyWeight(true);
		}
	};

	return (
		<Col lg={12}>
			<ChartContainer>
				<Row className="header">
					<Col className="align-self-center">
						<h1 className="align-self-center">{rigChartLabels.weight}</h1>
					</Col>
					<Col className="change-container">
						<button
							type="button"
							onClick={() => handleDailyWeight('weight')}
							className="change-span"
						>
							{dailyWeight ? 'Live chart' : '24h chart'}
						</button>
						<span>
							<DatePicker
								selected={selectedDate}
								onChange={(date) => chartselecteddate(date, 'weight')}
							/>
						</span>
					</Col>
				</Row>

				<>
					{dailyWeight && (
						<div>
							<DailyChart data={data?.charts?.weight || []} subitem="weight" />
						</div>
					)}

					{!dailyWeight && (
						<div>
							<LiveWeight />
						</div>
					)}
				</>
			</ChartContainer>
		</Col>
	);
}

export default ActivityWeightChart;
