import Header from 'components/menu/Header';
import Sidebar from 'components/menu/Sidebar';
import useResponsive from 'helpers/hooks/useResponsive';
import { useState } from 'react';
import styled from 'styled-components';

type WrapperPropsT = {
	$iscollapsed: boolean;
	$istablet: boolean;
	$ismobile: boolean;
};
const Wrapper = styled.div<WrapperPropsT>`
	transition: margin 0.3s ease-in-out;
	${(props) =>
		props.$iscollapsed === true
			? ` margin: 2rem 2rem 2rem 7rem;`
			: ` margin: 2rem 2rem 2rem 18rem;`}
	margin: ${(props: any) =>
		props.$istablet === true || props.$ismobile === true
			? '0 1rem !important'
			: undefined};
`;

export default function MenuLayout({ children }) {
	const { isTablet, isMobile } = useResponsive();
	const [isCollapsed, setIsCollapsed] = useState(true);

	const handleCollapse = () => {
		setIsCollapsed(!isCollapsed);
	};

	return (
		<>
			{isMobile || isTablet ? (
				<Header />
			) : (
				<Sidebar isCollapsed={isCollapsed} handleCollapse={handleCollapse} />
			)}
			<Wrapper
				$iscollapsed={isCollapsed}
				$istablet={isTablet}
				$ismobile={isMobile}
			>
				{children}
			</Wrapper>
		</>
	);
}
