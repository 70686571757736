import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { ReactComponent as PlusIcon } from 'assets/icons/add-custom.svg';
import CameraDetails from './CameraDetails';

export default function CameraView({
	data,
	fullColumn,
	handleDragandDrop,
	handleFullView,
	setModalShow,
	isLoading,
}: {
	data: any[];
	fullColumn: number;
	handleDragandDrop: (
		item: any,
	) => (e: React.DragEvent<HTMLDivElement>) => void;
	handleFullView: (values: any) => () => void;
	setModalShow: React.Dispatch<React.SetStateAction<boolean>>;
	isLoading: boolean;
}) {
	if (isLoading) {
		return (
			<div className="text-center">
				<h1>Loading...</h1>
			</div>
		);
	}

	return (
		<Row>
			{data?.map((item, index) => (
				<Col
					// eslint-disable-next-line react/no-array-index-key
					key={index}
					lg={fullColumn}
					className="px-0 py-0"
					style={{
						position: 'relative',
						minHeight: '250px',
					}}
					id={index.toString()}
					onDrop={handleDragandDrop(item)}
				>
					<CameraDetails data={item} handleFullView={handleFullView} />
				</Col>
			))}

			<Col
				lg={fullColumn}
				className="h-auto grid align-self-center text-center py-4"
			>
				<button
					type="button"
					className="bg-white border-0 py-4 justify-self-center"
					aria-label="Click to add a new camera"
					onClick={() => setModalShow(true)}
				>
					<PlusIcon fill="red" stroke="black" />
				</button>
			</Col>
		</Row>
	);
}
