import { ReactComponent as LinkIcon } from 'assets/icons/link.svg';
import TooltipHandler from 'components/TooltipHandler';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function CustomNavLink({
	title,
	toLink,
	active,
	disable,
	href,
	icon,
	isCollapsed,
}) {
	const style =
		disable === true ? { pointerEvents: 'none' } : { cursor: 'pointer' };

	if (isCollapsed) {
		return title === 'Resources' ? (
			<TooltipHandler content={title} placement="right">
				<li key={title}>
					<Nav.Link
						to={href}
						href={href}
						target="_blank"
						rel="noopener noreferrer"
						className={`${'link'} ${active && !disable ? 'active' : ''}`}
					>
						{icon}
						<span className="ms-2 link-title">{title}</span>
					</Nav.Link>
				</li>
			</TooltipHandler>
		) : (
			<TooltipHandler content={title} placement="right">
				<li key={title}>
					<Nav.Link
						style={style}
						as={Link}
						to={toLink}
						href={href}
						className={`${'link'} ${active && !disable ? 'active' : ''}`}
					>
						{icon}
						<span className="ms-2 link-title">{title}</span>
					</Nav.Link>
				</li>
			</TooltipHandler>
		);
	}

	return title === 'Resources' ? (
		<li key={title}>
			<Nav.Link
				to={href}
				href={href}
				target="_blank"
				rel="noopener noreferrer"
				className={`d-flex ${'link resources'} ${
					active && !disable ? 'active' : ''
				}`}
			>
				{icon}
				<span className="mx-2 link-title color-primary">{title}</span>
				<LinkIcon />
			</Nav.Link>
		</li>
	) : (
		<li key={title}>
			<Nav.Link
				style={style}
				as={Link}
				to={toLink}
				href={href}
				className={`d-flex ${'link'} ${active && !disable ? 'active' : ''}`}
			>
				{icon}
				<span className="ms-2 link-title">{title}</span>
			</Nav.Link>
		</li>
	);
}

export default CustomNavLink;
