import { ReactComponent as PlusIcon } from 'assets/icons/add-custom.svg';
import { useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import DashboardLayout from '../../components/Layouts/DashboardLayout';
import useCameraLayout from '../../helpers/hooks/useCameraLayout';
import { DashboardContextProvider } from '../dashboard/DashboardContext';
import DeleteCameraLayoutModal from './DeleteCameraLayout';
import EditCameraLayoutModal from './EditCameraLayout';
import LayoutCard from './LayoutCard';

export default function CameraLayoutList() {
	const [editModalShow, setEditModalShow] = useState<boolean>(false);
	const [deleteModalShow, setDeleteModalShow] = useState<boolean>(false);
	const [selectedItem, setSelectedItem] = useState<any>(null);
	const { cameraLayouts, isLoading, refetch } = useCameraLayout();

	const handleEditModal = (item: any) => {
		setSelectedItem(item);
		setEditModalShow(true);
	};
	const handleDeleteModal = (item: any) => {
		// confirm delete modal
		setSelectedItem(item);
		setDeleteModalShow(true);
	};
	return (
		<DashboardContextProvider>
			<DashboardLayout>
				<h1 className="assignment-header text-2xl font-bold">
					Security Camera View
				</h1>
				{isLoading ? (
					<div className="h-screen d-grid align-items-center justify-items-center ">
						<Spinner
							animation="border"
							style={{ display: 'grid' }}
							className="mx-auto mt-5"
						/>
					</div>
				) : (
					<div className="mt-6">
						<Row>
							{cameraLayouts.map((item: any, index: number) => (
								<LayoutCard
									item={item}
									index={index}
									key={item.id}
									handleEditModal={handleEditModal}
									handleDeleteModal={handleDeleteModal}
								/>
							))}
							<Col
								lg={6}
								className="h-auto grid align-self-center text-center py-4"
							>
								<button
									type="button"
									className="bg-white border-0 py-4 justify-self-center"
									aria-label="Click to add a new camera"
									onClick={() => handleEditModal(null)}
								>
									<PlusIcon fill="red" stroke="black" />
								</button>
							</Col>
							<EditCameraLayoutModal
								show={editModalShow}
								setModalShow={setEditModalShow}
								activeEqs={selectedItem?.selection || []}
								editingItem={selectedItem}
							/>
							{selectedItem?.id && (
								<DeleteCameraLayoutModal
									show={deleteModalShow}
									setModalShow={setDeleteModalShow}
									item={selectedItem}
									refetch={refetch}
								/>
							)}
						</Row>
					</div>
				)}
			</DashboardLayout>
		</DashboardContextProvider>
	);
}
