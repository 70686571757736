import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

const HeaderWrapper = styled.div`
	justify-content: space-between;
	.back-container {
		cursor: 'pointer';
		max-width: 'fit-content';
	}
	input {
		max-width: 20rem;
	}
`;
const FilterContainer = styled.div`
	display: grid;
	height: fit-content;
	gap: 0.5rem;
	border: 1px solid #d3d3d3;
	border-radius: 20px;

	.all-button {
		background: transparent;
		border: none;
		padding-top: 0.5rem;
		padding-bottom: 0.25rem;
		text-align: left;
		display: flex;
		align-items: center;
		border-bottom: 1px solid #d3d3d3;
		span {
			padding-right: 1.25rem;
			padding-left: 1.25rem;
		}
	}
	.timezone-button {
		background: transparent;
		border: none;
		padding-top: 0rem;
		text-align: left;
		display: flex;
		align-items: center;
		span {
			padding-right: 1.25rem;
			padding-left: 1.25rem;
		}
	}
`;
const DataContainer = styled.div`
	width: 100%;

	.zone-container {
		border-radius: 20px;
		border: 1px solid #dcdcdc;
		padding: 1rem;
	}
	.zone-title {
		background: #f3f3f3;
		border-radius: 10px;
		padding: 0.75rem 1rem;
		font-size: 1rem;
		margin-bottom: 0.5rem;
		font-weight: bold;
	}
`;
const SelectionModal = styled(Modal)``;

export { HeaderWrapper, FilterContainer, DataContainer, SelectionModal };
