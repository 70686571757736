import {
	Form,
	Row,
	Col,
	InputGroup,
	FormControl,
	Button,
} from 'react-bootstrap';
import { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import FormError from 'components/FormError';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import {
	createCustomProjectId,
	projectCustomId,
	getCustomers,
} from 'helpers/api/projectManagement';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import AddCustomerForm from '../create-project/steps/AddCustomerForm';

const RowWrapper = styled(Row)`
	.customer-add-button,
	.customer-remove-button {
		background-color: transparent;
		height: unset;
		font-weight: 500;
		font-size: 14px;
	}
	.customer-remove-button {
		color: red;
	}
`;

export default function ProjectInfo({ update, formData }) {
	const [customId, setCustomId] = useState('');
	const [customerList, setCustomerList] = useState([]);
	const [customerModal, setCustomerModal] = useState(false);
	const isMounted = useRef(false);

	const handleStartDate = (date) => {
		update({ ...formData, pbegdate: moment(date).format('YYYY-MM-DD') });
	};
	const handleEndDate = (date) => {
		update({ ...formData, penddate: moment(date).format('YYYY-MM-DD') });
	};

	const schema = yup
		.object({
			pprojectid: yup.string(),
			pname: yup.string().required('Project Name is required'),
			pbegdate: yup.string(),
			penddate: yup.string(),
			pwellid: yup.string().required('Well ID is required'),
			pclient: yup.string().required('Client is required'),
			// pcoordinates: yup.string().default(`${location.lat}, ${location.lng}`),
			pwellname: yup.string().required('Well Name is required'),
			pwellnumber: yup.string().required('Well Number is required'),
			ppadname: yup.string().required('Pad Name is required'),
			pafe: yup.string().required('AFE is required'),
			ptubingsize: yup.string().required('Tubing Size is required'),
			punit: yup.string().required('Unit is required'),
			passembly_length: yup.string().required('Assembly Length is required'),
			pfloor_height: yup.string().required('Floor Height is required'),
			pkb_height: yup.string().required('KB Height is required'),
		})
		.required();

	const { register, reset, formState, setValue } = useForm({
		resolver: yupResolver(schema),
	});

	const handleUpdateFormData = (e) => {
		const { name } = e.target;
		update({ ...formData, [name]: e.target.value });
	};

	const handleCustomId = () => {
		if (customId.length === 0) {
			createCustomProjectId().then((res) => {
				setCustomId(res);
				update({ ...formData, pprojectid: res });
			});
		} else {
			projectCustomId(customId).then((res) => {
				if (res.success === 'true') {
					setCustomId(customId);
					update({ ...formData, pprojectid: customId });
				} else {
					toast.error(res.msg);
					setCustomId('');
				}
			});
		}
	};

	useEffect(() => {
		reset(formData);
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, [formData]);

	const fetchCustomers = () => {
		getCustomers().then((res) => {
			if (res?.data) {
				setCustomerList(res?.data?.clientsByCustomerId);
			}
		});
	};

	const handleAddCustomer = () => {
		setCustomerModal(true);
	};
	const handleAddCustomerClose = () => {
		setCustomerModal(false);
	};
	const handleSaveCustomer = () => {
		handleAddCustomerClose();
		fetchCustomers();
	};
	const handleRemoveCustomer = () => {
		setValue('pclient', 'add');
	};

	useEffect(() => {
		// Get customers to populate the select box on customer field.
		fetchCustomers();
	}, []);

	const { errors } = formState;

	return (
		<>
			<p className="mb-3" style={{ opacity: 0.8 }}>
				To create a new Project ID please leave the field empty and click set.
			</p>
			<RowWrapper className="inputs">
				<Col lg={3}>
					Project ID
					<InputGroup>
						<FormControl
							placeholder="Project ID"
							aria-label="pprojectid"
							aria-describedby="project id"
							{...register('pprojectid')}
							value={formData.pprojectid}
							onChange={handleUpdateFormData}
							autoFocus
							readOnly
						/>
						<Button
							type="button"
							variant="outline-secondary"
							id="button-addon2"
							onClick={handleCustomId}
						>
							Set
						</Button>
					</InputGroup>
					<FormError>{errors.pprojectid?.message}</FormError>
				</Col>
				<Col lg={3}>
					<span>Start Date</span>
					<DatePicker
						className="date-picker"
						selected={
							formData.pbegdate ? new Date(formData.pbegdate) : new Date()
						}
						onChange={(date) => handleStartDate(date)}
					/>
				</Col>
				<Col lg={3}>
					<span>End Date</span>
					<DatePicker
						className="date-picker"
						selected={
							formData.penddate ? new Date(formData.penddate) : new Date()
						}
						onChange={(date) => handleEndDate(date)}
					/>
				</Col>
				<Col lg={3}>
					<label htmlFor="pname">
						Project Name
						<input
							{...register('pname')}
							id="pname"
							type="text"
							placeholder="Project Name"
							onChange={handleUpdateFormData}
						/>
					</label>
					<FormError>{errors.pname?.message}</FormError>
				</Col>
				<Col lg={3}>
					<label htmlFor="pwellid">
						API Well ID
						<input
							{...register('pwellid')}
							id="pwellid"
							type="text"
							placeholder="API Well ID"
							onChange={handleUpdateFormData}
						/>
					</label>
					<FormError>{errors.pwellid?.message}</FormError>
				</Col>
				<Col lg={3}>
					<label htmlFor="pclient">
						<div className="d-flex justify-content-between align-items-center">
							<span>Customer</span>
							<div className="d-flex">
								<button
									type="button"
									className="customer-add-button"
									onClick={handleAddCustomer}
								>
									Add
								</button>
								<button
									type="button"
									className="customer-remove-button"
									onClick={handleRemoveCustomer}
								>
									Remove
								</button>
							</div>
						</div>
						<Form.Group>
							<Form.Select
								{...register('pclient')}
								placeholder="--Select a customer--"
							>
								<option value="" disabled>
									--Select a customer--
								</option>
								{customerList.map((customer) => (
									<option key={customer.clid} value={customer.clid}>
										{customer.clname}
									</option>
								))}
							</Form.Select>
						</Form.Group>
					</label>

					<FormError>{errors.pclient?.message}</FormError>
				</Col>

				<Col lg={3}>
					<label htmlFor="pwellname">
						Well Name
						<input
							{...register('pwellname')}
							id="pwellname"
							type="text"
							placeholder="Well Name"
							onChange={handleUpdateFormData}
						/>
					</label>
					<FormError>{errors.pwellname?.message}</FormError>
				</Col>
				<Col lg={3}>
					<label htmlFor="pwellnumber">
						Well ID
						<input
							{...register('pwellnumber')}
							id="pwellnumber"
							name="pwellnumber"
							type="text"
							placeholder="Well Number"
							onChange={handleUpdateFormData}
						/>
					</label>
					<FormError>{errors.pwellnumber?.message}</FormError>
				</Col>
				<Col lg={3}>
					<label htmlFor="ppadname">
						Pad name
						<input
							{...register('ppadname')}
							id="ppadname"
							name="ppadname"
							type="text"
							placeholder="Pad Name"
							onChange={handleUpdateFormData}
						/>
					</label>
					<FormError>{errors.ppadname?.message}</FormError>
				</Col>
				<Col lg={3}>
					<label htmlFor="pafe">
						AFE
						<input
							{...register('pafe')}
							id="pafe"
							name="pafe"
							type="text"
							placeholder="AFE"
							onChange={handleUpdateFormData}
						/>
					</label>
					<FormError>{errors.pafe?.message}</FormError>
				</Col>
				<Col lg={3}>
					<label htmlFor="ptubingsize">
						Tubing Size
						<input
							{...register('ptubingsize')}
							id="ptubingsize"
							name="ptubingsize"
							type="number"
							placeholder="Tubing Size"
							onChange={handleUpdateFormData}
							min={0}
							onKeyDown={(evt) =>
								['-'].includes(evt.key) && evt.preventDefault()
							}
						/>
					</label>
					<FormError>{errors.ptubingsize?.message}</FormError>
				</Col>
				<Col lg={3}>
					<Form.Group>
						<Form.Label>Measure Unit</Form.Label>
						<Form.Select
							{...register('punit')}
							onChange={handleUpdateFormData}
							value={formData.measure_unit?.toLowerCase()}
						>
							<option value="unit" disabled selected>
								--Select a value--
							</option>
							<option value="BPM">BPM</option>
							<option value="GPM">GPM</option>
						</Form.Select>
					</Form.Group>
					<FormError>{errors.punit?.message}</FormError>
				</Col>
				<Col lg={3}>
					<label htmlFor="passembly_length">
						Assembly Length
						<input
							{...register('passembly_length')}
							id="passembly_length"
							type="number"
							placeholder="Assembly Length"
							onChange={handleUpdateFormData}
							min={0}
							onKeyDown={(evt) =>
								['-'].includes(evt.key) && evt.preventDefault()
							}
						/>
					</label>
					<FormError>{errors.passembly_length?.message}</FormError>
				</Col>
				<Col lg={3}>
					<label htmlFor="pfloor_height">
						Floor Height
						<input
							{...register('pfloor_height')}
							id="pfloor_height"
							type="number"
							placeholder="Floor Height"
							onChange={handleUpdateFormData}
							min={0}
							onKeyDown={(evt) =>
								['-'].includes(evt.key) && evt.preventDefault()
							}
						/>
					</label>
					<FormError>{errors.pfloor_height?.message}</FormError>
				</Col>
				<Col lg={3}>
					<label htmlFor="pkb_height">
						KB Height
						<input
							{...register('pkb_height')}
							id="pkb_height"
							type="number"
							placeholder="KB Weight"
							onChange={handleUpdateFormData}
							min={0}
							onKeyDown={(evt) =>
								['-'].includes(evt.key) && evt.preventDefault()
							}
						/>
					</label>
					<FormError>{errors.pkb_height?.message}</FormError>
				</Col>

				<Col lg={3}>
					<label htmlFor="pcoordinates">
						Coordinates:
						<input
							type="text"
							value={formData.pcoordinates}
							disabled
							id="pcoordinates"
						/>
					</label>
				</Col>
			</RowWrapper>
			<AddCustomerForm
				open={customerModal}
				onClose={handleAddCustomerClose}
				onSave={handleSaveCustomer}
				refetch={fetchCustomers}
			/>
		</>
	);
}
