import MenuLayout from 'components/Layouts/MenuLayout';
import { useAuth } from 'helpers/contexts/AuthContext';
import { getToken } from 'helpers/utils/auth-provider';
import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AdminRoutes, { ProtectedAdminRoute } from 'routes/AdminRoutes';
import PrivateRoutes, { ProtectedRoute } from 'routes/PrivateRoutes';
import PublicRoutes from 'routes/PublicRoutes';

function AppRoutes() {
	const { logout } = useAuth();

	/**
	 * Handles user logout functionality, including automatically logging out the user at midnight everyday.
	 */
	useEffect(() => {
		let timeoutId = 1;
		// Calculate the token expiry time until midnight.
		const now = new Date();
		const midnight = new Date(now);
		midnight.setHours(23, 59, 59, 999); // Set time to end of day
		// in seconds
		const expireInSeconds = Math.floor(
			(midnight.getTime() - now.getTime()) / 1000,
		);

		// Set interval to logout every day at midnight
		timeoutId = setTimeout(() => {
			if (getToken()) {
				logout(); // Call your logout function from AuthContext
			}
		}, expireInSeconds * 1000); // 24 hours in milliseconds

		// Clear timeout on unmount to prevent memory leaks
		return () => clearInterval(timeoutId);
	}, []);

	return (
		<Routes>
			{PublicRoutes.map((route) => (
				<Route key={route.id} path={route.path} element={<route.component />} />
			))}
			{PrivateRoutes.map((route) => (
				<Route
					key={route.id}
					path={route.path}
					element={
						<ProtectedRoute>
							<MenuLayout>
								<route.component />
							</MenuLayout>
						</ProtectedRoute>
					}
				/>
			))}
			{AdminRoutes.map((route) => (
				<Route
					key={route.id}
					path={route.path}
					element={
						<ProtectedRoute>
							<MenuLayout>
								<ProtectedAdminRoute>
									<route.component edit={route.edit} />
								</ProtectedAdminRoute>
							</MenuLayout>
						</ProtectedRoute>
					}
				/>
			))}

			{/* 404 */}
			<Route path="*" element={<Navigate replace to="/404" />} />
		</Routes>
	);
}

export default AppRoutes;
