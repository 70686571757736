import styled, { css } from 'styled-components';
import dropdown from 'assets/icons/dropdown_green.svg';
import rightIcon from 'assets/icons/right.svg';

export const DetailsContainer = styled.div`
	/* margin: ${(props) =>
		props.$istablet || props.$ismobile
			? '1rem 1rem'
			: '2rem 2rem 2rem 18rem;'}; */

	/* ${(props) =>
		props.$istablet || props.$ismobile
			? css`
					margin: 1rem 1rem;
				`
			: css`
					margin: 4rem 10%;
				`} */

	.details-information {
		padding: 1.25rem 2rem;
		background: white;
		box-shadow: 0px 9px 36px rgba(0, 0, 0, 0.08);
		border-radius: 12px;
		transition: 0.2s ease-in-out;

		${(props) =>
			props.$istablet || props.$ismobile
				? css`
						height: auto;
					`
				: css`
						height: auto;
					`}

		.top-right {
			${(props) =>
				props.$ismobile
					? css`
							float: left;
							padding-left: 0rem;
						`
					: css`
							float: right;
						`}
			width: fit-content;
			font-weight: 400;
			font-size: 1rem;
		}

		.eq-id {
			font-weight: 600;
			font-size: 1.25rem;
			height: 100%;
			vertical-align: middle;
			${(props) =>
				props.$ismobile
					? css`
							padding-left: 0rem;
						`
					: css``}
		}

		.date {
			font-weight: 400;
			font-size: 1rem;
			width: 300px;
			${(props) =>
				props.$ismobile
					? css`
							padding-left: 0rem;
						`
					: css``}
		}

		.bottom-right {
			width: fit-content;
			color: ${({ theme }) => theme.colors.successText};
			cursor: pointer;
			background: transparent;
			border: none;
			padding: 0;

			${(props) =>
				props.$istablet || props.$ismobile
					? css`
							display: block;
							padding-left: 0rem;
						`
					: css`
							float: right;
						`}

			&:after {
				content: '';
				display: inline-block;
				width: 12px;
				height: 10px;
				background: url(${dropdown}) no-repeat center;
				position: relative;
				margin-left: 10px;
				transition: 0.2s ease-in-out;
			}
		}

		.bottom-right-extended {
			width: fit-content;
			color: ${({ theme }) => theme.colors.successText};
			cursor: pointer;
			background: transparent;
			border: none;
			padding: 0;

			${(props) =>
				props.$istablet || props.$ismobile
					? css`
							display: block;
							padding-left: 0rem;
						`
					: css`
							float: right;
						`}

			&:after {
				content: '';
				display: inline-block;
				width: 12px;
				height: 10px;
				transform: rotate(-180deg);
				background: url(${dropdown}) no-repeat center;
				position: relative;
				margin-left: 10px;
				transition: 0.2s ease-in-out;
			}
		}

		.row {
			line-height: 2.5rem;
			display: block;
		}

		.tags {
			font-weight: 500;
			font-size: 0.875rem;
			color: #878787;
		}

		@media (max-width: 425px) {
			.tags {
				margin-bottom: 1rem;
				line-height: normal;
			}

			.bottom-right,
			.bottom-right-extended {
				display: inline;
				width: max-content;
			}

			.bottom-right {
				&:after {
					transform: rotate(-180deg);
				}
			}

			.bottom-right-extended {
				&:after {
					transform: rotate(0deg);
				}
			}
		}

		.value {
			font-weight: 600;
			font-size: 1rem;
			color: black;
		}

		.green {
			color: ${({ theme }) => theme.colors.successText};
			font-weight: 600;
			font-size: 1rem;
		}

		.more-info {
			border-top: 1px solid #dddddd;
			padding-top: 1.5rem;
			margin-top: 1.5rem;

			${(props) =>
				props.$istablet || props.$ismobile
					? css`
							display: flex;
						`
					: css``}

			.row {
				display: flex;

				${(props) =>
					props.$istablet || props.$ismobile
						? css`
								width: 300%;
							`
						: css``}
			}

			.tags {
				display: grid;
			}
		}
	}

	.extended {
		height: auto;
		transition: 0.2s ease-in-out;
	}

	.nav {
		margin-top: 3.25rem;
		margin-bottom: 2.75rem !important;
		border-bottom: 1px solid #dddddd;
		padding-bottom: 0.75rem;
		display: flex;
		/* min-height: 60px; */
		overflow-x: auto;
		overflow-y: hidden;
		flex-wrap: nowrap;

		::-webkit-scrollbar {
			display: none;
		}
	}

	.nav-item {
		min-width: max-content;

		.active {
			color: black;
			background-color: transparent;
			border: none;
			box-shadow: none;
			padding-bottom: 0.75rem;
			border-bottom: 2px solid #0035bf;
		}

		a {
			color: ${({ theme }) => theme.colors.gray};
			background-color: transparent;
			border: none;
			text-decoration: none;
			margin-right: 1rem;

			&:hover {
				background-color: transparent;
				border: none;
				padding-bottom: 0.75rem;
				border-bottom: 2px solid #0035bf;
			}
		}
	}

	.cards-full-height {
		/* height: 50vh; */
	}

	.cards {
		.data {
			float: right;
		}
	}

	.profile-container {
		margin-bottom: 0.4rem;

		.primary-color {
			/* margin-bottom: 1.5rem; */
			font-family: Poppins;
			font-style: normal;
			font-weight: bold;
			font-size: 0.875rem;
			color: ${({ theme }) => theme.colors.loginPrimary};
			background: transparent;
			border: none;
			padding: 0;
			float: right;
			cursor: pointer;
		}

		.information {
			font-weight: 600;
			font-size: 1.5rem;
			line-height: 2.25rem;
			/* margin-bottom: 1.5rem; */
		}
	}
`;

export const CardContainer = styled.div`
	background: #ffffff;
	box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.09);
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	.event-404 {
		place-self: center;
		align-self: center;
		margin-top: 3rem;
	}

	.card-content {
		/* overflow-y: auto;
		padding-right: 16px; */

		${(props) =>
			props.$align === true
				? css`
						overflow-y: auto;
						/* padding-right: 16px; */
						display: grid;
						text-align: left !important;
					`
				: css`
						flex: 1;
						display: flex;
						justify-content: center;
						align-items: center;
					`}

		${(props) =>
			props.$engine
				? css`
						display: grid;
					`
				: ''}
	}

	${(props) =>
		props.$fullheight
			? css`
					min-height: 555px;
				`
			: css`
					height: 298px;
				`}

	${(props) =>
		props.$threecontainer
			? css`
					height: 199px;
				`
			: css``}

	margin-bottom: 2rem;
	padding: 0.5rem;

	.head-title,
	button {
		font-weight: 600;
		font-size: 1rem;
		vertical-align: middle;
		background: transparent;
		border: none;
		padding: 0;

		.dropdown-toggle {
			margin-left: 0 !important;
		}
	}

	.header {
		display: flex;
		margin-bottom: 0.688rem;

		div {
			margin-left: auto;
		}
	}

	a,
	.clickable {
		font-weight: 600;
		font-size: 1rem;
		color: ${({ theme }) => theme.colors.loginPrimary};
		text-decoration: none;
		cursor: pointer;
	}

	img {
		max-width: 100%;
	}

	.filter-dropdown {
		align-self: center;
		/* padding-left: 0.7rem; */
		/* width: 130px; */
		margin-left: 0rem !important;

		button.btn,
		button.btn:hover,
		button.btn:focus,
		button.btn:active {
			text-transform: capitalize;
			background: none;
			border: none;
			color: black;
			font-size: 0.875rem;
			font-weight: 500;
			margin: 0rem 0.625rem 0rem 0.625rem;
			padding: 0rem 0rem;
			box-shadow: none;
		}

		.dropdown-toggle {
			margin: 0rem !important;

			&:after {
				vertical-align: middle;
			}
		}

		.dropdown-item {
			color: black;
			font-size: 0.875rem;
			font-weight: 500;
			span {
				margin-left: 1rem;
			}
			&:focus,
			&:active {
				background: #dddddd;
			}
		}

		.dropdown-item.active {
			background: none;

			span {
				margin-left: 4px;
			}

			&:before {
				content: '';
				display: inline-block;
				width: 12px;
				height: 10px;
				background: url(${rightIcon}) no-repeat center;
				position: relative;
				left: -5px;
			}
		}
	}

	.weight-on-hook {
		.dropdown-toggle,
		.dropdown-toggle:hover {
			font-weight: 600;
			font-size: 1rem;
			vertical-align: middle;
		}

		button.btn,
		button.btn:hover,
		button.btn:focus,
		button.btn:active {
			text-transform: capitalize;
			background: none;
			border: none;
			color: black;
			font-weight: 600;
			font-size: 1rem;
			vertical-align: middle;
			margin: 0rem 0.625rem 0rem 0.625rem;
			padding: 0rem 0rem;
			box-shadow: none;
		}

		.dropdown-item {
			color: black;
			font-size: 0.875rem;
			font-weight: 500;

			&:focus,
			&:active {
				background: #dddddd;
			}
		}
	}
`;

export const ActivityContainer = styled.div`
	${(props) =>
		props.loader
			? css`
					text-align: center;
					margin-top: 5rem;
				`
			: null}

	h1 {
		font-size: 1.125rem;
		font-weight: 600;
		padding: 1.5rem 1.75rem;
	}

	.table-container {
		margin-top: 3.4375rem;
		background: #ffffff;
		box-shadow: 0px 33px 100px rgba(0, 0, 0, 0.03);
		border-radius: 20px;
	}

	thead {
		background: #f3f3f3;
		border-radius: 8px;
		border: none;

		tr {
			border: none;
			height: 52px;
			vertical-align: middle;
			border-radius: 8px;

			th {
				font-weight: 600;
				font-size: 1rem;
				border: none;
			}
		}
	}

	.change {
		font-size: 1rem;
		line-height: 1.5rem;
		color: ${({ theme }) => theme.colors.loginPrimary};
		text-align: right;
		padding-right: 1.75rem;
		cursor: pointer;
		background: transparent;
		border: none;
		/* padding: 1rem; */
	}

	@media (max-width: 425px) {
		.change {
			float: left;
			margin-top: -1rem;
			padding: 0rem 1.75rem 1rem 1.75rem;
		}
	}

	.change-container {
		align-self: center;
		text-align: right;
	}

	tbody {
		border: none;
		border-top: none !important;

		tr {
			border: none;
			border-bottom: 1px solid #dddddd;
			height: 52px;
			vertical-align: middle;

			td {
				border: none;
			}
		}
	}

	canvas {
		background: white;
	}

	.table-responsive {
		border-radius: 24px;
		padding: 0.25rem;
	}
`;

export const ChartContainer = styled.div`
	box-shadow: 0px 33px 100px rgba(0, 0, 0, 0.05);
	border-radius: 12px;
	/* max-width: 585px; */
	padding: 1.5rem 2rem;
	margin-top: 1.875rem;
	background: #ffffff;

	.row {
		margin-bottom: 1rem;
	}

	h1 {
		font-weight: 600;
		font-size: 1rem;
		padding: 0rem;
		margin-bottom: 0rem;
	}

	.tag {
		font-size: 0.75rem;
		font-weight: 600;
		color: #8f8f8f;
	}

	span {
		font-size: 0.75rem;
		font-weight: 600;
		color: black;
	}

	.change-container {
		display: flex;
		align-self: center;
		text-align: right;

		button {
			background: transparent;
			border: none;
		}

		.change-span {
			font-size: 0.75rem;
			line-height: 1.5rem;
			color: ${({ theme }) => theme.colors.loginPrimary};
			text-align: right;
			padding-right: 0.75rem;
			cursor: pointer;
			margin-left: auto;
		}

		@media (max-width: 425px) {
			span,
			button {
				width: max-content;
			}
		}

		.react-datepicker-wrapper {
			input {
				max-width: 80px;
				background: transparent;
				border: none;
				font-size: 0.75rem;
				line-height: 1.5rem;
				color: ${({ theme }) => theme.colors.loginPrimary};
				cursor: pointer;
			}
		}
	}

	@media (max-width: 425px) {
		h1 {
			width: max-content;
		}

		.change-container {
			text-align: left;
		}
	}

	.details {
		background: #f3f3f3;
		border-radius: 9px;
		padding: 1.25rem 1.5rem;
		margin-bottom: 1.5rem;
		display: flex;
		flex-wrap: wrap;

		div {
			margin-right: 1.875rem;
		}
	}

	.nav-tabs {
		margin: 1.375rem 0rem !important;
		margin-bottom: 0 !important;
		padding-bottom: 0 !important;
	}

	.button-page,
	.nav-link {
		all: unset;
		min-width: max-content;

		color: ${({ theme }) => theme.colors.gray};
		background-color: transparent;
		border: none;
		text-decoration: none !important;
		/* margin-right: 1rem; */
		margin-left: 1rem;
		cursor: pointer;

		.active {
			color: black;
			background-color: transparent;
			border: none;
			box-shadow: none;
			padding-bottom: 0.75rem;
			border-bottom: 2px solid #0035bf;
		}

		&:hover,
		&:active {
			color: black;
			background-color: transparent;
			border: none;
			box-shadow: none;
			/* padding-bottom: 0.75rem; */
			/* border-bottom: 2px solid #0035bf; */
		}
	}
`;

export const WeightOnHookContainer = styled.div`
	background: #4a6558;
	/* padding: 1.5rem 0.75rem; */
	/* border-radius: 1rem; */
	/* width: 100%; */
	/* height: 100%; */
	display: grid;
	.chart-txtmeta {
		padding-top: 0.25rem;
		font-size: 0.75rem;
		line-height: 1.2;
		color: #ffffff;
		span {
			font-weight: 600;
		}
	}

	.chart-txtmeta-dead {
		font-size: 1rem;
		line-height: 3;
		color: #ffffff;
		span {
			font-weight: 600;
		}
	}
`;

export const CVContainer = styled.div`
	.camera-feed-container {
		--bs-gutter-x: 0rem;
		.posistopbar {
			.posistop {
				height: 5rem;
			}
			.bar {
				width: 1rem;
			}
		}
	}
	.camera-feed {
		cursor: pointer;
		// margin-bottom: 1.5rem;
		position: relative;
		img {
			width: 100%;
			height: auto;
			box-shadow: 0px 33px 100px rgba(0, 0, 0, 0.05);
			// border-radius: 12px;
		}
		svg {
			stroke-width: 1px;
		}
	}
	.cam-full-screen {
		position: relative;
		img {
			height: 100%;
			width: 100%;
			box-shadow: 0px 33px 100px rgba(0, 0, 0, 0.05);
			// border-radius: 12px;
		}
	}
`;
