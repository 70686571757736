import styled from 'styled-components';

const Error = styled.small`
	font-size: 0.75rem;
`;

export default function FormError({ children }) {
	if (children)
		return (
			<div className="mb-1">
				<Error className="text-danger">{children}</Error>
			</div>
		);

	return null;
}
