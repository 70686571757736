import React, { useState, useEffect, useCallback } from 'react';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { useDropzone } from 'react-dropzone';
import { getEquipmentDocuments } from 'helpers/api/docsys';
import RigLayout from '../../../components/Layouts/RigLayout';
import certOptions from '../../../helpers/const/certificateCategories';

const CustomSelect = styled.select`
	border: none;
	background: transparent;
`;

export default function DocSys() {
	const { id } = useParams();
	const [show, setShow] = useState(false);
	const [effectiveDate, setEffectiveDate] = useState(new Date());
	const [expirationDate, setExpirationDate] = useState(new Date());
	const [certicatesAvailable, setCerticatesAvailable] = useState(false);
	const [documents, setDocuments] = useState([]);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const onDrop = useCallback((acceptedFiles) => {
		// eslint-disable-next-line no-console
		console.log(acceptedFiles);
	}, []);
	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
	});
	const handleEffectiveDate = (date) => {
		setEffectiveDate(date);
	};
	const handleExpirationDate = (date) => {
		setExpirationDate(date);
	};
	const openDocument = (document) => {
		window.open(document, '_blank');
	};

	useEffect(() => {
		getEquipmentDocuments(id)
			.then((res) => {
				if (res.success) {
					setDocuments(res.data);
					setCerticatesAvailable(true);
				}
			})
			.catch((err) => {
				// eslint-disable-next-line no-console
				console.log(err);
			});
	}, []);

	return (
		<RigLayout>
			<Modal
				show={show}
				onHide={handleClose}
				centered
				className="profile-modal"
			>
				<Modal.Body>
					<h2 className="information">Add Certification</h2>
					<Form className="mt-4 d-grid g-1">
						<Form.Group controlId="eqNumber">
							<Form.Label>Equipment Number</Form.Label>
							<Form.Control type="text" value={id} readOnly />
						</Form.Group>
						<Form.Group controlId="certificationType">
							<Form.Label>Certification Type</Form.Label>
							<Form.Select>
								<option value="">--Select Certification Type--</option>
								{certOptions.map((cert) => (
									<option value={cert.code} key={cert.id}>
										{cert.category}
									</option>
								))}
							</Form.Select>
						</Form.Group>
						<Form.Group controlId="effectiveDate">
							<Form.Label>Effective Date</Form.Label>
							<DatePicker
								selected={effectiveDate}
								onChange={(date) => handleEffectiveDate(date)}
							/>
							{/* <Form.Control type="text" placeholder="Enter equipment number" /> */}
						</Form.Group>
						<Form.Group controlId="expirationDate">
							<Form.Label>Expiration Date</Form.Label>
							<DatePicker
								selected={expirationDate}
								onChange={(date) => handleExpirationDate(date)}
							/>
							{/* <Form.Control type="text" placeholder="Enter equipment number" /> */}
						</Form.Group>

						<div {...getRootProps()} className="p-0">
							<input {...getInputProps()} />
							<div className="upload-image-drag">
								<span className="heading">
									<span className="green">Choose file</span> or Drag and drop
								</span>
							</div>
						</div>

						<div className="d-flex ms-auto mt-4">
							<Button
								variant="bordered-green"
								className="me-3"
								onClick={handleClose}
							>
								Close
							</Button>
							<Button variant="primary-green" type="submit">
								Save
							</Button>
						</div>
					</Form>
				</Modal.Body>
			</Modal>
			<div className="d-flex flex-wrap">
				<Button variant="primary-green" onClick={handleShow}>
					Add certification
				</Button>
				<div className="ms-auto">
					<Button variant="unstyled-green" className="border-end">
						Summary Report
					</Button>
					<Button variant="unstyled-green" className="border-end">
						Expiration Report
					</Button>
					{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
					<label htmlFor="time" className="ms-3">
						Filter by:
						<CustomSelect id="time">
							<option>30 days or less</option>
							<option>60 days or less</option>
							<option>90 days or less</option>
						</CustomSelect>
					</label>
				</div>
			</div>
			<Table variant="primary" className="mt-4" responsive>
				<thead>
					<tr>
						<th>Certification</th>
						<th>Effective Date</th>
						<th>Expiration Date</th>
						<th>Days Left</th>
						{/* <th>Action</th> */}
					</tr>
				</thead>
				<tbody>
					{certicatesAvailable ? (
						documents.map((doc) => (
							<tr key={doc.id}>
								<td>
									<Button
										variant="unstyled-green"
										onClick={() => openDocument(doc.file)}
									>
										{doc.description}
									</Button>
								</td>
								<td>{doc.startDate}</td>
								<td>{doc.endDate}</td>
								<td>{doc.daysLeft}</td>
								{/* <td>...</td> */}
							</tr>
						))
					) : (
						<tr>
							<td rowSpan={5}>No certificates available</td>
						</tr>
					)}
				</tbody>
			</Table>
		</RigLayout>
	);
}
