/* eslint-disable no-unneeded-ternary */
import React from 'react';
import { Image } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import { ReactComponent as Logo } from '../../../assets/icons/posi-stop-logo.svg';
import Clock from './Clock';

const SWITCH_ROTATION = {
	on: 0,
	off: -60,
	set: 60,
};
const Wrapper = styled.div`
	width: 100%;
	max-width: 300px;
	text-align: left;
	.pchart-system {
		width: 100%;
		border: 1px solid #9c8f6d;
		border-radius: 5px;
		box-shadow: 0 2px 3px rgb(0 0 0 / 10%);
		margin-bottom: 10px;
		background-color: #cabfa1;
		padding: 20px 10px 5px;
	}
	.pchart-lights {
		border: 1px solid #9c8f6d;
		border-radius: 5px;
		box-shadow: 0 2px 3px rgb(0 0 0 / 10%);
		background-color: #cabfa1;
		box-sizing: content-box;
		text-transform: uppercase;
		padding-bottom: 6px;
	}
	.pos-logo {
		background-image: url(/images/posi-stop-sprite.png);
		background-repeat: no-repeat;
		background-size: 230px;
		width: 80px;
		height: 80px;
		margin-top: -10px;
		width: 55px;
		height: 55px;
		background-position: -123px -250px;
		margin-bottom: 5px;
	}
	.pos-title {
		font-size: 0.875rem;
		font-weight: 600;
		max-width: 200px;
		text-transform: uppercase;
	}
	.pchart-title {
		font-size: 0.875rem;
		&.bordered:after {
			content: '';
			display: block;
			display: inline-block;
			border: solid 1px #61605c;
			width: 70%;
			margin: auto auto;
			margin-top: 10px;
		}
	}
	.blockpos-container {
		width: 60px;
	}
	.blockpos-chart {
		margin: auto;
		width: 50px;
		height: 230px;
		box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.25) inset;
		background: rgb(205, 205, 205);
		position: relative;
		background: radial-gradient(
			circle,
			rgba(160, 158, 158, 0.7) 0%,
			rgba(114, 114, 113, 0.7) 100%
		);

		.filler {
			transition: all 4s ease-in-out;
			transform: all 0.3s;
			margin: 2px;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			width: 46px;
			height: 10px;
			background-color: rgba(77, 77, 77, 0.7);
		}
		&.active {
			background: rgb(255, 226, 225);
			background: radial-gradient(
				circle,
				rgba(255, 226, 225, 1) 0%,
				rgba(187, 153, 152, 1) 100%
			);
			.filler {
				background-color: #b42123;
			}
		}
	}
	.right-area {
		flex: 1;
		display: flex;
		justify-content: center;
		flex-direction: column;
	}
	.percentofchart-container {
		text-align: center;
	}
	.percentofchart-chart {
		font-size: 1.75rem;
		line-height: 50px;
		text-align: right;
		padding: 0 8px;
		font-weight: 600;
		display: inline-block;
		min-width: 84px;
		height: 50px;
		margin: auto;
		box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.25) inset;
		background: rgb(205, 205, 205);
		position: relative;
		background: radial-gradient(
			circle,
			rgba(160, 158, 158, 0.7) 0%,
			rgba(114, 114, 113, 0.7) 100%
		);
		color: rgba(77, 77, 77, 1);

		&.active {
			background: rgb(255, 226, 225);
			background: radial-gradient(
				circle,
				rgba(255, 226, 225, 1) 0%,
				rgba(187, 153, 152, 1) 100%
			);
			color: #b42123;
		}
	}
	.pchart-bulb .warning-bulb,
	.pchart-bulb .limit-bulb {
		background-image: url(/images/posi-stop-sprite.png);
		background-repeat: no-repeat;
		background-size: 230px;
		height: 67px;
		width: 67px;
	}
	.pchart-bulb .warning-bulb {
		background-position: -62px -16px;
		margin-top: -10px;
		&.active {
			background-position: -150px -16px;
			margin-top: -10px;
		}
	}
	.pchart-bulb .limit-bulb {
		background-position: -62px -88px;
		margin-top: -10px;
		&.active {
			background-position: -150px -88px;
			margin-top: -10px;
		}
	}
	.bottom-lights {
		display: flex;
		justify-content: space-between;
		margin-top: 1rem;
	}

	.encoder-span {
		font-size: 0.875rem !important;
	}
`;

const SwitchDial = styled.div`
	position: relative;
	margin: 20px;
	span {
		position: absolute;
		font-size: 0.7rem;
		font-weight: 600;
	}
	.off {
		left: -20px;
	}
	.set {
		right: -20px;
	}
	.on {
		top: -20px;
		left: 50%;
		transform: translateX(-50%);
	}
	img {
		transform: ${(props) => `rotate(${SWITCH_ROTATION[props.$status]}deg)`};
	}
`;
const LightBulb = styled.div`
	.bulb {
		margin: 0 auto;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background-color: #ababab !important;
		box-shadow: inset #585858 0 -1px 5px !important;
		${(props) =>
			props.on &&
			css`
				background-color: #abff00 !important;
				box-shadow:
					rgb(0 0 0 / 20%) 0 -1px 7px 1px,
					inset #304701 0 -1px 9px,
					#89ff00 0 2px 12px !important;
			`}
	}
	.title {
		font-size: 0.65rem;
		font-weight: 600;
	}
`;

/**
 *
 *
 * @param {*} {data} - data from the server
 * 
 * 	 active: true,
			blockPosition: data?.[9]?.positionPercent,
			percentOfTravel: data?.[9]?.vehicleSpeed,
			drawworksRpm: data?.[9]?.speedFeetPerMinute,
			warnLight: true,
			limitLight: true,
			posiSwitch: POSISTOP_DIAL[data?.[9]?.posistop_dial || 0], // on, off, set,
			bulb: [1, 2],
 * @return {*} 
 */

const DisconnectedBanner = styled.div`
	@keyframes blinkingText {
		from {
			color: #424242;
		}
		to {
			color: red;
		}
	}
	@keyframes blinkingDot {
		from {
			background-color: #42403a;
		}
		to {
			background-color: red;
		}
	}
	background: rgba(255, 255, 255, 0.51);
	border-radius: 49px;
	padding: 6px 14px;
	.dot {
		width: 7px;
		height: 7px;
		background: #42403a;
		opacity: 0.6;
		border-radius: 100%;
		animation: blinkingDot 2s infinite;
	}
	.banner {
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 500;
		font-size: 1.4rem;
		line-height: 20px;
		color: #424242;
		opacity: 0.8;
		animation: blinkingText 2s infinite;
	}
`;

function PosiStopChart({ data } = {}) {
	const {
		flag,
		blockPosition,
		percentOfTravel,
		drawworksRpm,
		warnLight,
		limitLight,
		posiSwitch,
		bulb,
		encoder,
		lastCalibrationDate,
	} = data;

	let activeFlag = true;
	// const testFlag = '5';
	const activeBanner = flag === '0';
	switch (flag) {
		case '0':
		case '9':
			activeFlag = false;
			break;
		case '5':
			activeFlag = false;
			break;
		default:
			activeFlag = true;
			break;
	}

	// # Missing Feature => 2. Posi-Stop Box display needs to display grayed out when Off
	if (posiSwitch === 'off') {
		activeFlag = false;
	}

	return (
		<Wrapper>
			<div className="pchart-system">
				<div className="d-flex align-items-center gap-2 mb-2">
					<Logo className="w-16 h-16" />
					<h3 className="pos-title">Block Limit Control System</h3>
				</div>
				{activeBanner && (
					<DisconnectedBanner className="d-flex justify-content-center align-items-center w-100 gap-2 mb-2">
						<div className="dot" />
						<span className="banner">Disconnected</span>
					</DisconnectedBanner>
				)}
				<div className="d-flex">
					<div className="blockpos-container">
						<h4 className="pchart-title text-center">
							BLOCK <br /> POSITION
						</h4>
						<div className={`blockpos-chart ${activeFlag ? 'active' : ''}`}>
							<div
								className="filler"
								style={{ bottom: `${((230 - 20) / 100) * blockPosition}px` }}
							/>
						</div>
					</div>

					<div className="right-area">
						<div className="percentofchart-container">
							<h4 className="pchart-title text-center">PERCENT OF TRAVEL</h4>
							<div
								className={`percentofchart-chart ${activeFlag ? 'active' : ''}`}
							>
								{percentOfTravel ?? '-'}
							</div>
						</div>
						<div className="rpmchart-container mt-4 text-center">
							<h4 className="pchart-title text-center bordered">
								DRAWWORKS RPM
							</h4>

							<div className="rpmchart-chart text-center flex justify-center">
								<Clock value={drawworksRpm} prevVal={0} />
							</div>
							<span className="fs-6 mb-3 text-center encoder-span">
								ENCODER: {Math.abs(encoder)}
							</span>
							<br />
							<span className="mb-3 text-center" style={{ fontSize: '12px' }}>
								Last Calibration: {lastCalibrationDate}
							</span>
						</div>
					</div>
				</div>
				<div className="bottom-lights">
					<div className="pchart-bulb">
						<h4 className="pchart-title text-center">WARNING</h4>
						<div className={`warning-bulb ${warnLight ? 'active' : ''}`} />
						{/* add active class to light the bulb */}
					</div>
					<div className="pchart-bulb">
						<h4 className="pchart-title text-center">LIMIT</h4>
						<div className={`limit-bulb ${limitLight ? 'active' : ''}`} />
						{/* add active class to light the bulb */}
					</div>

					<SwitchDial $status={posiSwitch}>
						{/* status : on, off, set */}
						<span className="off">OFF</span>
						<span className="on">ON</span>
						<span className="set">SET</span>
						<Image
							src="/images/posiSwitch.png"
							width={45}
							alt="Posi-Switch"
							title="posiSwitch"
						/>
					</SwitchDial>
				</div>
			</div>
			<div className="pchart-lights d-flex justify-content-around p-2">
				{['BOP', 'INTERLOCK', 'ROD MANUAL', 'ROD AUTO'].map((item, index) => (
					<LightBulb on={bulb.includes(index) ? 1 : 0} key={item}>
						{/* just add on attr to light the bulb */}
						<div className="bulb" />
						<span className="title">{item}</span>
					</LightBulb>
				))}
			</div>
		</Wrapper>
	);
}

export default PosiStopChart;
