import { createGlobalStyle } from 'styled-components';

export const GlobalTable = createGlobalStyle`
    .table-primary {
        border: none;
        tr {   
            th {
                padding: 0.75rem 1.125rem;         
                background: #F3F3F3;
                border: none;
                text-transform: uppercase;
                color: #818181;
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 1rem;
            }
            
            td {
                padding: 0.75rem 1.125rem;         
                background: transparent;
                border-bottom: 1px solid #F3F3F3;
                font-size: 0.875rem;
                vertical-align: middle;
            }
        }

        tbody, thead {
            border: none !important;
        }
    }
`;
