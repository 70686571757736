import styled from 'styled-components';

export const UsersContainer = styled.div`
	/* margin: ${(props) =>
		props.$istablet || props.$ismobile
			? '1rem 1rem'
			: '2rem 2rem 2rem 18rem;'}; */

	.delete {
		button {
			all: unset;
		}
		color: #e04321 !important;
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 400;
		font-size: 1rem;
		cursor: pointer;
	}
	.sortable-header-column {
		font-size: 1rem;
	}
	.mobile-sortby-view {
		.sortable-header-column {
			font-size: 1rem;
		}
		.more-actions {
			padding-bottom: 0.5rem;
			padding-right: 0.5rem;
			width: auto;
			margin-top: 0.5rem;
			padding-left: 0;
			&:hover,
			&:focus,
			&:active {
				background: transparent;
			}
			.dropdown-toggle {
				border: 1px solid ${({ theme }) => theme.colors.gray2};
				padding: 0.5rem 0.75rem;
			}
		}
		.sort-by-text {
			width: auto;
		}
		.sorting-icon-container {
			gap: 0.1rem;
			.sorting-up {
				transform: rotate(180deg);
			}
		}
	}

	.more-actions {
		button {
			background: transparent;
			border: none;
			transition: all 0s;
			box-shadow: none;
			border-color: transparent;

			&:after {
				display: none;
			}

			&:hover,
			&:active,
			&:focus {
				border: none;
				box-shadow: none;
				background: transparent;
				border-color: transparent;
			}
		}

		.dropdown-menu {
			transform: translate3d(-30px, -50px, 0px) !important;
			box-shadow: 0px 4px 33px rgba(0, 0, 0, 0.07);
			border-radius: 8px;
		}

		@media (max-width: 425px) {
			.dropdown-menu {
				transform: translate3d(0px, 50px, 0px) !important;
				border: none;
				box-shadow: 0px 4px 33px rgba(0, 0, 0, 0.07);
				border-radius: 8px;
			}
		}
	}

	.show {
		.more-actions {
			button {
				background: #3c3c3c;
			}
		}
	}

	tr {
		vertical-align: middle;
	}

	.project-id {
		color: #00834d !important;
	}

	h1 {
		margin-bottom: 1rem;
		font-weight: 700;
		font-family: 'Poppins', sans-serif;
		font-size: 1.75rem;
		margin-top: 0.5rem;
	}
`;
