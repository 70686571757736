import { Link } from 'react-router-dom';
import {
	WarningButton,
	SuccessButton,
	DeactiveButton,
} from 'style/GlobalStyles';
import { ReactComponent as EngineIcon } from 'assets/img/engine.svg';
import TooltipHandler from 'components/TooltipHandler';

/**
 * Checks if a rig is offline based on the status information provided.
 *
 * @param {object} rigDetail - The rig detail object containing the status information.
 * @returns {boolean} - True if the rig is offline, false otherwise.
 */
function isRigOffline(rigDetail) {
	return (
		rigDetail?.status?.status?.value === 'Offline' ||
		rigDetail?.status?.status?.message === 'Offline' ||
		rigDetail?.status?.status?.value === 'offline' ||
		rigDetail?.status?.status?.message === 'off'
	);
}

// Active / Not-Active / Offline
function status(eqItem) {
	const equipmentSignalStrength = `(${parseInt(
		eqItem?.status?.signal_strength,
		10,
	)})`;
	if (
		eqItem?.status?.value === 'Not Activated' ||
		eqItem?.status?.value === null
	)
		return <DeactiveButton>Not Activated</DeactiveButton>;
	if (eqItem?.status?.message === 'Offline')
		return <WarningButton>{eqItem?.status?.message}</WarningButton>;

	if (eqItem?.status?.signal_value.includes('Excellent'))
		return (
			<SuccessButton style={{ background: '#99FFF9' }}>
				{eqItem?.status?.signal_value} {equipmentSignalStrength}
			</SuccessButton>
		);

	if (eqItem?.status?.signal_value.includes('Very Good'))
		return (
			<SuccessButton style={{ background: '#9EFFD6' }}>
				{eqItem?.status?.signal_value} {equipmentSignalStrength}
			</SuccessButton>
		);

	if (
		eqItem?.status?.signal_value.includes('Bad') ||
		eqItem?.status?.signal_value.includes('Worst')
	)
		return (
			<SuccessButton style={{ background: '#FFDBDB', color: '#CD5555' }}>
				{eqItem?.status?.signal_value} {equipmentSignalStrength}
			</SuccessButton>
		);

	return (
		<SuccessButton style={{ background: '#F3FFD0' }}>
			{eqItem?.status?.message}
		</SuccessButton>
	);
}

// Equipment type Rig/Pump
function type(eqItem) {
	let eqType = '';
	switch (eqItem.type) {
		case 'pump':
			eqType = 'P';
			break;
		case 'rig':
			eqType = 'R';
			break;
		default:
			eqType = 'R';
			break;
	}
	return eqType;
}

// Camera Status
function cameraStatus(eqItem, mobile) {
	if (eqItem?.camera?.status === 'Online' && eqItem.type === 'rig') {
		return (
			<Link to={`/rigs/${eqItem.serial}/camera`}>
				<SuccessButton style={{ cursor: 'pointer' }}>
					{mobile ? `Camera ${eqItem?.camera?.status}` : eqItem?.camera?.status}
				</SuccessButton>
			</Link>
		);
	}
	if (eqItem?.camera?.status === 'Offline')
		return (
			<WarningButton>
				{mobile ? `Camera ${eqItem?.camera?.status}` : eqItem?.camera?.status}
			</WarningButton>
		);
	if (
		(!mobile && eqItem?.camera?.status !== 'Offline') ||
		eqItem?.camera?.status !== 'Online'
	) {
		return <DeactiveButton>Not Available</DeactiveButton>;
	}

	return null;
}

const equipmentCarrier = (eqItem) => {
	const canEnabled = eqItem?.engine?.value;
	const styles = {
		width: 25,
		height: 25,
	};

	if (canEnabled) {
		return (
			<TooltipHandler
				content={
					<h6 style={{ fontSize: '0.8rem' }}>
						Speed: {eqItem.vehicle?.speed} {eqItem.vehicle?.unit}
					</h6>
				}
			>
				<Link to={`/rigs/${eqItem.serial}/engine`}>
					{eqItem.vehicle.status} <EngineIcon style={styles} />
				</Link>
			</TooltipHandler>
		);
	}
	return eqItem.vehicle.status;
};

const posiStopStatus = (eqItem) => {
	const htmlColor = eqItem.status?.color;
	const message = eqItem.status?.shortmessage;
	if (htmlColor === '#d62727') {
		return (
			<td style={{ color: htmlColor }}>
				<strong>{message}</strong>
			</td>
		);
	}
	return <td style={{ color: htmlColor }}>{message}</td>;
};

const activeStatus = (active, eqStatus) => {
	if (active === 'Yes' && eqStatus === 'Not Calibrated') {
		return (
			<td style={{ color: '#d62727' }}>
				<strong>{active}</strong>
			</td>
		);
	}
	return <td style={{ color: '#000000' }}>{active}</td>;
};

export {
	status,
	type,
	cameraStatus,
	equipmentCarrier,
	posiStopStatus,
	activeStatus,
	isRigOffline,
};
